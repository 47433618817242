import React from "react";
import { Cookies } from "react-cookie";
import {
  Div,
  Divider,
  FlexColumn,
  FlexRow,
  Spacer,
} from "../../../core/Containers";
import { NavbarMain } from "../../../components/Navigation/NavbarMain";
import { TopNavBar } from "../../../components/Navigation/TopNavBar";
import { LBCModal } from "../../../components/Modals/LBCModal";
import { useLocation, useNavigate } from "react-router-dom";
import { RawDropdown } from "../../../core/Buttons";
import { RecurrenceOptions } from "../../../constants/Dropdowns";
import { Text } from "../../../core/Text";
import { Tab } from "@headlessui/react";
import { ImSpinner2 } from "react-icons/im";
import { classNames } from "../../../helpers/ClassNames";
import moment from "moment";
import { Pagination } from "../../../components/Tables/Pagination";
import { RemittanceCorpDataRow } from "../../../components/Tables/ReportsDataRow";
import { RemittanceCorpColumns } from "../../../constants/ConstantsData";
import axiosCAPApi from "../../../api/axiosCAPApi";
import axiosCAPApiPrivate from "../../../api/axiosCAPApiPrivate";

const cookies = new Cookies();

export const RemittanceStatusReport = ({ shipperAcct }) => {
  const navigate = useNavigate();
  const { search } = useLocation();
  const query = new URLSearchParams(search);
  const actionQuery = query.get("option");

  const [selectedIndex, setSelectedIndex] = React.useState(0);
  const tabs = ["Remittance Report"];

  const [sortBy, setSort] = React.useState("SHIPMENTS BY LAST STATUS");
  const [timeline, setTimeline] = React.useState("Daily");

  const [loading, setLoading] = React.useState(false);
  const [selectedList, setSelectedList] = React.useState([]);

  const [currentPage, setCurrentPage] = React.useState(1);
  const [articlesPerPage] = React.useState(5);
  const indexOfLastArticle = currentPage * articlesPerPage;
  const indexOfFirstArticle = indexOfLastArticle - articlesPerPage;
  const [remittanceCorpList, setRemittanceCorpList] = React.useState([]);
  const newList = remittanceCorpList.slice(
    indexOfFirstArticle,
    indexOfLastArticle
  );

  shipperAcct = cookies.get("shipperAcct", { path: "/" })
    ? cookies.get("shipperAcct", { path: "/" })
    : "";

  const [shipperAcctNo, setShipperAcctNo] = React.useState(
    shipperAcct.split("|")[1].toString().trim()
  );
  const [dateFrom, setDateFrom] = React.useState(
    moment(new Date()).format("yyyy-MM-DD")
  );
  const [dateTo, setDateTo] = React.useState(
    moment(new Date()).format("yyyy-MM-DD")
  );

  const [isToastOpen, setToastOpen] = React.useState(false);
  const [toastKind, setToastKind] = React.useState("");
  const [toastTitle, setToastTitle] = React.useState("");
  const [toastDesc, setToastDesc] = React.useState("");

  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  React.useEffect(() => {
    if (shipperAcct) {
      var shipAcctNo = shipperAcct.split("|");
      setShipperAcctNo(shipAcctNo[1].toString().trim());
    }
  }, [shipperAcct]);

  const getGetRemittanceCorpDaily = async () => {
    setLoading(true);

    try {
      const response = await axiosCAPApiPrivate.post("/api/GetRemittanceCorpDaily", {
        CustomerAccountNo: shipperAcctNo,
        FromDate: dateFrom,
        ToDate: dateTo,
      });

      const data = response.data;

      if (data.Code === "200") {
        setLoading(false);
        setRemittanceCorpList(data.Data);
      }
      setLoading(false);
    } catch (error) {
      setToastKind("error");
      setToastTitle("Remittance Status Failed!");
      setToastDesc(error.message);
      setTimeout(() => {
        setLoading(false);
      }, 1000);
      setTimeout(() => {
        setToastOpen(true);
      }, 1500);
      setTimeout(() => {
        setToastOpen(false);
      }, 3000);
    }
  };

  const getRemittanceCorpSummaryForThreeMonths = async () => {
    setLoading(true);

    try {
      const response = await axiosCAPApiPrivate.post(
        "/api/GetRemittanceCorpSummaryForThreeMonths",
        {
          CustomerAccountNo: shipperAcctNo,
          ReportRange: timeline,
        }
      );

      const data = response.data;

      if (data.Code === "200") {
        setLoading(false);
        setRemittanceCorpList(data.Data);
      }
      setLoading(false);
    } catch (error) {
      setToastKind("error");
      setToastTitle("Remittance Status Failed!");
      setToastDesc(error.message);
      setTimeout(() => {
        setLoading(false);
      }, 1000);
      setTimeout(() => {
        setToastOpen(true);
      }, 1500);
      setTimeout(() => {
        setToastOpen(false);
      }, 3000);
    }
  };

  React.useEffect(() => {
    if (actionQuery === "0") {
      setSelectedIndex(0);
      navigate("/reports");
    } else if (actionQuery === "1") {
      setSelectedIndex(1);
      navigate("/reports");
    } else if (actionQuery === "2") {
      setSelectedIndex(2);
      navigate("/reports");
    }
  }, [actionQuery, navigate]);

  const GenerateRemittanceCorp = () => {
    if (timeline === "Monthly") getRemittanceCorpSummaryForThreeMonths();
    else if (timeline === "Daily") {
      getGetRemittanceCorpDaily();
    }
  };

  React.useEffect(() => {}, [timeline]);

  return (
    <FlexRow className="w-full h-full items-center justify-between">
      {/* Side Navigation */}
      <NavbarMain route="Home" />

      {/* Main Dashboard */}
      <FlexColumn className="w-11/12 h-full">
        {/* Top Navigation */}
        <TopNavBar showMenu={true} showNotifBell={true} showSearchBar={true} />

        {/* Body */}
        <Div className="w-full h-full overflow-y-auto p-10 bg-white">
          <FlexRow className="w-full">
            <Tab.Group
              selectedIndex={selectedIndex}
              onChange={setSelectedIndex}
            >
              <FlexColumn className="w-full">
                <Tab.List className="tabletWide:space-x-4">
                  {tabs.map((tab) => (
                    <Tab
                      className={({ selected }) =>
                        classNames(
                          "p-2 font-semibold w-auto focus:border-b-4 focus:border-orange-400 ring:border-orange-400 outline-none",
                          selected
                            ? "text-orange-400 border-b-4 border-orange-400"
                            : "text-secondary-200  hover:text-secondary-100"
                        )
                      }
                      key={tab}
                    >
                      {tab}
                    </Tab>
                  ))}
                </Tab.List>
                <LBCModal
                  description={toastDesc}
                  isOpen={isToastOpen}
                  onClose={() => setToastOpen(false)}
                  title={toastTitle}
                  toastKind={toastKind}
                />
                <Divider className="text-grey-400" />
                <Tab.Panels className="w-full">
                  <Tab.Panel className={`w-full`}>
                    {/* <RemittanceHistory /> */}
                    <Div className="w-full">
                      <Spacer className="w-10 h-10" />
                      <Div className="w-full border border-grey-400 rounded-lg shadow-lg">
                        <FlexRow className="w-full items-center justify-between py-5">
                          <FlexRow className="items-center">
                            <Div className="w-48 mx-2">
                              <RawDropdown
                                icon={<div />}
                                width="w-full"
                                border="border phone:border-grey-100"
                                value={timeline}
                                options={RecurrenceOptions}
                                onSelect={setTimeline}
                              />
                            </Div>
                            {timeline === "Daily" ? (
                              <FlexRow>
                                <input
                                  className="text-sm w-40 mx-2 block rounded-lg"
                                  type="date"
                                  value={dateFrom}
                                  onChange={(e) => setDateFrom(e.target.value)}
                                />
                                <input
                                  className="text-sm w-40 mx-2 block rounded-lg"
                                  type="date"
                                  value={dateTo}
                                  onChange={(e) => setDateTo(e.target.value)}
                                />
                              </FlexRow>
                            ) : (
                              <></>
                            )}

                            <Div
                              className={`text-white text-center font-semibold bg-red-400 hover:bg-opacity-80 rounded-lg p-2 inline-flex justify-center items-center w-40 ml-2 h-10`}
                              onClick={() => GenerateRemittanceCorp()}
                            >
                              Generate
                            </Div>
                          </FlexRow>
                        </FlexRow>
                        <Spacer className="w-10 h-10" />

                        <FlexRow className="w-full items-center justify-between py-2 border border-grey-400">
                          {RemittanceCorpColumns.map((column) => (
                            <Text
                              className={`text-secondary-100 text-sm text-center font-semibold px-4 py-2 ${"w-1/4"}`}
                            >
                              {column}
                            </Text>
                          ))}
                        </FlexRow>

                        {loading ? (
                          <Text className="text-red-400 text-center flex flex-row justify-center items-center my-10">
                            <ImSpinner2 className="animate-spin mr-2 text-2xl desktop:text-3xl" />
                          </Text>
                        ) : (
                          <Div className="w-full">
                            {newList.map((item) => (
                              <RemittanceCorpDataRow data={item} />
                            ))}
                          </Div>
                        )}
                      </Div>
                      <Spacer className="w-full h-10" />
                      <Div className="w-full">
                        <Pagination
                          currentPage={currentPage}
                          itemsPerPage={articlesPerPage}
                          paginate={paginate}
                          totalItems={remittanceCorpList.length}
                        />
                      </Div>
                    </Div>
                  </Tab.Panel>
                </Tab.Panels>
              </FlexColumn>
            </Tab.Group>
          </FlexRow>
        </Div>
      </FlexColumn>
    </FlexRow>
  );
};
