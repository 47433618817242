import React from "react";
import { CSVLink } from "react-csv";
import { Div, FlexRow, Spacer } from "../../../core/Containers";
import { Text } from "../../../core/Text";
import { ColumnHeader } from "../../../components/Tables";
import { Pagination } from "../../../components/Tables/Pagination";
import { useLocation, useNavigate } from "react-router-dom";
import { ProofOfDeliveryCorporateDataRow } from "../../../components/Tables/ReportsDataRow";
import { ImSpinner2 } from "react-icons/im";
import axiosCAPApi from "../../../api/axiosCAPApi";
import axiosCAPApiPrivate from "../../../api/axiosCAPApiPrivate";

export const ProofOfDeliveryCorporate = ({ trackingNos }) => {
  const navigate = useNavigate();
  const { search } = useLocation();
  const query = new URLSearchParams(search);
  const actionQuery = query.get("active");

  const [loading, setLoading] = React.useState(false);
  const [isAscending, setAscending] = React.useState(true);
  const [sortList, setSortList] = React.useState([] /*tempProofDeliveryData*/);

  const [singleView, setSingleView] = React.useState("default");
  const [selected, setSelected] = React.useState("default");
  const [selectedList, setSelectedList] = React.useState([]);

  const [currentPage, setCurrentPage] = React.useState(1);
  const [articlesPerPage] = React.useState(10);
  const indexOfLastArticle = currentPage * articlesPerPage;
  const indexOfFirstArticle = indexOfLastArticle - articlesPerPage;

  const newList = sortList.slice(indexOfFirstArticle, indexOfLastArticle);

  const [isToastOpen, setToastOpen] = React.useState(false);
  const [toastKind, setToastKind] = React.useState("");
  const [toastTitle, setToastTitle] = React.useState("");
  const [toastDesc, setToastDesc] = React.useState("");

  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  React.useEffect(() => {
    if (actionQuery === "single-view") {
      //
    }
  }, [actionQuery]);

  React.useEffect(() => {
    if (trackingNos !== "") {
      getProofOfDeliveryCorporate();
    }
  }, [trackingNos]);

  const getProofOfDeliveryCorporate = async () => {
    setLoading(true);
    var data = {
      TrackingNo: trackingNos,
    };

    try {
      const response = await axiosCAPApiPrivate.post(
        "/api/GetProofOfDeliveryCorporate",
        {
          TrackingNo: trackingNos,
        }
      );

      const data = response.data;

      if (data.Code === "200") {
        setLoading(false);
        setSortList(data.Data);
      }
      setLoading(false);
    } catch (error) {
      setToastKind("error");
      setToastTitle("Proof Of Delivery Report Failed!");
      setToastDesc(error.message);
      setTimeout(() => {
        setLoading(false);
      }, 1000);
      setTimeout(() => {
        setToastOpen(true);
      }, 1500);
      setTimeout(() => {
        setToastOpen(false);
      }, 3000);
    }
  };

  const onSelectItems = (id) => {
    if (selectedList.includes(id)) {
      const tempList = [...selectedList].filter((value) => value !== id);
      setSelectedList(tempList);
    } else {
      const tempList = [...selectedList];
      tempList.push(id);
      setSelectedList(tempList);
    }
  };

  const onSort = async (column) => {
    setLoading(true);
    if (isAscending) {
      if (column === 0) {
        const tempList = await sortList.sort((a, b) => {
          if (a.TransactionDate < b.TransactionDate) {
            return -1;
          }
          if (a.TransactionDate > b.TransactionDate) {
            return 1;
          }
          return 0;
        });
        setSortList(tempList);
      } else if (column === 1) {
        const tempList = await sortList.sort((a, b) => {
          if (a.Customer < b.Customer) {
            return -1;
          }
          if (a.Customer > b.Customer) {
            return 1;
          }
          return 0;
        });
        setSortList(tempList);
      } else if (column === 2) {
        const tempList = await sortList.sort((a, b) => {
          if (a.DeliveryStatus < b.DeliveryStatus) {
            return -1;
          }
          if (a.DeliveryStatus > b.DeliveryStatus) {
            return 1;
          }
          return 0;
        });
        setSortList(tempList);
      } else if (column === 3) {
        const tempList = await sortList.sort((a, b) => {
          if (a.TrackingNo < b.TrackingNo) {
            return -1;
          }
          if (a.TrackingNo > b.TrackingNo) {
            return 1;
          }
          return 0;
        });
        setSortList(tempList);
      }
      setTimeout(() => {
        setLoading(false);
        setAscending(!isAscending);
      }, 1000);
    } else {
      if (column === 0) {
        const tempList = await sortList.sort((a, b) => {
          if (a.TransactionDate > b.TransactionDate) {
            return -1;
          }
          if (a.TransactionDate < b.TransactionDate) {
            return 1;
          }
          return 0;
        });
        setSortList(tempList);
      } else if (column === 1) {
        const tempList = await sortList.sort((a, b) => {
          if (a.Customer > b.Customer) {
            return -1;
          }
          if (a.Customer < b.Customer) {
            return 1;
          }
          return 0;
        });
        setSortList(tempList);
      } else if (column === 2) {
        const tempList = await sortList.sort((a, b) => {
          if (a.DeliveryStatus > b.DeliveryStatus) {
            return -1;
          }
          if (a.DeliveryStatus < b.DeliveryStatus) {
            return 1;
          }
          return 0;
        });
        setSortList(tempList);
      } else if (column === 3) {
        const tempList = await sortList.sort((a, b) => {
          if (a.TrackingNo > b.TrackingNo) {
            return -1;
          }
          if (a.TrackingNo < b.TrackingNo) {
            return 1;
          }
          return 0;
        });
        setSortList(tempList);
      }
      setTimeout(() => {
        setLoading(false);
        setAscending(!isAscending);
      }, 1000);
    }
  };

  return (
    <>
      <FlexRow className="w-full items-center justify-end py-5"></FlexRow>
      <Div className="w-full">
        <Div className="w-full border border-grey-400 rounded-lg shadow-lg">
          <FlexRow className="w-full items-center justify-between py-2 border border-grey-400">
            <Spacer />
            <ColumnHeader
              title="TRANSACTION DATE"
              onClick={() => onSort(0)}
              containerClass="hover:bg-grey-400 rounded-full w-15p px-4 py-2"
              titleClassName=""
            />
            <ColumnHeader
              title="CLIENT"
              onClick={() => onSort(1)}
              containerClass="hover:bg-grey-400 rounded-full w-15p px-4 py-2"
              titleClassName=""
            />
            <ColumnHeader
              title="DELIVERY STATUS"
              onClick={() => onSort(2)}
              containerClass="hover:bg-grey-400 rounded-full w-15p px-4 py-2"
              titleClassName=""
            />
            <ColumnHeader
              title="TRACKING NUMBER"
              onClick={() => onSort(3)}
              containerClass="hover:bg-grey-400 rounded-full w-15p px-4 py-2"
              titleClassName=""
            />
            <ColumnHeader
              title=""
              // containerClass="hover:bg-grey-400 rounded-full w-15p px-4 py-2"
              titleClassName=""
            />
          </FlexRow>
          {loading ? (
            <Text className="text-red-400 text-center flex flex-row justify-center items-center my-20">
              <ImSpinner2 className="animate-spin mr-2 text-2xl desktop:text-3xl" />
              Loading data ...
            </Text>
          ) : (
            <Div className="w-full">
              {newList.map((item) => (
                <ProofOfDeliveryCorporateDataRow
                  data={item}
                  list={selectedList}
                  onSelect={onSelectItems}
                />
              ))}
            </Div>
          )}
        </Div>
        <Spacer className="w-full h-10" />
        <Div className="w-full">
          <Pagination
            currentPage={currentPage}
            itemsPerPage={articlesPerPage}
            paginate={paginate}
            totalItems={sortList.length}
          />
        </Div>
      </Div>
    </>
  );
};
