import React from 'react';
import { Div, Divider, FlexRow } from '../../core/Containers';
import { Text } from '../../core/Text';

export const PerformanceDataRow = ({
  TrackingNo,
  Content,
  TransactionDate,
  ShipperName,
  ShipperAddress,
  Consignee,
  ConsigneeAddress,
  Status,
  DatePosted
}) => (
  <>
    <FlexRow className="items-center justify-between w-full">
      <Div className="w-1/4 px-2">
        <Text className="text-red-400 text-xs font-semibold">{TrackingNo}</Text>
        <Text className="text-secondary-200 text-xs">{Content}</Text>
        <Text className="text-secondary-200 text-xs">{TransactionDate}</Text>
      </Div>
      <Div className="w-1/4 px-2">
        <Text className="text-secondary-100 text-xs font-semibold">
          {ShipperName}
        </Text>
        <Text className="text-secondary-200 text-xs">{ShipperAddress}</Text>
      </Div>
      <Div className="w-1/4 px-2">
        <Text className="text-secondary-100 text-xs font-semibold">
          {Consignee}
        </Text>
        <Text className="text-secondary-200 text-xs">{ConsigneeAddress}</Text>
      </Div>
      <Div className="w-1/4 px-2">
        <Text className="text-orange-400 text-xs font-semibold">
          {Status.toUpperCase()}
        </Text>
        <Text className="text-orange-400 text-xs">{DatePosted}</Text>
      </Div>
    </FlexRow>
    <Divider className="text-grey-400 my-5" />
  </>
);
