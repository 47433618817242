import React from "react";
import Select, { createFilter } from "react-select";

export const RawSelect = ({
  id,
  name,
  label,
  placeholder,
  containerClass,
  className,
  classNamePrefix,
  isDisabled,
  onChange,
  value,
  maxMenuHeight,
  minHeight,
  options,
  filterOption,
  validation,
  props,
}) => {
  return (
    <div className={`${containerClass}${isDisabled ? " cursor-no-drop" : ""}`}>
      <Select
        id={id}
        name={name}
        label={label}
        placeholder={placeholder}
        className={`${className}${validation ? " react-select-error" : ""}`}
        classNamePrefix={classNamePrefix || ""}
        isDisabled={isDisabled || false}
        onChange={(value) => onChange(value)}
        value={value}
        maxMenuHeight={maxMenuHeight}
        minHeight={minHeight}
        options={options}
        filterOption={createFilter(filterOption)}
        {...props}
      />
      {validation && (
        <p className="mt-1 pl-2 text-xs text-left text-red-400 dark:text-red-400">
          {validation}
        </p>
      )}
    </div>
  );
};
