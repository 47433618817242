import React from "react";
import {
  Div,
  Divider,
  FlexColumn,
  FlexRow,
  Spacer,
} from "../../../core/Containers";
import { Text } from "../../../core/Text";
import { LBCModal } from "../../../components/Modals/LBCModal";
import { NavbarMain } from "../../../components/Navigation/NavbarMain";
import { TopNavBar } from "../../../components/Navigation/TopNavBar";
import { ColumnHeader } from "../../../components/Tables/ColumnHeader";
import {
  DeliveryDataRow,
  DeliveryDataTableRow,
} from "../../../components/Tables/DeliveryDataRow";
import { Pagination } from "../../../components/Tables/Pagination";
import { useSearchParams } from "react-router-dom";
import { ImSpinner2 } from "react-icons/im";
import * as FileSaver from "file-saver";
import axiosCAPApi from "../../../api/axiosCAPApi";
import axiosCAPApiPrivate from "../../../api/axiosCAPApiPrivate";

export const DashboardRemittanceCorpTableData = ({ shipperAcct }) => {
  const [loading, setLoading] = React.useState(true);
  const [isAscending, setAscending] = React.useState(true);
  const [sortList, setSortList] = React.useState([] /*tempDeliveryTableData*/);
  const [RemittedExcelList, setRemittedExcelList] = React.useState([]);
  const [UnRemittedExcelList, setUnRemittedExcelList] = React.useState([]);

  const [currentPage, setCurrentPage] = React.useState(1);
  const [articlesPerPage] = React.useState(5);
  const indexOfLastArticle = currentPage * articlesPerPage;
  const indexOfFirstArticle = indexOfLastArticle - articlesPerPage;

  const [shipperAcctNo, setShipperAcctNo] = React.useState("");

  const [isToastOpen, setToastOpen] = React.useState(false);
  const [toastKind, setToastKind] = React.useState("");
  const [toastTitle, setToastTitle] = React.useState("");
  const [toastDesc, setToastDesc] = React.useState("");

  const newItems = sortList.slice(indexOfFirstArticle, indexOfLastArticle);

  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  const [searchParams, setSearchParams] = useSearchParams();

  // React.useEffect(() => {
  //   if (shipperAcct) {
  //     var shipAcctNo = shipperAcct.split("|");
  //     setShipperAcctNo(shipAcctNo[1].toString().trim());
  //   }
  // }, [shipperAcct]);

  React.useEffect(() => {
    var shipAcctNo = shipperAcct.split("|");
    if (shipperAcct !== "") {
      setShipperAcctNo(shipAcctNo[1].toString().trim());
    }
  }, [shipperAcct]);

  const onSort = async (column) => {
    setLoading(true);
    if (isAscending) {
      if (column === 0) {
        const tempList = await sortList.sort((a, b) => {
          if (a.tracking < b.tracking) {
            return -1;
          }
          if (a.tracking > b.tracking) {
            return 1;
          }
          return 0;
        });
        setSortList(tempList);
      } else if (column === 1) {
        const tempList = await sortList.sort((a, b) => {
          if (a.encodedDate < b.encodedDate) {
            return -1;
          }
          if (a.encodedDate > b.encodedDate) {
            return 1;
          }
          return 0;
        });
        setSortList(tempList);
      } else if (column === 2) {
        const tempList = await sortList.sort((a, b) => {
          if (a.deliveryReq < b.deliveryReq) {
            return -1;
          }
          if (a.deliveryReq > b.deliveryReq) {
            return 1;
          }
          return 0;
        });
        setSortList(tempList);
      } else if (column === 3) {
        const tempList = await sortList.sort((a, b) => {
          if (a.consigneeName < b.consigneeName) {
            return -1;
          }
          if (a.consigneeName > b.consigneeName) {
            return 1;
          }
          return 0;
        });
        setSortList(tempList);
      } else if (column === 4 || column === 6) {
        const tempList = await sortList.sort((a, b) => {
          if (a.consigneeContact < b.consigneeContact) {
            return -1;
          }
          if (a.consigneeContact > b.consigneeContact) {
            return 1;
          }
          return 0;
        });
        setSortList(tempList);
      } else if (column === 5) {
        const tempList = await sortList.sort((a, b) => {
          if (a.consigneeAddress < b.consigneeAddress) {
            return -1;
          }
          if (a.consigneeAddress > b.consigneeAddress) {
            return 1;
          }
          return 0;
        });
        setSortList(tempList);
      } else if (column === 7) {
        const tempList = await sortList.sort((a, b) => {
          if (a.acceptanceDate < b.acceptanceDate) {
            return -1;
          }
          if (a.acceptanceDate > b.acceptanceDate) {
            return 1;
          }
          return 0;
        });
        setSortList(tempList);
      } else if (column === 8) {
        const tempList = await sortList.sort((a, b) => {
          if (a.status < b.status) {
            return -1;
          }
          if (a.status > b.status) {
            return 1;
          }
          return 0;
        });
        setSortList(tempList);
      } else if (column === 9) {
        const tempList = await sortList.sort((a, b) => {
          if (a.statusDate < b.statusDate) {
            return -1;
          }
          if (a.statusDate > b.statusDate) {
            return 1;
          }
          return 0;
        });
        setSortList(tempList);
      } else if (column === 10) {
        const tempList = await sortList.sort((a, b) => {
          if (a.reason < b.reason) {
            return -1;
          }
          if (a.reason > b.reason) {
            return 1;
          }
          return 0;
        });
        setSortList(tempList);
      }
      setTimeout(() => {
        setLoading(false);
        setAscending(!isAscending);
      }, 1000);
    } else {
      if (column === 0) {
        const tempList = await sortList.sort((a, b) => {
          if (a.tracking > b.tracking) {
            return -1;
          }
          if (a.tracking < b.tracking) {
            return 1;
          }
          return 0;
        });
        setSortList(tempList);
      } else if (column === 1) {
        const tempList = await sortList.sort((a, b) => {
          if (a.encodedDate > b.encodedDate) {
            return -1;
          }
          if (a.encodedDate < b.encodedDate) {
            return 1;
          }
          return 0;
        });
        setSortList(tempList);
      } else if (column === 2) {
        const tempList = await sortList.sort((a, b) => {
          if (a.deliveryReq > b.deliveryReq) {
            return -1;
          }
          if (a.deliveryReq < b.deliveryReq) {
            return 1;
          }
          return 0;
        });
        setSortList(tempList);
      } else if (column === 3) {
        const tempList = await sortList.sort((a, b) => {
          if (a.consigneeName > b.consigneeName) {
            return -1;
          }
          if (a.consigneeName < b.consigneeName) {
            return 1;
          }
          return 0;
        });
        setSortList(tempList);
      } else if (column === 4 || column === 6) {
        const tempList = await sortList.sort((a, b) => {
          if (a.consigneeContact > b.consigneeContact) {
            return -1;
          }
          if (a.consigneeContact < b.consigneeContact) {
            return 1;
          }
          return 0;
        });
        setSortList(tempList);
      } else if (column === 5) {
        const tempList = await sortList.sort((a, b) => {
          if (a.consigneeAddress > b.consigneeAddress) {
            return -1;
          }
          if (a.consigneeAddress < b.consigneeAddress) {
            return 1;
          }
          return 0;
        });
        setSortList(tempList);
      } else if (column === 7) {
        const tempList = await sortList.sort((a, b) => {
          if (a.acceptanceDate > b.acceptanceDate) {
            return -1;
          }
          if (a.acceptanceDate < b.acceptanceDate) {
            return 1;
          }
          return 0;
        });
        setSortList(tempList);
      } else if (column === 8) {
        const tempList = await sortList.sort((a, b) => {
          if (a.status > b.status) {
            return -1;
          }
          if (a.status < b.status) {
            return 1;
          }
          return 0;
        });
        setSortList(tempList);
      } else if (column === 9) {
        const tempList = await sortList.sort((a, b) => {
          if (a.statusDate > b.statusDate) {
            return -1;
          }
          if (a.statusDate < b.statusDate) {
            return 1;
          }
          return 0;
        });
        setSortList(tempList);
      } else if (column === 10) {
        const tempList = await sortList.sort((a, b) => {
          if (a.reason > b.reason) {
            return -1;
          }
          if (a.reason < b.reason) {
            return 1;
          }
          return 0;
        });
        setSortList(tempList);
      }
      setTimeout(() => {
        setLoading(false);
        setAscending(!isAscending);
      }, 1000);
    }
  };

  const GetRemittanceReportDetail = async () => {
    setLoading(true);
    var status = searchParams.get("remittanceStatus");

    try {
      const response = await axiosCAPApiPrivate.post(
        "/api/GetRemittanceReportDetail",
        {
          CustomerAccountNo: shipperAcctNo,
          Column: status,
          FromDate: searchParams.get("dateFrom"),
          ToDate: searchParams.get("dateTo"),
        }
      );

      const data = response.data;

      if (data.Code === "200") {
        setSortList(data.Data);
      }
      setLoading(false);
    } catch (error) {
      setToastKind("error");
      setToastTitle("Remittance Report Failed!");
      setToastDesc(error.message);
      setTimeout(() => {
        setLoading(false);
      }, 1000);
      setTimeout(() => {
        setToastOpen(true);
      }, 1500);
      setTimeout(() => {
        setToastOpen(false);
      }, 3000);
    }
  };

  // React.useEffect(() => {
  //   if(shipperAcctNo)
  //     GetRemittanceReportDetail();
  // }, [shipperAcctNo]);

  React.useEffect(() => {
    if (shipperAcctNo) {
      GetRemittanceReportDetail();

      GetRemittedExcelDetail();
      GetUnRemittedExcelDetail();
    }
  }, [shipperAcctNo]);

  const GetRemittedExcelDetail = async () => {
    try {
      const response = await axiosCAPApiPrivate.post(
        "/api/GetRemittanceReportDetail",
        {
          CustomerAccountNo: shipperAcctNo,
          Column: "CorpRemitted",
          FromDate: searchParams.get("dateFrom"),
          ToDate: searchParams.get("dateTo"),
        }
      );

      const data = response.data;

      if (data.Code === "200") {
        setRemittedExcelList(data.Data);
      }
    } catch (error) {}
  };

  const GetUnRemittedExcelDetail = async () => {
    const data = {
      CustomerAccountNo: shipperAcctNo,
      Column: "CorpUnRemitted",
      FromDate: searchParams.get("dateFrom"),
      ToDate: searchParams.get("dateTo"),
    };

    try {
      const response = await axiosCAPApiPrivate.post(
        "/api/GetRemittanceReportDetail",
        {
          CustomerAccountNo: shipperAcctNo,
          Column: "CorpUnRemitted",
          FromDate: searchParams.get("dateFrom"),
          ToDate: searchParams.get("dateTo"),
        }
      );

      const data = response.data;

      if (data.Code === "200") {
        setUnRemittedExcelList(data.Data);
      }
    } catch (error) {}
  };

  const exportToCSV = () => {
    const Excel = require("exceljs");
    const workbook = new Excel.Workbook();

    //#region Remitted Report
    const remittedReport = workbook.addWorksheet("Remitted");
    remittedReport.columns = [
      { header: "Tracking No.", key: "tracking", width: 15 },
      { header: "Encoded Date", key: "encodedDate", width: 20 },
      { header: "Delivery Requirement", key: "deliveryReq", width: 25 },
      { header: "Consignee Name", key: "consigneeName", width: 35 },
      { header: "Consignee Mobile", key: "consigneeContact", width: 18 },
      { header: "Consignee Address", key: "consigneeAddress", width: 50 },
      { header: "Consignee Mobile No.", key: "consigneeContact", width: 22 },
      { header: "Acceptance Date", key: "acceptanceDate", width: 20 },
      { header: "Last Status", key: "status", width: 30 },
      { header: "Last Status Date", key: "statusDate", width: 15 },
      { header: "Reason", key: "reason", width: 30 },
    ];

    remittedReport.insertRow(1, "");
    remittedReport.insertRow(2, "");
    remittedReport.getCell("A2").value = "REMITTED";
    remittedReport.getCell("A2").font = { bold: true };
    remittedReport.insertRow(3, "");
    remittedReport.mergeCells("A2:B2");
    remittedReport.getRow(4).font = { bold: true };
    addBorder(remittedReport, 4);

    var row = 5;
    RemittedExcelList.map((list, index) => {
      remittedReport.addRow({
        tracking: list.tracking,
        encodedDate: list.encodedDate,
        deliveryReq: list.deliveryReq,
        consigneeName: list.consigneeName,
        consigneeContact: list.consigneeContact,
        consigneeAddress: list.consigneeAddress,
        consigneeContact: list.consigneeContact,
        acceptanceDate: list.acceptanceDate,
        status: list.status,
        statusDate: list.statusDate,
        reason: list.reason,
      });
      addBorder(remittedReport, row);
      row++;
    });
    //#endregion Remitted

    //#region Un-Remitted Report
    const unRemittedReport = workbook.addWorksheet("Un-Remitted");
    unRemittedReport.columns = remittedReport.columns;
    unRemittedReport.insertRow(1, "");
    unRemittedReport.insertRow(2, "");
    unRemittedReport.getCell("A2").value = "UN-REMITTED";
    unRemittedReport.getCell("A2").font = { bold: true };
    unRemittedReport.insertRow(3, "");
    unRemittedReport.mergeCells("A2:B2");
    unRemittedReport.getRow(4).font = { bold: true };
    addBorder(unRemittedReport, 4);

    var row = 5;
    UnRemittedExcelList.map((list, index) => {
      unRemittedReport.addRow({
        tracking: list.tracking,
        encodedDate: list.encodedDate,
        deliveryReq: list.deliveryReq,
        consigneeName: list.consigneeName,
        consigneeContact: list.consigneeContact,
        consigneeAddress: list.consigneeAddress,
        consigneeContact: list.consigneeContact,
        acceptanceDate: list.acceptanceDate,
        status: list.status,
        statusDate: list.statusDate,
        reason: list.reason,
      });
      addBorder(unRemittedReport, row);
      row++;
    });
    //#endregion Un-Remitted

    workbook.xlsx
      .writeBuffer()
      .then((buffer) =>
        FileSaver.saveAs(
          new Blob([buffer]),
          `${searchParams.get("dateFrom")} - ${searchParams.get("dateTo")}.xlsx`
        )
      )
      .catch((err) => console.log("Error writing excel export", err));
  };

  const addBorder = (sheet, row) => {
    sheet.getCell("A" + row).border = {
      top: { style: "thin" },
      left: { style: "thin" },
      bottom: { style: "thin" },
      right: { style: "thin" },
    };
    sheet.getCell("B" + row).border = {
      top: { style: "thin" },
      left: { style: "thin" },
      bottom: { style: "thin" },
      right: { style: "thin" },
    };
    sheet.getCell("C" + row).border = {
      top: { style: "thin" },
      left: { style: "thin" },
      bottom: { style: "thin" },
      right: { style: "thin" },
    };
    sheet.getCell("D" + row).border = {
      top: { style: "thin" },
      left: { style: "thin" },
      bottom: { style: "thin" },
      right: { style: "thin" },
    };
    sheet.getCell("E" + row).border = {
      top: { style: "thin" },
      left: { style: "thin" },
      bottom: { style: "thin" },
      right: { style: "thin" },
    };
    sheet.getCell("F" + row).border = {
      top: { style: "thin" },
      left: { style: "thin" },
      bottom: { style: "thin" },
      right: { style: "thin" },
    };
    sheet.getCell("G" + row).border = {
      top: { style: "thin" },
      left: { style: "thin" },
      bottom: { style: "thin" },
      right: { style: "thin" },
    };
    sheet.getCell("H" + row).border = {
      top: { style: "thin" },
      left: { style: "thin" },
      bottom: { style: "thin" },
      right: { style: "thin" },
    };
    sheet.getCell("I" + row).border = {
      top: { style: "thin" },
      left: { style: "thin" },
      bottom: { style: "thin" },
      right: { style: "thin" },
    };
    sheet.getCell("J" + row).border = {
      top: { style: "thin" },
      left: { style: "thin" },
      bottom: { style: "thin" },
      right: { style: "thin" },
    };
    sheet.getCell("K" + row).border = {
      top: { style: "thin" },
      left: { style: "thin" },
      bottom: { style: "thin" },
      right: { style: "thin" },
    };
  };

  return (
    <FlexRow className="w-full h-full items-center justify-between">
      {/* Side Navigation */}
      <NavbarMain route="Home" />

      {/* Main Dashboard */}
      <FlexColumn className="w-11/12 h-full">
        {/* Top Navigation */}
        <TopNavBar showMenu={true} showNotifBell={true} showSearchBar={true} />

        {/* Body */}
        <Div className="w-full h-full overflow-y-auto p-10 bg-white">
          <FlexRow className="justify-between w-full">
            <Div className="shadow-lg rounded-lg border border-grey-400 p-5 w-3/5 h-48">
              <Text className="text-secondary-100 text-xs font-bold">
                {searchParams.get("remittanceStatus").replace("Corp", "")} From{" "}
                {searchParams.get("dateFrom")} To {searchParams.get("dateTo")}
              </Text>
            </Div>
            <FlexColumn className="justify-between shadow-lg rounded-lg border border-grey-400 p-5 w-1/3 h-48">
              <Text className="text-secondary-100 text-xs font-bold">
                Summary per Remarks
              </Text>
              <Div className="overflow-scroll w-full h-28">
                {/* {tempDispostionData.map(list => (
                  <DeliveryDataRow
                    deliveryId={list.deliveryId}
                    deliverySize={list.deliverySize}
                    logisticsType={list.logisticsType}
                    deliveryDate={list.deliveryDate}
                    transactionDate={list.transactionDate}
                    clientNameFrom={list.clientNameFrom}
                    clientAddressFrom={list.clientAddressFrom}
                    clientNameTo={list.clientNameTo}
                    clientAddressTo={list.clientAddressTo}
                  />
                ))} */}
                <DeliveryDataRow
                  deliveryStatus={searchParams
                    .get("remittanceStatus")
                    .replace("Corp", "")}
                  count={searchParams.get("count")}
                  exportToCSV={exportToCSV}
                />
              </Div>
            </FlexColumn>
          </FlexRow>
          <Spacer className="h-10" />
          <FlexColumn className="w-full">
            <Div className="w-full overflow-x-scroll border border-grey-400 rounded-lg shadow-lg p-5">
              <FlexRow className="items-center justify-between w-2800px">
                <ColumnHeader
                  title="Tracking No."
                  onClick={() => onSort(0)}
                  containerClass="hover:bg-grey-400 rounded-full w-200px px-4 py-2"
                  titleClassName=""
                />
                <ColumnHeader
                  title="Encoded Date"
                  onClick={() => onSort(1)}
                  containerClass="hover:bg-grey-400 rounded-full w-200px px-4 py-2"
                  titleClassName=""
                />
                <ColumnHeader
                  title="Delivery Requirement"
                  onClick={() => onSort(2)}
                  containerClass="hover:bg-grey-400 rounded-full w-200px px-4 py-2"
                  titleClassName=""
                />
                <ColumnHeader
                  title="Consignee Name"
                  onClick={() => onSort(3)}
                  containerClass="hover:bg-grey-400 rounded-full w-200px px-4 py-2"
                  titleClassName=""
                />
                <ColumnHeader
                  title="Consignee Mobile"
                  onClick={() => onSort(4)}
                  containerClass="hover:bg-grey-400 rounded-full w-200px px-4 py-2"
                  titleClassName=""
                />
                <Div className="w-400px">
                  <ColumnHeader
                    title="Consignee Address"
                    onClick={() => onSort(5)}
                    containerClass="hover:bg-grey-400 rounded-full w-200px px-4 py-2 mx-auto"
                    titleClassName=""
                  />
                </Div>
                <ColumnHeader
                  title="Consignee Mobile No."
                  onClick={() => onSort(6)}
                  containerClass="hover:bg-grey-400 rounded-full w-200px px-4 py-2"
                  titleClassName=""
                />
                <ColumnHeader
                  title="Acceptance Date"
                  onClick={() => onSort(7)}
                  containerClass="hover:bg-grey-400 rounded-full w-200px px-4 py-2"
                  titleClassName=""
                />
                <ColumnHeader
                  title="Last Status"
                  onClick={() => onSort(8)}
                  containerClass="hover:bg-grey-400 rounded-full w-200px px-4 py-2"
                  titleClassName=""
                />
                <ColumnHeader
                  title="Last Status Date"
                  onClick={() => onSort(9)}
                  containerClass="hover:bg-grey-400 rounded-full w-200px px-4 py-2"
                  titleClassName=""
                />
                <ColumnHeader
                  title="Remarks"
                  onClick={() => onSort(10)}
                  containerClass="hover:bg-grey-400 rounded-full w-200px px-4 py-2"
                  titleClassName=""
                />
              </FlexRow>
              <Divider className="text-grey-100 my-2 w-2800px" />

              <LBCModal
                description={toastDesc}
                isOpen={isToastOpen}
                onClose={() => setToastOpen(false)}
                title={toastTitle}
                toastKind={toastKind}
              />

              {loading ? (
                <Text className="text-red-400 text-center flex flex-row justify-center items-center my-20">
                  <ImSpinner2 className="animate-spin mr-2 text-2xl desktop:text-3xl" />
                  Loading data ...
                </Text>
              ) : (
                <>
                  {newItems.map((list) => (
                    <DeliveryDataTableRow
                      data={list}
                      status={searchParams.get("remittanceStatus")}
                    />
                  ))}
                </>
              )}
            </Div>
            <Text className="text-red-400 text-xs font-bold my-2">
              {`Disclaimer: Coverage is Twelve (12) months from encoded date.`}
            </Text>
            <Div className="w-full">
              <Pagination
                currentPage={currentPage}
                itemsPerPage={articlesPerPage}
                paginate={paginate}
                totalItems={sortList.length}
              />
            </Div>
          </FlexColumn>
        </Div>
      </FlexColumn>
    </FlexRow>
  );
};
