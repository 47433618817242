import React from 'react';
import { Cookies } from "react-cookie";
import moment from "moment";
import Barcode from "react-barcode";
import QRCode from "qrcode.react";
import { FlexRow, Div, Spacer, FlexColumn } from '../../../core/Containers';
import { Text, Span } from '../../../core/Text';
import { Image } from '../../../core/Image';
import { ImInfo, ImSpinner2 } from 'react-icons/im';
import { useLocation } from 'react-router-dom';
import {
  PickupScheduleItem,
  PickupScheduleItemSummary,
} from '../../../components';
import {
  scheduledPickups,
  tempBorderColors,
  tempPickupScheduleData,
} from '../../../constants/TempData';
import { RawButton } from '../../../core/Buttons/RawButton';
import { PickUpScheduleCreate } from '../../../components/Modals/PickupScheduleModal';
import { HiCheckCircle } from 'react-icons/hi';
import { Button } from '../../../core/Buttons';
import { Images } from '../../../assets/images/images';
import { formatDate } from '../../../helpers/formatters';
import { BsChevronRight, BsChevronLeft } from 'react-icons/bs';
import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css';
import BookNow from "../../../services/BookNow.service";
import Auth from "../../../services/Auth.service";

const cookies = new Cookies();

export const SchedulePickUpTab = ({ shipperAcct }) => {
  const { search } = useLocation();
  const query = new URLSearchParams(search);
  const actionQuery = query.get('schedule');

  const [bookingNo, setBookingNo] = React.useState("");
  const [pickUpSchedule, setPickUpSchedule] = React.useState("");
  const [contactPerson, setContactPerson] = React.useState("");
  const [contactPersonNo, setContactPersonNo] = React.useState("");
  const [address, setAddress] = React.useState("");
  const [estVolumePerPickup, setEstVolumePerPickup] = React.useState("");
  const [specialInstruction, setSpecialInstruction] = React.useState("");

  const [loading, setLoading] = React.useState(true);
  const [loading1, setLoading1] = React.useState(true);
  const [isToastOpenA, setToastOpenA] = React.useState(false);
  const [isSingleView, setIsSingleView] = React.useState(false);

  const [currentDate, setDate] = React.useState(new Date());

  const [shipperAcctNo, setShipperAcctNo] = React.useState("");
  const [companyName, setCompanyName] = React.useState("");
  const [schedulePickupList, setSchedulePickupList] = React.useState([]);
  const [dailyBookings, setDailyBookings] = React.useState([]);

  const [isToastOpen, setToastOpen] = React.useState(false);
  const [toastKind, setToastKind] = React.useState("");
  const [toastTitle, setToastTitle] = React.useState("");
  const [toastDesc, setToastDesc] = React.useState("");

  React.useEffect(() => {
    if (shipperAcct) {
      var shipAcctNo = shipperAcct ? shipperAcct.split("|") : [];
      setCompanyName(shipAcctNo[0] ? shipAcctNo[0].toString().trim() : "");
      setShipperAcctNo(shipAcctNo[1] ? shipAcctNo[1].toString().trim() : "");
    }
  }, [shipperAcct]);

  React.useEffect(() => {
    if (actionQuery === 'updated') {
      setBookingNo(query.get("bookingNo"));
      setPickUpSchedule(query.get("pickUpSchedule"));
      setContactPerson(query.get("contactPerson"));
      setContactPersonNo(query.get("contactPersonNo"));
      setAddress(query.get("address"));
      setEstVolumePerPickup(query.get("estVolumePerPickup"));
      setSpecialInstruction(query.get("specialInstruction"));
      setIsSingleView(true);
    }
  }, [actionQuery]);

  // React.useEffect(() => {
  //   setLoading(true);
  //   setLoading1(true);
  //   setTimeout(() => {
  //     setLoading(false);
  //   }, 1000);
  //   setTimeout(() => {
  //     setLoading1(false);
  //   }, 1500);
  // }, [isSingleView, currentDate]);

  // const GetSchedulePickupList = () => {
  //   Auth.validateToken()
  //     .then(async (response) => {
  //       return await response.data;
  //     })
  //     .then((data) => {
  //       if (data.Code === "403") {
  //         Auth.refreshToken()
  //           .then(async (response) => {
  //             return await response.data;
  //           })
  //           .then(async (data) => {
  //             if (data.Code === "200") {
  //               const userInfo = cookies.get("userInfo");
  //               const newToken = data.Data;
  //               userInfo.token = newToken;
  //               cookies.set("userInfo", userInfo, { path: "/" });
  //               cookies.set("accessToken", newToken, { path: "/" });

  //               procGetSchedulePickupList();
  //             } else {
  //               setToastKind("error");
  //               setToastTitle("Schedule Pickup Failed!");
  //               setToastDesc(data.message);
  //               setTimeout(() => {
  //                 setLoading(false);
  //               }, 1000);
  //               setTimeout(() => {
  //                 setToastOpen(true);
  //               }, 1500);
  //               setTimeout(() => {
  //                 setToastOpen(false);
  //               }, 3000);
  //             }
  //           })
  //           .catch((error) => {
  //             setToastKind("error");
  //             setToastTitle("Schedule Pickup Failed!");
  //             setToastDesc(error.message);
  //             setTimeout(() => {
  //               setLoading(false);
  //             }, 1000);
  //             setTimeout(() => {
  //               setToastOpen(true);
  //             }, 1500);
  //             setTimeout(() => {
  //               setToastOpen(false);
  //             }, 3000);
  //           });
  //       } else {
  //         procGetSchedulePickupList();
  //       }
  //     })
  //     .catch((error) => {
  //       setToastKind("error");
  //       setToastTitle("Schedule Pickup Failed!");
  //       setToastDesc(error.message);
  //       setTimeout(() => {
  //         setLoading(false);
  //       }, 1000);
  //       setTimeout(() => {
  //         setToastOpen(true);
  //       }, 1500);
  //       setTimeout(() => {
  //         setToastOpen(false);
  //       }, 3000);
  //     });
  // };

  const GetDailyBookings = () => {
    setLoading(true);
    const data = {
      CustomerAccountNo: shipperAcctNo,
      Date: moment(currentDate).format('MM/DD/yyyy'),
    };

    BookNow.GetDailyBookings(data)
      .then((response) => {
        return response.data;
      })
      .then((data) => {
        if (data.Code === "200") {
          setDailyBookings(data.Data)
        }
        setLoading(false);
      })
      .catch((error) => {
        setToastKind("error");
        setToastTitle("Daily Bookings Failed!");
        setToastDesc(error.message);
        setTimeout(() => {
          setLoading(false);
        }, 1000);
        setTimeout(() => {
          setToastOpen(true);
        }, 1500);
        setTimeout(() => {
          setToastOpen(false);
        }, 3000);
      });
  };

  const GetSchedulePickupList = () => {
    setLoading1(true);
    BookNow.GetSchedulePickupList(shipperAcctNo)
      .then((response) => {
        return response.data;
      })
      .then((data) => {
        if (data.Code === "200") {
          setSchedulePickupList(data.Data);
        }
        setLoading1(false);
      })
      .catch((error) => {
        setToastKind("error");
        setToastTitle("Schedule Pickup Failed!");
        setToastDesc(error.message);
        setTimeout(() => {
          setLoading1(false);
        }, 1000);
        setTimeout(() => {
          setToastOpen(true);
        }, 1500);
        setTimeout(() => {
          setToastOpen(false);
        }, 3000);
      });
  };

  React.useEffect(() => {
    if(shipperAcctNo) 
      GetDailyBookings();
  }, [currentDate]);

  React.useEffect(() => {
    if (shipperAcctNo) {
      //GetDailyBookings();
      GetSchedulePickupList();
    }
  }, [shipperAcctNo, isToastOpenA]);

  return (
    <>
      <PickUpScheduleCreate shipperAcct={shipperAcct}
        isOpen={isToastOpenA}
        onClose={() => setToastOpenA(false)}
      />
      {isSingleView ? (
        /* Pick Up Schedule Single View */
        <FlexColumn className="items-center justify-start w-full">
          {/*<Div className="w-500px border border-grey-400 rounded-lg shadow-lg my-5">
            <Div className="flex flex-col items-center justify-between p-4 w-full bg-white border-l-8 rounded-lg shadow-lg border-green-100">
              <Div className="w-full flex flex-row items-center phone:justify-between tablet:justify-center">
                <Div className="w-1/5">
                  <HiCheckCircle className="text-green-100 text-4xl" />
                </Div>
                <Div className="w-4/5">
                  <Text className="font-bold italic text-2xl text-secondary-100 pb-2">
                    Change Request Submitted
                  </Text>
                  <Text className="text-sm text-secondary-200 pt-2">
                    Current schedule will be followed until further notice.
                    November 11, 2022 9:06 AM
                  </Text>
                </Div>
              </Div>
            </Div>
          </Div>*/}

          <Div className="w-700px rounded-sm shadow-sm border border-grey-400 p-10">
            <FlexRow className="w-full items-center justify-between">
              <Image
                className="h-14 w-14"
                alt="LBC logo"
                src={Images.LBClogo}
              />
              <Div>
                <Text className="text-sm text-secondary-200">
                  PICK UP CHANGE REQUEST REFERENCE NO.
                </Text>
                <Text className="text-xl text-secondary-200 font-bold">
                  {bookingNo}
                </Text>
              </Div>
              <Div>
                <Div className="flex flex-row h-24 items-center justify-start w-300px">
                  <QRCode
                    value={bookingNo}
                    style={{
                      width: "70px",
                      height: "70px",
                      marginRight: "10px",
                    }}
                  />
                  <Barcode
                    value={bookingNo}
                    height={70}
                    width={2}
                    displayValue={false}
                  />
                </Div>
                <Text className="flex flex-row items-center jusitfy-center text-secondary-200 text-xs font-bold">
                  <ImInfo className="inline mr-2" />
                  <Span>Keep this QR and barcode as reference</Span>
                </Text>
              </Div>
            </FlexRow>

            <Spacer className="h-5" />

            <FlexRow className="w-full py-1">
              <Text className="text-secondary-200 text-sm text-right w-1/3">
                PICK UP SCHEDULE:
              </Text>
              <Spacer className="w-2" />
              <Text className="text-secondary-100 text-sm w-2/3">
                {pickUpSchedule} {/* Recurs every Mondays, Wednesdays, and Thursdays */}
              </Text>
            </FlexRow>
            <FlexRow className="w-full py-1">
              <Text className="text-secondary-200 text-sm text-right w-1/3">
                CONTACT PERSON:
              </Text>
              <Spacer className="w-2" />
              <Text className="text-secondary-100 text-sm w-2/3">
                {contactPerson} ({contactPersonNo}){/*Rio Aquino (+63 9317 336 3188) */}
              </Text>
            </FlexRow>
            <FlexRow className="w-full py-1">
              <Text className="text-secondary-200 text-sm text-right w-1/3">
                PICK UP ADDRESS:
              </Text>
              <Spacer className="w-2" />
              <Text className="text-secondary-100 text-sm w-2/3">
                {address}{/*Blk 14 Lot 6 MS5 Lancaster New City, General Trias, Cavite,
                Barangay Navarro*/}
              </Text>
            </FlexRow>
            <FlexRow className="w-full py-1">
              <Text className="text-secondary-200 text-sm text-right w-1/3">
                ESTIMATED VOLUME PER PICK UP:
              </Text>
              <Spacer className="w-2" />
              <Text className="text-secondary-100 text-sm w-2/3">
                {estVolumePerPickup} Transactions{/*75 Transactions*/}
              </Text>
            </FlexRow>
            <FlexRow className="w-full py-1">
              <Text className="text-secondary-200 text-sm text-right w-1/3">
                SPECIAL INSTRUCTIONS:
              </Text>
              <Spacer className="w-2" />
              <Text className="text-secondary-100 text-sm w-2/3">
                {specialInstruction}{/*Please handle it with care.*/}
              </Text>
            </FlexRow>
          </Div>

          <FlexRow className="w-full items-center justify-center pt-5 pb-20">
            <Button
              className="w-32 mr-2 phone:bg-white phone:text-secondary-200 hover:bg-grey-500 phone:border phone:border-grey-400 h-10"
              onClick={() => setIsSingleView(false)}
            >
              Back
            </Button>
            <Button
              className="w-32 ml-2 h-10"
              onClick={() => setIsSingleView(false)}
            >
              View Activity
            </Button>
          </FlexRow>
        </FlexColumn>
      ) : (
        /* Pick Up Schedule Summary */

        // <FlexRow className="justify-between w-full">
          <Div className="w-1/1 h-750px px-5 py-10">
            <Div className="shadow-lg rounded-lg border border-grey-400 p-5 w-full h-full">
              <Text className="text-secondary-200 font-bold">
                PICK UP SCHEDULES
              </Text>
              <Spacer className="h-5" />
              {loading1 ? (
                <Text className="text-red-400 text-center flex flex-row justify-center items-center my-24">
                  <ImSpinner2 className="animate-spin mr-2 text-2xl desktop:text-3xl" />
                  Loading data ...
                </Text>
              ) : (
                <Div className="overflow-y-scroll h-400px">
                  {schedulePickupList.map((item, index) => (
                    <PickupScheduleItem
                      data={item}
                      className={`${tempBorderColors[index % 2]}`}
                      id={item.Id}
                      pickUpSchedule={item.PickupSchedule}
                      address={item.PickUpAddress}
                      contactPerson={item.ContactPerson}
                      estVolumePerPickup={0/*item.estVolumePerPickup*/}
                      specialInstruction={item.SpecialInstructions}
                    />
                  ))}
                </Div>
              )}
            </Div>
          </Div>
        // </FlexRow>
      )}
    </>
  );
};
