import React, { useRef } from "react";
import { Cookies } from "react-cookie";
import {
  Div,
  Divider,
  FlexColumn,
  FlexRow,
  Spacer,
} from "../../../core/Containers";
import { Text } from "../../../core/Text";
import { LBCModal } from "../../../components/Modals/LBCModal";
import { ColumnHeader } from "../../../components/Tables/ColumnHeader";
import { DeliveryDataTableRow } from "../../../components/Tables/DeliveryDataRow";
import { Pagination } from "../../../components/Tables/Pagination";
import { ImSpinner2 } from "react-icons/im";
import axiosCAPApi from "../../../api/axiosCAPApi";
import axiosCAPApiPrivate from "../../../api/axiosCAPApiPrivate";

const cookies = new Cookies();

export const ForDispoTab = ({ dateFrom, dateTo }) => {
  const dataFetchedRef = useRef(false);

  const [loading, setLoading] = React.useState(true);
  const [isAscending, setAscending] = React.useState(true);

  const [currentPage, setCurrentPage] = React.useState(1);
  const [articlesPerPage] = React.useState(5);
  const indexOfLastArticle = currentPage * articlesPerPage;
  const indexOfFirstArticle = indexOfLastArticle - articlesPerPage;

  const [shipperAcctName, setShipperAcctName] = React.useState("");
  const [shipperAcctNo, setShipperAcctNo] = React.useState("");

  const [deliveryStatus, setDeliveryStatus] = React.useState("For Disposition");

  const [reportDetail, setReportDetail] = React.useState([]);
  const [reportDetailLoaded, setReportDetailLoaded] = React.useState([]);
  const [deliveryStatusSummary, setDeliveryStatusSummary] =
    React.useState(null);
  const [deliveryStatusSummaryLoaded, setDeliveryStatusSummaryLoaded] =
    React.useState(false);

  const [isToastOpen, setToastOpen] = React.useState(false);
  const [toastKind, setToastKind] = React.useState("");
  const [toastTitle, setToastTitle] = React.useState("");
  const [toastDesc, setToastDesc] = React.useState("");

  const newItems = reportDetail.slice(indexOfFirstArticle, indexOfLastArticle);

  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  React.useEffect(() => {
    if (dataFetchedRef.current) return;
    dataFetchedRef.current = true;

    var _shipperAcct = cookies.get("shipperAcct", { path: "/" });
    var _shipperAcctSplit = _shipperAcct ? _shipperAcct.split("|") : [];
    var _shipperAcctName = _shipperAcctSplit[0]
      ? _shipperAcctSplit[0].toString().trim()
      : "";
    var _shipperAcctNo = _shipperAcctSplit[1]
      ? _shipperAcctSplit[1].toString().trim()
      : "";

    setShipperAcctName(_shipperAcctName);
    setShipperAcctNo(_shipperAcctNo);

    getDeliveryStatusSummary(_shipperAcctNo);
    GetReportDetail(_shipperAcctNo);
  }, []);

  React.useEffect(() => {
    var _shipperAcct = cookies.get("shipperAcct", { path: "/" });
    var _shipperAcctSplit = _shipperAcct ? _shipperAcct.split("|") : [];
    var _shipperAcctName = _shipperAcctSplit[0]
      ? _shipperAcctSplit[0].toString().trim()
      : "";
    var _shipperAcctNo = _shipperAcctSplit[1]
      ? _shipperAcctSplit[1].toString().trim()
      : "";

    if (shipperAcctNo !== "" && _shipperAcctNo !== shipperAcctNo) {
      setShipperAcctName(_shipperAcctName);
      setShipperAcctNo(_shipperAcctNo);

      getDeliveryStatusSummary(_shipperAcctNo);
      GetReportDetail(_shipperAcctNo);
    }
  }, [cookies.get("shipperAcct", { path: "/" })]);

  React.useEffect(() => {
    if (shipperAcctNo !== "") {
      getDeliveryStatusSummary(shipperAcctNo);
      GetReportDetail(shipperAcctNo);
    }
  }, [dateFrom, dateTo]);

  const getDeliveryStatusSummary = async (shipperAcctNo) => {
    setDeliveryStatusSummary(null);
    setDeliveryStatusSummaryLoaded(false);
    setLoading(true);

    try {
      const response = await axiosCAPApiPrivate.post(
        "/api/GetDeliveryStatusSummary",
        {
          CustomerAccountNo: shipperAcctNo,
          FromDate: dateFrom,
          ToDate: dateTo,
        }
      );

      const data = response.data;

      if (data.Code === "200") {
        setDeliveryStatusSummary(data.Data);
        setDeliveryStatusSummaryLoaded(true);
        setLoading(false);
      }
      setLoading(false);
    } catch (error) {
      setToastKind("error");
      setToastTitle("Delivery Status Failed!");
      setToastDesc(error.message);
      setTimeout(() => {
        setLoading(false);
      }, 1000);
      setTimeout(() => {
        setToastOpen(true);
      }, 1500);
      setTimeout(() => {
        setToastOpen(false);
      }, 3000);
    }
  };

  const GetReportDetail = async (shipperAccountNo) => {
    setReportDetail([]);
    setReportDetailLoaded(false);
    setLoading(true);

    var status = "";
    if (deliveryStatus === "In-transit") {
      status = "ForUpdate";
    } else if (deliveryStatus === "For Disposition") {
      status = "Pending";
    } else if (deliveryStatus === "Delivered") {
      status = "Delivered";
    } else if (deliveryStatus === "Returned") {
      status = "RTS";
    } else if (deliveryStatus === "Others") {
      status = "LOST";
    } else if (deliveryStatus === "ViewAll") {
      status = "ViewAll";
    }

    try {
      const response = await axiosCAPApiPrivate.post("/api/GetReportDetail", {
        CustomerAccountNo: shipperAccountNo,
        Column: status,
        FromDate: dateFrom,
        ToDate: dateTo,
      });

      const data = response.data;

      if (data.Code === "200") {
        setReportDetail(data.Data);
        setReportDetailLoaded(true);
      }
      setLoading(false);
    } catch (error) {
      setToastKind("error");
      setToastTitle("Delivery Report Failed!");
      setToastDesc(error.message);
      setTimeout(() => {
        setLoading(false);
      }, 1000);
      setTimeout(() => {
        setToastOpen(true);
      }, 1500);
      setTimeout(() => {
        setToastOpen(false);
      }, 3000);
    }
  };

  const onSort = async (column) => {
    setLoading(true);
    if (isAscending) {
      if (column === 0) {
        const tempList = await reportDetail.sort((a, b) => {
          if (a.tracking < b.tracking) {
            return -1;
          }
          if (a.tracking > b.tracking) {
            return 1;
          }
          return 0;
        });
        setReportDetail(tempList);
      } else if (column === 1) {
        const tempList = await reportDetail.sort((a, b) => {
          if (a.encodedDate < b.encodedDate) {
            return -1;
          }
          if (a.encodedDate > b.encodedDate) {
            return 1;
          }
          return 0;
        });
        setReportDetail(tempList);
      } else if (column === 2) {
        const tempList = await reportDetail.sort((a, b) => {
          if (a.deliveryReq < b.deliveryReq) {
            return -1;
          }
          if (a.deliveryReq > b.deliveryReq) {
            return 1;
          }
          return 0;
        });
        setReportDetail(tempList);
      } else if (column === 3) {
        const tempList = await reportDetail.sort((a, b) => {
          if (a.consigneeName < b.consigneeName) {
            return -1;
          }
          if (a.consigneeName > b.consigneeName) {
            return 1;
          }
          return 0;
        });
        setReportDetail(tempList);
      } else if (column === 4 || column === 6) {
        const tempList = await reportDetail.sort((a, b) => {
          if (a.consigneeContact < b.consigneeContact) {
            return -1;
          }
          if (a.consigneeContact > b.consigneeContact) {
            return 1;
          }
          return 0;
        });
        setReportDetail(tempList);
      } else if (column === 5) {
        const tempList = await reportDetail.sort((a, b) => {
          if (a.consigneeAddress < b.consigneeAddress) {
            return -1;
          }
          if (a.consigneeAddress > b.consigneeAddress) {
            return 1;
          }
          return 0;
        });
        setReportDetail(tempList);
      } else if (column === 7) {
        const tempList = await reportDetail.sort((a, b) => {
          if (a.acceptanceDate < b.acceptanceDate) {
            return -1;
          }
          if (a.acceptanceDate > b.acceptanceDate) {
            return 1;
          }
          return 0;
        });
        setReportDetail(tempList);
      } else if (column === 8) {
        const tempList = await reportDetail.sort((a, b) => {
          if (a.status < b.status) {
            return -1;
          }
          if (a.status > b.status) {
            return 1;
          }
          return 0;
        });
        setReportDetail(tempList);
      } else if (column === 9) {
        const tempList = await reportDetail.sort((a, b) => {
          if (a.statusDate < b.statusDate) {
            return -1;
          }
          if (a.statusDate > b.statusDate) {
            return 1;
          }
          return 0;
        });
        setReportDetail(tempList);
      } else if (column === 10) {
        const tempList = await reportDetail.sort((a, b) => {
          if (a.reason < b.reason) {
            return -1;
          }
          if (a.reason > b.reason) {
            return 1;
          }
          return 0;
        });
        setReportDetail(tempList);
      }
      setTimeout(() => {
        setLoading(false);
        setAscending(!isAscending);
      }, 1000);
    } else {
      if (column === 0) {
        const tempList = await reportDetail.sort((a, b) => {
          if (a.tracking > b.tracking) {
            return -1;
          }
          if (a.tracking < b.tracking) {
            return 1;
          }
          return 0;
        });
        setReportDetail(tempList);
      } else if (column === 1) {
        const tempList = await reportDetail.sort((a, b) => {
          if (a.encodedDate > b.encodedDate) {
            return -1;
          }
          if (a.encodedDate < b.encodedDate) {
            return 1;
          }
          return 0;
        });
        setReportDetail(tempList);
      } else if (column === 2) {
        const tempList = await reportDetail.sort((a, b) => {
          if (a.deliveryReq > b.deliveryReq) {
            return -1;
          }
          if (a.deliveryReq < b.deliveryReq) {
            return 1;
          }
          return 0;
        });
        setReportDetail(tempList);
      } else if (column === 3) {
        const tempList = await reportDetail.sort((a, b) => {
          if (a.consigneeName > b.consigneeName) {
            return -1;
          }
          if (a.consigneeName < b.consigneeName) {
            return 1;
          }
          return 0;
        });
        setReportDetail(tempList);
      } else if (column === 4 || column === 6) {
        const tempList = await reportDetail.sort((a, b) => {
          if (a.consigneeContact > b.consigneeContact) {
            return -1;
          }
          if (a.consigneeContact < b.consigneeContact) {
            return 1;
          }
          return 0;
        });
        setReportDetail(tempList);
      } else if (column === 5) {
        const tempList = await reportDetail.sort((a, b) => {
          if (a.consigneeAddress > b.consigneeAddress) {
            return -1;
          }
          if (a.consigneeAddress < b.consigneeAddress) {
            return 1;
          }
          return 0;
        });
        setReportDetail(tempList);
      } else if (column === 7) {
        const tempList = await reportDetail.sort((a, b) => {
          if (a.acceptanceDate > b.acceptanceDate) {
            return -1;
          }
          if (a.acceptanceDate < b.acceptanceDate) {
            return 1;
          }
          return 0;
        });
        setReportDetail(tempList);
      } else if (column === 8) {
        const tempList = await reportDetail.sort((a, b) => {
          if (a.status > b.status) {
            return -1;
          }
          if (a.status < b.status) {
            return 1;
          }
          return 0;
        });
        setReportDetail(tempList);
      } else if (column === 9) {
        const tempList = await reportDetail.sort((a, b) => {
          if (a.statusDate > b.statusDate) {
            return -1;
          }
          if (a.statusDate < b.statusDate) {
            return 1;
          }
          return 0;
        });
        setReportDetail(tempList);
      } else if (column === 10) {
        const tempList = await reportDetail.sort((a, b) => {
          if (a.reason > b.reason) {
            return -1;
          }
          if (a.reason < b.reason) {
            return 1;
          }
          return 0;
        });
        setReportDetail(tempList);
      }
      setTimeout(() => {
        setLoading(false);
        setAscending(!isAscending);
      }, 1000);
    }
  };

  const onDispoChange = (tracking, value) => {
    const newReportDetail = reportDetail.map((item) => {
      if (item.tracking === tracking) {
        return { ...item, disposition: value };
      }
      return item;
    });

    setReportDetail(newReportDetail);
  };

  return (
    <>
      <LBCModal
        id="alertModal"
        description={toastDesc}
        isOpen={isToastOpen}
        onClose={() => setToastOpen(false)}
        title={toastTitle}
        toastKind={toastKind}
      />

      <Div className="w-full ">
        <Spacer className="w-full h-10" />
        <Div className="w-full h-full overflow-y-auto p-10 bg-white">
          <FlexRow className="justify-between w-full">
            <Div className="shadow-lg rounded-lg border border-grey-400 p-5 w-3/5 h-48">
              {deliveryStatus === "ViewAll" ? (
                <Text className="text-secondary-100 text-xs font-bold">
                  View All {dateFrom}
                </Text>
              ) : (
                <Text className="text-secondary-100 text-xs font-bold">
                  {deliveryStatus} From {dateFrom} To {dateTo}
                </Text>
              )}
            </Div>
            <FlexColumn className="justify-between shadow-lg rounded-lg border border-grey-400 p-5 w-1/3 h-48">
              <Text className="text-secondary-100 text-xs font-bold">
                Summary per Reason
              </Text>
              <Div className="overflow-scroll w-full h-28">
                <FlexRow className="items-center justify-between py-2 hover:bg-grey-400">
                  <Text className="text-secondary-100 text-sm w-200px px-4">
                    {deliveryStatus}
                  </Text>
                  <Text className="text-secondary-100 text-sm w-200px px-4">
                    {(deliveryStatusSummary?.Pending || 0)
                      .toString()
                      .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                  </Text>
                </FlexRow>
              </Div>
            </FlexColumn>
          </FlexRow>
          <Spacer className="h-10" />
          <FlexColumn className="w-full">
            <Div className="w-full overflow-x-scroll border border-grey-400 rounded-lg shadow-lg p-5">
              <FlexRow className="items-center justify-between w-2800px">
                <ColumnHeader
                  title="Tracking No."
                  onClick={() => onSort(0)}
                  containerClass="hover:bg-grey-400 rounded-full w-200px px-4 py-2"
                  titleClassName=""
                />
                <ColumnHeader
                  title="Encoded Date"
                  onClick={() => onSort(1)}
                  containerClass="hover:bg-grey-400 rounded-full w-200px px-4 py-2"
                  titleClassName=""
                />
                <ColumnHeader
                  title="Delivery Requirement"
                  onClick={() => onSort(2)}
                  containerClass="hover:bg-grey-400 rounded-full w-200px px-4 py-2"
                  titleClassName=""
                />
                <ColumnHeader
                  title="Consignee Name"
                  onClick={() => onSort(3)}
                  containerClass="hover:bg-grey-400 rounded-full w-200px px-4 py-2"
                  titleClassName=""
                />
                <ColumnHeader
                  title="Consignee Mobile"
                  onClick={() => onSort(4)}
                  containerClass="hover:bg-grey-400 rounded-full w-200px px-4 py-2"
                  titleClassName=""
                />
                <Div className="w-400px">
                  <ColumnHeader
                    title="Consignee Address"
                    onClick={() => onSort(5)}
                    containerClass="hover:bg-grey-400 rounded-full w-200px px-4 py-2 mx-auto"
                    titleClassName=""
                  />
                </Div>
                <ColumnHeader
                  title="Consignee Mobile No."
                  onClick={() => onSort(6)}
                  containerClass="hover:bg-grey-400 rounded-full w-200px px-4 py-2"
                  titleClassName=""
                />
                <ColumnHeader
                  title="Acceptance Date"
                  onClick={() => onSort(7)}
                  containerClass="hover:bg-grey-400 rounded-full w-200px px-4 py-2"
                  titleClassName=""
                />
                <ColumnHeader
                  title="Last Status"
                  onClick={() => onSort(8)}
                  containerClass="hover:bg-grey-400 rounded-full w-200px px-4 py-2"
                  titleClassName=""
                />
                <ColumnHeader
                  title="Last Status Date"
                  onClick={() => onSort(9)}
                  containerClass="hover:bg-grey-400 rounded-full w-200px px-4 py-2"
                  titleClassName=""
                />
                <ColumnHeader
                  title="Reason"
                  onClick={() => onSort(10)}
                  containerClass="hover:bg-grey-400 rounded-full w-200px px-4 py-2"
                  titleClassName=""
                />
                {deliveryStatus === "For Disposition" ? (
                  <Text className="text-secondary-100 text-xs text-center font-bold w-200px px-4 py-2">
                    Disposition
                  </Text>
                ) : (
                  <></>
                )}
                <Text className="text-secondary-100 text-xs text-center font-bold w-200px px-4 py-2">
                  Action
                </Text>
              </FlexRow>
              <Divider className="text-grey-100 my-2 w-2800px" />

              <LBCModal
                description={toastDesc}
                isOpen={isToastOpen}
                onClose={() => setToastOpen(false)}
                title={toastTitle}
                toastKind={toastKind}
              />

              {loading ||
              !deliveryStatusSummaryLoaded ||
              !reportDetailLoaded ? (
                <Text className="text-red-400 text-center flex flex-row justify-center items-center my-20">
                  <ImSpinner2 className="animate-spin mr-2 text-2xl desktop:text-3xl" />
                  Loading data ...
                </Text>
              ) : (
                <>
                  {newItems.map((list) => (
                    <DeliveryDataTableRow
                      data={list}
                      status={deliveryStatus}
                      onDispoChange={onDispoChange}
                    />
                  ))}
                </>
              )}
            </Div>
            <Text className="text-red-400 text-xs font-bold my-2">
              {`Disclaimer: Coverage is Twelve (12) months from encoded date.`}
            </Text>
            <Div className="w-full">
              <Pagination
                currentPage={currentPage}
                itemsPerPage={articlesPerPage}
                paginate={paginate}
                totalItems={reportDetail.length}
              />
            </Div>
          </FlexColumn>
        </Div>
      </Div>
    </>
  );
};
