import React, { Fragment, useRef } from "react";
import { Cookies } from "react-cookie";
import { Dialog, Transition } from "@headlessui/react";
import { Div, FlexColumn, FlexRow, Spacer } from "../../core/Containers";
import { Text } from "../../core/Text";
import { RawButton } from "../../core/Buttons";
import { RawInput } from "../../core/Forms";
import { useNavigate } from "react-router-dom";
import { LBCModal } from "./LBCModal";
import { ImSpinner2 } from "react-icons/im";
import { FaTimes } from "react-icons/fa";
import { BiSearchAlt2 } from "react-icons/bi";
import { filter } from "lodash";
import axiosCAPApi from "../../api/axiosCAPApi";
import axiosCAPApiPrivate from "../../api/axiosCAPApiPrivate";

const cookies = new Cookies();

const applyFilter = (data, query) => {
  const stabilizedThis = data.map((el, index) => [el, index]);
  if (query) {
    return filter(
      data,
      (_data) =>
        _data.contactPerson.toLowerCase().indexOf(query.toLowerCase()) !== -1 ||
        _data.mobileNo.toLowerCase().indexOf(query.toLowerCase()) !== -1 ||
        _data.pickupAddress.toLowerCase().indexOf(query.toLowerCase()) !== -1 ||
        `${_data.brgyName}, ${_data.cityName}, ${_data.provName}`
          .toLowerCase()
          .indexOf(query.toLowerCase()) !== -1
    );
  }
  return stabilizedThis.map((el) => el[0]);
};

export const AddressBookModal = ({ isOpen, onClose, data, onSelect }) => {
  const navigate = useNavigate();
  const dataFetchedRef = useRef(false);

  const [loading, setLoading] = React.useState(false);
  const [addressBookData, setAddressBookData] = React.useState([]);
  const [addressBookDataFilter, setAddressBookDataFilter] = React.useState([]);
  const [searchAddress, setSearchAddress] = React.useState("");
  const [senderName, setSenderName] = React.useState("");

  const [isToastOpen, setToastOpen] = React.useState(false);
  const [toastKind, setToastKind] = React.useState("");
  const [toastTitle, setToastTitle] = React.useState("");
  const [toastDesc, setToastDesc] = React.useState("");

  React.useEffect(() => {
    if (dataFetchedRef.current) return;
    dataFetchedRef.current = true;

    if (isOpen) {
      getAddressBookData(data.customerAccountNo);
    }
  }, [isOpen]);

  const getAddressBookData = async (customerAccountNo) => {
    setAddressBookData([]);
    setAddressBookDataFilter([]);
    setLoading(true);

    try {
      const response = await axiosCAPApiPrivate.get(
        "/api/GetShipperPickupAddress/" + customerAccountNo
      );

      const data = response.data;

      if (data.Code === "200") {
        setAddressBookData(data.Data);
        setAddressBookDataFilter(data.Data);
        setLoading(false);
      } else {
        setToastKind("error");
        setToastTitle("API Fetch Failed!");
        setToastDesc(data.Message);
        setTimeout(() => {
          setLoading(false);
        }, 1000);
        setTimeout(() => {
          setToastOpen(true);
        }, 1500);
        setTimeout(() => {
          setToastOpen(false);
        }, 5000);
      }
    } catch (error) {
      setToastKind("error");
      setToastTitle("API Fetch Failed!");
      setToastDesc(error.message);
      setTimeout(() => {
        setLoading(false);
      }, 1000);
      setTimeout(() => {
        setToastOpen(true);
      }, 1500);
      setTimeout(() => {
        setToastOpen(false);
      }, 5000);
    }
  };

  React.useEffect(() => {
    const newAddressBookData = applyFilter(addressBookData, searchAddress);
    setAddressBookDataFilter(newAddressBookData);
  }, [searchAddress]);

  const onSelectItems = (item) => {
    onSelect(item);
    onClose();
  };

  return (
    <>
      <Transition appear as={Fragment} show={isOpen}>
        <Dialog
          as="div"
          className="fixed inset-0 z-50 overflow-hide"
          onClose={onClose}
        >
          <div className="h-full w-full text-center flex justify-end">
            <Transition.Child
              as={Fragment}
              enter="transition ease duration-300 transform"
              enterFrom="opacity-0 -translate-y-full"
              enterTo="opacity-100 translate-y-0"
              leave="transition ease duration-300 transform"
              leaveFrom="opacity-100 translate-y-0"
              leaveTo="opacity-0 -translate-y-full"
            >
              <div className="h-full w-full overflow-hidden z-20 p-5 align-middle transition-all transform flex flex-col items-center justify-center bg-secondary-200">
                {/* ---------- Modal Body ---------- */}

                {/* {loading && <Loading />} */}

                <LBCModal
                  id="alertModal"
                  description={toastDesc}
                  isOpen={isToastOpen}
                  onClose={() => setToastOpen(false)}
                  title={toastTitle}
                  toastKind={toastKind}
                />

                <FlexColumn className="justify-start bg-white border border-grey-400 h-95% w-700px px-5 py-5 rounded-lg shadow-lg">
                  <FlexRow className="w-full items-center justify-between">
                    <Text className="text-secondary-200 text-sm font-bold">
                      Address Book
                    </Text>
                    <RawButton onClick={onClose}>
                      <FaTimes className="text-secondary-200 text-xl" />
                    </RawButton>
                  </FlexRow>

                  <Spacer className="h-5" />

                  <FlexRow className="items-center border border-grey-400 rounded-lg shadow-lg p-2 bg-white">
                    <BiSearchAlt2 className="text-red-400 text-2xl mr-2 ml-1" />
                    <RawInput
                      className="phone:border-none p-2"
                      containerClass="w-full"
                      name="searchAddress"
                      placeholder="Search Address"
                      onChange={setSearchAddress}
                      type="text"
                      value={searchAddress}
                      autoComplete="off"
                      disabled={addressBookData.length === 0}
                    />
                  </FlexRow>

                  <Spacer className="h-3" />

                  <Div className="w-full h-full my-2 overflow-y-scroll">
                    {loading ? (
                      <Text className="text-red-400 text-center flex flex-row justify-center items-center my-20">
                        <ImSpinner2 className="animate-spin mr-2 text-2xl desktop:text-3xl" />
                        Loading data ...
                      </Text>
                    ) : addressBookDataFilter.length > 0 ? (
                      addressBookDataFilter.map((item, i) => {
                        return (
                          <>
                            <FlexRow
                              className="items-start justify-between w-full hover:bg-grey-400 mb-2"
                              key={i}
                              onClick={() => onSelectItems(item)}
                            >
                              <FlexColumn className="items-start justify-start w-full">
                                <Text className="text-red-400 text-sm font-semibold text-left">
                                  {item.contactPerson}
                                </Text>
                                <Text className="text-secondary-100 text-xs text-left">
                                  {`+63 ${item.mobileNo}`}
                                </Text>
                                <Text className="text-secondary-100 text-xs text-left">
                                  {item.pickupAddress}
                                </Text>
                                <Text className="text-secondary-100 text-xs text-left">
                                  {`${item.brgyName}, ${item.cityName}, ${item.provName}`}
                                </Text>
                              </FlexColumn>
                            </FlexRow>
                            <hr className="text-grey-400 my-2" role="none" />
                          </>
                        );
                      })
                    ) : (
                      <Text className="text-secondary-200 text-sm text-center py-10">
                        {addressBookData.length === 0
                          ? "You have no contacts yet."
                          : "Contact not found."}
                      </Text>
                    )}
                  </Div>
                </FlexColumn>
              </div>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition>
    </>
  );
};
