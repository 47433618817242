import React, { useRef } from "react";
import { Cookies } from "react-cookie";
import { Div, FlexRow, Spacer } from "../../../core/Containers";
import { Text } from "../../../core/Text";
import { TransactionDataRow, ColumnHeader } from "../../../components/Tables";
import { Pagination } from "../../../components/Tables/Pagination";
import { useNavigate } from "react-router-dom";
import { ImSpinner2 } from "react-icons/im";
import { LBCModal } from "../../../components/Modals";
import axiosCAPApiPrivate from "../../../api/axiosCAPApiPrivate";
import axiosLexaApiPrivate from "../../../api/axiosLexaApiPrivate";

const cookies = new Cookies();

export const PastTab = ({ dateFrom, dateTo }) => {
  const navigate = useNavigate();
  const dataFetchedRef = useRef(false);

  const [shipperAcctName, setShipperAcctName] = React.useState("");
  const [shipperAcctNo, setShipperAcctNo] = React.useState("");

  const [accountActivities, setAccountActivities] = React.useState([]);

  const [loading, setLoading] = React.useState(true);
  const [isAscending, setAscending] = React.useState(true);
  const [sortList, setSortList] = React.useState(accountActivities);

  const [singleView, setSingleView] = React.useState("default");
  const [selected, setSelected] = React.useState("default");
  const [selectedList, setSelectedList] = React.useState([]);

  const [currentPage, setCurrentPage] = React.useState(1);
  const [articlesPerPage] = React.useState(10);
  const indexOfLastArticle = currentPage * articlesPerPage;
  const indexOfFirstArticle = indexOfLastArticle - articlesPerPage;

  const newItems = sortList.slice(indexOfFirstArticle, indexOfLastArticle);

  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  const [isToastOpen, setToastOpen] = React.useState(false);
  const [toastKind, setToastKind] = React.useState("");
  const [toastTitle, setToastTitle] = React.useState("");
  const [toastDesc, setToastDesc] = React.useState("");

  React.useEffect(() => {
    if (dataFetchedRef.current) return;
    dataFetchedRef.current = true;

    var _shipperAcct = cookies.get("shipperAcct", { path: "/" });
    var _shipperAcctSplit = _shipperAcct ? _shipperAcct.split("|") : [];
    var _shipperAcctName = _shipperAcctSplit[0]
      ? _shipperAcctSplit[0].toString().trim()
      : "";
    var _shipperAcctNo = _shipperAcctSplit[1]
      ? _shipperAcctSplit[1].toString().trim()
      : "";

    setShipperAcctName(_shipperAcctName);
    setShipperAcctNo(_shipperAcctNo);

    getAccountActivities(_shipperAcctNo);
  }, []);

  React.useEffect(() => {
    var _shipperAcct = cookies.get("shipperAcct", { path: "/" });
    var _shipperAcctSplit = _shipperAcct ? _shipperAcct.split("|") : [];
    var _shipperAcctName = _shipperAcctSplit[0]
      ? _shipperAcctSplit[0].toString().trim()
      : "";
    var _shipperAcctNo = _shipperAcctSplit[1]
      ? _shipperAcctSplit[1].toString().trim()
      : "";

    if (shipperAcctNo !== "" && _shipperAcctNo !== shipperAcctNo) {
      setShipperAcctName(_shipperAcctName);
      setShipperAcctNo(_shipperAcctNo);

      getAccountActivities(_shipperAcctNo);
    }
  }, [cookies.get("shipperAcct", { path: "/" })]);

  React.useEffect(() => {
    if (shipperAcctNo !== "") {
      getAccountActivities(shipperAcctNo);
    }
  }, [dateFrom, dateTo]);

  const getAccountActivities = async (shipperAccountNo) => {
    setAccountActivities([]);
    setLoading(true);

    const reqData = {
      customerAccountNo: shipperAccountNo,
      activityType: "PAST",
      dateFrom: dateFrom,
      dateTo: dateTo,
    };

    try {
      const response = await axiosCAPApiPrivate.post(
        "/api/GetAccountActivities",
        reqData
      );
      const resData = response.data;

      if (resData.Code === "200") {
        // const tempData = [
        //   {
        //     trackingNo: "123456789012345",
        //     consigneeName: "test test",
        //     consigneeAddress: "test address, Carsadag Bago 1, Imus, Cavite",
        //     product: "N-Pack Small",
        //     transactionDate: "1/26/2024 3:10:59 PM",
        //     lastStatus: "PINDING PICKUP"
        //   }
        // ];
        // setAccountActivities(tempData);
        // setSortList(tempData);

        setAccountActivities(resData.Data);
        setSortList(resData.Data);
        setLoading(false);
      } else {
        setToastKind("error");
        setToastTitle("API Fetch Failed!");
        setToastDesc(resData.Message);
        setTimeout(() => {
          setLoading(false);
        }, 1000);
        setTimeout(() => {
          setToastOpen(true);
        }, 1500);
        setTimeout(() => {
          setToastOpen(false);
        }, 5000);
      }
    } catch (error) {
      setToastKind("error");
      setToastTitle("API Fetch Failed!");
      setToastDesc(error.message);
      setTimeout(() => {
        setLoading(false);
      }, 1000);
      setTimeout(() => {
        setToastOpen(true);
      }, 1500);
      setTimeout(() => {
        setToastOpen(false);
      }, 5000);
    }
  };

  const onSelectItems = (id) => {
    if (selectedList.includes(id)) {
      const tempList = [...selectedList].filter((value) => value !== id);
      setSelectedList(tempList);
    } else {
      const tempList = [...selectedList];
      tempList.push(id);
      setSelectedList(tempList);
    }
  };

  const onSort = async (column) => {
    setLoading(true);
    if (isAscending) {
      if (column === 0) {
        const tempList = await sortList.sort((a, b) => {
          if (a.createdBy < b.createdBy) {
            return -1;
          }
          if (a.createdBy > b.createdBy) {
            return 1;
          }
          return 0;
        });
        setSortList(tempList);
      } else if (column === 1) {
        const tempList = await sortList.sort((a, b) => {
          if (a.bookType < b.bookType) {
            return -1;
          }
          if (a.bookType > b.bookType) {
            return 1;
          }
          return 0;
        });
        setSortList(tempList);
      } else if (column === 2) {
        const tempList = await sortList.sort((a, b) => {
          if (a.bookDate < b.bookDate) {
            return -1;
          }
          if (a.bookDate > b.bookDate) {
            return 1;
          }
          return 0;
        });
        setSortList(tempList);
      }
      setTimeout(() => {
        setLoading(false);
        setAscending(!isAscending);
      }, 1000);
    } else {
      if (column === 0) {
        const tempList = await sortList.sort((a, b) => {
          if (a.createdBy > b.createdBy) {
            return -1;
          }
          if (a.createdBy < b.createdBy) {
            return 1;
          }
          return 0;
        });
        setSortList(tempList);
      } else if (column === 1) {
        const tempList = await sortList.sort((a, b) => {
          if (a.bookType > b.bookType) {
            return -1;
          }
          if (a.bookType < b.bookType) {
            return 1;
          }
          return 0;
        });
        setSortList(tempList);
      } else if (column === 2) {
        const tempList = await sortList.sort((a, b) => {
          if (a.bookDate > b.bookDate) {
            return -1;
          }
          if (a.bookDate < b.bookDate) {
            return 1;
          }
          return 0;
        });
        setSortList(tempList);
      }
      setTimeout(() => {
        setLoading(false);
        setAscending(!isAscending);
      }, 1000);
    }
  };

  return (
    <>
      <LBCModal
        id="alertModal"
        description={toastDesc}
        isOpen={isToastOpen}
        onClose={() => setToastOpen(false)}
        title={toastTitle}
        toastKind={toastKind}
      />

      <Div className="w-full ">
        <Spacer className="w-full h-10" />
        <Div className="w-full border border-grey-400 rounded-lg shadow-lg">
          <FlexRow className="items-center justify-between py-2 pl-2 border border-grey-400">
            <Text className="text-secondary-100 text-xs text-left font-semibold w-40 px-2 py-2">
              Tracking No.
            </Text>
            <ColumnHeader
              title="Consignee"
              onClick={() => onSort(0)}
              containerClass="justify-start hover:bg-grey-400 rounded-full w-1/6 px-2 py-2"
              titleClassName=""
            />
            <ColumnHeader
              title="Consignee Address"
              onClick={() => onSort(0)}
              containerClass="justify-start hover:bg-grey-400 rounded-full w-1/6 px-2 py-2"
              titleClassName=""
            />
            <ColumnHeader
              title="Shipment"
              onClick={() => onSort(1)}
              containerClass="justify-start hover:bg-grey-400 rounded-full w-32 px-2 py-2"
              titleClassName=""
            />
            <ColumnHeader
              title="Last Posted Date"
              onClick={() => onSort(2)}
              containerClass="justify-start hover:bg-grey-400 rounded-full w-32 px-2 py-2"
              titleClassName=""
            />
            <Text className="text-secondary-100 text-xs text-left font-semibold w-1/6 px-2 py-2">
              Status
            </Text>
            {/* <Text className="text-secondary-100 text-xs text-center font-semibold w-1/6 px-4 py-2">
              Action
            </Text> */}
          </FlexRow>
          {loading ? (
            <Text className="text-red-400 text-center flex flex-row justify-center items-center my-20">
              <ImSpinner2 className="animate-spin mr-2 text-2xl desktop:text-3xl" />
              Loading data ...
            </Text>
          ) : (
            <Div className="w-full">
              {newItems.map((item) => (
                <TransactionDataRow
                  data={item}
                  onSelect={setSingleView}
                  diableDelete={true}
                  diableEdit={true}
                />
              ))}
            </Div>
          )}
        </Div>
        <Spacer className="w-full h-10" />
        <Div className="w-full">
          <Pagination
            currentPage={currentPage}
            itemsPerPage={articlesPerPage}
            paginate={paginate}
            totalItems={accountActivities.length}
          />
        </Div>
      </Div>
    </>
  );
};
