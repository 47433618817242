import axios from "axios";
import { appConfig } from "../helpers/common";

const axiosLexaApi = axios.create({
  baseURL: appConfig.api.lexaapiUrl,
  headers: {
    "Content-Type": "application/json",
    token: appConfig.api.lexaapiToken,
    lbcOAkey: appConfig.api.lexaapiLBCOAkey,
  },
  withCredentials: true,
});

export default axiosLexaApi;
