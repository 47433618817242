import React from "react";
import { Cookies } from "react-cookie";
import moment from "moment";
import { Div, FlexRow, TextDivider } from "../../../core/Containers";
import { Text } from "../../../core/Text";
import { monthlyPerformancePercent } from "../../../constants/TempData";
import { PerformanceDataRow } from "../../../components/Tables/PerformanceDataRow";
import { Link } from "react-router-dom";
import { RawDropdown } from "../../../core/Buttons";
import { RecurrenceDeliveryPerformanceOptions } from "../../../constants/Dropdowns";
import { ImSpinner2 } from "react-icons/im";
import axiosCAPApi from "../../../api/axiosCAPApi";
import axiosCAPApiPrivate from "../../../api/axiosCAPApiPrivate";

const cookies = new Cookies();

export const PerformanceReports = ({ shipperAcct }) => {
  const [loading, setLoading] = React.useState(true);
  const [loading1, setLoading1] = React.useState(true);

  const [timeline, setTimeline] = React.useState("Monthly");
  const [currentData, setData] = React.useState(monthlyPerformancePercent);
  const [newList, setList] = React.useState([] /*tempPerformanceDataRow*/);

  const [deliveryPerformanceDate, setDeliveryPerformanceDate] = React.useState(
    moment(new Date()).format("yyyy-MM")
  );
  const [shipperAcctNo, setShipperAcctNo] = React.useState("");
  const [viewAllLink, setViewAllLink] = React.useState(
    "/dashboard-home/delivery-table-data?customerAccountNo=" +
      shipperAcctNo +
      "&deliveryStatus=ViewAll&dateFrom=" +
      deliveryPerformanceDate
  );

  const [isToastOpen, setToastOpen] = React.useState(false);
  const [toastKind, setToastKind] = React.useState("");
  const [toastTitle, setToastTitle] = React.useState("");
  const [toastDesc, setToastDesc] = React.useState("");

  React.useEffect(() => {
    if (shipperAcct) {
      var shipAcctNo = shipperAcct.split("|");
      setShipperAcctNo(shipAcctNo[1].toString().trim());
      //if(shipperAcctNo) {
      //  setList([]);
      //  getPerformanceDataRow('ForUpdate');
      //}
    }
  }, [shipperAcct]);

  //React.useEffect(() => {
  //  const tempList = [...newList].reverse();
  //  setList(tempList);
  //  if (timeline === "Monthly") {
  //    setData(monthlyPerformancePercent);
  //  } else if (timeline === "Weekly") {
  //    setData(weeklyPerformancePercent);
  //  } else {
  //    setData(dailyPerformancePercent);
  //  }
  //}, [timeline]);

  //React.useEffect(() => {
  //  var status = cookies.get("deliveryStatus");
  //  if(shipperAcctNo) {
  //    setList([]);
  //    getPerformanceDataRow(status);
  //  }
  //}, [cookies.get("deliveryStatus")]);

  // React.useEffect(() => {
  //   setLoading(true);
  //   setTimeout(() => {
  //     setLoading(false);
  //   }, 750);
  // }, [timeline]);

  // React.useEffect(() => {
  //   setTimeout(() => {
  //     setLoading(false);
  //   }, 2000);
  //   setTimeout(() => {
  //     setLoading1(false);
  //   }, 2500);
  // }, []);

  // const getPerformanceDataRow = () => {
  //   Auth.validateToken()
  //     .then(async (response) => {
  //       return await response.data;
  //     })
  //     .then((data) => {
  //       if (data.Code === "403") {
  //         Auth.refreshToken()
  //           .then(async (response) => {
  //             return await response.data;
  //           })
  //           .then(async (data) => {
  //             if (data.Code === "200") {
  //               const userInfo = cookies.get("userInfo");
  //               const newToken = data.Data;
  //               userInfo.token = newToken;
  //               cookies.set("userInfo", userInfo, { path: "/" });
  //               cookies.set("accessToken", newToken, { path: "/" });

  //               procGetPerformanceDataRow();
  //             } else {
  //               setToastKind("error");
  //               setToastTitle("Delivery Performance Failed!");
  //               setToastDesc(data.message);
  //               setTimeout(() => {
  //                 setLoading(false);
  //               }, 1000);
  //               setTimeout(() => {
  //                 setToastOpen(true);
  //               }, 1500);
  //               setTimeout(() => {
  //                 setToastOpen(false);
  //               }, 3000);
  //             }
  //           })
  //           .catch((error) => {
  //             setToastKind("error");
  //             setToastTitle("Delivery Performance Failed!");
  //             setToastDesc(error.message);
  //             setTimeout(() => {
  //               setLoading(false);
  //             }, 1000);
  //             setTimeout(() => {
  //               setToastOpen(true);
  //             }, 1500);
  //             setTimeout(() => {
  //               setToastOpen(false);
  //             }, 3000);
  //           });
  //       } else {
  //         procGetPerformanceDataRow();
  //       }
  //     })
  //     .catch((error) => {
  //       setToastKind("error");
  //       setToastTitle("Delivery Performance Failed!");
  //       setToastDesc(error.message);
  //       setTimeout(() => {
  //         setLoading(false);
  //       }, 1000);
  //       setTimeout(() => {
  //         setToastOpen(true);
  //       }, 1500);
  //       setTimeout(() => {
  //         setToastOpen(false);
  //       }, 3000);
  //     });
  // };

  const getPerformanceDataRow = async (status) => {
    //setLoading(true);
    setLoading1(true);

    try {
      const response = await axiosCAPApiPrivate.post("/api/GetPerformanceDataRow", {
        CustomerAccountNo: shipperAcctNo,
        MonthFrom: deliveryPerformanceDate,
      });

      const data = response.data;

      if (data.Code === "200") {
        setList([]);
        setList(data.Data);
        //setLoading(false);
        setLoading1(false);
      }
    } catch (error) {
      setToastKind("error");
      setToastTitle("Performance DataRow Failed!");
      setToastDesc(error.message);
      setTimeout(() => {
        setLoading(false);
      }, 1000);
      setTimeout(() => {
        setToastOpen(true);
      }, 1500);
      setTimeout(() => {
        setToastOpen(false);
      }, 3000);
    }
  };

  // const getDeliveryPerformanceByMonth = () => {
  //   Auth.validateToken()
  //     .then(async (response) => {
  //       return await response.data;
  //     })
  //     .then((data) => {
  //       if (data.Code === "403") {
  //         Auth.refreshToken()
  //           .then(async (response) => {
  //             return await response.data;
  //           })
  //           .then(async (data) => {
  //             if (data.Code === "200") {
  //               const userInfo = cookies.get("userInfo");
  //               const newToken = data.Data;
  //               userInfo.token = newToken;
  //               cookies.set("userInfo", userInfo, { path: "/" });
  //               cookies.set("accessToken", newToken, { path: "/" });

  //               procGetDeliveryPerformanceByMonth();
  //             } else {
  //               setToastKind("error");
  //               setToastTitle("Delivery Performance Failed!");
  //               setToastDesc(data.message);
  //               setTimeout(() => {
  //                 setLoading(false);
  //               }, 1000);
  //               setTimeout(() => {
  //                 setToastOpen(true);
  //               }, 1500);
  //               setTimeout(() => {
  //                 setToastOpen(false);
  //               }, 3000);
  //             }
  //           })
  //           .catch((error) => {
  //             setToastKind("error");
  //             setToastTitle("Delivery Performance Failed!");
  //             setToastDesc(error.message);
  //             setTimeout(() => {
  //               setLoading(false);
  //             }, 1000);
  //             setTimeout(() => {
  //               setToastOpen(true);
  //             }, 1500);
  //             setTimeout(() => {
  //               setToastOpen(false);
  //             }, 3000);
  //           });
  //       } else {
  //         procGetDeliveryPerformanceByMonth();
  //       }
  //     })
  //     .catch((error) => {
  //       setToastKind("error");
  //       setToastTitle("Delivery Performance Failed!");
  //       setToastDesc(error.message);
  //       setTimeout(() => {
  //         setLoading(false);
  //       }, 1000);
  //       setTimeout(() => {
  //         setToastOpen(true);
  //       }, 1500);
  //       setTimeout(() => {
  //         setToastOpen(false);
  //       }, 3000);
  //     });
  // };

  const getDeliveryPerformanceByMonth = async () => {
    setLoading(true);
    //setLoading1(true);

    try {
      const response = await axiosCAPApiPrivate.post(
        "/api/GetDeliveryPerformanceByMonth",
        {
          CustomerAccountNo: shipperAcctNo,
          MonthFrom: deliveryPerformanceDate,
        }
      );

      const data = response.data;

      if (data.Code === "200") {
        monthlyPerformancePercent[0].percent = data.Data.YTDDeliveryPerformance;
        monthlyPerformancePercent[1].percent = data.Data.MTDDeliveryPerformance;
        monthlyPerformancePercent[2].percent =
          data.Data.MTDDeliveryPercentWithinSLA;
        monthlyPerformancePercent[3].percent =
          data.Data.MTDDeliveryPercentReturnWithinSLA;
        setData(monthlyPerformancePercent);
      }
      setLoading(false);
      //setLoading1(false);
    } catch (error) {
      setToastKind("error");
      setToastTitle("Delivery Performance Failed!");
      setToastDesc(error.message);
      setTimeout(() => {
        setLoading(false);
      }, 1000);
      setTimeout(() => {
        setToastOpen(true);
      }, 1500);
      setTimeout(() => {
        setToastOpen(false);
      }, 3000);
    }
  };

  React.useEffect(() => {
    if (shipperAcctNo) {
      setList([]);
      getDeliveryPerformanceByMonth();
      getPerformanceDataRow("ForUpdate");
      setViewAllLink(
        "/dashboard-home/delivery-table-data?customerAccountNo=" +
          shipperAcctNo +
          "&deliveryStatus=ViewAll&dateFrom=" +
          deliveryPerformanceDate
      );
    }
  }, [shipperAcctNo, deliveryPerformanceDate]);

  return (
    <Div className="w-full rounded-lg border border-grey-400">
      <FlexRow className="justify-between items-center py-4 px-10">
        <Text className="text-secondary-100 text-sm font-bold">
          Delivery Performance
        </Text>
        <RawDropdown
          icon={<div />}
          width="w-36"
          value={timeline}
          options={RecurrenceDeliveryPerformanceOptions}
          onSelect={setTimeline}
        />
        <input
          className="text-sm w-48 mx-2 block rounded-lg"
          type="month"
          value={deliveryPerformanceDate}
          onChange={(e) => setDeliveryPerformanceDate(e.target.value)}
        />
      </FlexRow>

      {loading ? (
        <Text className="text-red-400 text-center flex flex-row justify-center items-center my-4">
          <ImSpinner2 className="animate-spin mr-2 text-2xl desktop:text-3xl" />
        </Text>
      ) : (
        <FlexRow className="justify-between items-center border border-grey-400 shadow-xl rounded-xl m-5 py-4 px-10">
          {currentData.map((item, i) => (
            <Div key={i}>
              <Text className="text-green-100 text-xl text-center font-bold">
                {item.percent}%
              </Text>
              <Text className="text-secondary-100 text-xs text-center">
                {item.title}
              </Text>
            </Div>
          ))}
        </FlexRow>
      )}

      <TextDivider
        className="bg-grey-400"
        textType="end"
        textElement={
          <Link to={viewAllLink}>
            <Text className="text-red-400 text-xs font-bold">View all</Text>
          </Link>
        }
      />

      {loading1 ? (
        <Text className="text-red-400 text-center flex flex-row justify-center items-center my-4">
          <ImSpinner2 className="animate-spin mr-2 text-2xl desktop:text-3xl" />
        </Text>
      ) : (
        <Div className="w-full p-5">
          {newList.map((list, i) => (
            <PerformanceDataRow
              TrackingNo={list.TrackingNo}
              Content={list.Content}
              TransactionDate={list.TransactionDate}
              ShipperName={list.ShipperName}
              ShipperAddress={list.ShipperAddress}
              Consignee={list.Consignee}
              ConsigneeAddress={list.ConsigneeAddress}
              Status={list.Status}
              DatePosted={list.DatePosted}
              key={i}
            />
          ))}
        </Div>
      )}
      <TextDivider className="text-grey-400" />
      <Div>
        <br></br>
        <Text className="text-red-400 text-xs text-center justify-center items-center font-semibold">
          "Delivery Performance is based on track and trace status posted 2
          hours ago. Up-to-date shipment status may differ."
        </Text>
      </Div>
    </Div>
  );
};
