import React from "react";
import { Cookies } from "react-cookie";
import { Div, FlexRow, Spacer } from "../../../core/Containers";
import { Text } from "../../../core/Text";
import { useLocation, useNavigate } from "react-router-dom";
import { Button, RawDropdown } from "../../../core/Buttons";
import { FaFilter } from "react-icons/fa";
import {
  RecurrenceOptionsStatusReport,
  ShipmentOptionsStatusReport,
} from "../../../constants/Dropdowns";
import { ResponsiveBar } from "@nivo/bar";
import moment from "moment";
import { ImSpinner2 } from "react-icons/im";
import axiosCAPApi from "../../../api/axiosCAPApi";
import axiosCAPApiPrivate from "../../../api/axiosCAPApiPrivate";

const cookies = new Cookies();

export const StatusReport = () => {
  const navigate = useNavigate();
  const { search } = useLocation();
  const query = new URLSearchParams(search);
  const actionQuery = query.get("active");

  const [shipperAcctName, setShipperAcctName] = React.useState("");
  const [shipperAcctNo, setShipperAcctNo] = React.useState("");

  const [dateFrom, setDateFrom] = React.useState(
    moment(new Date()).format("yyyy-MM-DD")
  );
  const [dateTo, setDateTo] = React.useState(
    moment(new Date()).format("yyyy-MM-DD")
  );

  const [isToastOpen, setToastOpen] = React.useState(false);
  const [toastKind, setToastKind] = React.useState("");
  const [toastTitle, setToastTitle] = React.useState("");
  const [toastDesc, setToastDesc] = React.useState("");

  const [loading, setLoading] = React.useState(true);

  const [singleView, setSingleView] = React.useState("default");

  const [timeline, setTimeline] = React.useState("Monthly");
  const [shipment, setShipment] = React.useState("Transaction Date");

  const colors = ["#F37777", "#3173AF", "#63C9A8", "#202123B3"];

  const [barGraph, setBarGraph] = React.useState([]);

  React.useEffect(() => {
    var _shipperAcct = cookies.get("shipperAcct", { path: "/" });
    var _shipperAcctSplit = _shipperAcct ? _shipperAcct.split("|") : [];
    var _shipperAcctName = _shipperAcctSplit[0]
      ? _shipperAcctSplit[0].toString().trim()
      : "";
    var _shipperAcctNo = _shipperAcctSplit[1]
      ? _shipperAcctSplit[1].toString().trim()
      : "";

    setShipperAcctName(_shipperAcctName);
    setShipperAcctNo(_shipperAcctNo);
  }, []);

  const GetStatusReportByTransactionDate = async () => {
    setLoading(true);

    try {
      const response = await axiosCAPApiPrivate.post(
        "/api/GetStatusReportByTransactionDate",
        {
          CustomerAccountNo: shipperAcctNo,
        }
      );

      const data = response.data;

      if (data.Code === "200") {
        setLoading(false);

        var graph = [];
        data.Data.map((detail, index) => {
          graph.push({
            country: detail.DateText,
            "Picked up/Dropped Off": 0,
            "For Disposition": detail.Pending,
            "In-transit": detail.ForUpdate,
            Delivered: detail.Delivered,
            Returned: detail.RTS,
          });
        });
      }
      setBarGraph(graph);
      setLoading(false);
    } catch (error) {
      setToastKind("error");
      setToastTitle("Delivery Status Failed!");
      setToastDesc(error.message);
      setTimeout(() => {
        setLoading(false);
      }, 1000);
      setTimeout(() => {
        setToastOpen(true);
      }, 1500);
      setTimeout(() => {
        setToastOpen(false);
      }, 3000);
    }
  };

  const GetStatusReportByLastStatusDate = async () => {
    setLoading(true);

    try {
      const response = await axiosCAPApiPrivate.post(
        "/api/GetStatusReportByLastStatusDate",
        {
          CustomerAccountNo: shipperAcctNo,
          FromDate: dateFrom,
          ToDate: dateTo,
        }
      );

      const data = response.data;

      if (data.Code === "200") {
        setLoading(false);

        var graph = [];
        data.Data.map((detail, index) => {
          graph.push({
            country: detail.DateText,
            "Picked up/Dropped Off": 0,
            "For Disposition": detail.Pending,
            "In-transit": detail.ForUpdate,
            Delivered: detail.Delivered,
            Returned: detail.RTS,
          });
        });
      }
      setBarGraph(graph);
      setLoading(false);
    } catch (error) {
      setToastKind("error");
      setToastTitle("Delivery Status Failed!");
      setToastDesc(error.message);
      setTimeout(() => {
        setLoading(false);
      }, 1000);
      setTimeout(() => {
        setToastOpen(true);
      }, 1500);
      setTimeout(() => {
        setToastOpen(false);
      }, 3000);
    }
  };

  const GenerateStatusReport = () => {
    GetStatusReportByLastStatusDate();
  };

  React.useEffect(() => {
    if (shipperAcctNo !== "") {
      if (shipment === "Last Status Date") GetStatusReportByLastStatusDate();
      else GetStatusReportByTransactionDate();
    }
  }, [shipment, shipperAcctNo]);

  React.useEffect(() => {
    //if(shipperAcctNo !== "")
    //GetStatusReportByTransactionDate();
  }, [shipperAcctNo]);

  return (
    <>
      <Div className="w-full">
        <Spacer className="w-10 h-10" />
        <Div className="w-full border border-grey-400 rounded-lg shadow-lg">
          {shipment === "Last Status Date" ? (
            <FlexRow>
              <FlexRow>
                <input
                  className="text-sm w-40 mx-2 block rounded-lg"
                  type="date"
                  value={dateFrom}
                  onChange={(e) => setDateFrom(e.target.value)}
                />
                <input
                  className="text-sm w-40 mx-2 block rounded-lg"
                  type="date"
                  value={dateTo}
                  onChange={(e) => setDateTo(e.target.value)}
                />
              </FlexRow>
              <Button
                className="w-40 ml-2 h-10"
                isLoading={loading}
                onClick={() => GenerateStatusReport()}
              >
                Generate
              </Button>
            </FlexRow>
          ) : (
            <></>
          )}
          <FlexRow className="w-full items-center justify-between py-5">
            <Div className="w-96">
              <RawDropdown
                icon={<FaFilter className="text-secondary-200 text-xl mr-5" />}
                width="w-full"
                value={shipment}
                options={ShipmentOptionsStatusReport}
                onSelect={setShipment}
              />
            </Div>
            <FlexRow className="items-center">
              <Div className="w-48 mx-2">
                <RawDropdown
                  icon={<div />}
                  width="w-full"
                  border="border phone:border-grey-100"
                  value={timeline}
                  options={RecurrenceOptionsStatusReport}
                  onSelect={setTimeline}
                />
              </Div>
              {/*<input
                className="text-sm w-48 mx-2 block rounded-lg"
                type="date"
                value="2023-01-01"
              />
              <FaUserAlt className="text-secondary-200 text-xl mx-2 hover:bg-grey-400" />
              <Link
                className="w-10"
                to="/LBC_shipments.xlsx"
                target="_blank"
                download
              >
                <AiOutlineDownload className="text-secondary-200 text-2xl hover:bg-grey-400 rounded-lg" />
              </Link> */}
            </FlexRow>
          </FlexRow>

          {/* Bar Graph */}
          {loading ? (
            <Text className="text-red-400 text-center flex flex-row justify-center items-center my-20">
              <ImSpinner2 className="animate-spin mr-2 text-2xl desktop:text-3xl" />
              Loading data ...
            </Text>
          ) : (
            <Div className="h-200px w-full">
              <ResponsiveBar
                data={barGraph}
                keys={[
                  "For Disposition",
                  "In-transit",
                  "Delivered",
                  "Returned",
                ]}
                indexBy="country"
                margin={{ top: 12, right: 12, bottom: 25, left: 50 }}
                padding={0.3}
                valueScale={{ type: "linear" }}
                indexScale={{ type: "band", round: true }}
                colors={colors}
                enableGridX={false}
                enableGridY={false}
                borderColor={{
                  from: "color",
                  modifiers: [["darker", 1.6]],
                }}
                axisTop={null}
                axisRight={null}
                axisBottom={{
                  tickSize: 5,
                  tickPadding: 5,
                  tickRotation: 0,
                  legendPosition: "middle",
                  legendOffset: 32,
                }}
                axisLeft={{
                  tickSize: 5,
                  tickPadding: 5,
                  tickRotation: 0,
                  tickValues: 5,
                  legendPosition: "middle",
                  legendOffset: 10,
                }}
                labelSkipWidth={12}
                labelSkipHeight={12}
                labelTextColor={{
                  from: "color",
                  modifiers: [["darker", 1.6]],
                }}
                legends={[]}
                role="application"
                ariaLabel="Nivo bar chart demo"
                barAriaLabel={(e) =>
                  e.id +
                  ": " +
                  e.formattedValue +
                  " in country: " +
                  e.indexValue
                }
              />
            </Div>
          )}

          {/*  Custom Legends - ( Non-Interactive ) */}
          <FlexRow className="items-center justify-start p-5">
            <FlexRow className="w-1/7 items-center justify-start py-2">
              <Spacer className="w-4 h-4 rounded-sm mr-4 bg-red-100" />
              <Text className="text-secondary-100 text-xs font-semibold">
                For Disposition
              </Text>
            </FlexRow>
            <FlexRow className="w-1/7 items-center justify- py-2">
              <Spacer className="w-4 h-4 rounded-sm mr-4 bg-blue-100" />
              <Text className="text-secondary-100 text-xs font-semibold">
                In-transit
              </Text>
            </FlexRow>
            <FlexRow className="w-1/7 items-center justify-start py-2">
              {/* <Spacer className="w-4 h-4 rounded-sm mr-4 bg-green-100" /> */}
              <div
                className="w-4 h-4 rounded-sm mr-4"
                style={{
                  backgroundColor: "#63C9A8",
                }}
              />
              <Text className="text-secondary-100 text-xs font-semibold">
                Delivered
              </Text>
            </FlexRow>
            <FlexRow className="w-1/7 items-center justify-start py-2">
              <Spacer className="w-4 h-4 rounded-sm mr-4 bg-secondary-200" />
              <Text className="text-secondary-100 text-xs font-semibold">
                Returned
              </Text>
            </FlexRow>
          </FlexRow>
        </Div>
      </Div>
    </>
  );
};
