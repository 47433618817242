import React, { useRef } from "react";
import { Cookies } from "react-cookie";
import { Div, FlexRow, Spacer } from "../../../core/Containers";
import { Text } from "../../../core/Text";
import { DraftDataRow, ColumnHeader } from "../../../components/Tables";
import { Pagination } from "../../../components/Tables/Pagination";
import { useNavigate } from "react-router-dom";
import { ImSpinner2 } from "react-icons/im";
import { LBCModal, LBCModalTwoFunc } from "../../../components/Modals";
import moment from "moment";
import axiosCAPApiPrivate from "../../../api/axiosCAPApiPrivate";
import axiosLexaApiPrivate from "../../../api/axiosLexaApiPrivate";

const cookies = new Cookies();

export const DraftTab = ({ dateFrom, dateTo }) => {
  const navigate = useNavigate();
  const dataFetchedRef = useRef(false);

  const [shipperAcctName, setShipperAcctName] = React.useState("");
  const [shipperAcctNo, setShipperAcctNo] = React.useState("");

  const [bookingDetails, setBookingDetails] = React.useState([]);

  const [loading, setLoading] = React.useState(true);
  const [isAscending, setAscending] = React.useState(true);

  const [singleView, setSingleView] = React.useState("default");
  const [selected, setSelected] = React.useState("default");
  const [selectedList, setSelectedList] = React.useState([]);

  const [currentPage, setCurrentPage] = React.useState(1);
  const [articlesPerPage] = React.useState(10);
  const indexOfLastArticle = currentPage * articlesPerPage;
  const indexOfFirstArticle = indexOfLastArticle - articlesPerPage;

  const newItems = bookingDetails.slice(
    indexOfFirstArticle,
    indexOfLastArticle
  );

  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  const [selectedItem, setSelectedItem] = React.useState(null);

  const [isToastOpen, setToastOpen] = React.useState(false);
  const [toastKind, setToastKind] = React.useState("");
  const [toastTitle, setToastTitle] = React.useState("");
  const [toastDesc, setToastDesc] = React.useState("");

  const [isToastOpen2, setToastOpen2] = React.useState(false);
  const [toastKind2, setToastKind2] = React.useState("");
  const [toastTitle2, setToastTitle2] = React.useState("");
  const [toastDesc2, setToastDesc2] = React.useState("");

  React.useEffect(() => {
    if (dataFetchedRef.current) return;
    dataFetchedRef.current = true;

    var _shipperAcct = cookies.get("shipperAcct", { path: "/" });
    var _shipperAcctSplit = _shipperAcct ? _shipperAcct.split("|") : [];
    var _shipperAcctName = _shipperAcctSplit[0]
      ? _shipperAcctSplit[0].toString().trim()
      : "";
    var _shipperAcctNo = _shipperAcctSplit[1]
      ? _shipperAcctSplit[1].toString().trim()
      : "";

    setShipperAcctName(_shipperAcctName);
    setShipperAcctNo(_shipperAcctNo);

    getBookingDetails(_shipperAcctNo);
  }, []);

  React.useEffect(() => {
    var _shipperAcct = cookies.get("shipperAcct", { path: "/" });
    var _shipperAcctSplit = _shipperAcct ? _shipperAcct.split("|") : [];
    var _shipperAcctName = _shipperAcctSplit[0]
      ? _shipperAcctSplit[0].toString().trim()
      : "";
    var _shipperAcctNo = _shipperAcctSplit[1]
      ? _shipperAcctSplit[1].toString().trim()
      : "";

    if (shipperAcctNo !== "" && _shipperAcctNo !== shipperAcctNo) {
      setShipperAcctName(_shipperAcctName);
      setShipperAcctNo(_shipperAcctNo);

      getBookingDetails(_shipperAcctNo);
    }
  }, [cookies.get("shipperAcct", { path: "/" })]);

  React.useEffect(() => {
    if (shipperAcctNo !== "") {
      getBookingDetails(shipperAcctNo);
    }
  }, [dateFrom, dateTo]);

  const getBookingDetails = async (shipperAccountNo) => {
    setBookingDetails([]);
    setLoading(true);

    const reqData = {
      customerAccountNo: shipperAccountNo,
      dateFrom: dateFrom,
      dateTo: dateTo,
      transactionStatus: "DRAFT",
    };

    try {
      const response = await axiosCAPApiPrivate.post(
        `/api/GetCorpDomesticStagingDetails`,
        reqData
      );
      const resData = response.data;

      if (resData.Code === "200") {
        setBookingDetails(resData.Data);
        setBookingDetails(resData.Data);
        setLoading(false);
      } else {
        setToastKind("error");
        setToastTitle("API Fetch Failed!");
        setToastDesc(resData.Message);
        setTimeout(() => {
          setLoading(false);
        }, 1000);
        setTimeout(() => {
          setToastOpen(true);
        }, 1500);
        setTimeout(() => {
          setToastOpen(false);
        }, 5000);
      }
    } catch (error) {
      setToastKind("error");
      setToastTitle("API Fetch Failed!");
      setToastDesc(error.message);
      setTimeout(() => {
        setLoading(false);
      }, 1000);
      setTimeout(() => {
        setToastOpen(true);
      }, 1500);
      setTimeout(() => {
        setToastOpen(false);
      }, 5000);
    }
  };

  const onSelectItems = (id) => {
    if (selectedList.includes(id)) {
      const tempList = [...selectedList].filter((value) => value !== id);
      setSelectedList(tempList);
    } else {
      const tempList = [...selectedList];
      tempList.push(id);
      setSelectedList(tempList);
    }
  };

  const onSort = async (column) => {
    setLoading(true);
    if (isAscending) {
      if (column === 0) {
        const tempList = await bookingDetails.sort((a, b) => {
          if (
            (a.CreatedDate !== ""
              ? moment(a.CreatedDate).format("MMM. D, YYYY")
              : "") <
            (b.CreatedDate !== ""
              ? moment(b.CreatedDate).format("MMM. D, YYYY")
              : "")
          ) {
            return -1;
          }
          if (
            (a.CreatedDate !== ""
              ? moment(a.CreatedDate).format("MMM. D, YYYY")
              : "") >
            (b.CreatedDate !== ""
              ? moment(b.CreatedDate).format("MMM. D, YYYY")
              : "")
          ) {
            return 1;
          }
          return 0;
        });
        setBookingDetails(tempList);
      } else if (column === 1) {
        const tempList = await bookingDetails.sort((a, b) => {
          if (a.Description < b.Description) {
            return -1;
          }
          if (a.Description > b.Description) {
            return 1;
          }
          return 0;
        });
        setBookingDetails(tempList);
      } else if (column === 2) {
        const tempList = await bookingDetails.sort((a, b) => {
          if (a.ProductName < b.ProductName) {
            return -1;
          }
          if (a.ProductName > b.ProductName) {
            return 1;
          }
          return 0;
        });
        setBookingDetails(tempList);
      } else if (column === 3) {
        const tempList = await bookingDetails.sort((a, b) => {
          if (a.DeclaredValue < b.DeclaredValue) {
            return -1;
          }
          if (a.DeclaredValue > b.DeclaredValue) {
            return 1;
          }
          return 0;
        });
        setBookingDetails(tempList);
      } else if (column === 4) {
        const tempList = await bookingDetails.sort((a, b) => {
          if (a.Shipper < b.Shipper) {
            return -1;
          }
          if (a.Shipper > b.Shipper) {
            return 1;
          }
          return 0;
        });
        setBookingDetails(tempList);
      } else if (column === 5) {
        const tempList = await bookingDetails.sort((a, b) => {
          if (a.Consignee < b.Consignee) {
            return -1;
          }
          if (a.Consignee > b.Consignee) {
            return 1;
          }
          return 0;
        });
        setBookingDetails(tempList);
      }
      setTimeout(() => {
        setLoading(false);
        setAscending(!isAscending);
      }, 1000);
    } else {
      if (column === 0) {
        const tempList = await bookingDetails.sort((a, b) => {
          if (
            (a.CreatedDate !== ""
              ? moment(a.CreatedDate).format("MMM. D, YYYY")
              : "") >
            (b.CreatedDate !== ""
              ? moment(b.CreatedDate).format("MMM. D, YYYY")
              : "")
          ) {
            return -1;
          }
          if (
            (a.CreatedDate !== ""
              ? moment(a.CreatedDate).format("MMM. D, YYYY")
              : "") <
            (b.CreatedDate !== ""
              ? moment(b.CreatedDate).format("MMM. D, YYYY")
              : "")
          ) {
            return 1;
          }
          return 0;
        });
        setBookingDetails(tempList);
      } else if (column === 1) {
        const tempList = await bookingDetails.sort((a, b) => {
          if (a.Description > b.Description) {
            return -1;
          }
          if (a.Description < b.Description) {
            return 1;
          }
          return 0;
        });
        setBookingDetails(tempList);
      } else if (column === 2) {
        const tempList = await bookingDetails.sort((a, b) => {
          if (a.ProductName > b.ProductName) {
            return -1;
          }
          if (a.ProductName < b.ProductName) {
            return 1;
          }
          return 0;
        });
        setBookingDetails(tempList);
      } else if (column === 3) {
        const tempList = await bookingDetails.sort((a, b) => {
          if (a.DeclaredValue > b.DeclaredValue) {
            return -1;
          }
          if (a.DeclaredValue < b.DeclaredValue) {
            return 1;
          }
          return 0;
        });
        setBookingDetails(tempList);
      } else if (column === 4) {
        const tempList = await bookingDetails.sort((a, b) => {
          if (a.Shipper > b.Shipper) {
            return -1;
          }
          if (a.Shipper < b.Shipper) {
            return 1;
          }
          return 0;
        });
        setBookingDetails(tempList);
      } else if (column === 5) {
        const tempList = await bookingDetails.sort((a, b) => {
          if (a.Consignee > b.Consignee) {
            return -1;
          }
          if (a.Consignee < b.Consignee) {
            return 1;
          }
          return 0;
        });
        setBookingDetails(tempList);
      }
      setTimeout(() => {
        setLoading(false);
        setAscending(!isAscending);
      }, 1000);
    }
  };

  const updateBulkStatus = async (
    shipperAccountNo,
    bulkSessionID,
    bulkstatus
  ) => {
    setLoading(true);

    const config = {
      headers: {
        shipperuuid: shipperAccountNo,
      },
    };

    const reqData = {
      bulkSessionId: bulkSessionID,
      bulkstatus: bulkstatus,
    };

    try {
      const response = await axiosLexaApiPrivate.post(
        "/UpdateBulkStatus",
        reqData,
        config
      );
      const resData = response.data;

      getBookingDetails(shipperAcctNo);
    } catch (error) {
      setToastKind("error");
      setToastTitle("API Fetch Failed!");
      setToastDesc(error.message);
      setTimeout(() => {
        setLoading(false);
      }, 1000);
      setTimeout(() => {
        setToastOpen(true);
      }, 1500);
      setTimeout(() => {
        setToastOpen(false);
      }, 5000);
    }
  };

  const onDeleteClick = (item) => {
    setSelectedItem(item);
    setToastKind2("Delete");
    setToastTitle2("Delete");
    setToastDesc2("Are you sure you want to delete this item?");
    setToastOpen2(true);
  };

  const onConfirmClick = () => {
    setSelectedList([]);
    if (toastKind2 === "Delete") {
      updateBulkStatus(
        shipperAcctNo,
        selectedItem.BookingNo,
        "DELETED BY USER"
      );
    }

    setToastOpen2(false);
  };

  const onEditClick = (item) => {
    navigate(`/book-now?option=0&bulksessionid=${item.BulkSessionId}`);
  };

  return (
    <>
      <LBCModal
        id="alertModal"
        description={toastDesc}
        isOpen={isToastOpen}
        onClose={() => setToastOpen(false)}
        title={toastTitle}
        toastKind={toastKind}
      />

      <LBCModalTwoFunc
        id="confirmModal"
        bodyClassName="justify-between bg-white border border-grey-400 w-500px p-5 rounded-lg shadow-lg"
        description={toastDesc2}
        isOpen={isToastOpen2}
        onClose={() => setToastOpen2(false)}
        title={toastTitle2}
        buttonTextFirst="Yes"
        buttonTextSecond="No"
        onClickFirst={onConfirmClick}
        onClickSecond={() => setToastOpen2(false)}
      />

      <Div className="w-full ">
        <Spacer className="w-full h-10" />
        <Div className="w-full border border-grey-400 rounded-lg shadow-lg">
          <FlexRow className="items-center justify-between py-2 pl-2 border border-grey-400">
            <ColumnHeader
              title="Date Created"
              onClick={() => onSort(0)}
              containerClass="justify-start hover:bg-grey-400 rounded-full w-32 px-2 py-2"
              titleClassName=""
            />
            <ColumnHeader
              title="Item"
              onClick={() => onSort(1)}
              containerClass="justify-start hover:bg-grey-400 rounded-full w-40 px-2 py-2"
              titleClassName=""
            />
            <ColumnHeader
              title="Packaging"
              onClick={() => onSort(2)}
              containerClass="justify-start hover:bg-grey-400 rounded-full w-40 px-2 py-2"
              titleClassName=""
            />
            <ColumnHeader
              title="Item Value"
              onClick={() => onSort(3)}
              containerClass="justify-start hover:bg-grey-400 rounded-full w-40 px-2 py-2"
              titleClassName=""
            />
            <ColumnHeader
              title="Sender"
              onClick={() => onSort(4)}
              containerClass="justify-start hover:bg-grey-400 rounded-full w-1/6 px-2 py-2"
              titleClassName=""
            />
            <ColumnHeader
              title="Receiver"
              onClick={() => onSort(5)}
              containerClass="justify-start hover:bg-grey-400 rounded-full w-1/6 px-2 py-2"
              titleClassName=""
            />
            <ColumnHeader
              title="Action"
              containerClass="justify-center hover:bg-grey-400 rounded-full w-32 px-2 py-2"
              titleClassName=""
            />
          </FlexRow>
          {loading ? (
            <Text className="text-red-400 text-center flex flex-row justify-center items-center my-20">
              <ImSpinner2 className="animate-spin mr-2 text-2xl desktop:text-3xl" />
              Loading data ...
            </Text>
          ) : (
            <Div className="w-full">
              {newItems.map((item) => (
                <DraftDataRow
                  data={item}
                  onSelect={setSingleView}
                  diableDelete={true}
                  diableEdit={true}
                  onDeleteClick={onDeleteClick}
                  onEditClick={onEditClick}
                />
              ))}
            </Div>
          )}
        </Div>
        <Spacer className="w-full h-10" />
        <Div className="w-full">
          <Pagination
            currentPage={currentPage}
            itemsPerPage={articlesPerPage}
            paginate={paginate}
            totalItems={bookingDetails.length}
          />
        </Div>
      </Div>
    </>
  );
};
