import React, { useRef } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { FlexRow, FlexColumn, Spacer, Div } from "../../core/Containers";
import { Text } from "../../core/Text";
import { Image } from "../../core/Image";
import { isValidPassword } from "../../helpers/common";
import { RawInput } from "../../core/Forms";
import { LBCModal } from "../../components/Modals/LBCModal";
import { Loading } from "../Window/Loading";
import { Images } from "../../assets/images/images";
import { Button } from "../../core/Buttons";
import { HiCheckCircle } from "react-icons/hi";
import axiosCAPApi from "../../api/axiosCAPApi";
import axiosCAPApiPrivate from "../../api/axiosCAPApiPrivate";

export const ChangePassword = () => {
  const navigate = useNavigate();
  const { search } = useLocation();
  const query = new URLSearchParams(search);
  const dataFetchedRef = useRef(false);

  const [loading, setLoading] = React.useState(false);

  const [isToastOpen, setToastOpen] = React.useState(false);
  const [toastKind, setToastKind] = React.useState("");
  const [toastTitle, setToastTitle] = React.useState("Account Created");
  const [toastDesc, setToastDesc] = React.useState(
    "Redirecting to onboarding."
  );
  const [userID, setUserID] = React.useState("");
  const [activeSection, setActiveSection] = React.useState(0);
  const [newPassword, setNewPassword] = React.useState("");
  const [newPasswordErr, setNewPasswordErr] = React.useState("");
  const [confirmNewPassword, setConfirmNewPassword] = React.useState("");
  const [confirmNewPasswordErr, setConfirmNewPasswordErr] = React.useState("");
  const [showNewPass, setShowNewPass] = React.useState(false);
  const [showConfirmNewPass, setConfirmShowNewPass] = React.useState(false);

  React.useEffect(() => {
    if (dataFetchedRef.current) return;
    dataFetchedRef.current = true;

    const _newPassword = document.getElementById("newPassword");
    if (_newPassword !== null) _newPassword.focus();
  }, []);

  React.useEffect(() => {
    const _userID = query.get("userID");

    if (!_userID) {
      navigate("/");
      return;
    }

    setUserID(_userID);
    navigate("/change-password");
  }, [navigate]);

  const updateUserPassword = async () => {
    setLoading(true);

    try {
      const response = await axiosCAPApiPrivate.post(
        "/api/UpdateUserPassword",
        {
          userID,
          newPassword,
          isDefaultPass: true,
        }
      );

      const data = response.data;

      if (data.Code === "200") {
        setActiveSection(1);
        setLoading(false);
      } else {
        setToastKind("error");
        setToastTitle("Update Password Failed!");
        setToastDesc(data.Message);
        setTimeout(() => {
          setLoading(false);
        }, 1000);
        setTimeout(() => {
          setToastOpen(true);
        }, 1500);
        setTimeout(() => {
          setToastOpen(false);
        }, 5000);
      }
    } catch (error) {
      setToastKind("error");
      setToastTitle("Update Password Failed!");
      setToastDesc(error.message);
      setTimeout(() => {
        setLoading(false);
      }, 1000);
      setTimeout(() => {
        setToastOpen(true);
      }, 1500);
      setTimeout(() => {
        setToastOpen(false);
      }, 5000);
    }
  };

  const onSubmitChangePass = () => {
    let validData = true;
    setNewPasswordErr("");
    setConfirmNewPasswordErr("");

    if (newPassword === "") {
      setNewPasswordErr("Password is required.");
      validData = false;
    } else if (!isValidPassword(newPassword)) {
      setNewPasswordErr("Not a valid Password.");
      validData = false;
    }

    if (confirmNewPassword === "") {
      setConfirmNewPasswordErr("Confirm Password is required.");
      validData = false;
    } else if (confirmNewPassword !== newPassword) {
      setConfirmNewPasswordErr("Password and Confirm Password does not match.");
      validData = false;
    }

    if (validData) {
      updateUserPassword();
    }
  };

  return (
    <>
      {loading ? <Loading /> : <></>}

      <LBCModal
        description={toastDesc}
        isOpen={isToastOpen}
        onClose={() => setToastOpen(false)}
        title={toastTitle}
        toastKind={toastKind}
      />

      <FlexRow className="justify-center items-center h-full w-full">
        <FlexColumn className="justify-center items-center w-500px rounded-lg shadow-xl border border-grey-400 desktop:w-600px py-7">
          <Image
            className="h-32 w-36 desktop:h-32 desktop:w-36"
            alt="LBC logo"
            src={Images.LBClogo}
          />

          <Spacer className="h-2" />

          {activeSection === 0 && (
            <>
              <FlexRow className="flex-row justify-center items-center w-full">
                <svg
                  className="w-4 h-4 text-gray-800 dark:text-white cursor-pointer"
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 14 10"
                  onClick={() => navigate(`/`)}
                >
                  <path
                    stroke="currentColor"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M13 5H1m0 0 4 4M1 5l4-4"
                  />
                </svg>
                <Text className="text-secondary-100 text-lg font-bold text-center desktop:text-base ml-3">
                  Create New Password
                </Text>
              </FlexRow>

              <Spacer className="h-5" />

              <Div className="mx-auto flex items-center justify-center w-full max-w-md flex-col">
                <Div className="flex flex-col items-center justify-center text-center space-y-2">
                  <Div className="flex flex-row text-sm font-medium text-gray-400 text-grey-1000">
                    <p>Set your new password</p>
                  </Div>
                </Div>

                <Spacer className="h-5" />

                <Div>
                  <form
                    className="flex flex-col items-center justify-center"
                    onSubmit={(e) => e.preventDefault()}
                  >
                    <Div className="relative w-300px">
                      <div
                        className="absolute inset-y-0 right-0 flex items-center px-3 h-10 cursor-pointer"
                        onClick={() => setShowNewPass(!showNewPass)}
                      >
                        {showNewPass ? (
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            strokeWidth={1.5}
                            stroke="currentColor"
                            className="w-5 h-5"
                          >
                            <path
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              d="M3.98 8.223A10.477 10.477 0 001.934 12C3.226 16.338 7.244 19.5 12 19.5c.993 0 1.953-.138 2.863-.395M6.228 6.228A10.45 10.45 0 0112 4.5c4.756 0 8.773 3.162 10.065 7.498a10.523 10.523 0 01-4.293 5.774M6.228 6.228L3 3m3.228 3.228l3.65 3.65m7.894 7.894L21 21m-3.228-3.228l-3.65-3.65m0 0a3 3 0 10-4.243-4.243m4.242 4.242L9.88 9.88"
                            />
                          </svg>
                        ) : (
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            strokeWidth={1.5}
                            stroke="currentColor"
                            className="w-5 h-5"
                          >
                            <path
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              d="M2.036 12.322a1.012 1.012 0 010-.639C3.423 7.51 7.36 4.5 12 4.5c4.638 0 8.573 3.007 9.963 7.178.07.207.07.431 0 .639C20.577 16.49 16.64 19.5 12 19.5c-4.638 0-8.573-3.007-9.963-7.178z"
                            />
                            <path
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              d="M15 12a3 3 0 11-6 0 3 3 0 016 0z"
                            />
                          </svg>
                        )}
                      </div>
                      <RawInput
                        className="text-secondary-200"
                        containerClass="w-full"
                        name="newPassword"
                        placeholder="Password"
                        onChange={setNewPassword}
                        type={showNewPass ? "text" : "password"}
                        value={newPassword}
                        maxlength={20}
                        validation={newPasswordErr}
                      />
                    </Div>

                    <Spacer className="h-4" />

                    <Div className="flex flex-row text-sm font-medium text-gray-400 text-grey-1000 text-left w-300px">
                      <p>
                        Password must be at least 8 characters, has uppercase
                        and lowercase letters, contains a number and a special
                        character.
                      </p>
                    </Div>

                    <Spacer className="h-4" />

                    <Div className="relative w-300px">
                      <div
                        className="absolute inset-y-0 right-0 flex items-center px-3 h-10 cursor-pointer"
                        onClick={() =>
                          setConfirmShowNewPass(!showConfirmNewPass)
                        }
                      >
                        {showConfirmNewPass ? (
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            strokeWidth={1.5}
                            stroke="currentColor"
                            className="w-5 h-5"
                          >
                            <path
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              d="M3.98 8.223A10.477 10.477 0 001.934 12C3.226 16.338 7.244 19.5 12 19.5c.993 0 1.953-.138 2.863-.395M6.228 6.228A10.45 10.45 0 0112 4.5c4.756 0 8.773 3.162 10.065 7.498a10.523 10.523 0 01-4.293 5.774M6.228 6.228L3 3m3.228 3.228l3.65 3.65m7.894 7.894L21 21m-3.228-3.228l-3.65-3.65m0 0a3 3 0 10-4.243-4.243m4.242 4.242L9.88 9.88"
                            />
                          </svg>
                        ) : (
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            strokeWidth={1.5}
                            stroke="currentColor"
                            className="w-5 h-5"
                          >
                            <path
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              d="M2.036 12.322a1.012 1.012 0 010-.639C3.423 7.51 7.36 4.5 12 4.5c4.638 0 8.573 3.007 9.963 7.178.07.207.07.431 0 .639C20.577 16.49 16.64 19.5 12 19.5c-4.638 0-8.573-3.007-9.963-7.178z"
                            />
                            <path
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              d="M15 12a3 3 0 11-6 0 3 3 0 016 0z"
                            />
                          </svg>
                        )}
                      </div>
                      <RawInput
                        className="text-secondary-200"
                        containerClass="w-full"
                        name="confirmNewPassword"
                        placeholder="Confirm Password"
                        onChange={setConfirmNewPassword}
                        type={showConfirmNewPass ? "text" : "password"}
                        value={confirmNewPassword}
                        maxlength={20}
                        validation={confirmNewPasswordErr}
                      />
                    </Div>

                    <Spacer className="h-5" />

                    <Button
                      className="w-36 ml-2 h-10"
                      type="button"
                      onClick={onSubmitChangePass}
                    >
                      Submit
                    </Button>
                  </form>
                </Div>
              </Div>
            </>
          )}

          {activeSection === 1 && (
            <Div className="w-250px">
              <FlexRow className="flex-col justify-center items-center w-full">
                <HiCheckCircle className="text-green-100 text-4xl h-20 w-20" />
                <Text className="text-secondary-100 text-lg font-bold text-center desktop:text-base ml-3">
                  You've successfully set your new password!
                </Text>
              </FlexRow>

              <Spacer className="h-5" />

              <Div className="mx-auto flex items-center justify-center w-full max-w-md flex-col">
                <Div className="flex flex-col items-center justify-center text-center space-y-2">
                  <Div className="flex flex-row text-sm font-medium text-gray-400 text-grey-1000">
                    <p>You may now login with your new password.</p>
                  </Div>
                </Div>

                <Spacer className="h-10" />

                <Div className="flex flex-col items-center justify-center">
                  <Button
                    className="w-36 ml-2 h-10"
                    type="button"
                    onClick={() => navigate("/")}
                  >
                    Login
                  </Button>
                </Div>
              </Div>
            </Div>
          )}
        </FlexColumn>
      </FlexRow>
    </>
  );
};
