import React from "react";
import { Cookies } from "react-cookie";
import { Div, FlexRow, Spacer } from "../../core/Containers";
import { Text } from "../../core/Text";
import { RawInput } from "../../core/Forms";
import { BiSearchAlt2 } from "react-icons/bi";
import { SlBell } from "react-icons/sl";
import { Link, useNavigate } from "react-router-dom";
import { Dropdown } from "../../core/Buttons/Dropdown";
import { LBCModalSingleFunc } from "../Modals";
import { Loading } from "../../pages/Window/Loading";
import { isNumberWithComma } from "../../helpers/common";
import { RawButton } from "../../core/Buttons";

const cookies = new Cookies();
const duplicatedCommas = /,+/g; // for Mobile Number  

export const TopNavBar = ({
  showSearchBar,
  showNotifBell,
  showMenu,
  setShipperAcct,
  searchTracking,
}) => {
  const navigate = useNavigate();
  const [loading, setLoading] = React.useState(false);
  const [trackingNos, setTrackingNos] = React.useState("");
  const [isToastOpen, setToastOpen] = React.useState(false);

  const onTrackingNosChange = (value) => {
    if (
      isNumberWithComma(value.replaceAll("\n", " ")) &&
      value.substr(value.replaceAll("\n", " ").length - 2) !== ",," &&
      value.substr(value.replaceAll("\n", " ").length - 2) !== "  " &&
      value.substr(value.replaceAll("\n", " ").length - 2) !== "\t\t"
    ) {
      setTrackingNos(value);
    }
  };

  const onSubmit = (e) => {
    e.preventDefault();

    setLoading(true);

    setLoading(false);
    let _trackingNos = "";

    if (trackingNos != "") {
      _trackingNos = trackingNos.replaceAll("\n", ",").replaceAll("\t", ",").replaceAll(" ", ",").replace(duplicatedCommas, ",");
      if (_trackingNos.substring(_trackingNos.length - 1) === ",") {
        _trackingNos = _trackingNos.substring(0, _trackingNos.length - 1);
      }
    }

    const trackingNosSplit = _trackingNos.split(",");

    if (trackingNosSplit && trackingNosSplit.length > 50) {
      setToastOpen(true);
    } else {
      cookies.set("trackingnos", _trackingNos, { path: "/" });
      navigate(`/track-and-trace?option=0`);
    }

    searchTracking && searchTracking();
    setTrackingNos("");
  };

  const onAccountClick = (acct) => {
    if (setShipperAcct) setShipperAcct(acct);
    cookies.set("shipperAcct", acct, { path: "/" });
  };

  return (
    <>
      {loading && <Loading />}

      <LBCModalSingleFunc
        bodyClassName="justify-between bg-white border border-grey-400 w-500px p-5 rounded-lg shadow-lg"
        description={
          <>
            You have exceeded the maximum number of{" "}
            <strong>50 tracking numbers</strong> allowed to continue. Please
            remove some tracking numbers and try again.
          </>
        }
        isOpen={isToastOpen}
        onClose={() => setToastOpen(false)}
        title="LBC Track and Trace"
        buttonText="OK"
        onClick={() => setToastOpen(false)}
      />

      <FlexRow className="w-full px-10 py-5 items-center justify-between bg-grey-400">
        {showSearchBar ? (
          <FlexRow className="items-center border border-grey-400 rounded-lg shadow-lg p-2 bg-white">
            {/* <BiSearchAlt2 className="text-red-400 text-2xl mr-2 ml-1" />
            <form className="" onSubmit={(e) => onSubmit(e)}>
              <RawInput
                className="phone:border-none p-2"
                containerClass="w-400px"
                name="trackingNos"
                placeholder="Track Package"
                onChange={onTrackingNosChange}
                type="text"
                value={trackingNos}
                autoComplete="off"
              />
              <button className="hidden" type="submit">
                Search
              </button>
            </form> */}
            <form onSubmit={(e) => onSubmit(e)}>
              <div className="flex items-center px-1 py-1 rounded-lg bg-gray-50">
                <textarea
                  id="trackingNos"
                  rows="2"
                  cols="40"
                  className="block w-full text-sm text-gray-900 bg-white rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500"
                  placeholder="Track Package"
                  autoComplete="off"
                  onChange={e => onTrackingNosChange(e.target.value)}
                  value={trackingNos}
                />
                <button type="submit" className="inline-flex justify-center ml-2 p-2 text-blue-600 rounded-full cursor-pointer hover:bg-red-70">
                  <BiSearchAlt2 className="text-red-400 text-2xl" />
                </button>
              </div>
            </form>
          </FlexRow>
        ) : (
          <Spacer />
        )}

        <FlexRow className="items-center">
          {/* {showNotifBell ? (
            <Link
              className="text-red-400 text-2xl p-4 mx-2 rounded-lg hover:bg-grey-400 relative"
              to="/notifications"
            >
              <SlBell />
              <Div className="flex items-center justify-center absolute top-2 left-2 bg-red-400 rounded-full h-5 w-5">
                <Text className="text-white text-xs text-center">3</Text>
              </Div>
            </Link>
          ) : (
            <></>
          )} */}

          {showMenu ? (
            <Dropdown onAccountClick={(acct) => onAccountClick(acct)} />
          ) : (
            <></>
          )}
        </FlexRow>
      </FlexRow>
    </>
  );
};
