import React, { useEffect, useState } from "react";
import { Cookies } from "react-cookie";
import { useLocation, useNavigate } from "react-router-dom";
import { Route, Routes } from "react-router-dom";
import { Login } from "./pages/Auth/Login";
import { useIdleTimer, workerTimers } from "react-idle-timer";
import { ForgotPassword } from "./pages/Auth/ForgotPassword";
import { ChangePassword } from "./pages/Auth/ChangePassword";
import {
  BookNow,
  Reports,
  Payments,
  DashboardHome,
  Activity,
  Transaction,
  TrackAndTrace,
} from "./pages/Dashboard/";
import { UserProfile } from "./pages/Profile/UserProfile";
import { Error, Incompatible } from "./pages/Window/Error";
import { NoAuth } from "./pages/Window/NoAuth";
import { Loading } from "./pages/Window/Loading";
import { Text } from "./core/Text";
import { FlexRow } from "./core/Containers";
import { BsFillChatRightDotsFill } from "react-icons/bs";
import { DeliveryStatusReport } from "./pages/Dashboard/DashboardHome/DeliveryStatusReport";
import { RemittanceStatusReport } from "./pages/Dashboard/DashboardHome/RemittanceStatusReport";
import { DashboardDeliveryTableData } from "./pages/Dashboard/DashboardHome/DeliveryReports";
import { DashboardRemittanceCorpTableData } from "./pages/Dashboard/DashboardHome/RemittanceCorpReports";
import { SummaryRemittanceReportsPage } from "./pages/Dashboard/DashboardHome/SummaryRemittanceReportsPage";
import { RemittanceReportsPage } from "./pages/Dashboard/DashboardHome/RemittanceReportsPage";
import { Notifications } from "./pages/Notifications";
import { Logout } from "./pages/Auth/Logout";
import { getCookie, useCookie, useWindowSize } from "./hooks";
import IdleTimeOutModal from "./components/IdleTimeOutModal";

const cookies = new Cookies();

function App() {
  const token = getCookie("accessToken");
  const dimension = useWindowSize();
  const navigate = useNavigate();
  const location = useLocation();

  const [cookie, updateCookie] = useCookie("location", "/");

  // Set timeout values
  const timeout = 1000 * 60 * 20;
  const promptBeforeIdle = 1000 * 30;

  const [open, setOpen] = useState(false);
  const [openAlert, setOpenAlert] = useState(false);
  const [alertType, setAlertType] = useState("");
  const [alertMessage, setAlertMessage] = useState("");
  // Modal open state
  const [openIdle, setOpenIdle] = useState(false);
  // Time before idle
  const [remaining, setRemaining] = useState(0);

  const userInfo = cookies.get("userInfo", { path: "/" });

  const onPrompt = () => {
    // onPrompt will be called after the timeout value is reached
    // In this case 30 minutes. Here you can open your prompt.
    // All events are disabled while the prompt is active.
    // If the user wishes to stay active, call the `reset()` method.
    // You can get the remaining prompt time with the `getRemainingTime()` method,
    setOpenIdle(true);
    setRemaining(promptBeforeIdle);
  };

  const onIdle = () => {
    // onIdle will be called after the promptBeforeIdle is reached.
    // In this case 30 seconds. Here you can close your prompt and
    // perform what ever idle action you want such as log out your user.
    // Events will be rebound as long as `stopOnMount` is not set.
    handleLogout();
    setOpenIdle(false);
    setRemaining(0);
  };

  const onActive = () => {
    // onActive will only be called if `reset()` is called while `isPrompted()`
    // is true. Here you will also want to close your modal and perform
    // any active actions.
    setOpenIdle(false);
    setRemaining(0);
  };

  const { getRemainingTime, isPrompted, reset } = useIdleTimer({
    timeout,
    promptBeforeIdle,
    onPrompt,
    onIdle,
    onActive,
  });

  const handleStillHere = () => {
    setOpenIdle(false);
    reset();
  };

  const handleLogout = () => {
    navigate("/logout", { replace: true });
  };

  useEffect(() => {
    const interval = setInterval(() => {
      if (isPrompted()) {
        setRemaining(Math.ceil(getRemainingTime() / 1000));
      }
    }, 1000);
    return () => {
      clearInterval(interval);
    };
  }, [getRemainingTime, isPrompted]);

  const showAlert = (type, message) => {
    setAlertType(type);
    setAlertMessage(message);
    setOpenAlert(true);
  };

  const hideAlert = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpenAlert(false);
  };

  const [shipperAcct, setShipperAcct] = React.useState("");

  React.useEffect(() => {
    setShipperAcct(
      cookies.get("shipperAcct", { path: "/" })
        ? cookies.get("shipperAcct", { path: "/" })
        : ""
    );
  }, []);

  React.useEffect(() => {
    if (dimension.width < 1024) {
      //navigate('/incompatible');
    } else if (location.pathname === "/incompatible") {
      //navigate(cookie);
    } else {
      updateCookie(location.pathname, 0.5);
    }
  }, [dimension]);

  React.useEffect(() => {
    if (
      token !== "" &&
      (location.pathname === "/" || location.pathname === "/forgot-password")
    ) {
      navigate("/dashboard-home");
    } else if (token !== "" && location.pathname === "*") {
      navigate("/dashboard-home");
    } else if (
      token === "" &&
      location.pathname !== "/" &&
      location.pathname != "/forgot-password" &&
      location.pathname != "/change-password"
    ) {
      navigate("/");
    } else {
      //
    }
  }, [location]);

  return (
    //<div className="phone:h-780px phone:w-1440px tabletWide:h-full tabletWide:w-full relative">
    <div className="h-full w-full relative">
      <Routes>
        {/* Authentication Pages */}
        <Route element={<Login />} path="/" />
        <Route element={<ForgotPassword />} path="/forgot-password" />
        <Route element={<ChangePassword />} path="/change-password" />

        {/* Dashboard Pages */}
        <Route element={<DashboardHome />} path="/dashboard-home" />
        <Route
          element={<DashboardDeliveryTableData shipperAcct={shipperAcct} />}
          path="/dashboard-home/delivery-table-data"
        />
        <Route
          element={
            <DashboardRemittanceCorpTableData shipperAcct={shipperAcct} />
          }
          path="/dashboard-home/remittance-table-data"
        />
        <Route
          element={<SummaryRemittanceReportsPage />}
          path="/dashboard-home/summary-remittance-table-data"
        />
        {/* <Route
          element={<RemittanceReportsPage />}
          path="/dashboard-home/remittance-table-data"
        /> */}
        <Route
          element={<DeliveryStatusReport shipperAcct={shipperAcct} />}
          path="/dashboard-home/delivery-status-report"
        />
        <Route
          element={<RemittanceStatusReport shipperAcct={shipperAcct} />}
          path="/dashboard-home/remittance-status-report"
        />

        {/* Book Now Pages */}
        {(userInfo?.accessModules?.includes("bookNow") ||
          userInfo?.accessModules?.includes("bookNowPUpSched") ||
          userInfo?.accessModules?.includes("bookNowReqSupplies")) && (
          <Route element={<BookNow />} path="/book-now" />
        )}

        {/* Reports Pages */}
        {(userInfo?.accessModules?.includes("reportsStatReport") ||
          userInfo?.accessModules?.includes("reportsdelReport") ||
          userInfo?.accessModules?.includes("reportsRemitReport") ||
          userInfo?.accessModules?.includes("reportsPOD") ||
          userInfo?.accessModules?.includes("reportsRemitHistory")) && (
          <Route element={<Reports />} path="/reports" />
        )}

        {/* Activity Pages */}
        {userInfo?.accessModules?.includes("activityOLBookings") && (
          <Route element={<Activity />} path="/activity" />
        )}

        {/* Transaction Pages */}
        {userInfo?.accessModules?.includes("activityAllTrans") && (
          <Route element={<Transaction />} path="/transaction" />
        )}

        {/* Payment Pages */}
        <Route element={<Payments />} path="/payments" />

        {/* Profile */}
        <Route element={<UserProfile />} path="/profile" />
        <Route element={<Notifications />} path="/notifications" />

        {/* Track and Trace Pages */}
        <Route element={<TrackAndTrace />} path="/track-and-trace" />

        {/* Window States */}
        <Route element={<Error />} path="*" />
        <Route element={<Loading />} path="/load" />
        <Route element={<NoAuth />} path="/no-auth" />
        <Route element={<Logout />} path="/logout" />
        <Route element={<Incompatible />} path="/incompatible" />
      </Routes>

      {/* {location.pathname === "/" ||
      location.pathname === "/forgot-password" ||
      location.pathname === "/incompatible" ||
      location.pathname === "/load" ||
      location.pathname === "/*" ||
      location.pathname === "/no-auth" ||
      location.pathname === "/logout" ? (
        <></>
      ) : (
        <>
          <IdleTimeOutModal
            showModal={openIdle}
            handleLogout={handleLogout}
            handleClose={handleStillHere}
            remaining={remaining}
          />
          <div className="absolute z-10 bottom-0 right-24 h-12 w-36 bg-white rounded-tr-xl rounded-tl-xl">
            <FlexRow className="items-center justify-center h-full w-full bg-grey-200 hover:bg-grey-300 rounded-tr-xl rounded-tl-xl cursor-pointer">
              <BsFillChatRightDotsFill className="text-red-400 mr-2" />
              <Text className="text-red-400 font-semibold">Chat with us!</Text>
            </FlexRow>
          </div>
        </>
      )} */}
    </div>
  );
}

export default App;
