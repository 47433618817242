//import React from 'react';
import React from "react";
import { Cookies } from "react-cookie";
import { Div } from "../../core/Containers";
import { Image } from "../../core/Image";
import { Images } from "../../assets/images/images";
import { Text } from "../../core/Text";
import { ImSpinner2 } from "react-icons/im";
import { useNavigate } from "react-router-dom";
import { browserName } from "react-device-detect";
import axiosCAPApi from "../../api/axiosCAPApi";
import axiosCAPApiPrivate from "../../api/axiosCAPApiPrivate";

const cookies = new Cookies();

export const Logout = () => {
  const navigate = useNavigate();

  const logoutLog = async (data) => {
    try {
      const response = await axiosCAPApiPrivate.post("/api/Authenticate", data);

      const data = response.data;

      if (data.Code === "200") {
      } else {
      }
    } catch (error) {}

    setTimeout(() => {
      navigate("/");
    }, 2000);
  };

  const logout = async () => {
    const response = await fetch("https://ipapi.co/json/");
    const dataIp = await response.json();

    if (typeof cookies.get("accessToken") !== "undefined") {
      var userInfo = cookies.get("userInfo", { path: "/" });

      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(
          (position) => {
            const { latitude, longitude } = position.coords;
            var Geolocation = { latitude, longitude };
            const data = {
              IPAddress: dataIp.ip,
              Browser: browserName,
              Type: "Out",
              HTTPMethod: "POST",
              UserAgent: userInfo.username,
              Latitude: Geolocation.latitude,
              Longitude: Geolocation.longitude,
              URL: window.location.href,
            };
            logoutLog(data);
          },
          (error) => {
            console.error("Error getting user location:", error);
          }
        );
      } else {
        console.error("Geolocation is not supported by this browser.");
        const data = {
          IPAddress: dataIp.ip,
          Browser: browserName,
          Type: "Out",
          HTTPMethod: "POST",
          UserAgent: userInfo.username,
          Latitude: "",
          Longitude: "",
          URL: window.location.href,
        };
        logoutLog(data);
      }
    }

    cookies.remove("userInfo", { path: "/" });
    cookies.remove("accessToken", {
      secure: true,
      path: "/",
      sameSite: "none",
    });
    cookies.remove("lexaAccessToken", {
      secure: true,
      path: "/",
      sameSite: "none",
    });
    cookies.remove("userShipperList", { path: "/" });
    cookies.remove("shipperAcct", { path: "/" });
  };

  React.useEffect(() => {
    logout();
  }, [navigate]);

  return (
    <Div className="fixed top-0 left-0 right-0 bottom-0 z-20 h-screen w-screen flex flex-col justify-center items-center">
      <Image
        className="h-32 w-36 desktop:h-32 desktop:w-36"
        alt="LBC logo"
        src={Images.LBClogo}
      />
      <Text className="text-secondary-200 text-sm text-center flex flex-row justify-center items-center my-4 desktop:text-base">
        <ImSpinner2 className="animate-spin mr-2 desktop:text-xl" />
        Logging out
      </Text>
    </Div>
  );
};
