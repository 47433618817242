import React from "react";
import { Switch } from "@headlessui/react";
import { Cookies } from "react-cookie";
import { Div, FlexRow, Spacer, Divider } from "../../../core/Containers";
import { Text } from "../../../core/Text";
import { LBCModal, LBCModalTwoFunc } from "../../../components/Modals/LBCModal";
import { AnalyticsDropdown } from "../../../core/Buttons/AnalyticsDropdown";
import { ResponsiveBar } from "@nivo/bar";
import { FaUserCircle, FaChartBar } from "react-icons/fa";
import { getCookie } from "../../../hooks";
import { ImSpinner2 } from "react-icons/im";
import { RawButton } from "../../../core/Buttons";
import { RawInput } from "../../../core/Forms";
import { Link } from "react-router-dom";
import Calendar from "react-calendar";
import moment from "moment";
import { useNavigate } from "react-router-dom";
import axiosCAPApi from "../../../api/axiosCAPApi";
import axiosCAPApiPrivate from "../../../api/axiosCAPApiPrivate";

const cookies = new Cookies();

export const Analytics = ({ shipperAcct }) => {
  const navigate = useNavigate();

  const [customLegend, setLegend] = React.useState(true);

  const [loading, setLoading] = React.useState(true);
  const [loading1, setLoading1] = React.useState(true);

  const firstname = getCookie("firstName").toString();
  const lastname = getCookie("lastName").toString();
  //const newDate = new Date().toString().substring(4, 21);
  var newDate = new Date();
  newDate.setHours(newDate.getHours() - 2);
  newDate = newDate.toString().substring(4, 21);

  const [enabled, setEnabled] = React.useState(true);
  const [timeline, setTimeline] = React.useState("Monthly");
  const [current, setCurrent] = React.useState("Delivery Report");
  const [sortDeliveryStatus, setSortDeliveryStatus] = React.useState([
    "Make Default",
  ]);
  const [sortRemittanceStatus, setSortRemittanceStatus] = React.useState([
    "Make Default",
  ]);

  const [userInfo, setUserInfo] = React.useState("");
  const [shipperAcctNo, setShipperAcctNo] = React.useState("");

  const [isToastOpen, setToastOpen] = React.useState(false);
  const [toastKind, setToastKind] = React.useState("");
  const [toastTitle, setToastTitle] = React.useState("");
  const [toastDesc, setToastDesc] = React.useState("");

  React.useEffect(() => {
    setUserInfo(cookies.get("userInfo", { path: "/" }));
    cookies.set("deliveryStatus", "ForUpdate");
  }, []);

  React.useEffect(() => {
    if (shipperAcct) {
      var shipAcctNo = shipperAcct ? shipperAcct.split("|") : "";
      setShipperAcctNo(shipAcctNo[1] ? shipAcctNo[1].toString().trim() : "");
    }
  }, [shipperAcct]);

  const colors = [
    // "#F79520",
    "#F37777",
    "#3173AF",
    "#63C9A8",
    "#202123B3",
    // "#F79520",
  ];

  const [isToastDateFromOpen, setToastDateFromOpen] = React.useState(false);
  const [isToastDateToOpen, setToastDateToOpen] = React.useState(false);
  const [dateFromErr, setDateFromErr] = React.useState("");
  const [dateToErr, setDateToErr] = React.useState("");

  const [dateFrom, setDateFrom] = React.useState(
    moment().subtract(3, "months").format("yyyy-MM-DD")
  );
  const [dateTo, setDateTo] = React.useState(
    moment(new Date()).format("yyyy-MM-DD")
  );

  const [inTransit, setInTransit] = React.useState("");
  const [barGraph, setBarGraph] = React.useState([]);

  const [pickedUpDroppedOffLink, setPickedUpDroppedOffLink] = React.useState(
    "/dashboard-home/delivery-table-data?customerAccountNo=" +
      shipperAcctNo +
      "&deliveryStatus=Picked Up/ Dropped Off&dateFrom=" +
      dateFrom
  );
  const [forDispositionLink, setForDispositionLink] = React.useState(
    "/dashboard-home/delivery-table-data?customerAccountNo=" +
      shipperAcctNo +
      "&deliveryStatus=For Disposition&dateFrom=" +
      dateFrom
  );
  const [intransitLink, setIntransitLink] = React.useState(
    "/dashboard-home/delivery-table-data?customerAccountNo=" +
      shipperAcctNo +
      "&deliveryStatus=In-transit&dateFrom=" +
      dateFrom
  );
  const [deliveredLink, setDeliveredLink] = React.useState(
    "/dashboard-home/delivery-table-data?customerAccountNo=" +
      shipperAcctNo +
      "&deliveryStatus=Delivered&dateFrom=" +
      dateFrom
  );
  const [returnedLink, setReturnedLink] = React.useState(
    "/dashboard-home/delivery-table-data?customerAccountNo=" +
      shipperAcctNo +
      "&deliveryStatus=Returned&dateFrom=" +
      dateFrom
  );
  const [othersLink, setOthersLink] = React.useState(
    "/dashboard-home/delivery-table-data?customerAccountNo=" +
      shipperAcctNo +
      "&deliveryStatus=Others&dateFrom=" +
      dateFrom
  );

  const onSelectMenu = (menu) => {
    setCurrent(menu);
    if (menu === "Delivery Report") {
      navigate(
        `/reports?option=1&filter=${sortDeliveryStatus}&dateFrom=${dateFrom}&dateTo=${dateTo}`
      );
    } else {
      navigate(
        `/reports?option=2&filter=${sortRemittanceStatus}&dateFrom=${dateFrom}&dateTo=${dateTo}`
      );
    }
  };

  // const onSelectItems = (type, sort) => {
  //   if (type === "Delivery Report") {
  //     if (
  //       sort === "Make Default" &&
  //       !sortDeliveryStatus.includes("Make Default")
  //     ) {
  //       setSortDeliveryStatus([sort]);
  //     } else if (
  //       sort !== "Make Default" &&
  //       sortDeliveryStatus.includes("Make Default")
  //     ) {
  //       setSortDeliveryStatus([sort]);
  //     } else if (
  //       sort === "Others" &&
  //       sortDeliveryStatus.includes("Make Default")
  //     ) {
  //       setSortDeliveryStatus(["Others", "Picked up/Dropped Off"]);
  //     } else if (
  //       sort === "Others" &&
  //       (sortDeliveryStatus.includes("Picked up/Dropped Off") ||
  //         sortDeliveryStatus.includes("Others"))
  //     ) {
  //       const tempList = [...sortDeliveryStatus].filter(
  //         (value) => value !== sort && value !== "Picked up/Dropped Off"
  //       );
  //       setSortDeliveryStatus(tempList);
  //     } else if (
  //       sort === "Others" &&
  //       (!sortDeliveryStatus.includes("Picked up/Dropped Off") ||
  //         !sortDeliveryStatus.includes("Others"))
  //     ) {
  //       const tempList = [...sortDeliveryStatus];
  //       tempList.push("Others");
  //       tempList.push("Picked up/Dropped Off");
  //       setSortDeliveryStatus(tempList);
  //     } else if (sortDeliveryStatus.includes(sort)) {
  //       const tempList = [...sortDeliveryStatus].filter(
  //         (value) => value !== sort
  //       );
  //       setSortDeliveryStatus(tempList);
  //     } else {
  //       const tempList = [...sortDeliveryStatus];
  //       tempList.push(sort);
  //       setSortDeliveryStatus(tempList);
  //     }
  //   } else {
  //     if (sortRemittanceStatus.includes(sort)) {
  //       const tempList = [...sortRemittanceStatus].filter(
  //         (value) => value !== sort
  //       );
  //       setSortRemittanceStatus(tempList);
  //     } else {
  //       const tempList = [...sortRemittanceStatus];
  //       tempList.push(sort);
  //       setSortRemittanceStatus(tempList);
  //     }
  //   }

  //   cookies.set("deliveryStatus", sort)
  // };

  const onSelectItems = (type, sort) => {
    setCurrent(type);
    if (type === "Delivery Report") {
      if (sort === "Make Default") {
        setSortDeliveryStatus(["Make Default"]);
      } else {
        if (sortDeliveryStatus.includes(sort)) {
          const tempList = [...sortDeliveryStatus].filter(
            (value) => value !== sort && value !== "Make Default"
          );
          setSortDeliveryStatus(
            tempList.length > 0 ? tempList : ["Make Default"]
          );
        } else {
          const tempList = [...sortDeliveryStatus].filter(
            (value) => value !== "Make Default"
          );
          tempList.push(sort);
          setSortDeliveryStatus(tempList);
        }
      }
    } else {
      if (sort === "Make Default") {
        setSortRemittanceStatus(["Make Default"]);
      } else {
        if (sortRemittanceStatus.includes(sort)) {
          const tempList = [...sortRemittanceStatus].filter(
            (value) => value !== sort && value !== "Make Default"
          );
          setSortRemittanceStatus(
            tempList.length > 0 ? tempList : ["Make Default"]
          );
        } else {
          const tempList = [...sortRemittanceStatus].filter(
            (value) => value !== "Make Default"
          );
          tempList.push(sort);
          setSortRemittanceStatus(tempList);
        }
      }
    }

    cookies.set("deliveryStatus", sort);
  };

  const onDateFromClick = () => {
    setToastDateFromOpen(true);
  };

  const onDateToClick = () => {
    setToastDateToOpen(true);
  };

  const onDateFromChange = (value) => {
    setDateFrom(moment(value).format("yyyy-MM-DD"));
    setToastDateFromOpen(false);
  };

  const onDateToChange = (value) => {
    setDateTo(moment(value).format("yyyy-MM-DD"));
    setToastDateToOpen(false);
  };

  //const onSelectedTimeline = () => {
  //  getDeliveryStatusSummary();
  //};

  // const getDeliveryStatusSummary = () => {
  //   setLoading(true);

  //   Auth.validateToken()
  //     .then(async (response) => {
  //       return await response.data;
  //     })
  //     .then((data) => {
  //       if (data.Code === "403") {
  //         Auth.refreshToken()
  //           .then(async (response) => {
  //             return await response.data;
  //           })
  //           .then(async (data) => {
  //             if (data.Code === "200") {
  //               const userInfo = cookies.get("userInfo");
  //               const newToken = data.Data;
  //               userInfo.token = newToken;
  //               cookies.set("userInfo", userInfo, { path: "/" });
  //               cookies.set("accessToken", newToken, { path: "/" });

  //               procGetDeliveryStatusSummary();
  //             } else {
  //               setToastKind("error");
  //               setToastTitle("Delivery Status Failed!");
  //               setToastDesc(data.message);
  //               setTimeout(() => {
  //                 setLoading(false);
  //               }, 1000);
  //               setTimeout(() => {
  //                 setToastOpen(true);
  //               }, 1500);
  //               setTimeout(() => {
  //                 setToastOpen(false);
  //               }, 3000);
  //             }
  //           })
  //           .catch((error) => {
  //             setToastKind("error");
  //             setToastTitle("Delivery Status Failed!");
  //             setToastDesc(error.message);
  //             setTimeout(() => {
  //               setLoading(false);
  //             }, 1000);
  //             setTimeout(() => {
  //               setToastOpen(true);
  //             }, 1500);
  //             setTimeout(() => {
  //               setToastOpen(false);
  //             }, 3000);
  //           });
  //       } else {
  //         procGetDeliveryStatusSummary();
  //       }
  //     })
  //     .catch((error) => {
  //       setToastKind("error");
  //       setToastTitle("Delivery Status Failed!");
  //       setToastDesc(error.message);
  //       setTimeout(() => {
  //         setLoading(false);
  //       }, 1000);
  //       setTimeout(() => {
  //         setToastOpen(true);
  //       }, 1500);
  //       setTimeout(() => {
  //         setToastOpen(false);
  //       }, 3000);
  //     });
  // };

  const getDeliveryStatusSummary = async () => {
    setLoading(true);

    const data = {
      CustomerAccountNo: shipperAcctNo,
      FromDate: dateFrom,
      ToDate: dateTo,
    };

    try {
      const response = await axiosCAPApiPrivate.post(
        "/api/GetDeliveryStatusSummary",
        {
          CustomerAccountNo: shipperAcctNo,
          FromDate: dateFrom,
          ToDate: dateTo,
        }
      );

      const data = response.data;

      if (data.Code === "200") {
        setInTransit(data.Data);

        setForDispositionLink(
          "/dashboard-home/delivery-table-data?customerAccountNo=" +
            shipperAcctNo +
            "&deliveryStatus=For Disposition&dateFrom=" +
            dateFrom +
            "&dateTo=" +
            dateTo +
            "&count=" +
            data.Data.Pending
        );
        setIntransitLink(
          "/dashboard-home/delivery-table-data?customerAccountNo=" +
            shipperAcctNo +
            "&deliveryStatus=In-transit&dateFrom=" +
            dateFrom +
            "&dateTo=" +
            dateTo +
            "&count=" +
            data.Data.ForUpdate
        );
        setDeliveredLink(
          "/dashboard-home/delivery-table-data?customerAccountNo=" +
            shipperAcctNo +
            "&deliveryStatus=Delivered&dateFrom=" +
            dateFrom +
            "&dateTo=" +
            dateTo +
            "&count=" +
            data.Data.Delivered
        );
        setReturnedLink(
          "/dashboard-home/delivery-table-data?customerAccountNo=" +
            shipperAcctNo +
            "&deliveryStatus=Returned&dateFrom=" +
            dateFrom +
            "&dateTo=" +
            dateTo +
            "&count=" +
            data.Data.RTS
        );
        setOthersLink(
          "/dashboard-home/delivery-table-data?customerAccountNo=" +
            shipperAcctNo +
            "&deliveryStatus=Others&dateFrom=" +
            dateFrom +
            "&dateTo=" +
            dateTo +
            "&count=" +
            data.Data.Lost
        );

        setLoading(false);
      }
      setLoading(false);
    } catch (error) {
      setToastKind("error");
      setToastTitle("Delivery Status Failed!");
      setToastDesc(error.message);
      setTimeout(() => {
        setLoading(false);
      }, 1000);
      setTimeout(() => {
        setToastOpen(true);
      }, 1500);
      setTimeout(() => {
        setToastOpen(false);
      }, 3000);
    }
  };

  // const getDeliveryStatusSummaryForThreeMonths = () => {
  //   setLoading1(true);

  //   Auth.validateToken()
  //     .then(async (response) => {
  //       return await response.data;
  //     })
  //     .then((data) => {
  //       if (data.Code === "403") {
  //         Auth.refreshToken()
  //           .then(async (response) => {
  //             return await response.data;
  //           })
  //           .then(async (data) => {
  //             if (data.Code === "200") {
  //               const userInfo = cookies.get("userInfo");
  //               const newToken = data.Data;
  //               userInfo.token = newToken;
  //               cookies.set("userInfo", userInfo, { path: "/" });
  //               cookies.set("accessToken", newToken, { path: "/" });

  //               procGetDeliveryStatusSummaryForThreeMonths();
  //             } else {
  //               setToastKind("error");
  //               setToastTitle("Delivery Status Failed!");
  //               setToastDesc(data.message);
  //               setTimeout(() => {
  //                 setLoading1(false);
  //               }, 1000);
  //               setTimeout(() => {
  //                 setToastOpen(true);
  //               }, 1500);
  //               setTimeout(() => {
  //                 setToastOpen(false);
  //               }, 3000);
  //             }
  //           })
  //           .catch((error) => {
  //             setToastKind("error");
  //             setToastTitle("Delivery Status Failed!");
  //             setToastDesc(error.message);
  //             setTimeout(() => {
  //               setLoading1(false);
  //             }, 1000);
  //             setTimeout(() => {
  //               setToastOpen(true);
  //             }, 1500);
  //             setTimeout(() => {
  //               setToastOpen(false);
  //             }, 3000);
  //           });
  //       } else {
  //         procGetDeliveryStatusSummaryForThreeMonths();
  //       }
  //     })
  //     .catch((error) => {
  //       setToastKind("error");
  //       setToastTitle("Delivery Status Failed!");
  //       setToastDesc(error.message);
  //       setTimeout(() => {
  //         setLoading1(false);
  //       }, 1000);
  //       setTimeout(() => {
  //         setToastOpen(true);
  //       }, 1500);
  //       setTimeout(() => {
  //         setToastOpen(false);
  //       }, 3000);
  //     });
  // };

  const getDeliveryStatusSummaryForThreeMonths = async () => {
    setLoading1(true);

    const data = {
      CustomerAccountNo: shipperAcctNo,
    };

    try {
      const response = await axiosCAPApiPrivate.post(
        "/api/GetDeliveryStatusSummaryForThreeMonths",
        {
          CustomerAccountNo: shipperAcctNo,
        }
      );

      const data = response.data;

      if (data.Code === "200") {
        setLoading1(false);

        var graph = [];
        data.Data?.map((detail, index) => {
          graph.push({
            country: detail.DateText,
            "Picked up/Dropped Off": 0,
            "For Disposition": detail.Pending,
            "In-transit": detail.ForUpdate,
            Delivered: detail.Delivered,
            Returned: detail.RTS,
          });
        });
      }
      if (graph != null) setBarGraph(graph);
      setLoading1(false);
    } catch (error) {
      setToastKind("error");
      setToastTitle("Delivery Status Failed!");
      setToastDesc(error.message);
      setTimeout(() => {
        setLoading1(false);
      }, 1000);
      setTimeout(() => {
        setToastOpen(true);
      }, 1500);
      setTimeout(() => {
        setToastOpen(false);
      }, 3000);
    }
  };

  const getRemittanceCorpCollectedSummaryForThreeMonths = async () => {
    setLoading1(true);
    const data = {
      CustomerAccountNo: shipperAcctNo,
      ReportRange: timeline,
    };

    try {
      const response = await axiosCAPApiPrivate.post(
        "/api/GetRemittanceCorpCollectedSummaryForThreeMonths",
        {
          CustomerAccountNo: shipperAcctNo,
          ReportRange: timeline,
        }
      );

      const data = response.data;

      if (data.Code === "200") {
        setLoading1(false);
        var graph = [];
        data.Data.map((detail, index) => {
          graph.push({
            country: detail.DateText,
            Remitted: detail.Remitted,
            UnRemitted: detail.UnRemitted,
          });
        });
      }
      setBarGraph(graph);
      setLoading1(false);
    } catch (error) {
      setToastKind("error");
      setToastTitle("Delivery Status Failed!");
      setToastDesc(error.message);
      setTimeout(() => {
        setLoading1(false);
      }, 1000);
      setTimeout(() => {
        setToastOpen(true);
      }, 1500);
      setTimeout(() => {
        setToastOpen(false);
      }, 3000);
    }
  };

  // React.useEffect(() => {
  //   setTimeout(() => {
  //     setLoading(false);
  //   }, 1000);
  //   setTimeout(() => {
  //     setLoading1(false);
  //   }, 1500);
  // }, []);

  // React.useEffect(() => {
  //   setLoading(true);
  //   setLoading1(true);
  //   setTimeout(() => {
  //     setLoading(false);
  //   }, 1000);
  //   setTimeout(() => {
  //     setLoading1(false);
  //   }, 1500);
  // }, [timeline]);

  // React.useEffect(() => {
  //   getDeliveryStatusSummary();
  // }, []);

  React.useEffect(() => {
    setPickedUpDroppedOffLink(
      "/dashboard-home/delivery-table-data?customerAccountNo=" +
        shipperAcctNo +
        "&deliveryStatus=Picked Up/ Dropped Off&dateFrom=" +
        dateFrom +
        "&dateTo=" +
        dateTo
    );
    setForDispositionLink(
      "/dashboard-home/delivery-table-data?customerAccountNo=" +
        shipperAcctNo +
        "&deliveryStatus=For Disposition&dateFrom=" +
        dateFrom +
        "&dateTo=" +
        dateTo
    );
    setIntransitLink(
      "/dashboard-home/delivery-table-data?customerAccountNo=" +
        shipperAcctNo +
        "&deliveryStatus=In-transit&dateFrom=" +
        dateFrom +
        "&dateTo=" +
        dateTo
    );
    setDeliveredLink(
      "/dashboard-home/delivery-table-data?customerAccountNo=" +
        shipperAcctNo +
        "&deliveryStatus=Delivered&dateFrom=" +
        dateFrom +
        "&dateTo=" +
        dateTo
    );
    setReturnedLink(
      "/dashboard-home/delivery-table-data?customerAccountNo=" +
        shipperAcctNo +
        "&deliveryStatus=Returned&dateFrom=" +
        dateFrom +
        "&dateTo=" +
        dateTo
    );
    setOthersLink(
      "/dashboard-home/delivery-table-data?customerAccountNo=" +
        shipperAcctNo +
        "&deliveryStatus=Others&dateFrom=" +
        dateFrom +
        "&dateTo=" +
        dateTo
    );

    if (
      shipperAcctNo != null &&
      shipperAcctNo != "" &&
      dateFrom !== "" &&
      dateTo !== ""
    )
      getDeliveryStatusSummary();
  }, [shipperAcctNo, dateFrom, dateTo]);

  React.useEffect(() => {
    if (shipperAcctNo != null && shipperAcctNo != "") {
      getDeliveryStatusSummaryForThreeMonths();
      //getRemittanceCorpCollectedSummaryForThreeMonths();
    }
  }, [shipperAcctNo]);

  const onGenerateClick = () => {
    navigate(`/reports?option=0&filter=&dateFrom=${dateFrom}&dateTo=${dateTo}`);
  };

  return (
    <Div className="border border-grey-400 rounded-xl shadow-lg w-2/5 h-full">
      <FlexRow className="items-center justify-start p-5">
        <FaUserCircle className="text-grey-300 rounded-full w-10 h-10 laptop:h-16 laptop:w-16" />
        <Spacer className="w-5" />
        <Div>
          <Text className="text-secondary-100 font-bold text-2xl laptop:text-3xl">
            Hello, {userInfo && userInfo.fullName}!
          </Text>
          {/* <Text className="text-secondary-200">
            Account Number: {shipperAcctNo}
          </Text> */}
        </Div>
      </FlexRow>
      <Divider className="text-grey-400" />
      <FlexRow className="w-full items-center justify-between">
        <AnalyticsDropdown
          onSelectMenu={onSelectMenu}
          onSelectSubMenu={onSelectItems}
          list1={sortDeliveryStatus}
          list2={sortRemittanceStatus}
          value={current}
        />
      </FlexRow>

      <FlexRow className="w-full items-center justify-between px-4">
        <LBCModalTwoFunc
          id="dateFromCalendar"
          description={
            <>
              <FlexRow className="w-full items-start justify-center">
                <Calendar
                  className="pickup-calendar"
                  onChange={(value) => onDateFromChange(value)}
                  value={dateFrom}
                  calendarType="US"
                />
              </FlexRow>
            </>
          }
          isOpen={isToastDateFromOpen}
          onClose={() => setToastDateFromOpen(false)}
          title="Select a Date From"
          toastKind="info"
          loading={loading}
          showContorl={false}
        />

        <FlexRow className="w-1/2 items-start justify-start py-2">
          <Text className="text-secondary-200 text-sm text-right w-1/3 pr-2 pt-2">
            From:
          </Text>
          <Div className="relative w-full pr-2" onClick={onDateFromClick}>
            <div className="absolute inset-y-0 right-4 flex items-center pl-3 pointer-events-none h-10">
              <svg
                aria-hidden="true"
                className="w-5 h-5 text-grey-400 dark:text-grey-400"
                style={{ color: "rgb(139 139 139)" }}
                fill="currentColor"
                viewBox="0 0 25 25"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fillRule="evenodd"
                  d="M6.75 2.25A.75.75 0 017.5 3v1.5h9V3A.75.75 0 0118 3v1.5h.75a3 3 0 013 3v11.25a3 3 0 01-3 3H5.25a3 3 0 01-3-3V7.5a3 3 0 013-3H6V3a.75.75 0 01.75-.75zm13.5 9a1.5 1.5 0 00-1.5-1.5H5.25a1.5 1.5 0 00-1.5 1.5v7.5a1.5 1.5 0 001.5 1.5h13.5a1.5 1.5 0 001.5-1.5v-7.5z"
                  clipRule="evenodd"
                ></path>
              </svg>
            </div>
            <RawInput
              className="text-secondary-200 text-sm h-10 pr-8 placeholder:italic"
              containerClass="w-full"
              name="dateFrom"
              placeholder="Select Date"
              onChange={setDateFrom}
              value={dateFrom}
              type="text"
              readOnly={true}
              autoComplete="off"
              validation={dateFromErr}
            />
          </Div>
          {/* <Spacer className="w-1/3 pl-2" /> */}
        </FlexRow>

        <LBCModalTwoFunc
          id="dateToCalendar"
          description={
            <>
              <FlexRow className="w-full items-start justify-center">
                <Calendar
                  className="pickup-calendar"
                  onChange={(value) => onDateToChange(value)}
                  value={dateTo}
                  calendarType="US"
                />
              </FlexRow>
            </>
          }
          isOpen={isToastDateToOpen}
          onClose={() => setToastDateToOpen(false)}
          title="Select a Date To"
          toastKind="info"
          loading={loading}
          showContorl={false}
        />

        <FlexRow className="w-1/2 items-start justify-start py-2">
          <Text className="text-secondary-200 text-sm text-right w-1/3 pr-2 pt-2">
            To:
          </Text>
          <Div className="relative w-full pr-2" onClick={onDateToClick}>
            <div className="absolute inset-y-0 right-4 flex items-center pl-3 pointer-events-none h-10">
              <svg
                aria-hidden="true"
                className="w-5 h-5 text-grey-400 dark:text-grey-400"
                style={{ color: "rgb(139 139 139)" }}
                fill="currentColor"
                viewBox="0 0 25 25"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fillRule="evenodd"
                  d="M6.75 2.25A.75.75 0 017.5 3v1.5h9V3A.75.75 0 0118 3v1.5h.75a3 3 0 013 3v11.25a3 3 0 01-3 3H5.25a3 3 0 01-3-3V7.5a3 3 0 013-3H6V3a.75.75 0 01.75-.75zm13.5 9a1.5 1.5 0 00-1.5-1.5H5.25a1.5 1.5 0 00-1.5 1.5v7.5a1.5 1.5 0 001.5 1.5h13.5a1.5 1.5 0 001.5-1.5v-7.5z"
                  clipRule="evenodd"
                ></path>
              </svg>
            </div>
            <RawInput
              className="text-secondary-200 text-sm h-10 pr-8 placeholder:italic"
              containerClass="w-full"
              name="dateTo"
              placeholder="Select Date"
              onChange={setDateTo}
              value={dateTo}
              type="text"
              readOnly={true}
              autoComplete="off"
              validation={dateToErr}
            />
          </Div>
          {/* <Spacer className="w-1/3 pl-2" /> */}
        </FlexRow>

        <FlexRow className="w-28 items-start justify-start py-2">
          <Div
            className={`text-white text-center font-semibold bg-red-400 hover:bg-opacity-80 rounded-lg p-2 inline-flex justify-center items-center w-full h-10`}
            onClick={onGenerateClick}
          >
            Generate
          </Div>
        </FlexRow>

        {/* <AiOutlineCalendar className="text-secondary-200 text-xl m-5 hover:bg-grey-400 rounded-lg" /> */}
      </FlexRow>

      <LBCModal
        description={toastDesc}
        isOpen={isToastOpen}
        onClose={() => setToastOpen(false)}
        title={toastTitle}
        toastKind={toastKind}
      />

      {loading ? (
        <Text className="text-red-400 text-center flex flex-row justify-center items-center my-10">
          <ImSpinner2 className="animate-spin mr-2 text-2xl desktop:text-3xl" />
        </Text>
      ) : (
        <Div className="w-full">
          <FlexRow className="items-start justify-between px-2">
            <Div className="w-1/4 p-2">
              <Text className="text-secondary-200 text-xs">
                Total Transactions
              </Text>
              <Text className="text-secondary-100 text-xs font-bold">
                {inTransit ? inTransit.Total.toLocaleString() : "0"}
              </Text>
            </Div>
            {/* <Div className="w-1/4 p-2">
              <Link to={pickedUpDroppedOffLink} params={{ shipperAcct: { shipperAcct } }} >
                <Text className="text-secondary-200 text-xs">Pickup/ Dropped Off</Text>
              </Link>
              <Text className="text-secondary-100 text-xs font-bold">0</Text>
            </Div> */}
            <Div className="w-1/4 p-2">
              <Link to={returnedLink}>
                <Text className="text-secondary-200 text-xs">Returned</Text>
              </Link>
              <Text className="text-secondary-100 text-xs font-bold">
                {inTransit ? inTransit.RTS.toLocaleString() : "0"}
              </Text>
            </Div>
            <Div className="w-1/4 p-2">
              <Link to={othersLink}>
                <Text className="text-secondary-200 text-xs">Others</Text>
              </Link>
              <Text className="text-secondary-100 text-xs font-bold">
                {inTransit ? inTransit.Lost.toLocaleString() : "0"}
              </Text>
            </Div>
          </FlexRow>
          <Divider className="text-grey-400 p-2" />
          <FlexRow className="items-start justify-between px-2">
            <Div className="w-1/4 p-2">
              <Link to={deliveredLink}>
                <Text className="text-secondary-200 text-xs">Delivered</Text>
              </Link>
              <Text className="text-secondary-100 text-xs font-bold">
                {inTransit ? inTransit.Delivered.toLocaleString() : "0"}
              </Text>
            </Div>
            <Div className="w-1/4 p-2">
              <Link to={intransitLink}>
                <Text className="text-secondary-200 text-xs">In-transit</Text>
              </Link>
              <Text className="text-secondary-100 text-xs font-bold">
                {inTransit ? inTransit.ForUpdate.toLocaleString() : "0"}
              </Text>
            </Div>
            <Div className="w-1/4 p-2">
              <Link to={forDispositionLink}>
                <Text className="text-secondary-200 text-xs">
                  For Disposition
                </Text>
              </Link>
              <Text className="text-secondary-100 text-xs font-bold">
                {inTransit ? inTransit.Pending.toLocaleString() : "0"}
              </Text>
            </Div>
          </FlexRow>
        </Div>
      )}

      <Spacer className="w-5 h-5" />

      <FlexRow className="items-center justify-start px-5">
        <Switch
          checked={enabled}
          onChange={setEnabled}
          className={`${
            enabled ? "bg-red-400" : "bg-secondary-200"
          } relative inline-flex h-6 w-12 items-center rounded-full`}
        >
          <span className="sr-only">{""}</span>
          <span
            className={`${
              enabled ? "translate-x-6" : "translate-x-1"
            } inline-block h-4 w-4 transform rounded-full bg-white transition text-red-400`}
          />
        </Switch>
        <Spacer className="w-5 h-5" />
        <Text className="text-secondary-200 text-sm">Show labels</Text>
        <RawButton
          className="ml-auto cursor-auto"
          onClick={() => setLegend(!customLegend)}
        >
          <div title="This is Test Debugger Button">
            <Text className="text-secondary-200 text-sm">
              Data Updates as of {newDate}
            </Text>
          </div>
        </RawButton>
      </FlexRow>

      <Spacer className="w-10 h-10" />

      {/* Bar Graph */}
      {loading1 ? (
        <Text className="text-red-400 text-center flex flex-row justify-center items-center my-16">
          <ImSpinner2 className="animate-spin mr-2 text-2xl desktop:text-3xl" />
        </Text>
      ) : (
        <>
          {sortDeliveryStatus.length === 0 ? (
            <Div className="py-20 mx-auto">
              <FaChartBar className="mx-auto block text-secondary-200 text-2xl text-center desktop:text-3xl" />
              <Text className="text-secondary-200 text-sm text-center desktop:text-base my-5">
                No data to be displayed
              </Text>
            </Div>
          ) : (
            <Div className="h-200px w-full ">
              <ResponsiveBar
                enableLabel={enabled}
                data={barGraph}
                keys={
                  sortDeliveryStatus.includes("Make Default")
                    ? ["For Disposition", "In-transit", "Delivered", "Returned"]
                    : sortDeliveryStatus
                }
                indexBy="country"
                margin={
                  customLegend
                    ? { top: 50, right: 50, bottom: 50, left: 50 }
                    : { top: 0, right: 200, bottom: 50, left: 0 }
                }
                padding={0.3}
                valueScale={{ type: "linear" }}
                indexScale={{ type: "band", round: true }}
                colors={colors}
                enableGridX={false}
                enableGridY={false}
                borderColor={{
                  from: "color",
                  modifiers: [["darker", 1.6]],
                }}
                axisTop={null}
                axisRight={null}
                axisBottom={{
                  tickSize: 5,
                  tickPadding: 5,
                  tickRotation: 0,
                }}
                axisLeft={null}
                labelSkipWidth={12}
                labelSkipHeight={12}
                labelTextColor={{
                  from: "color",
                  modifiers: [["darker", 1.6]],
                }}
                legends={
                  customLegend
                    ? []
                    : [
                        {
                          dataFrom: "keys",
                          anchor: "bottom-right",
                          direction: "column",
                          justify: false,
                          translateX: 120,
                          translateY: 0,
                          itemsSpacing: 2,
                          itemWidth: 100,
                          itemHeight: 20,
                          itemDirection: "left-to-right",
                          itemOpacity: 0.85,
                          symbolSize: 20,
                          effects: [
                            {
                              on: "hover",
                              style: {
                                itemOpacity: 1,
                              },
                            },
                          ],
                        },
                      ]
                }
                role="application"
                ariaLabel="Nivo bar chart demo"
                barAriaLabel={(e) =>
                  e.id +
                  ": " +
                  e.formattedValue +
                  " in country: " +
                  e.indexValue
                }
              />
            </Div>
          )}
        </>
      )}

      {/*  Custom Legends - ( Non-Interactive ) */}
      {customLegend && !loading1 ? (
        <FlexRow className="items-start justify-center">
          <Div
            className={`w-1/3 p-2 ${
              sortDeliveryStatus.includes("For Disposition") ||
              sortDeliveryStatus.includes("In-transit") ||
              sortDeliveryStatus.includes("Make Default")
                ? ""
                : "hidden"
            }`}
          >
            {sortDeliveryStatus.includes("For Disposition") ||
            sortDeliveryStatus.includes("Make Default") ? (
              <FlexRow className="w-full items-center justify-start py-2">
                {/* <Spacer className='w-4 h-4 rounded-sm mr-4 bg-red-100' /> */}
                <div
                  className="w-4 h-4 rounded-sm mr-4"
                  style={{
                    backgroundColor: sortDeliveryStatus.includes("Make Default")
                      ? "#E94128"
                      : colors[sortDeliveryStatus.indexOf("For Disposition")],
                  }}
                />
                <Link to={forDispositionLink}>
                  <Text className="text-secondary-100 text-xs font-semibold">
                    For Disposition
                  </Text>
                </Link>
              </FlexRow>
            ) : (
              <></>
            )}
            {sortDeliveryStatus.includes("In-transit") ||
            sortDeliveryStatus.includes("Make Default") ? (
              <FlexRow className="w-full items-center justify- py-2">
                {/* <Spacer className='w-4 h-4 rounded-sm mr-4 bg-blue-100' /> */}
                <div
                  className="w-4 h-4 rounded-sm mr-4"
                  style={{
                    backgroundColor: sortDeliveryStatus.includes("Make Default")
                      ? "#3173AF"
                      : colors[sortDeliveryStatus.indexOf("In-transit")],
                  }}
                />
                <Link to={intransitLink}>
                  <Text className="text-secondary-100 text-xs font-semibold">
                    In-transit
                  </Text>
                </Link>
              </FlexRow>
            ) : (
              <></>
            )}
          </Div>
          <Div
            className={`w-1/3 p-2 ${
              sortDeliveryStatus.includes("Delivered") ||
              sortDeliveryStatus.includes("Returned") ||
              sortDeliveryStatus.includes("Make Default")
                ? ""
                : "hidden"
            }`}
          >
            {sortDeliveryStatus.includes("Delivered") ||
            sortDeliveryStatus.includes("Make Default") ? (
              <FlexRow className="w-full items-center justify-start py-2">
                {/* <Spacer className='w-4 h-4 rounded-sm mr-4 bg-green-100' /> */}
                <div
                  className="w-4 h-4 rounded-sm mr-4"
                  style={{
                    backgroundColor: sortDeliveryStatus.includes("Make Default")
                      ? "#63C9A8"
                      : colors[sortDeliveryStatus.indexOf("Delivered")],
                  }}
                />
                <Link to={deliveredLink}>
                  <Text className="text-secondary-100 text-xs font-semibold">
                    Delivered
                  </Text>
                </Link>
              </FlexRow>
            ) : (
              <></>
            )}
            {sortDeliveryStatus.includes("Returned") ||
            sortDeliveryStatus.includes("Make Default") ? (
              <FlexRow className="w-full items-center justify-start py-2">
                {/* <Spacer className='w-4 h-4 rounded-sm mr-4 bg-secondary-200' /> */}
                <div
                  className="w-4 h-4 rounded-sm mr-4"
                  style={{
                    backgroundColor: sortDeliveryStatus.includes("Make Default")
                      ? "#202123B3"
                      : colors[sortDeliveryStatus.indexOf("Returned")],
                  }}
                />
                <Link to={returnedLink}>
                  <Text className="text-secondary-100 text-xs font-semibold">
                    Returned
                  </Text>
                </Link>
              </FlexRow>
            ) : (
              <></>
            )}
          </Div>
        </FlexRow>
      ) : (
        <></>
      )}
    </Div>
  );
};
