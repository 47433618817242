import React, { Fragment, useRef } from "react";
import { Cookies } from "react-cookie";
import { Dialog, Transition } from "@headlessui/react";
import { Div, FlexColumn, FlexRow, Spacer } from "../../core/Containers";
import { Span, Text } from "../../core/Text";
import { Button, RawButton } from "../../core/Buttons";
import { CheckBox, Radio, RawInput, RawSelect } from "../../core/Forms";
import { useNavigate } from "react-router-dom";
import { LBCModal, LBCModalTwoFunc } from "./LBCModal";
import { BookNowStage } from "../../constants/ConstantsData";
import { StageUI } from "../Feature";
import { FormLabel } from "../../core/Forms/FormLabel";
import { CorpPortalAcctPackageItem } from "../Feature/PackageItem";
import { Loading } from "../../pages/Window/Loading";
import moment from "moment/moment";
import {
  getProvinceRegionId,
  isNumber,
  isMobileNumber,
} from "../../helpers/common";
import Calendar from "react-calendar";
import { Images } from "../../assets/images/images";
import { AddressBookModal } from "./AddressBookModal";
import { ImInfo } from "react-icons/im";
import axiosCAPApi from "../../api/axiosCAPApi";
import axiosCAPApiPrivate from "../../api/axiosCAPApiPrivate";
import axiosLexaApiPrivate from "../../api/axiosLexaApiPrivate";
import axiosCommonRefApi from "../../api/axiosCommonRefApi";

const cookies = new Cookies();

const filterConfig = {
  ignoreCase: true,
  ignoreAccents: true,
  trim: true,
  matchFrom: "any",
  stringify: (option) => `${option.label}`,
};

const blackoutDates = [new Date("2023-12-25").getDate()];

export const BookNowModal = ({
  isOpen,
  onClose,
  data,
  onBookingConfirmed,
  setShowConfirmLeave,
}) => {
  const navigate = useNavigate();
  const dataFetchedRef = useRef();

  const [isToastOpen1, setToastOpen1] = React.useState(false);

  const [isToastOpen2, setToastOpen2] = React.useState(false);
  const [toastKind2, setToastKind2] = React.useState("");
  const [toastTitle2, setToastTitle2] = React.useState("");
  const [toastDesc2, setToastDesc2] = React.useState("");

  const [isToastOpen3, setToastOpen3] = React.useState(false);

  const [isToastOpen4, setToastOpen4] = React.useState(false);

  const [isABMOpen, setIsABMOpen] = React.useState(false);

  const [loading, setLoading] = React.useState(false);
  const [currentStage, setStage] = React.useState(1);

  const [bulkSessionId, setBulkSessionId] = React.useState("");
  const [editValidated, setEditValidated] = React.useState(false);

  const [minDate, setMinDate] = React.useState(moment());
  const [maxDate, setMaxDate] = React.useState(moment().add(10, "days"));

  const [isSaveForLater, setIsSaveForLater] = React.useState(false);

  {
    /* == Shipper Account States == */
  }
  const [shipperAcctName, setShipperAcctName] = React.useState("");
  const [shipperAcctNo, setShipperAcctNo] = React.useState("");
  const [customerDetails, setCustomerDetails] = React.useState(null);

  {
    /* == Sender Details States == */
  }
  const [sendingMethod, setSendingMethod] = React.useState("RIDER PICK UP");
  const [senderName, setSenderName] = React.useState("");
  const [senderNameErr, setSenderNameErr] = React.useState("");
  const [senderNum, setSenderNum] = React.useState("");
  const [senderNumErr, setSenderNumErr] = React.useState("");
  const [senderCompany, setSenderCompany] = React.useState("");
  const [senderCompanyErr, setSenderCompanyErr] = React.useState("");
  const [senderUnit, setSenderUnit] = React.useState("");
  const [senderUnitErr, setSenderUnitErr] = React.useState("");
  const [senderStreet, setSenderStreet] = React.useState("");
  const [senderStreetErr, setSenderStreetErr] = React.useState("");
  const [senderProvinceData, setSenderProvinceData] = React.useState([]);
  const [senderProvince, setSenderProvince] = React.useState({
    value: 0,
    label: "Province",
  });
  const [senderProvinceErr, setSenderProvinceErr] = React.useState("");
  const [senderCityData, setSenderCityData] = React.useState([]);
  const [senderCity, setSenderCity] = React.useState({
    value: 0,
    label: "City/Municipality",
  });
  const [senderCityErr, setSenderCityErr] = React.useState("");
  const [senderBrgyData, setSenderBrgyData] = React.useState([]);
  const [senderBrgy, setSenderBrgy] = React.useState({
    value: 0,
    label: "Barangay",
  });
  const [senderBrgyErr, setSenderBrgyErr] = React.useState("");
  const [senderLandmark, setSenderLandmark] = React.useState("");
  const [senderLandmarkErr, setSenderLandmarkErr] = React.useState("");
  const [pickUpDate, setPickUpDate] = React.useState("");
  const [pickUpDateErr, setPickUpDateErr] = React.useState("");
  const [senderSelectedAddress, setSenderSelectedAddress] =
    React.useState(null);
  const [senderProvinceFilled, setSenderProvinceFilled] = React.useState(false);
  const [senderCityFilled, setSenderCityFilled] = React.useState(false);
  const [senderBrgyFilled, setSenderBrgyFilled] = React.useState(false);

  {
    /* == Receiver Details States == */
  }
  const [deliveryMethod, setDeliveryMethod] = React.useState("HOME DELIVERY");
  const [receiverName, setReceiverName] = React.useState("");
  const [receiverNameErr, setReceiverNameErr] = React.useState("");
  const [receiverNum, setReceiverNum] = React.useState("");
  const [receiverNumErr, setReceiverNumErr] = React.useState("");
  const [receiverUnit, setReceiverUnit] = React.useState("");
  const [receiverUnitErr, setReceiverUnitErr] = React.useState("");
  const [receiverStreet, setReceiverStreet] = React.useState("");
  const [receiverStreetErr, setReceiverStreetErr] = React.useState("");
  const [receiverProvinceData, setReceiverProvinceData] = React.useState([]);
  const [receiverProvince, setReceiverProvince] = React.useState({
    value: 0,
    label: "Province",
  });
  const [receiverProvinceErr, setReceiverProvinceErr] = React.useState("");
  const [receiverCityData, setReceiverCityData] = React.useState([]);
  const [receiverCity, setReceiverCity] = React.useState({
    value: 0,
    label: "City/Municipality",
  });
  const [receiverCityErr, setReceiverCityErr] = React.useState("");
  const [receiverBrgyData, setReceiverBrgyData] = React.useState([]);
  const [receiverBrgy, setReceiverBrgy] = React.useState({
    value: 0,
    label: "Barangay",
  });
  const [receiverBrgyErr, setReceiverBrgyErr] = React.useState("");
  const [receiverLandmark, setReceiverLandmark] = React.useState("");
  const [receiverLandmarkErr, setReceiverLandmarkErr] = React.useState("");
  const [receiverProvinceFilled, setReceiverProvinceFilled] =
    React.useState(false);
  const [receiverCityFilled, setReceiverCityFilled] = React.useState(false);
  const [receiverBrgyFilled, setReceiverBrgyFilled] = React.useState(false);

  {
    /* == Package Details States == */
  }
  const [itemName, setItemName] = React.useState("");
  const [itemNameErr, setItemNameErr] = React.useState("");
  const [itemValue, setItemValue] = React.useState("");
  const [itemValueErr, setItemValueErr] = React.useState("");
  const [shipmentType, setShipmentType] = React.useState("Air");
  const [useCODCOP, setUseCODCOP] = React.useState(false);
  const [useCODCOPErr, setUseCODCOPErr] = React.useState("");
  const [collectCODCOPAmt, setCollectCODCOPAmt] = React.useState(false);
  // const [collectFreightAmt, setCollectFreightAmt] = React.useState(false);
  const [codcopAmt, setCODCOPAmt] = React.useState("");
  const [codcopAmtErr, setCODCOPAmtErr] = React.useState("");
  const [actWeight, setActWeight] = React.useState("0");
  const [actWeightErr, setActWeightErr] = React.useState("");
  const [cbmLength, setCBMLength] = React.useState("0");
  const [cbmLengthErr, setCBMLengthErr] = React.useState("");
  const [cbmWidth, setCBMWidth] = React.useState("0");
  const [cbmWidthErr, setCBMWidthErr] = React.useState("");
  const [cbmHeight, setCBMHeight] = React.useState("0");
  const [cbmHeightErr, setCBMHeightErr] = React.useState("");
  const [pkgData, setPkgData] = React.useState([]);
  const [selectedPkg, setSelectedPkg] = React.useState(null);
  const [selectedPkgErr, setSelectedPkgErr] = React.useState("");
  const [pkgServiceFee, setPkgServiceFee] = React.useState(null);
  const [selectedPkgFilled, setSelectedPkgFilled] = React.useState(false);
  const [holidays, setHolidays] = React.useState([]);

  React.useEffect(() => {
    if (dataFetchedRef.current) return;
    dataFetchedRef.current = true;

    if (isOpen) {
      var _shipperAcct = cookies.get("shipperAcct", { path: "/" });
      var _shipperAcctSplit = _shipperAcct ? _shipperAcct.split("|") : [];
      var _shipperAcctName = _shipperAcctSplit[0]
        ? _shipperAcctSplit[0].toString().trim()
        : "";
      var _shipperAcctNo = _shipperAcctSplit[1]
        ? _shipperAcctSplit[1].toString().trim()
        : "";

      setShipperAcctName(_shipperAcctName);
      setShipperAcctNo(_shipperAcctNo);

      getCustomerDetails(_shipperAcctNo);
      getHolidays();

      configPickUpDate();

      if (data && data.editItem) {
        setEditStates(data.editItem, _shipperAcctName, _shipperAcctNo);
      } else {
        setSenderCompany(_shipperAcctName);
        setBulkSessionId(data.bulkSessionId || "");

        getProvinces(sendingMethod);
        getProvinces(deliveryMethod);
      }
    }
  }, [isOpen]);

  React.useEffect(() => {
    if (
      !editValidated &&
      data &&
      data.editItem &&
      senderProvinceFilled &&
      senderCityFilled &&
      senderBrgyFilled &&
      receiverProvinceFilled &&
      receiverCityFilled &&
      receiverBrgyFilled &&
      !loading
    ) {
      validateSenderDetails();
      validateReceiverDetails();
      validatePackageDetails();
      setEditValidated(true);
    }
  }, [
    editValidated,
    senderProvinceFilled,
    senderCityFilled,
    senderBrgyFilled,
    receiverProvinceFilled,
    receiverCityFilled,
    receiverBrgyFilled,
    loading,
  ]);

  {
    /* == Actions == */
  }

  const clearStates = () => {
    setToastOpen1(false);
    setToastOpen2(false);
    setToastKind2("");
    setToastTitle2("");
    setToastDesc2("");
    setToastOpen3(false);
    setToastOpen4(false);
    setIsABMOpen(false);
    setLoading(false);
    setStage(1);
    setBulkSessionId("");
    setEditValidated(false);
    setMinDate(moment());
    setMaxDate(moment().add(10, "days"));

    setShipperAcctName("");
    setShipperAcctNo("");

    setSendingMethod("RIDER PICK UP");
    setSenderName("");
    setSenderNameErr("");
    setSenderNum("");
    setSenderNumErr("");
    setSenderCompany("");
    setSenderCompanyErr("");
    setSenderUnit("");
    setSenderUnitErr("");
    setSenderStreet("");
    setSenderStreetErr("");
    setSenderProvinceData([]);
    setSenderProvince({ value: 0, label: "Province" });
    setSenderProvinceErr("");
    setSenderCityData([]);
    setSenderCity({ value: 0, label: "City/Municipality" });
    setSenderCityErr("");
    setSenderBrgyData([]);
    setSenderBrgy({ value: 0, label: "Barangay" });
    setSenderBrgyErr("");
    setSenderLandmark("");
    setSenderLandmarkErr("");
    setPickUpDate("");
    setPickUpDateErr("");
    setSenderSelectedAddress(null);
    setSenderProvinceFilled(false);
    setSenderCityFilled(false);
    setSenderBrgyFilled(false);

    setDeliveryMethod("HOME DELIVERY");
    setReceiverName("");
    setReceiverNameErr("");
    setReceiverNum("");
    setReceiverNumErr("");
    setReceiverUnit("");
    setReceiverUnitErr("");
    setReceiverStreet("");
    setReceiverStreetErr("");
    setReceiverProvinceData([]);
    setReceiverProvince({ value: 0, label: "Province" });
    setReceiverProvinceErr("");
    setReceiverCityData([]);
    setReceiverCity({ value: 0, label: "City/Municipality" });
    setReceiverCityErr("");
    setReceiverBrgyData([]);
    setReceiverBrgy({ value: 0, label: "Barangay" });
    setReceiverBrgyErr("");
    setReceiverLandmark("");
    setReceiverLandmarkErr("");
    setReceiverProvinceFilled(false);
    setReceiverCityFilled(false);
    setReceiverBrgyFilled(false);

    setItemName("");
    setItemNameErr("");
    setItemValue("");
    setItemValueErr("");
    setShipmentType("Air");
    setUseCODCOP(false);
    setUseCODCOPErr("");
    setCollectCODCOPAmt(false);
    // setCollectFreightAmt(false);
    setCODCOPAmt("");
    setCODCOPAmtErr("");
    setActWeight("0");
    setActWeightErr("");
    setCBMLength("0");
    setCBMLengthErr("");
    setCBMWidth("0");
    setCBMWidthErr("");
    setCBMHeight("0");
    setCBMHeightErr("");
    setPkgData([]);
    setSelectedPkg(null);
    setSelectedPkgErr("");
    setPkgServiceFee(null);
    setSelectedPkgFilled(false);
  };

  const setEditStates = (item, shipperAcctName, shipperAcctNo) => {
    const _senderAddress = item.ShipperStBldg.split(",");
    const _senderUnit = _senderAddress[0] ? _senderAddress[0].trim() : "";
    const _senderStreet = _senderAddress[1] ? _senderAddress[1].trim() : "";
    const _senderLandmark = _senderAddress[2] ? _senderAddress[2].trim() : "";

    const _receiverAddress = item.ConsigneeStBldg.split(",");
    const _receiverUnit = _receiverAddress[0] ? _receiverAddress[0].trim() : "";
    const _receiverStreet = _receiverAddress[1]
      ? _receiverAddress[1].trim()
      : "";
    const _receiverLandmark = _receiverAddress[2]
      ? _receiverAddress[2].trim()
      : "";

    const attachmentNameThree = item.AttachmentNameThree.split("|");
    const _useCODCOP = attachmentNameThree[1]
      ? Number(attachmentNameThree[1].trim())
      : 0;

    setBulkSessionId(item.BulkSessionId);

    setShipperAcctName(item.Shipper);
    setShipperAcctNo(
      item.ShipperAccountNo.trim() === ""
        ? shipperAcctNo
        : item.ShipperAccountNo.trim()
    );

    setSendingMethod(item.ReferenceNoThree);
    setSenderName(item.ContactPerson);
    setSenderNum(item.ShipperMobileNumber);
    setSenderCompany(
      item.Shipper.trim() === "" || item.Shipper.trim() === " "
        ? shipperAcctName
        : item.Shipper.trim()
    );
    setSenderUnit(_senderUnit);
    setSenderStreet(_senderStreet);
    setSenderLandmark(_senderLandmark);
    setPickUpDate(moment(item.PickupDate).format("dddd, MMMM DD, YYYY"));

    setDeliveryMethod(item.ReferenceNoFour);
    setReceiverName(item.Consignee);
    setReceiverNum(item.ConsigneeMobileNumber);
    setReceiverUnit(_receiverUnit);
    setReceiverStreet(_receiverStreet);
    setReceiverLandmark(_receiverLandmark);

    setItemName(item.Description);
    setItemValue(Number(item.DeclaredValue).toFixed(0));
    setShipmentType(item.ShipmentMode === "AIR" ? "Air" : "Sea");
    setUseCODCOP(_useCODCOP !== 0);
    setCollectCODCOPAmt(_useCODCOP === 1 || _useCODCOP === 3);
    // setCollectFreightAmt(_useCODCOP === 2 || _useCODCOP === 3);
    setCODCOPAmt(Number(item.CODAmount).toFixed(0));
    setActWeight(Number(item.ACTWTkgs).toFixed(0));
    setCBMLength(Number(item.LengthCM).toFixed(0));
    setCBMWidth(Number(item.WidthCM).toFixed(0));
    setCBMHeight(Number(item.HeightCM).toFixed(0));

    getProvinces(sendingMethod);
    getProvinces(deliveryMethod);
  };

  const configPickUpDate = () => {
    const cutOffTime = moment().hour(23).minute(0);
    const ncrSameDayPickUpCutOff = moment().hour(12).minute(0);
    const provincialSameDayPickUpCutOff = moment().hour(11).minute(0);

    let subStractDate = 0;
    const provinceRegionId = getProvinceRegionId(
      senderProvinceData,
      senderProvince.value
    );

    if (
      (provinceRegionId === 1 &&
        Number(senderProvince.value) !== 405800000 &&
        moment().isAfter(ncrSameDayPickUpCutOff)) ||
      ((provinceRegionId !== 1 ||
        (provinceRegionId === 1 &&
          Number(senderProvince.value) === 405800000)) &&
        moment().isAfter(provincialSameDayPickUpCutOff))
    ) {
      subStractDate = -1;
    }

    const _minDate = moment().subtract(subStractDate, "days");

    setMinDate(_minDate);

    let _maxDate = moment().add(10, "days");
    const _dayWeek = moment().day();

    if (moment().isAfter(cutOffTime)) {
      if (_dayWeek === 5) {
        _maxDate = moment().add(10, "days");
      } else {
        _maxDate = moment().add(9, "days");
      }
    } else {
      if (_dayWeek === 6) {
        _maxDate = moment().add(9, "days");
      } else {
        _maxDate = moment().add(8, "days");
      }
    }

    setMaxDate(_maxDate);
  };

  const getCustomerDetails = async (customerAccountNo) => {
    setCustomerDetails(null);
    setLoading(true);

    try {
      const response = await axiosCAPApiPrivate.get(
        "/api/GetCustomerDetails/" + customerAccountNo
      );
      const resData = response.data;

      if (resData.Code === "200") {
        setCustomerDetails(resData.Data);
        setLoading(false);
      } else {
        setToastKind2("info");
        setToastTitle2("Invormation");
        setToastDesc2(resData.Message);
        setTimeout(() => {
          setLoading(false);
        }, 1000);
        setTimeout(() => {
          setToastOpen2(true);
        }, 1500);
        setTimeout(() => {
          setToastOpen2(false);
        }, 5000);
      }
    } catch (error) {
      setToastKind2("error");
      setToastTitle2("API Fetch Failed!");
      setToastDesc2(error.message);
      setTimeout(() => {
        setLoading(false);
      }, 1000);
      setTimeout(() => {
        setToastOpen2(true);
      }, 1500);
      setTimeout(() => {
        setToastOpen2(false);
      }, 5000);
    }
  };

  const getProvinces = async (sendDelMethod) => {
    setLoading(true);

    let url = ";";
    if (sendDelMethod === "RIDER PICK UP") {
      url =
        "/RequestRefFirstMileProvince?token=TEJDdmlzdHJhcGhfdXNlclYxc3RSQHBoMjAxNw==";
    } else if (sendDelMethod === "DROP-OFF") {
      url =
        "/RequestRefProvince?token=TEJDdmlzdHJhcGhfdXNlclYxc3RSQHBoMjAxNw==";
    } else if (sendDelMethod === "HOME DELIVERY") {
      url =
        "/RequestRefProvince?token=TEJDdmlzdHJhcGhfdXNlclYxc3RSQHBoMjAxNw==";
    } else {
      url =
        "/RequestRefProvinceWithActiveBranch?token=TEJDdmlzdHJhcGhfdXNlclYxc3RSQHBoMjAxNw==";
    }

    try {
      const response = await axiosCommonRefApi.get(url);
      const resData = response.data;

      if (resData.Code === "200") {
        if (sendDelMethod === "RIDER PICK UP" || sendDelMethod === "DROP-OFF") {
          setSenderProvinceData(resData.Province);
        } else {
          setReceiverProvinceData(resData.Province);
        }

        setLoading(false);
      } else {
        if (sendDelMethod === "RIDER PICK UP" || sendDelMethod === "DROP-OFF") {
          setSenderProvinceData([]);
          if (data && data.editItem) {
            setSenderProvinceFilled(true);
            setSenderCityFilled(true);
            setSenderBrgyFilled(true);
          }
        } else {
          setReceiverProvinceData([]);
          if (data && data.editItem) {
            setReceiverProvinceFilled(true);
            setReceiverCityFilled(true);
            setReceiverBrgyFilled(true);
          }
        }

        setToastKind2("info");
        setToastTitle2("Invormation");
        setToastDesc2(resData.Message);
        setTimeout(() => {
          setLoading(false);
        }, 1000);
        setTimeout(() => {
          setToastOpen2(true);
        }, 1500);
        setTimeout(() => {
          setToastOpen2(false);
        }, 5000);
      }
    } catch (error) {
      setToastKind2("error");
      setToastTitle2("API Fetch Failed!");
      setToastDesc2(error.message);

      if (sendDelMethod === "RIDER PICK UP" || sendDelMethod === "DROP-OFF") {
        setSenderProvinceData([]);
        if (data && data.editItem) {
          setSenderProvinceFilled(true);
          setSenderCityFilled(true);
          setSenderBrgyFilled(true);
        }
      } else {
        setReceiverProvinceData([]);
        if (data && data.editItem) {
          setReceiverProvinceFilled(true);
          setReceiverCityFilled(true);
          setReceiverBrgyFilled(true);
        }
      }

      setTimeout(() => {
        setLoading(false);
      }, 1000);
      setTimeout(() => {
        setToastOpen2(true);
      }, 1500);
      setTimeout(() => {
        setToastOpen2(false);
      }, 5000);
    }
  };

  const getCities = async (sendDelMethod, provinceId) => {
    setLoading(true);

    let url = "";
    if (sendDelMethod === "RIDER PICK UP") {
      url = `/RequestRefFirstMileCity?token=TEJDdmlzdHJhcGhfdXNlclYxc3RSQHBoMjAxNw==&provinceid=${provinceId}`;
    } else if (sendDelMethod === "DROP-OFF") {
      url = `/RequestRefCities?token=TEJDdmlzdHJhcGhfdXNlclYxc3RSQHBoMjAxNw==&provinceid=${provinceId}`;
    } else if (sendDelMethod === "HOME DELIVERY") {
      url = `/RequestRefCities?token=TEJDdmlzdHJhcGhfdXNlclYxc3RSQHBoMjAxNw==&provinceid=${provinceId}`;
    } else {
      url = `/RequestRefCitiesWithActiveBranch?token=TEJDdmlzdHJhcGhfdXNlclYxc3RSQHBoMjAxNw==&provinceid=${provinceId}`;
    }

    try {
      const response = await axiosCommonRefApi.get(url);
      const resData = response.data;

      if (resData.Code === "200") {
        if (sendDelMethod === "RIDER PICK UP" || sendDelMethod === "DROP-OFF") {
          setSenderCityData(resData.City);
        } else {
          setReceiverCityData(resData.City);
        }

        setLoading(false);
      } else if (resData.Code === "204") {
        if (sendDelMethod === "RIDER PICK UP" || sendDelMethod === "DROP-OFF") {
          setSenderCityData([]);
          if (data && data.editItem) {
            setSenderCityFilled(true);
            setSenderBrgyFilled(true);
          }
        } else {
          setReceiverCityData([]);
          if (data && data.editItem) {
            setReceiverCityFilled(true);
            setReceiverBrgyFilled(true);
          }
        }

        setToastKind2("info");
        setToastTitle2("Information");
        setToastDesc2("No serviceable city for this selected province.");
        setTimeout(() => {
          setLoading(false);
        }, 1000);
        setTimeout(() => {
          setToastOpen2(true);
        }, 1500);
        setTimeout(() => {
          setToastOpen2(false);
        }, 5000);
      } else {
        if (sendDelMethod === "RIDER PICK UP" || sendDelMethod === "DROP-OFF") {
          setSenderCityData([]);
          if (data && data.editItem) {
            setSenderCityFilled(true);
            setSenderBrgyFilled(true);
          }
        } else {
          setReceiverCityData([]);
          if (data && data.editItem) {
            setReceiverCityFilled(true);
            setReceiverBrgyFilled(true);
          }
        }

        setToastKind2("info");
        setToastTitle2("Information");
        setToastDesc2(resData.Message);
        setTimeout(() => {
          setLoading(false);
        }, 1000);
        setTimeout(() => {
          setToastOpen2(true);
        }, 1500);
        setTimeout(() => {
          setToastOpen2(false);
        }, 5000);
      }
    } catch (error) {
      setToastKind2("error");
      setToastTitle2("API Fetch Failed!");
      setToastDesc2(error.message);

      if (sendDelMethod === "RIDER PICK UP" || sendDelMethod === "DROP-OFF") {
        setSenderCityData([]);
        if (data && data.editItem) {
          setSenderCityFilled(true);
          setSenderBrgyFilled(true);
        }
      } else {
        setReceiverCityData([]);
        if (data && data.editItem) {
          setReceiverCityFilled(true);
          setReceiverBrgyFilled(true);
        }
      }

      setTimeout(() => {
        setLoading(false);
      }, 1000);
      setTimeout(() => {
        setToastOpen2(true);
      }, 1500);
      setTimeout(() => {
        setToastOpen2(false);
      }, 5000);
    }
  };

  const getBarangays = async (sendDelMethod, provinceId, cityId) => {
    setLoading(true);

    let url = "";
    if (sendDelMethod === "RIDER PICK UP") {
      url = `/RequestRefFirstMileBarangay?token=TEJDdmlzdHJhcGhfdXNlclYxc3RSQHBoMjAxNw==&cityid=${cityId}`;
    } else if (sendDelMethod === "DROP-OFF") {
      url = `/RequestRefBarangayPSGC?token=TEJDdmlzdHJhcGhfdXNlclYxc3RSQHBoMjAxNw==&cityid=${cityId}`;
    } else if (sendDelMethod === "HOME DELIVERY") {
      url = `/RequestRefBarangayPSGC?token=TEJDdmlzdHJhcGhfdXNlclYxc3RSQHBoMjAxNw==&cityid=${cityId}`;
    } else {
      url = `/RequestRefBranch?token=TEJDdmlzdHJhcGhfdXNlclYxc3RSQHBoMjAxNw==&provinceid=${provinceId}&cityid=${cityId}`;
    }

    try {
      const response = await axiosCommonRefApi.get(url);
      const resData = response.data;

      if (resData.Code === "200") {
        if (sendDelMethod === "RIDER PICK UP" || sendDelMethod === "DROP-OFF") {
          setSenderBrgyData(resData.Barangay);
        } else {
          setReceiverBrgyData(resData.Barangay || resData.Branch);
        }

        setLoading(false);
      } else if (resData.Code === "204") {
        if (sendDelMethod === "RIDER PICK UP" || sendDelMethod === "DROP-OFF") {
          setSenderBrgyData([]);
          if (data && data.editItem) {
            setSenderBrgyFilled(true);
          }
        } else {
          setReceiverBrgyData([]);
          if (data && data.editItem) {
            setReceiverBrgyFilled(true);
          }
        }

        setToastKind2("info");
        setToastTitle2("Information");
        if (
          sendDelMethod === "RIDER PICK UP" ||
          sendDelMethod === "HOME DELIVERY"
        ) {
          setToastDesc2("No serviceable barangay for this selected city.");
        } else if (sendDelMethod === "BRANCH PICK UP") {
          setToastDesc2("No active branch for this selected city.");
        }

        setTimeout(() => {
          setLoading(false);
        }, 1000);
        setTimeout(() => {
          setToastOpen2(true);
        }, 1500);
        setTimeout(() => {
          setToastOpen2(false);
        }, 5000);
      } else {
        if (sendDelMethod === "RIDER PICK UP" || sendDelMethod === "DROP-OFF") {
          setSenderBrgyData([]);
          if (data && data.editItem) {
            setSenderBrgyFilled(true);
          }
        } else {
          setReceiverBrgyData([]);
          if (data && data.editItem) {
            setReceiverBrgyFilled(true);
          }
        }

        setToastKind2("info");
        setToastTitle2("Information");
        setToastDesc2(resData.Message);
        setTimeout(() => {
          setLoading(false);
        }, 1000);
        setTimeout(() => {
          setToastOpen2(true);
        }, 1500);
        setTimeout(() => {
          setToastOpen2(false);
        }, 5000);
      }
    } catch (error) {
      setToastKind2("error");
      setToastTitle2("API Fetch Failed!");
      setToastDesc2(error.message);

      if (sendDelMethod === "RIDER PICK UP" || sendDelMethod === "DROP-OFF") {
        setSenderBrgyData([]);
        if (data && data.editItem) {
          setSenderBrgyFilled(true);
        }
      } else {
        setReceiverBrgyData([]);
        if (data && data.editItem) {
          setReceiverBrgyFilled(true);
        }
      }

      setTimeout(() => {
        setLoading(false);
      }, 1000);
      setTimeout(() => {
        setToastOpen2(true);
      }, 1500);
      setTimeout(() => {
        setToastOpen2(false);
      }, 5000);
    }
  };

  const getCorpPortalAcctProducts = async (productFor) => {
    setPkgData([]);
    setLoading(true);

    const reqData = {
      productFor: productFor,
    };

    try {
      const response = await axiosCAPApiPrivate.post(
        "/api/GetCorpPortalAcctProducts",
        reqData
      );
      const resData = response.data;

      if (resData.Code === "200") {
        setPkgData(resData.Data);

        setLoading(false);
      } else {
        setToastKind2("info");
        setToastTitle2("Information");
        setToastDesc2(resData.Message);
        setTimeout(() => {
          setLoading(false);
        }, 1000);
        setTimeout(() => {
          setToastOpen2(true);
        }, 1500);
        setTimeout(() => {
          setToastOpen2(false);
        }, 5000);
      }
    } catch (error) {
      setToastKind2("error");
      setToastTitle2("API Fetch Failed!");
      setToastDesc2(error.message);
      setTimeout(() => {
        setLoading(false);
      }, 1000);
      setTimeout(() => {
        setToastOpen2(true);
      }, 1500);
      setTimeout(() => {
        setToastOpen2(false);
      }, 5000);
    }
  };

  const getHolidays = async () => {
    setHolidays([]);
    setLoading(true);

    try {
      const response = await axiosLexaApiPrivate.get("/getHolidays");
      const resData = response.data;

      setHolidays(resData);
      setLoading(false);
    } catch (error) {
      setToastKind2("error");
      setToastTitle2("API Fetch Failed!");
      setToastDesc2(error.message);
      setTimeout(() => {
        setLoading(false);
      }, 1000);
      setTimeout(() => {
        setToastOpen2(true);
      }, 1500);
      setTimeout(() => {
        setToastOpen2(false);
      }, 5000);
    }
  };

  const calculatePriceNewRates = async () => {
    setPkgServiceFee(null);
    setLoading(true);

    const reqData = {
      OriginProvinceId: Number(senderProvince.value),
      OriginCityId: Number(senderCity.value),
      DestinationCityId: Number(receiverCity.value),
      CorpProductId: Number(selectedPkg.prodId),
      DeclaredValue: Number(itemValue),
      Weight: Number(actWeight),
      SendingMethod: sendingMethod,
      DeliveryMethod: deliveryMethod,
      SoShopTier: "",
    };

    try {
      const response = await axiosLexaApiPrivate.post(
        "/CalculatePriceNewRates",
        reqData
      );
      const resData = response.data;

      setPkgServiceFee(resData);
      onSaveOrder(resData);
    } catch (error) {
      setToastKind2("error");
      setToastTitle2("API Fetch Failed!");
      setToastDesc2(error.message);
      setLoading(false);
      setTimeout(() => {
        setToastOpen2(true);
      }, 1500);
      setTimeout(() => {
        setToastOpen2(false);
      }, 5000);
    }
  };

  const onSaveLater = () => {
    setLoading(true);
    if (currentStage > 1) {
      setTimeout(() => {
        setShowConfirmLeave(false);
      }, 2000);
      setTimeout(() => {
        setLoading(false);
        setToastOpen1(false);
        setIsSaveForLater(true);
      }, 3000);
    }
  };

  React.useEffect(() => {
    if (isSaveForLater) {
      onSaveOrder(pkgServiceFee);
    }
  }, [isSaveForLater]);

  const onSaveOrder = async (pkgServiceFee) => {
    setLoading(true);

    try {
      const today = new Date();
      const date = `${today.getFullYear()}-${
        today.getMonth() + 1
      }-${today.getDate()} ${today.getHours()}:${today.getMinutes()}:${today.getSeconds()}`;
      const tomorrow = new Date(today);
      tomorrow.setDate(today.getDate() + 1);
      const changeDate = new Date(tomorrow);

      let senderAddress = "";
      let pickupDate = `${changeDate.getFullYear()}-${
        changeDate.getMonth() + 1
      }-${changeDate.getDate()} 08:00:00`;
      if (sendingMethod === "RIDER PICK UP") {
        senderAddress = `${senderUnit
          .trim()
          .replaceAll(",", "")}, ${senderStreet
          .trim()
          .replaceAll(",", "")}, ${senderLandmark.trim().replaceAll(",", "")}`;
        pickupDate = `${moment(pickUpDate).format("YYYY-MM-DD")} 8:00:00`;
      }

      let receiverAddress = "";
      if (deliveryMethod === "HOME DELIVERY") {
        receiverAddress = `${receiverUnit
          .trim()
          .replaceAll(",", "")}, ${receiverStreet
          .trim()
          .replaceAll(",", "")}, ${receiverLandmark
          .trim()
          .replaceAll(",", "")}`;
      }
      const receiverBarangayData = receiverBrgyData;

      let destinationCode = "";
      let consigneeStBldg = receiverAddress;

      if (deliveryMethod === "BRANCH PICK UP") {
        const getReceiverBarangayData = receiverBarangayData.find(
          (item) => Number(item.branchid) === Number(receiverBrgy.value)
        );
        if (getReceiverBarangayData) {
          destinationCode = getReceiverBarangayData.BranchCode;
          consigneeStBldg = `${getReceiverBarangayData.BranchDescr}, ${getReceiverBarangayData.address}`;
        }
      }

      const packageData = pkgData;
      const getpackageData = packageData.find((pkgData) =>
        pkgData.prodId === selectedPkg ? selectedPkg.prodId : 0
      );

      let productLine = 0;
      let pkgLength = 1.0;
      let pkgWidth = 1.0;
      let pkgHeight = 1.0;
      let pkgWeight = 1.0;

      if (getpackageData && getpackageData !== null) {
        productLine = getpackageData.productLineId;
        pkgLength = getpackageData.length !== 0 ? getpackageData.length : 1.0;
        pkgWidth = getpackageData.width !== 0 ? getpackageData.width : 1.0;
        pkgHeight = getpackageData.height !== 0 ? getpackageData.height : 1.0;

        const volumetric = (pkgLength * pkgWidth * pkgHeight) / 3500;
        pkgWeight = Math.ceil(volumetric);
      }

      // pkgLength = Number(cbmLength);
      // pkgWidth = Number(cbmWidth);
      // pkgHeight = Number(cbmHeight);
      // pkgWeight = Number(actWeight);

      const ownBoxLength = Number(cbmLength);
      const ownBoxWidth = Number(cbmWidth);
      const ownBoxHeight = Number(cbmHeight);
      const ownBoxWeight = Number(actWeight);

      const availPayCollectServc = useCODCOP;
      const availPayCollectServcItemValue = collectCODCOPAmt;
      const availPayCollectServcServiceFee = false; // collectFreightAmt;
      const payCollectOption = "";
      const walletMobileNumber = "";
      const walletEmailAddress = "";
      const selectedBank = "";
      const claimantFName = "";
      const claimantLName = "";
      const claimantMobileNo = "";
      const claimantDetailsUpdated = false;

      let bankData = "";
      let payCollectServc = "";
      let collectType = "";
      let codcopAmount = 0;

      let shippingFee = 0;
      let valuationFee = 0;
      let boxFee = 0;
      let estimatedFreightRate = 0;
      let codcopItemValue = 0;
      let codcopServiceFee = 0;

      let actualBoxFee = 0;
      let actualEstimatedFreightRate = 0;
      let actualCodCopServiceFee = 0;
      let actualCodCopAmount = 0;

      if (pkgServiceFee !== null) {
        shippingFee = pkgServiceFee.ShippingFee;
        valuationFee = pkgServiceFee.ValuationFee;
        boxFee = pkgServiceFee.BoxFee;
        estimatedFreightRate = pkgServiceFee.EstimatedFreightRate;
        actualBoxFee = pkgServiceFee.ActualBoxFee;
        actualEstimatedFreightRate = pkgServiceFee.ActualEstimatedFreightRate;
      }

      codcopItemValue = itemValue === "" ? 0 : Number(itemValue);
      codcopServiceFee = estimatedFreightRate;
      actualCodCopServiceFee = actualEstimatedFreightRate;

      if (availPayCollectServc) {
        // if (payCollectOption === 'MyLBCWallet') {
        //   collectType = 'MyLBCWallet';
        //   bankData = `${
        //     myLBCWalletData.acctNo
        //   } | ${walletMobileNumber} | ${walletEmailAddress} | ${
        //     myLBCWalletData.firstName
        //   } | ${myLBCWalletData.lastName}`;
        // } else if (payCollectOption === 'BANK DEPOSIT') {
        //   collectType = 'BANK DEPOSIT';
        //   bankData = `${selectedBank.BankID} | ${
        //     selectedBank.ShipperBankName
        //   } | ${selectedBank.ShipperBankNumber}`;
        // } else {
        //   collectType = 'BRANCH COLLECT';
        //   const trimedName = `${senderFirstName} ${senderLastName}`.substring(
        //     0,
        //     35,
        //   );
        //   bankData = `${trimedName} | ${senderMobileNumber}`;
        // }

        if (
          availPayCollectServcItemValue /* && !availPayCollectServcServiceFee */
        ) {
          codcopAmount = codcopItemValue;
          actualCodCopAmount = codcopItemValue;
          payCollectServc = "1";
        } else if (
          !availPayCollectServcItemValue &&
          availPayCollectServcServiceFee
        ) {
          codcopAmount = codcopServiceFee;
          actualCodCopAmount = actualCodCopServiceFee;
          payCollectServc = "2";
        } else if (
          availPayCollectServcItemValue &&
          availPayCollectServcServiceFee
        ) {
          codcopAmount = codcopItemValue + codcopServiceFee;
          actualCodCopAmount = codcopItemValue + actualCodCopServiceFee;
          payCollectServc = "3";
        } else {
          codcopAmount = 0;
          actualCodCopAmount = 0;
          payCollectServc = "0";
        }
      }

      let soShopTier = "";
      // if (
      //   cookies.get('userSoShop') !== undefined &&
      //   cookies.get('userSoShop') !== null
      // ) {
      //   soShopTier = cookies.get('userSoShop')[0].SoShopTier;
      // }

      const serviceMode =
        sendingMethod === "RIDER PICK UP" && deliveryMethod === "BRANCH PICK UP"
          ? 10
          : 8;

      let lastStatusLocation = "INCOMPLETE BOOKING";
      if (
        data &&
        data.editItem &&
        data.editItem.Status !== "INCOMPLETE BOOKING"
      ) {
        lastStatusLocation =
          sendingMethod === "RIDER PICK UP"
            ? "PENDING PICK UP"
            : "PENDING DROP OFF";
      }

      const reqData = [
        {
          BookingNo: data && data.editItem ? data.editItem.BookingNo : "",
          TrackingNo: data && data.editItem ? data.editItem.TrackingNo : "",
          ShipmentMode: shipmentType === "Air" ? 1 : 2,
          TransactionDate: date,
          ShipperAccountNo: shipperAcctNo,
          ShipperFirstName: "",
          ShipperMiddleName: "",
          ShipperLastName: "",
          Shipper: senderCompany,
          ShipperStBldg: senderAddress,
          ShipperBrgy: senderBrgy.value,
          ShipperCityMunicipality: senderCity.value,
          ShipperProvince: senderProvince.value,
          ShipperContactNumber: senderNum,
          ShipperSendSMS: 1,
          ShipperMobileNumber: senderNum,
          ShipperEmail: customerDetails?.emailAddress || "",
          ProductLine: productLine,
          ServiceMode: serviceMode,
          CODAmount: Number(codcopAmt), // actualCodCopAmount,
          ConsigneeFirstName: "",
          ConsigneeMiddleName: "",
          ConsigneeLastName: "",
          Consignee: receiverName,
          ConsigneeStBldg: consigneeStBldg,
          ConsigneeBrgy: receiverBrgy.value,
          ConsigneeCityMunicipality: receiverCity.value,
          ConsigneeProvince: receiverProvince.value,
          ConsigneeContactNumber: receiverNum,
          ConsigneeMobileNumber: receiverNum,
          ConsigneeSendSMS: 1,
          ReferenceNoOne: bankData,
          ReferenceNoTwo: "ACCEPTED AT",
          ReferenceNoThree: sendingMethod,
          Description: itemName,
          AttachmentNameOne: collectType,
          ReferenceNoFour: deliveryMethod,
          Client: "CorpTNT",
          DestinationCode: destinationCode,
          Quantity: 1,
          PKG: selectedPkg ? selectedPkg.prodId : 0,
          ACTWTKgs: selectedPkg
            ? selectedPkg.prodId === 16
              ? Number(ownBoxWeight)
              : pkgWeight
            : 0,
          LengthCM: selectedPkg
            ? selectedPkg.prodId === 16
              ? Number(ownBoxLength)
              : pkgLength
            : 0,
          WidthCM: selectedPkg
            ? selectedPkg.prodId === 16
              ? Number(ownBoxWidth)
              : pkgWidth
            : 0,
          HeightCM: selectedPkg
            ? selectedPkg.prodId === 16
              ? Number(ownBoxHeight)
              : pkgHeight
            : 0,
          VolWTcbm: selectedPkg
            ? selectedPkg.prodId === 16
              ? Number(ownBoxWeight)
              : pkgWeight
            : 0,
          CBM: 1,
          ChargeableWT: selectedPkg
            ? selectedPkg.prodId === 16
              ? Number(ownBoxWeight)
              : pkgWeight
            : 0,
          DeclaredValue: parseFloat(
            itemValue !== "" ? itemValue : "0.00"
          ).toFixed(2),
          Commodity: 0,
          ForCrating: 0,
          Paymentmode: customerDetails?.payMode || 1, // 1,
          ValuationFee: valuationFee,
          ShippingFee: shippingFee,
          EstimatedFreightRate: actualEstimatedFreightRate,
          PickupDate: pickupDate,
          SpecialInstructions: "",
          LastStatusLocation: lastStatusLocation,
          ContactPerson: senderName,
          LastDatePosted: date,
          BulkSessionId: bulkSessionId,
          ShipperZipCode: 0,
          ConsigneeZipCode: 0,
          RefBookingNo: "",
          PayCollectServc: payCollectServc,
          BoxFee: parseFloat(actualBoxFee).toFixed(2),
          SoShopTier: soShopTier,
          SaveForLater: isSaveForLater,
        },
      ];

      if (data && data.editItem) {
        try {
          const response = await axiosLexaApiPrivate.post(
            "/CorpDomesticBookingUpdate",
            reqData
          );
          const resData = response.data;

          clearStates();

          if (isSaveForLater) {
            setShowConfirmLeave(false);
            navigate("/dashboard-home");
          } else {
            onBookingConfirmed();
            onClose();
          }

          setLoading(false);
        } catch (error) {
          setToastKind2("error");
          setToastTitle2("API Fetch Failed!");
          setToastDesc2(error.message);
          setTimeout(() => {
            setLoading(false);
          }, 1000);
          setTimeout(() => {
            setToastOpen2(true);
          }, 1500);
          setTimeout(() => {
            setToastOpen2(false);
          }, 5000);
        }
      } else {
        try {
          const response = await axiosLexaApiPrivate.post(
            "/CorpDomesticBooking",
            reqData
          );
          const resData = response.data;

          clearStates();

          if (isSaveForLater) {
            setShowConfirmLeave(false);
            navigate("/dashboard-home");
          } else {
            onBookingConfirmed();
            onClose();
          }

          setLoading(false);
        } catch (error) {
          setToastKind2("error");
          setToastTitle2("API Fetch Failed!");
          setToastDesc2(error.message);
          setTimeout(() => {
            setLoading(false);
          }, 1000);
          setTimeout(() => {
            setToastOpen2(true);
          }, 1500);
          setTimeout(() => {
            setToastOpen2(false);
          }, 5000);
        }
      }

      if (isSaveForLater) {
        setShowConfirmLeave(false);
        navigate("/dashboard-home");
      }
    } catch (err) {
      setToastKind2("error");
      setToastTitle2("Oops! Something went wrong.");
      setToastDesc2(err.message);
      setTimeout(() => {
        setLoading(false);
      }, 1000);
      setTimeout(() => {
        setToastOpen2(true);
      }, 1500);
      setTimeout(() => {
        setToastOpen2(false);
      }, 5000);
    }
  };

  const validateSenderDetails = () => {
    let validDetails = true;

    setSenderNameErr(senderName === "" ? "Contact Name is required." : "");
    setSenderNumErr(
      senderNum === ""
        ? "Contact Number is required."
        : !isMobileNumber(senderNum)
        ? "Enter a valid mobile number."
        : ""
    );
    setSenderCompanyErr(
      senderCompany === "" ? "Shipper Name is required." : ""
    );
    setSenderUnitErr(
      sendingMethod === "RIDER PICK UP" && senderUnit === ""
        ? "Unit is required."
        : ""
    );
    // setSenderStreetErr(
    //   sendingMethod === "RIDER PICK UP" && senderStreet === ""
    //     ? "Street is required."
    //     : ""
    // );
    setSenderProvinceErr(
      senderProvince.value === 0 ? "Please select a Province." : ""
    );
    setSenderCityErr(senderCity.value === 0 ? "Please select a City." : "");
    setSenderBrgyErr(
      sendingMethod === "RIDER PICK UP" && senderBrgy.value === 0
        ? "Please select a Barangay."
        : ""
    );
    setPickUpDateErr(pickUpDate === "" ? "Please select a Pick Up Date." : "");

    validDetails =
      senderName !== "" &&
      senderNum !== "" &&
      isMobileNumber(senderNum) &&
      senderCompany !== "" &&
      ((sendingMethod === "RIDER PICK UP" && senderUnit !== "") ||
        sendingMethod !== "RIDER PICK UP") &&
      // ((sendingMethod === "RIDER PICK UP" && senderStreet !== "") ||
      //   sendingMethod !== "RIDER PICK UP") &&
      senderProvince.value !== 0 &&
      senderCity.value !== 0 &&
      ((sendingMethod === "RIDER PICK UP" && senderBrgy.value !== 0) ||
        sendingMethod !== "RIDER PICK UP") &&
      ((sendingMethod === "RIDER PICK UP" && pickUpDate !== "") ||
        sendingMethod !== "RIDER PICK UP");

    return validDetails;
  };

  const validateReceiverDetails = () => {
    let validDetails = true;

    setReceiverNameErr(receiverName === "" ? "Contact Name is required." : "");
    setReceiverNumErr(
      receiverNum === ""
        ? "Contact Number is required."
        : !isMobileNumber(receiverNum)
        ? "Enter a valid mobile number."
        : ""
    );
    setReceiverUnitErr(
      deliveryMethod === "HOME DELIVERY" && receiverUnit === ""
        ? "Unit is required."
        : ""
    );
    // setReceiverStreetErr(
    //   deliveryMethod === "HOME DELIVERY" && receiverStreet === ""
    //     ? "Street is required."
    //     : ""
    // );
    setReceiverProvinceErr(
      receiverProvince.value === 0 ? "Please select a Province." : ""
    );
    setReceiverCityErr(receiverCity.value === 0 ? "Please select a City." : "");
    setReceiverBrgyErr(
      receiverBrgy.value === 0
        ? deliveryMethod === "HOME DELIVERY"
          ? "Please select a Barangay."
          : "Please select a Branch."
        : ""
    );

    validDetails =
      receiverName !== "" &&
      receiverNum !== "" &&
      isMobileNumber(receiverNum) &&
      ((deliveryMethod === "HOME DELIVERY" && receiverUnit !== "") ||
        deliveryMethod !== "HOME DELIVERY") &&
      // ((deliveryMethod === "HOME DELIVERY" && receiverStreet !== "") ||
      //   deliveryMethod !== "HOME DELIVERY") &&
      receiverProvince.value !== 0 &&
      receiverCity.value !== 0 &&
      receiverBrgy.value !== 0;

    return validDetails;
  };

  const validatePackageDetails = () => {
    let validDetails = true;

    setItemNameErr(itemName === "" ? "Item Name is required." : "");
    setItemValueErr(
      itemValue === ""
        ? "Item Value is required."
        : Number(itemValue) < 100
        ? "Minimum Declared Value for each transaction is Php100.00."
        : Number(itemValue) > 100000
        ? "Maximum Declared Value for each transaction is Php100,000.00."
        : ""
    );
    setUseCODCOPErr(
      useCODCOP && !collectCODCOPAmt /* && !collectFreightAmt */
        ? "Please select which amount to collect for COD/COP."
        : ""
    );
    setCODCOPAmtErr(
      useCODCOP && codcopAmt === ""
        ? "COD/COP Amount is required."
        : collectCODCOPAmt && Number(codcopAmt) < 100
        ? "Minimum COD/COP Amount is Php100.00."
        : collectCODCOPAmt && Number(codcopAmt) > 100000
        ? "COD amount exceeding Php 100,000.00 shall be subject for approval for special handling, kindly contact you assigned account handler."
        : ""
    );

    setSelectedPkgErr(selectedPkg === null ? "Please select a Packaging." : "");

    if (selectedPkg && selectedPkg.prodId === 16) {
      setActWeightErr(
        actWeight === "" || Number(actWeight) === 0
          ? "Act. Weight (kg) is required."
          : Number(actWeight) > 100
          ? "Actual weight should not be more than 100kgs."
          : ""
      );
      setCBMLengthErr(
        cbmLength === "" || Number(cbmLength) === 0 ? "Length is required." : ""
      );
      setCBMWidthErr(
        cbmWidth === "" || Number(cbmWidth) === 0 ? "Width is required." : ""
      );
      setCBMHeightErr(
        cbmHeight === "" || Number(cbmHeight) === 0 ? "Height is required." : ""
      );
    }

    validDetails =
      itemName !== "" &&
      itemValue !== "" &&
      Number(itemValue) >= 100 &&
      Number(itemValue) <= 100000 &&
      !((useCODCOP && !collectCODCOPAmt) /* && !collectFreightAmt */) &&
      !(useCODCOP && codcopAmt === "") &&
      !(collectCODCOPAmt && Number(codcopAmt) < 100) &&
      !(collectCODCOPAmt && Number(codcopAmt) > 100000) &&
      selectedPkg !== null &&
      ((selectedPkg &&
        selectedPkg.prodId === 16 &&
        actWeight !== "" &&
        Number(actWeight) !== 0 &&
        Number(actWeight) <= 100 &&
        cbmLength !== "" &&
        Number(cbmLength) !== 0 &&
        cbmWidth !== "" &&
        Number(cbmWidth) !== 0 &&
        cbmHeight !== "" &&
        Number(cbmHeight) !== 0) ||
        (selectedPkg && selectedPkg.prodId !== 16));

    return validDetails;
  };

  const onCloseModal = () => {
    clearStates();
    onClose();
  };

  const onChangeStage = (stage, type) => {
    let validSender = true;
    if (type === "next" && stage === 1) {
      validSender = validateSenderDetails();
    }

    let validReceiver = true;
    if (type === "next" && stage === 2) {
      validReceiver = validateReceiverDetails();
    }

    let validPackage = true;
    if (type === "next" && stage === 3) {
      validPackage = validatePackageDetails();
    }

    if (validSender && validReceiver && validPackage) {
      if (type === "next" && stage === 2) {
        if (
          selectedPkg &&
          selectedPkg.prodId === 16 &&
          deliveryMethod === "BRANCH PICK UP"
        ) {
          setSelectedPkg(null);
          setActWeight("0");
          setActWeightErr("");
          setCBMLength("0");
          setCBMLengthErr("");
          setCBMWidth("0");
          setCBMWidthErr("");
          setCBMHeight("0");
          setCBMHeightErr("");
        }

        getCorpPortalAcctProducts("Booking");
      }

      if (type === "next" && stage === 3) {
        //setToastOpen3(true);
        onConfirmBooking();
      } else if (type === "next") {
        setStage(stage + 1);
      } else if (type === "prev") {
        setStage(stage - 1);
      }
    }
  };

  const onConfirmBooking = () => {
    setToastOpen3(false);
    // calculatePriceNewRates();
    onSaveOrder(pkgServiceFee);
  };

  const onPickUpDateChange = (value) => {
    setPickUpDate(moment(value).format("dddd, MMMM DD, YYYY"));
    setToastOpen4(false);

    setPickUpDateErr("");
  };

  {
    /* == End Action == */
  }

  {
    /* == Sender Details Actions == */
  }
  const onSendingMethodChange = (sendingMethod) => {
    setSendingMethod(sendingMethod);

    setSenderUnit("");
    setSenderUnitErr("");
    setSenderStreet("");
    setSenderStreetErr("");
    setSenderProvinceData([]);
    setSenderProvince({
      value: 0,
      label: "Province",
    });
    setSenderProvinceErr("");
    setSenderCityData([]);
    setSenderCity({
      value: 0,
      label: "City/Municipality",
    });
    setSenderCityErr("");
    setSenderBrgyData([]);
    setSenderBrgy({
      value: 0,
      label: "Barangay",
    });
    setSenderBrgyErr("");
    setSenderLandmark("");
    setSenderLandmarkErr("");

    getProvinces(sendingMethod);
  };

  const onSenderProvinceChange = (sendingMethod, senderProvince) => {
    setSenderProvince(senderProvince);

    if (senderProvince.value !== 0) {
      setSenderCityData([]);
      setSenderCity({
        value: 0,
        label: "City/Municipality",
      });
      setSenderBrgyData([]);
      setSenderBrgy({
        value: 0,
        label: "Barangay",
      });

      getCities(sendingMethod, senderProvince.value);
    }

    setSenderProvinceErr(
      senderProvince.value === 0 ? "Please select a Province." : ""
    );
  };

  const onSenderCityChange = (sendingMethod, senderProvince, senderCity) => {
    setSenderCity(senderCity);

    if (sendingMethod === "RIDER PICK UP" && senderCity.value !== 0) {
      setSenderBrgyData([]);
      setSenderBrgy({
        value: 0,
        label: "Barangay",
      });

      getBarangays(sendingMethod, senderProvince.value, senderCity.value);
    }

    setSenderCityErr(senderCity.value === 0 ? "Please select a City." : "");
  };

  const onSenderBrgyChange = (senderBrgy) => {
    setSenderBrgy(senderBrgy);

    setSenderBrgyErr(
      sendingMethod === "RIDER PICK UP" && senderBrgy.value === 0
        ? "Please select a Barangay."
        : ""
    );
  };

  React.useEffect(() => {
    if (
      ((data && data.editItem) || senderSelectedAddress) &&
      !senderProvinceFilled &&
      senderProvinceData.length > 0
    ) {
      if (data && data.editItem && senderSelectedAddress === null) {
        setSenderProvince({
          value:
            data.editItem.ShipperProvince !== ""
              ? data.editItem.ShipperProvinceId
              : 0,
          label:
            data.editItem.ShipperProvince !== ""
              ? data.editItem.ShipperProvince
              : "Province",
        });

        if (data.editItem.ShipperProvince !== "") {
          getCities(sendingMethod, data.editItem.ShipperProvinceId);
        } else {
          setSenderCityFilled(true);
          setSenderBrgyFilled(true);
        }
      } else if (senderSelectedAddress) {
        setSenderProvince({
          value:
            senderSelectedAddress.provName !== ""
              ? senderSelectedAddress.provId
              : 0,
          label:
            senderSelectedAddress.provName !== ""
              ? senderSelectedAddress.provName
              : "Province",
        });

        if (senderSelectedAddress.provName !== "") {
          getCities(sendingMethod, senderSelectedAddress.provId);
        } else {
          setSenderCityErr("Please select a City.");
          setSenderBrgyErr(
            sendingMethod === "RIDER PICK UP" ? "Please select a Barangay." : ""
          );
          setSenderCityFilled(true);
          setSenderBrgyFilled(true);
        }

        setSenderProvinceErr(
          senderSelectedAddress.provName === ""
            ? "Please select a Province."
            : ""
        );
      }

      setSenderProvinceFilled(true);
    }
  }, [senderProvinceFilled, senderProvinceData]);

  React.useEffect(() => {
    if (
      ((data && data.editItem) || senderSelectedAddress) &&
      !senderCityFilled &&
      senderCityData.length > 0
    ) {
      if (data && data.editItem && senderSelectedAddress === null) {
        const editItemShipperCity = senderCityData.find(
          (city) =>
            Number(city.CityId || city.CityID) ===
            Number(data.editItem.ShipperCityMunicipalityId)
        );
        setSenderCity({
          value:
            data.editItem.ShipperCityMunicipality !== "" && editItemShipperCity
              ? data.editItem.ShipperCityMunicipalityId
              : 0,
          label:
            data.editItem.ShipperCityMunicipality !== "" && editItemShipperCity
              ? data.editItem.ShipperCityMunicipality
              : "City/Municipality",
        });

        if (
          sendingMethod === "RIDER PICK UP" &&
          data.editItem.ShipperCityMunicipality !== "" &&
          editItemShipperCity
        ) {
          getBarangays(
            sendingMethod,
            data.editItem.ShipperProvinceId,
            data.editItem.ShipperCityMunicipalityId
          );
        } else {
          setSenderBrgyFilled(true);
        }
      } else if (senderSelectedAddress) {
        const senderSelectedCity = senderCityData.find(
          (city) =>
            Number(city.CityId || city.CityID) ===
            Number(senderSelectedAddress.cityId)
        );
        setSenderCity({
          value:
            senderSelectedAddress.cityName !== "" && senderSelectedCity
              ? senderSelectedAddress.cityId
              : 0,
          label:
            senderSelectedAddress.cityName !== "" && senderSelectedCity
              ? senderSelectedAddress.cityName
              : "City/Municipality",
        });

        if (
          sendingMethod === "RIDER PICK UP" &&
          senderSelectedAddress.cityName !== "" &&
          senderSelectedCity
        ) {
          getBarangays(
            sendingMethod,
            senderSelectedAddress.provId,
            senderSelectedAddress.cityId
          );
        } else {
          setSenderBrgyErr(
            sendingMethod === "RIDER PICK UP" ? "Please select a Barangay." : ""
          );
          setSenderBrgyFilled(true);
        }

        setSenderCityErr(
          senderSelectedAddress.cityName === "" || !senderSelectedCity
            ? "Please select a City."
            : ""
        );
      }

      setSenderCityFilled(true);
    }
  }, [senderCityFilled, senderCityData]);

  React.useEffect(() => {
    if (
      ((data && data.editItem) || senderSelectedAddress) &&
      !senderBrgyFilled &&
      senderBrgyData.length > 0
    ) {
      if (data && data.editItem && senderSelectedAddress === null) {
        const editItemShipperBrgy = senderBrgyData.find(
          (brgy) =>
            Number(brgy.BarangayId || brgy.BarangayID) ===
            Number(data.editItem.ShipperBrgyId)
        );
        setSenderBrgy({
          value:
            data.editItem.ShipperBrgy !== "" && editItemShipperBrgy
              ? data.editItem.ShipperBrgyId
              : 0,
          label:
            data.editItem.ShipperBrgy !== "" && editItemShipperBrgy
              ? data.editItem.ShipperBrgy
              : "Barangay",
        });
      } else if (senderSelectedAddress) {
        const senderSelectedBrgy = senderBrgyData.find(
          (brgy) =>
            Number(brgy.BarangayId || brgy.BarangayID) ===
            Number(senderSelectedAddress.brgyId)
        );
        setSenderBrgy({
          value:
            senderSelectedAddress.brgyName !== "" && senderSelectedBrgy
              ? senderSelectedAddress.brgyId
              : 0,
          label:
            senderSelectedAddress.brgyName !== "" && senderSelectedBrgy
              ? senderSelectedAddress.brgyName
              : "Barangay",
        });

        setSenderBrgyErr(
          sendingMethod === "RIDER PICK UP" &&
            (senderSelectedAddress.brgyName === "" || !senderSelectedBrgy)
            ? "Please select a Barangay."
            : ""
        );
      }

      setSenderBrgyFilled(true);
    }
  }, [senderBrgyFilled, senderBrgyData]);

  const onSenderNameChange = (value) => {
    setSenderName(value);
    setSenderNameErr(value === "" ? "Contact Name is required." : "");
  };

  const onSenderNumChange = (value) => {
    if (isNumber(value)) {
      setSenderNum(value);
      setSenderNumErr(
        value === ""
          ? "Contact Number is required."
          : !isMobileNumber(value)
          ? "Enter a valid mobile number."
          : ""
      );
    }
  };

  const onSenderUnitChange = (value) => {
    setSenderUnit(value);
    setSenderUnitErr(
      sendingMethod === "RIDER PICK UP" && value === ""
        ? "Unit is required."
        : ""
    );
  };

  const onPickUpDateClick = () => {
    configPickUpDate();
    setToastOpen4(true);
  };

  const onSelectAddress = (item) => {
    setSenderSelectedAddress(item);
  };

  React.useEffect(() => {
    if (senderSelectedAddress !== null) {
      onSenderNameChange(senderSelectedAddress.contactPerson);
      onSenderNumChange(senderSelectedAddress.mobileNo);
      onSenderUnitChange(
        senderSelectedAddress.pickupAddress.replaceAll(",", "")
      );
      setSenderProvinceFilled(false);
      setSenderCityFilled(false);
      setSenderBrgyFilled(false);
      getProvinces(sendingMethod);
    }
  }, [senderSelectedAddress]);

  {
    /* == End Sender Details Actions == */
  }

  {
    /* == Receiver Details Actions == */
  }
  const onDeliveryMethodChange = (deliveryMethod) => {
    setDeliveryMethod(deliveryMethod);

    setReceiverUnit("");
    setReceiverUnitErr("");
    setReceiverStreet("");
    setReceiverStreetErr("");
    setReceiverProvinceData([]);
    setReceiverProvince({
      value: 0,
      label: "Province",
    });
    setReceiverProvinceErr("");
    setReceiverCityData([]);
    setReceiverCity({
      value: 0,
      label: "City/Municipality",
    });
    setReceiverCityErr("");
    setReceiverBrgyData([]);
    setReceiverBrgy({
      value: 0,
      label: deliveryMethod === "HOME DELIVERY" ? "Barangay" : "Branch",
    });
    setReceiverBrgyErr("");
    setReceiverLandmark("");
    setReceiverLandmarkErr("");

    getProvinces(deliveryMethod);
  };

  const onReceiverProvinceChange = (deliveryMethod, receiverProvince) => {
    setReceiverProvince(receiverProvince);

    if (receiverProvince.value !== 0) {
      setReceiverCityData([]);
      setReceiverCity({
        value: 0,
        label: "City/Municipality",
      });
      setReceiverBrgyData([]);
      setReceiverBrgy({
        value: 0,
        label: deliveryMethod === "HOME DELIVERY" ? "Barangay" : "Branch",
      });

      getCities(deliveryMethod, receiverProvince.value);
    }

    setReceiverProvinceErr(
      receiverProvince.value === 0 ? "Please select a Province." : ""
    );
  };

  const onReceiverCityChange = (
    deliveryMethod,
    receiverProvince,
    receiverCity
  ) => {
    setReceiverCity(receiverCity);

    if (receiverCity.value !== 0) {
      setReceiverBrgyData([]);
      setReceiverBrgy({
        value: 0,
        label: deliveryMethod === "HOME DELIVERY" ? "Barangay" : "Branch",
      });

      getBarangays(deliveryMethod, receiverProvince.value, receiverCity.value);
    }

    setReceiverCityErr(receiverCity.value === 0 ? "Please select a City." : "");
  };

  const onReceiverBrgyChange = (receiverBrgy) => {
    setReceiverBrgy(receiverBrgy);

    setReceiverBrgyErr(
      receiverBrgy.value === 0
        ? deliveryMethod === "HOME DELIVERY"
          ? "Please select a Barangay."
          : "Please select a Branch."
        : ""
    );
  };

  React.useEffect(() => {
    if (
      data &&
      data.editItem &&
      !receiverProvinceFilled &&
      receiverProvinceData.length > 0
    ) {
      setReceiverProvince({
        value:
          data.editItem.ConsigneeProvince !== ""
            ? data.editItem.ConsigneeProvinceId
            : 0,
        label:
          data.editItem.ConsigneeProvince !== ""
            ? data.editItem.ConsigneeProvince
            : "Province",
      });

      if (data.editItem.ConsigneeProvince !== "") {
        getCities(deliveryMethod, data.editItem.ConsigneeProvinceId);
      } else {
        setReceiverCityFilled(true);
        setReceiverBrgyFilled(true);
      }

      setReceiverProvinceFilled(true);
    }
  }, [receiverProvinceFilled, receiverProvinceData]);

  React.useEffect(() => {
    if (
      data &&
      data.editItem &&
      !receiverCityFilled &&
      receiverCityData.length > 0
    ) {
      const editItemConsigneeCity = receiverCityData.find(
        (city) =>
          Number(city.CityId || city.CityID) ===
          Number(data.editItem.ConsigneeCityMunicipalityId)
      );
      setReceiverCity({
        value:
          data.editItem.ConsigneeCityMunicipality !== "" &&
          editItemConsigneeCity
            ? data.editItem.ConsigneeCityMunicipalityId
            : 0,
        label:
          data.editItem.ConsigneeCityMunicipality !== "" &&
          editItemConsigneeCity
            ? data.editItem.ConsigneeCityMunicipality
            : "City/Municipality",
      });

      if (
        data.editItem.ConsigneeCityMunicipality !== "" &&
        editItemConsigneeCity
      ) {
        getBarangays(
          deliveryMethod,
          data.editItem.ConsigneeProvinceId,
          data.editItem.ConsigneeCityMunicipalityId
        );
      } else {
        setReceiverBrgyFilled(true);
      }

      setReceiverCityFilled(true);
    }
  }, [receiverCityFilled, receiverCityData]);

  React.useEffect(() => {
    if (
      data &&
      data.editItem &&
      !receiverBrgyFilled &&
      receiverBrgyData.length > 0
    ) {
      const editItemConsigneeBrgy = receiverBrgyData.find(
        (brgy) =>
          Number(brgy.BarangayId || brgy.BarangayID || brgy.branchid) ===
          Number(data.editItem.ConsigneeBrgyId)
      );
      setReceiverBrgy({
        value:
          data.editItem.ConsigneeBrgy !== "" &&
          Number(data.editItem.ConsigneeBrgyId) !== 0 &&
          editItemConsigneeBrgy
            ? data.editItem.ConsigneeBrgyId
            : 0,
        label:
          data.editItem.ConsigneeBrgy !== "" &&
          Number(data.editItem.ConsigneeBrgyId) !== 0 &&
          editItemConsigneeBrgy
            ? data.editItem.ConsigneeBrgy
            : deliveryMethod === "HOME DELIVERY"
            ? "Barangay"
            : "Branch",
      });

      setReceiverBrgyFilled(true);
    }
  }, [receiverBrgyFilled, receiverBrgyData]);

  const onReceiverNameChange = (value) => {
    setReceiverName(value);
    setReceiverNameErr(value === "" ? "Contact Name is required." : "");
  };

  const onReceiverNumChange = (value) => {
    if (isNumber(value)) {
      setReceiverNum(value);
      setReceiverNumErr(
        value === ""
          ? "Contact Number is required."
          : !isMobileNumber(value)
          ? "Enter a valid mobile number."
          : ""
      );
    }
  };

  const onReceiverUnitChange = (value) => {
    setReceiverUnit(value);
    setReceiverUnitErr(
      deliveryMethod === "HOME DELIVERY" && value === ""
        ? "Unit is required."
        : ""
    );
  };

  {
    /* == End Receiver Details Actions == */
  }

  {
    /* == Package Details Actions == */
  }

  const onItemNameChange = (value) => {
    setItemName(value);

    setItemNameErr(value === "" ? "Item Name is required." : "");
  };

  const onItemValueChange = (value) => {
    if (isNumber(value)) {
      setItemValue(value);

      setItemValueErr(
        value === ""
          ? "Item Value is required."
          : Number(value) < 100
          ? "Minimum Declared Value for each transaction is Php100.00."
          : Number(value) > 100000
          ? "Maximum Declared Value for each transaction is Php100,000.00."
          : ""
      );
    }
  };

  const onUseCODCOPChange = (value) => {
    setCollectCODCOPAmt(false);
    // setCollectFreightAmt(false);
    setCODCOPAmt("");
    setUseCODCOP(value);
    setUseCODCOPErr("");
  };

  const onCODCOPAmtChange = (value) => {
    if (isNumber(value)) {
      setCollectCODCOPAmt(true);
      setCODCOPAmt(value);

      setCODCOPAmtErr(
        value === ""
          ? "COD/COP Amount is required."
          : Number(value) < 100
          ? "Minimum COD/COP Amount is Php100.00."
          : collectCODCOPAmt && Number(value) > 100000
          ? "COD amount exceeding Php 100,000.00 shall be subject for approval for special handling, kindly contact you assigned account handler."
          : ""
      );
    }
  };

  const onSelectPkg = (item) => {
    setSelectedPkg(item);

    setSelectedPkgErr(item === null ? "Please select a Packaging." : "");
  };

  const onActWeightChange = (value) => {
    if (isNumber(value)) {
      setActWeight(value);

      setActWeightErr(
        value === "" || Number(value) === 0
          ? "Act. Weight (kg) is required."
          : Number(value) > 100
          ? "Actual weight should not be more than 100kgs."
          : ""
      );
    }
  };

  const onCBMLengthChange = (value) => {
    if (isNumber(value)) {
      setCBMLength(value);

      setCBMLengthErr(
        value === "" || Number(value) === 0 ? "Length is required." : ""
      );
    }
  };

  const onCBMWidthChange = (value) => {
    if (isNumber(value)) {
      setCBMWidth(value);

      setCBMWidthErr(
        value === "" || Number(value) === 0 ? "Width is required." : ""
      );
    }
  };

  const onCBMHeightChange = (value) => {
    if (isNumber(value)) {
      setCBMHeight(value);

      setCBMHeightErr(
        value === "" || Number(value) === 0 ? "Height is required." : ""
      );
    }
  };

  React.useEffect(() => {
    setUseCODCOPErr(
      useCODCOP && !collectCODCOPAmt /* && !collectFreightAmt */
        ? "Please select which amount to collect for COD/COP."
        : ""
    );
  }, [collectCODCOPAmt]);

  React.useEffect(() => {
    if (data && data.editItem && !selectedPkgFilled && pkgData.length > 0) {
      if (
        Number(data.editItem.ProdId) === 16 &&
        data.editItem.ReferenceNoFour === "BRANCH PICK UP"
      ) {
        setSelectedPkg(null);
        setActWeight("0");
        setActWeightErr("");
        setCBMLength("0");
        setCBMLengthErr("");
        setCBMWidth("0");
        setCBMWidthErr("");
        setCBMHeight("0");
        setCBMHeightErr("");
      } else {
        const getPkgData = pkgData.find(
          (pkg) => Number(pkg.prodId) === Number(data.editItem.ProdId)
        );
        setSelectedPkg(getPkgData);
        setSelectedPkgErr(
          getPkgData === null ? "Please select a Packaging." : ""
        );
      }

      setSelectedPkgFilled(true);
    }
  }, [selectedPkgFilled, pkgData]);

  {
    /* == End Package Details Actions == */
  }

  const disabledDates = (date) => {
    // const christmasDay = moment(new Date(`${date.getFullYear()}-12-25`)).format("YYYY-MM-DD");
    // const blackoutDates = [christmasDay];
    // return date.getDay() === 0 || blackoutDates.includes(moment(date).format("YYYY-MM-DD"));

    const blackoutDates = [];
    holidays.forEach((holiday) => {
      blackoutDates.push(
        moment(new Date(holiday.Holidays)).format("YYYY-MM-DD")
      );
    });

    return (
      date.getDay() === 0 ||
      blackoutDates.includes(moment(date).format("YYYY-MM-DD"))
    );
  };

  return (
    <Transition appear as={Fragment} show={isOpen}>
      <Dialog
        as="div"
        className="fixed inset-0 z-50 overflow-hide"
        onClose={onClose}
      >
        <div className="h-full w-full text-center flex justify-end">
          <Transition.Child
            as={Fragment}
            enter="transition ease duration-300 transform"
            enterFrom="opacity-0 -translate-y-full"
            enterTo="opacity-100 translate-y-0"
            leave="transition ease duration-300 transform"
            leaveFrom="opacity-100 translate-y-0"
            leaveTo="opacity-0 -translate-y-full"
          >
            <div className="h-full w-full overflow-hidden z-20 p-5 align-middle transition-all transform flex flex-col items-center justify-center bg-secondary-200">
              {/* ---------- Modal Body ---------- */}

              {(loading ||
                (data &&
                  data.editItem &&
                  (!senderProvinceFilled ||
                    !senderCityFilled ||
                    !senderBrgyFilled ||
                    !receiverProvinceFilled ||
                    !receiverCityFilled ||
                    !receiverBrgyFilled))) && <Loading />}

              <LBCModalTwoFunc
                id="saveForLater"
                description={
                  <>
                    <Span>
                      You can go back anytime to finish your booking by clicking
                    </Span>
                    <Span className="mx-1 font-bold">
                      Activity {`>`} Online Bookings {`>`} Draft
                    </Span>
                    <Span>in the menu.</Span>
                  </>
                }
                isOpen={isToastOpen1}
                onClose={() => setToastOpen1(false)}
                title="Save for later?"
                toastKind="info"
                buttonTextFirst="Cancel"
                buttonTextSecond="Save for later"
                onClickSecond={onSaveLater}
                loading={loading}
              />

              <LBCModal
                id="alertModal"
                description={toastDesc2}
                isOpen={isToastOpen2}
                onClose={() => setToastOpen2(false)}
                title={toastTitle2}
                toastKind={toastKind2}
              />

              <LBCModalTwoFunc
                id="confirmModal"
                description="Are you sure you want to proceed?"
                isOpen={isToastOpen3}
                onClose={() => setToastOpen3(false)}
                title="Booking Confirmation"
                buttonTextFirst="Cancel"
                buttonTextSecond="Continue"
                onClickFirst={() => setToastOpen3(false)}
                onClickSecond={onConfirmBooking}
              />

              <LBCModalTwoFunc
                id="pickUpCalendar"
                description={
                  <>
                    <FlexRow className="w-full items-start justify-center">
                      <Calendar
                        className="pickup-calendar"
                        onChange={(value) => onPickUpDateChange(value)}
                        value={pickUpDate}
                        minDate={minDate ? minDate.toDate() : moment().toDate()}
                        maxDate={
                          maxDate
                            ? maxDate.toDate()
                            : moment().add(10, "days").toDate()
                        }
                        tileDisabled={({ date }) => disabledDates(date)}
                      />
                    </FlexRow>
                  </>
                }
                isOpen={isToastOpen4}
                onClose={() => setToastOpen4(false)}
                title="Select a Pick Up Date"
                toastKind="info"
                onClickSecond={onSaveLater}
                loading={loading}
                showContorl={false}
              />

              {isABMOpen && (
                <AddressBookModal
                  isOpen={isABMOpen}
                  onClose={() => setIsABMOpen(false)}
                  data={{ customerAccountNo: shipperAcctNo }}
                  onSelect={onSelectAddress}
                />
              )}

              <FlexColumn className="justify-between bg-white border border-grey-400 h-5/6 w-900px px-10 py-5 rounded-lg shadow-lg">
                <FlexRow className="w-full items-start justify-start">
                  {BookNowStage.map((item, index) => (
                    <StageUI
                      label={item.name}
                      count={item.stage}
                      currentIndex={currentStage}
                      onStage={
                        currentStage === item.stage || currentStage > item.stage
                      }
                      key={index}
                    />
                  ))}
                </FlexRow>

                <Div className="w-full h-3/4 my-2 p-5 overflow-y-scroll">
                  {/* == Sender Details == */}
                  {currentStage === 1 && (
                    <Div className="w-full">
                      <Div className="w-full">
                        <Text className="text-secondary-200 text-left">
                          How do you want to send your package?
                        </Text>
                        <div className="send-del-option">
                          <div className="optn-container">
                            <input
                              type="radio"
                              id="rider-pickup-optn"
                              name="send-optn"
                              onChange={() =>
                                onSendingMethodChange("RIDER PICK UP")
                              }
                              checked={sendingMethod === "RIDER PICK UP"}
                              className="w-4 h-4 border-red-100 focus:ring-2"
                            />
                            <label
                              htmlFor="rider-pickup-optn"
                              style={{
                                marginRight: "5px",
                                paddingLeft: "25px",
                                cursor: "pointer",
                              }}
                            >
                              <div className="send-del-optn-body">
                                <div
                                  id="rider-pickup-icon"
                                  className="send-del-optn-icon"
                                  style={{ width: "115px" }}
                                />
                                <div className="send-del-optn-details">
                                  <div className="title">Pick Up</div>
                                  LBC team will pick up the package at your
                                  address.
                                </div>
                              </div>
                              <span className="checkmark" />
                            </label>
                          </div>
                          {/*<div className="optn-container">
                            <input
                              type="radio"
                              id="drop-off-optn"
                              name="send-optn"
                              onChange={() => onSendingMethodChange("DROP-OFF")}
                              checked={sendingMethod === "DROP-OFF"}
                            />
                            <label
                              htmlFor="drop-off-optn"
                              style={{ marginLeft: "5px" }}
                            >
                              <div className="send-del-optn-body">
                                <div
                                  id="drop-off-icon"
                                  className="send-del-optn-icon"
                                />
                                <div className="send-del-optn-details">
                                  <div className="title">Drop Off</div>
                                  Drop off at the nearest LBC Corporate
                                  Solutions branch.
                                </div>
                              </div>
                              <span className="checkmark" />
                            </label>
                          </div>*/}
                        </div>
                      </Div>
                      <FlexRow className="w-full items-start justify-between py-2">
                        <Text className="text-secondary-200 text-sm text-right w-1/3 pr-2 pt-2">
                          CONTACT PERSON:
                        </Text>
                        <Div className="relative w-1/3 pr-2">
                          <div className="absolute inset-y-0 right-4 flex items-center pl-3 pointer-events-auto h-10">
                            <img
                              className="w-6 h-5 cursor-pointer"
                              src={Images.addressBook1}
                              onClick={() => setIsABMOpen(true)}
                            />
                          </div>
                          <RawInput
                            className="text-secondary-200 text-sm h-10 pr-9"
                            containerClass="w-full"
                            name="senderName"
                            placeholder="Contact Name"
                            onChange={onSenderNameChange}
                            type="text"
                            value={senderName}
                            autoComplete="off"
                            validation={senderNameErr}
                          />
                        </Div>
                        <div className="relative w-1/3 pl-2">
                          <div className="absolute inset-y-0 left-1 flex items-center pl-3 pointer-events-none h-10 text-secondary-200 text-sm">
                            +63
                          </div>
                          <RawInput
                            className="text-secondary-200 text-sm h-10 pl-9"
                            containerClass="w-full"
                            name="senderNum"
                            placeholder="9000000000"
                            onChange={onSenderNumChange}
                            type="text"
                            value={senderNum}
                            autoComplete="off"
                            validation={senderNumErr}
                            maxlength="10"
                          />
                        </div>
                      </FlexRow>
                      <FlexRow className="w-full items-start justify-between py-2">
                        <Spacer className="w-1/3" />
                        <RawInput
                          className=" text-secondary-200 text-sm h-10"
                          containerClass="w-2/3"
                          name="senderCompany"
                          placeholder="Shipper Name"
                          onChange={setSenderCompany}
                          type="text"
                          value={senderCompany}
                          readOnly={true}
                          autoComplete="off"
                          validation={senderCompanyErr}
                        />
                        {/* <Spacer className="w-1/3" /> */}
                      </FlexRow>
                      {sendingMethod === "RIDER PICK UP" && (
                        <FlexRow className="w-full items-start justify-between py-2">
                          <Text className="text-secondary-200 text-sm text-right w-1/3 pr-2 pt-2">
                            PICK UP ADDRESS:
                          </Text>
                          <RawInput
                            className="text-secondary-200 text-sm h-10"
                            containerClass="w-1/3 pr-2"
                            name="senderUnit"
                            placeholder="Floor/Unit No."
                            onChange={onSenderUnitChange}
                            value={senderUnit}
                            type="text"
                            autoComplete="off"
                            validation={senderUnitErr}
                          />
                          <RawInput
                            className="text-secondary-200 text-sm h-10"
                            containerClass="w-1/3 pl-2"
                            name="senderStreet"
                            placeholder="Street/Building (Optional)"
                            onChange={setSenderStreet}
                            value={senderStreet}
                            type="text"
                            autoComplete="off"
                            validation={senderStreetErr}
                          />
                        </FlexRow>
                      )}
                      <FlexRow className="w-full items-start justify-between py-2">
                        {sendingMethod === "RIDER PICK UP" ? (
                          <Spacer className="w-1/3" />
                        ) : (
                          <Text className="text-secondary-200 text-sm text-right w-1/3 pr-2 pt-2">
                            DROP OFF ADDRESS
                          </Text>
                        )}
                        <RawSelect
                          id="senderProvince"
                          name="senderProvince"
                          label=""
                          placeholder="Province"
                          containerClass="w-1/3 pr-2"
                          className="border-grey-400 text-secondary-200 text-sm h-10 block w-full"
                          classNamePrefix="react-select"
                          isDisabled={senderProvinceData.length === 0}
                          onChange={(value) =>
                            onSenderProvinceChange(sendingMethod, value)
                          }
                          value={senderProvince}
                          maxMenuHeight={180}
                          minHeight={50}
                          options={senderProvinceData.map((item) => ({
                            value: `${item.ProvinceId || item.ProvinceID}`,
                            label: `${item.ProvinceName}`,
                          }))}
                          filterOption={filterConfig}
                          validation={senderProvinceErr}
                        />
                        <RawSelect
                          id="senderCity"
                          name="senderCity"
                          label=""
                          placeholder="City/Municipality"
                          containerClass="w-1/3 pl-2"
                          className="border-grey-400 text-secondary-200 text-sm h-10 block w-full"
                          classNamePrefix="react-select"
                          isDisabled={senderCityData.length === 0}
                          onChange={(value) =>
                            onSenderCityChange(
                              sendingMethod,
                              senderProvince,
                              value
                            )
                          }
                          value={senderCity}
                          maxMenuHeight={180}
                          minHeight={50}
                          options={senderCityData.map((item) => ({
                            value: `${item.CityId || item.CityID}`,
                            label: `${item.CityName}`,
                          }))}
                          filterOption={filterConfig}
                          validation={senderCityErr}
                        />
                      </FlexRow>
                      {sendingMethod === "RIDER PICK UP" && (
                        <FlexRow className="w-full items-start justify-between py-2">
                          <Spacer className="w-1/3" />
                          <RawSelect
                            id="senderBrgy"
                            name="senderBrgy"
                            label=""
                            placeholder="Barangay"
                            containerClass="w-1/3 pr-2"
                            className="border-grey-400 text-secondary-200 text-sm h-10 block w-full"
                            classNamePrefix="react-select"
                            isDisabled={senderBrgyData.length === 0}
                            onChange={(value) => onSenderBrgyChange(value)}
                            value={senderBrgy}
                            maxMenuHeight={180}
                            minHeight={50}
                            options={senderBrgyData.map((item) => ({
                              value: `${item.BarangayId || item.BarangayID}`,
                              label: `${item.BarangayName}`,
                            }))}
                            filterOption={filterConfig}
                            validation={senderBrgyErr}
                          />
                          <RawInput
                            className="text-secondary-200 text-sm h-10"
                            containerClass="w-1/3 pl-2"
                            name="senderLandmark"
                            placeholder="Landmark/Special Instructions (Optional)"
                            onChange={setSenderLandmark}
                            value={senderLandmark}
                            type="text"
                            autoComplete="off"
                            validation={senderLandmarkErr}
                          />
                        </FlexRow>
                      )}
                      {/* <FlexRow className="w-full items-start justify-between py-2">
                        <Spacer className="w-1/3" />
                        <CheckBox
                          containerClass="w-2/3"
                          id="save-address"
                          name="save-address"
                          label={
                            <Text className="text-secondary-200 text-sm">
                              Save Contact to Address Book
                            </Text>
                          }
                        />
                      </FlexRow> */}
                      {sendingMethod === "RIDER PICK UP" && (
                        <FlexRow className="w-full items-start justify-start py-2">
                          <Text className="text-secondary-200 text-sm text-right w-1/3 pr-2 pt-2">
                            PICK UP SCHEDULE:
                          </Text>
                          <Div
                            className="relative w-1/3 pr-2"
                            onClick={onPickUpDateClick}
                          >
                            <div className="absolute inset-y-0 right-4 flex items-center pl-3 pointer-events-none h-10">
                              <svg
                                aria-hidden="true"
                                className="w-5 h-5 text-grey-400 dark:text-grey-400"
                                style={{ color: "rgb(139 139 139)" }}
                                fill="currentColor"
                                viewBox="0 0 25 25"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  fillRule="evenodd"
                                  d="M6.75 2.25A.75.75 0 017.5 3v1.5h9V3A.75.75 0 0118 3v1.5h.75a3 3 0 013 3v11.25a3 3 0 01-3 3H5.25a3 3 0 01-3-3V7.5a3 3 0 013-3H6V3a.75.75 0 01.75-.75zm13.5 9a1.5 1.5 0 00-1.5-1.5H5.25a1.5 1.5 0 00-1.5 1.5v7.5a1.5 1.5 0 001.5 1.5h13.5a1.5 1.5 0 001.5-1.5v-7.5z"
                                  clipRule="evenodd"
                                ></path>
                              </svg>
                            </div>
                            <RawInput
                              className="text-secondary-200 text-sm h-10 pr-8 placeholder:italic"
                              containerClass="w-full"
                              name="pickUpDate"
                              placeholder="Select Date"
                              onChange={setPickUpDate}
                              value={pickUpDate}
                              type="text"
                              readOnly={true}
                              autoComplete="off"
                              validation={pickUpDateErr}
                            />
                          </Div>
                          <Spacer className="w-1/3 pl-2" />
                        </FlexRow>
                      )}

                      <Spacer className="w-full h-4" />

                      <Div className="bg-grey-1300 py-2 rounded-lg">
                        <FlexRow className="items-start justify-start w-full px-4 py-1">
                          <ImInfo className="w-9 mr-1 text-secondary-200" />
                          <FlexColumn className="items-start justify-start text-secondary-200 text-xs">
                            {sendingMethod === "RIDER PICK UP" && (
                              <>
                                <Text className="text-left">
                                  Book before 11pm today to have your parcels
                                  pickup the next business day.
                                </Text>
                                <Spacer className="w-full h-4" />
                                <Text className="text-left">
                                  For SAME DAY pick ups, our cutoff period for
                                  booking are the ff:
                                </Text>
                                <Spacer className="w-full h-4" />
                                <Text className="text-left">
                                  <strong>NCR</strong> - 12:00 noon
                                </Text>
                                <Text className="text-left">
                                  <strong>
                                    Rizal & Provincial (or outside of NCR)
                                  </strong>{" "}
                                  - 11:00 AM
                                </Text>

                                <Spacer className="w-full h-4" />
                                <Text className="text-left">
                                  These bookings can be picked up between 2pm -
                                  8pm. Any bookings made after the cutoff will
                                  be processed the next day (excluding Sundays).
                                </Text>
                                <Spacer className="w-full h-4" />
                                <Text className="text-left">
                                  Find serviceable pick up locations{" "}
                                  <a
                                    className="text-blue-100"
                                    href="https://lbconline.lbcexpress.com/pickuplocations"
                                    target="blank"
                                  >
                                    here
                                  </a>
                                </Text>
                              </>
                            )}
                            {sendingMethod === "DROP-OFF" && (
                              <>
                                <Text className="text-left">
                                  You may drop off your package in any LBC
                                  branch within 2 weeks starting from your
                                  booking date.
                                </Text>
                                <Spacer className="w-full h-4" />
                                <Text className="text-left">
                                  Find LBC branches{" "}
                                  <a
                                    className="text-blue-100"
                                    href="https://www.lbcexpress.com/branches"
                                    target="blank"
                                  >
                                    here
                                  </a>
                                </Text>
                              </>
                            )}
                          </FlexColumn>
                        </FlexRow>
                      </Div>
                    </Div>
                  )}

                  {/* == Receiver Details == */}
                  {currentStage === 2 && (
                    <Div className="w-full">
                      <Div className="w-full">
                        <FormLabel className="text-secondary-200 text-left">
                          How do you want to your package received?
                        </FormLabel>
                        <div className="send-del-option">
                          <div className="optn-container">
                            <input
                              type="radio"
                              id="rider-delivery-optn"
                              name="del-optn"
                              onChange={() =>
                                onDeliveryMethodChange("HOME DELIVERY")
                              }
                              checked={deliveryMethod === "HOME DELIVERY"}
                            />
                            <label
                              htmlFor="rider-delivery-optn"
                              style={{
                                marginRight: "5px",
                                cursor: "pointer",
                              }}
                            >
                              <div className="send-del-optn-body">
                                <div
                                  id="rider-delivery-icon"
                                  className="send-del-optn-icon"
                                />
                                <div className="send-del-optn-details">
                                  <div className="title">Rider Delivery</div>
                                  The package will be delivered to the
                                  receiver's address.
                                </div>
                              </div>
                              <span className="checkmark" />
                            </label>
                          </div>
                          <div className="optn-container">
                            <input
                              type="radio"
                              id="branch-pickup-optn"
                              name="del-optn"
                              onChange={() =>
                                onDeliveryMethodChange("BRANCH PICK UP")
                              }
                              checked={deliveryMethod === "BRANCH PICK UP"}
                            />
                            <label
                              htmlFor="branch-pickup-optn"
                              style={{
                                marginLeft: "5px",
                                cursor: "pointer",
                              }}
                            >
                              <div className="send-del-optn-body">
                                <div
                                  id="branch-pickup-icon"
                                  className="send-del-optn-icon"
                                />
                                <div className="send-del-optn-details">
                                  <div className="title">Branch Pick Up</div>
                                  The Receiver will pick up the item at the
                                  branch.
                                </div>
                              </div>
                              <span className="checkmark" />
                            </label>
                          </div>
                        </div>
                      </Div>
                      <FlexRow className="w-full items-start justify-between py-2">
                        <Text className="text-secondary-200 text-sm text-left w-1/3 pt-2">
                          CONTACT PERSON:
                        </Text>
                        <RawInput
                          className="text-secondary-200 text-sm h-10"
                          containerClass="w-1/3 pr-2"
                          name="receiverName"
                          placeholder="Contact Name"
                          onChange={onReceiverNameChange}
                          type="text"
                          value={receiverName}
                          autoComplete="off"
                          validation={receiverNameErr}
                        />
                        <div className="relative w-1/3 pl-2">
                          <div className="absolute inset-y-0 left-1 flex items-center pl-3 pointer-events-none h-10 text-secondary-200 text-sm">
                            +63
                          </div>
                          <RawInput
                            className="text-secondary-200 text-sm h-10 pl-9"
                            containerClass="w-full"
                            name="receiverNum"
                            placeholder="9000000000"
                            onChange={onReceiverNumChange}
                            type="text"
                            value={receiverNum}
                            autoComplete="off"
                            validation={receiverNumErr}
                            maxlength="10"
                          />
                        </div>
                      </FlexRow>
                      {deliveryMethod === "HOME DELIVERY" && (
                        <FlexRow className="w-full items-start justify-between py-2">
                          <Text className="text-secondary-200 text-sm text-left w-1/3 pt-2">
                            DELIVERY ADDRESS
                          </Text>
                          <RawInput
                            className="text-secondary-200 text-sm h-10"
                            containerClass="w-1/3 pr-2"
                            name="receiverUnit"
                            placeholder="Floor/Unit No."
                            onChange={onReceiverUnitChange}
                            type="text"
                            value={receiverUnit}
                            autoComplete="off"
                            validation={receiverUnitErr}
                          />
                          <RawInput
                            className="text-secondary-200 text-sm h-10"
                            containerClass="w-1/3 pl-2"
                            name="receiverStreet"
                            placeholder="Street/Building (Optional)"
                            onChange={setReceiverStreet}
                            type="text"
                            value={receiverStreet}
                            autoComplete="off"
                            validation={receiverStreetErr}
                          />
                        </FlexRow>
                      )}
                      <FlexRow className="w-full items-start justify-between py-2">
                        {deliveryMethod === "HOME DELIVERY" ? (
                          <Spacer className="w-1/3" />
                        ) : (
                          <Text className="text-secondary-200 text-sm text-left w-1/3 pt-2">
                            BRANCH PICK UP ADDRESS
                          </Text>
                        )}
                        <RawSelect
                          id="receiverProvince"
                          name="receiverProvince"
                          label=""
                          placeholder="Province"
                          containerClass="w-1/3 pr-2"
                          className="border-grey-400 text-secondary-200 text-sm h-10 block w-full"
                          classNamePrefix="react-select"
                          isDisabled={receiverProvinceData.length === 0}
                          onChange={(value) =>
                            onReceiverProvinceChange(deliveryMethod, value)
                          }
                          value={receiverProvince}
                          maxMenuHeight={180}
                          minHeight={50}
                          options={receiverProvinceData.map((item) => ({
                            value: `${item.ProvinceId || item.ProvinceID}`,
                            label: `${item.ProvinceName}`,
                          }))}
                          filterOption={filterConfig}
                          validation={receiverProvinceErr}
                        />
                        <RawSelect
                          id="receiverCity"
                          name="receiverCity"
                          label=""
                          placeholder="City/Municipality"
                          containerClass="w-1/3 pl-2"
                          className="border-grey-400 text-secondary-200 text-sm h-10 block w-full"
                          classNamePrefix="react-select"
                          isDisabled={receiverCityData.length === 0}
                          onChange={(value) =>
                            onReceiverCityChange(
                              deliveryMethod,
                              receiverProvince,
                              value
                            )
                          }
                          value={receiverCity}
                          maxMenuHeight={180}
                          minHeight={50}
                          options={receiverCityData.map((item) => ({
                            value: `${item.CityId || item.CityID}`,
                            label: `${item.CityName}`,
                          }))}
                          filterOption={filterConfig}
                          validation={receiverCityErr}
                        />
                      </FlexRow>
                      <FlexRow className="w-full items-start justify-between py-2">
                        <Spacer className="w-1/3" />
                        <RawSelect
                          id="receiverBrgy"
                          name="receiverBrgy"
                          label=""
                          placeholder={
                            deliveryMethod === "HOME DELIVERY"
                              ? "Barangay"
                              : "Branch"
                          }
                          containerClass="w-1/3 pr-2"
                          className="border-grey-400 text-secondary-200 text-sm h-10 block w-full"
                          classNamePrefix="react-select"
                          isDisabled={receiverBrgyData.length === 0}
                          onChange={(value) => onReceiverBrgyChange(value)}
                          value={receiverBrgy}
                          maxMenuHeight={180}
                          minHeight={50}
                          options={receiverBrgyData.map((item) => ({
                            value: `${
                              item.BarangayId ||
                              item.BarangayID ||
                              item.branchid
                            }`,
                            label: `${item.BarangayName || item.BranchDescr}`,
                          }))}
                          filterOption={filterConfig}
                          validation={receiverBrgyErr}
                        />
                        {deliveryMethod === "HOME DELIVERY" ? (
                          <RawInput
                            className="text-secondary-200 text-sm h-10"
                            containerClass="w-1/3 pl-2"
                            name="receiverLandmark"
                            placeholder="Landmark (Optional)"
                            onChange={setReceiverLandmark}
                            value={receiverLandmark}
                            type="text"
                            autoComplete="off"
                            validation={receiverLandmarkErr}
                          />
                        ) : (
                          <Spacer className="w-1/3" />
                        )}
                      </FlexRow>
                      {/* <FlexRow className="w-full items-start justify-between py-2">
                        <Spacer className="w-1/3" />
                        <CheckBox
                          containerClass="w-2/3"
                          id="save-address"
                          name="save-address"
                          label={
                            <Text className="text-secondary-200 text-sm">
                              Save Contact to Address Book
                            </Text>
                          }
                        />
                      </FlexRow> */}
                    </Div>
                  )}

                  {/* == Package Details == */}
                  {currentStage === 3 && (
                    <Div className="w-full">
                      <FlexRow className="w-full items-start justify-between">
                        <Div className="w-1/4 p-2">
                          <FormLabel
                            className="text-secondary-200 text-left"
                            isRequired
                          >
                            Item name
                          </FormLabel>
                          <RawInput
                            className="text-secondary-200 text-sm h-10"
                            containerClass="my-2"
                            name="itemName"
                            placeholder=""
                            onChange={onItemNameChange}
                            value={itemName}
                            type="text"
                            autoComplete="off"
                            validation={itemNameErr}
                          />
                        </Div>
                        <Div className="w-1/4 p-2">
                          <FormLabel
                            className="text-secondary-200 text-left"
                            isRequired
                          >
                            Item Value
                          </FormLabel>
                          <RawInput
                            className="text-secondary-200 text-sm h-10"
                            containerClass="my-2"
                            name="itemValue"
                            placeholder=""
                            onChange={onItemValueChange}
                            value={itemValue}
                            type="text"
                            maxlength={6}
                            autoComplete="off"
                            validation={itemValueErr}
                          />
                        </Div>
                        <Div className="w-1/2 p-2">
                          <FormLabel
                            className="text-secondary-200 text-left"
                            isRequired
                          >
                            Shipment Type
                          </FormLabel>
                          <FlexRow className="w-full items-start justify-start">
                            <Radio
                              containerClass="rounded-lg p-2 border border-grey-400 m-2 w-2/5"
                              isChecked={shipmentType === "Air"}
                              label="Air"
                              name="air"
                              onClick={() => setShipmentType("Air")}
                              optionId="shipment"
                            />
                            <Radio
                              containerClass="rounded-lg p-2 border border-grey-400 m-2 w-2/5"
                              isChecked={shipmentType === "Sea"}
                              label="Sea"
                              name="sea"
                              onClick={() => setShipmentType("Sea")}
                              optionId="shipment"
                            />
                          </FlexRow>
                        </Div>
                      </FlexRow>

                      <CheckBox
                        containerClass=""
                        id="useCODCOP"
                        name="useCODCOP"
                        label="Use Cash on Delivery (COD) or Cash on Pick Up (COP)"
                        checked={useCODCOP}
                        onClick={(e) => onUseCODCOPChange(e.target.checked)}
                      />

                      {useCODCOP && (
                        <>
                          <Text className="text-secondary-200 text-left text-sm p-2">
                            LBC will collect the payment from the receiver on
                            your behalf upon delivery of your item
                          </Text>

                          <Div
                            className={`pl-5 pt-2 ${
                              useCODCOPErr
                                ? "border rounded-lg border-red-100 dark:border-red-400"
                                : ""
                            }`}
                          >
                            <FlexRow className="w-full items-start justify-start">
                              <CheckBox
                                containerClass="mt-2"
                                id="collectCODCOPAmt"
                                name="collectCODCOPAmt"
                                label="COD/COP Amount"
                                checked={collectCODCOPAmt}
                                onClick={(e) =>
                                  setCollectCODCOPAmt(e.target.checked)
                                }
                              />
                              <RawInput
                                className="text-secondary-200 text-sm h-10 w-200px"
                                containerClass="w-200px pl-2"
                                name="codcopAmt"
                                placeholder="Enter amount"
                                onChange={onCODCOPAmtChange}
                                value={codcopAmt}
                                type="text"
                                autoComplete="off"
                                maxlength={6}
                                validation={codcopAmtErr}
                              />
                            </FlexRow>
                            {/* <CheckBox
                              containerClass="h-10"
                              id="collectFreightAmt"
                              name="collectFreightAmt"
                              label="Freight Amount"
                              checked={collectFreightAmt}
                              onClick={(e) =>
                                setCollectFreightAmt(e.target.checked)
                              }
                            /> */}
                          </Div>
                          {useCODCOPErr && (
                            <p className="mt-1 pl-2 text-xs text-left text-red-400 dark:text-red-400 w-full">
                              {useCODCOPErr}
                            </p>
                          )}
                        </>
                      )}

                      <Spacer className="h-5" />

                      <Text className="text-secondary-200 text-left font-bold">
                        Packaging
                      </Text>

                      {pkgData.length > 0 && (
                        <>
                          <Div
                            className={`w-full overflow-x-scroll py-2 ${
                              selectedPkgErr
                                ? "border rounded-lg border-red-100 dark:border-red-400"
                                : ""
                            }`}
                          >
                            <FlexRow className="w-min py-2">
                              {pkgData
                                .filter((pkg) =>
                                  sendingMethod !== "" && deliveryMethod !== ""
                                    ? sendingMethod === "RIDER PICK UP" &&
                                      deliveryMethod === "HOME DELIVERY"
                                      ? pkg.prodId !== 164 && pkg.prodId !== 165
                                      : sendingMethod === "RIDER PICK UP" &&
                                        deliveryMethod === "BRANCH PICK UP"
                                      ? pkg.prodId !== 16 &&
                                        pkg.prodId !== 164 &&
                                        pkg.prodId !== 165
                                      : pkg.prodId !== 16
                                    : true
                                )
                                .map((item, key) => (
                                  <CorpPortalAcctPackageItem
                                    key={key}
                                    className="w-200px mx-2"
                                    isSelected={
                                      selectedPkg &&
                                      selectedPkg.prodId === item.prodId
                                    }
                                    data={item}
                                    onClick={onSelectPkg}
                                    isCM={true}
                                  />
                                ))}
                            </FlexRow>
                          </Div>
                          {selectedPkgErr && (
                            <p className="mt-1 pl-2 text-xs text-left text-red-400 dark:text-red-400 w-full">
                              {selectedPkgErr}
                            </p>
                          )}
                        </>
                      )}

                      {selectedPkg && selectedPkg.prodId === 16 && (
                        <>
                          <Spacer className="h-5" />

                          <FlexRow className="w-full items-start justify-between">
                            <Div className="w-1/4 p-2">
                              <FormLabel
                                className="text-secondary-200 text-left"
                                isRequired
                              >
                                {`Act. Weight (kg)`}
                              </FormLabel>
                              <RawInput
                                className="text-secondary-200 text-sm h-10"
                                containerClass="my-2"
                                name="actWeight"
                                placeholder=""
                                onChange={onActWeightChange}
                                value={actWeight}
                                type="text"
                                autoComplete="off"
                                maxlength="4"
                                validation={actWeightErr}
                              />
                            </Div>
                            <Div className="w-3/4 p-2">
                              <FormLabel
                                className="text-secondary-200 text-left"
                                isRequired
                              >
                                {`Item Dimensions (cm)`}
                              </FormLabel>
                              <FlexRow className="w-full items-start justify-start">
                                <Text className="text-secondary-200 text-xs pt-5">
                                  Length
                                </Text>
                                <RawInput
                                  className="text-secondary-200 text-sm h-10"
                                  containerClass="m-2 w-16"
                                  name="cbmLength"
                                  placeholder=""
                                  onChange={onCBMLengthChange}
                                  value={cbmLength}
                                  type="text"
                                  autoComplete="off"
                                  maxlength="4"
                                  validation={cbmLengthErr}
                                />
                                <Text className="text-secondary-200 text-xs pt-5">
                                  x Width
                                </Text>
                                <RawInput
                                  className="text-secondary-200 text-sm h-10"
                                  containerClass="m-2 w-16"
                                  name="cbmWidth"
                                  placeholder=""
                                  onChange={onCBMWidthChange}
                                  value={cbmWidth}
                                  type="text"
                                  autoComplete="off"
                                  maxlength="4"
                                  validation={cbmWidthErr}
                                />
                                <Text className="text-secondary-200 text-xs pt-5">
                                  x Height
                                </Text>
                                <RawInput
                                  className="text-secondary-200 text-sm h-10"
                                  containerClass="m-2 w-16"
                                  name="cbmHeight"
                                  placeholder=""
                                  onChange={onCBMHeightChange}
                                  value={cbmHeight}
                                  type="text"
                                  autoComplete="off"
                                  maxlength="4"
                                  validation={cbmHeightErr}
                                />
                              </FlexRow>
                            </Div>
                          </FlexRow>
                        </>
                      )}
                    </Div>
                  )}
                </Div>

                <FlexRow className="w-full items-start justify-center py-2">
                  {currentStage > 1 && (
                    <RawButton
                      className="w-1/4"
                      onClick={() => setToastOpen1(true)}
                    >
                      <Text className="text-red-400 font-bold">
                        Save for later
                      </Text>
                    </RawButton>
                  )}
                  <Spacer className="w-1/2" />
                  <Button
                    className="w-1/4 mr-2 phone:bg-white phone:text-secondary-200 hover:bg-grey-500 phone:border phone:border-grey-400 h-10"
                    onClick={
                      currentStage === 1
                        ? onCloseModal
                        : () => onChangeStage(currentStage, "prev")
                    }
                  >
                    {currentStage === 1 ? "Cancel" : "Back"}
                  </Button>
                  <Button
                    className="w-1/4 ml-2 h-10"
                    onClick={() => onChangeStage(currentStage, "next")}
                    isLoading={loading}
                  >
                    Next
                  </Button>
                </FlexRow>
              </FlexColumn>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition>
  );
};
