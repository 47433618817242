import React, { useRef } from "react";
import { Cookies } from "react-cookie";
import { Div, FlexRow, Spacer } from "../../../core/Containers";
import { LBCModal } from "../../../components/Modals/LBCModal";
import { useLocation, useNavigate } from "react-router-dom";
import { Button, RawDropdown } from "../../../core/Buttons";
import {
  RecurrenceOptionsRemittance,
  FilterOptionsRemittance,
} from "../../../constants/Dropdowns";
import { Text } from "../../../core/Text";
import { ImSpinner2 } from "react-icons/im";
import moment from "moment";
import { Pagination } from "../../../components/Tables/Pagination";

import { RemittanceCorpDataRow } from "../../../components/Tables/ReportsDataRow";
import { RemittanceCorpColumns } from "../../../constants/ConstantsData";

import axiosCAPApi from "../../../api/axiosCAPApi";
import axiosCAPApiPrivate from "../../../api/axiosCAPApiPrivate";

const cookies = new Cookies();

export const RemittanceReport = ({
  reportFilter,
  reportDateFrom,
  reportDateTo,
}) => {
  const navigate = useNavigate();
  const dataFetchedRef = useRef(false);
  const { search } = useLocation();
  const query = new URLSearchParams(search);
  const actionQuery = query.get("option");

  const [sortBy, setSort] = React.useState("SHIPMENTS BY LAST STATUS");
  const [filter, setFilter] = React.useState("Transaction Date");
  const [timeline, setTimeline] = React.useState("Daily");

  const [loading, setLoading] = React.useState(false);
  const [selectedList, setSelectedList] = React.useState([]);

  const [currentPage, setCurrentPage] = React.useState(1);
  const [articlesPerPage] = React.useState(5);
  const indexOfLastArticle = currentPage * articlesPerPage;
  const indexOfFirstArticle = indexOfLastArticle - articlesPerPage;
  const [remittanceCorpList, setRemittanceCorpList] = React.useState([]);
  const [remittanceCorpListFilter, setRemittanceCorpListFilter] =
    React.useState([]);
  const newList = remittanceCorpListFilter.slice(
    indexOfFirstArticle,
    indexOfLastArticle
  );

  const [shipperAcctName, setShipperAcctName] = React.useState("");
  const [shipperAcctNo, setShipperAcctNo] = React.useState("");

  //const [filter, setFilter] = React.useState([]);
  const [dateFrom, setDateFrom] = React.useState(
    moment(new Date()).format("yyyy-MM-DD")
  );
  const [dateTo, setDateTo] = React.useState(
    moment(new Date()).format("yyyy-MM-DD")
  );

  const [isToastOpen, setToastOpen] = React.useState(false);
  const [toastKind, setToastKind] = React.useState("");
  const [toastTitle, setToastTitle] = React.useState("");
  const [toastDesc, setToastDesc] = React.useState("");

  React.useEffect(() => {
    if (dataFetchedRef.current) return;
    dataFetchedRef.current = true;

    var _shipperAcct = cookies.get("shipperAcct", { path: "/" });
    var _shipperAcctSplit = _shipperAcct ? _shipperAcct.split("|") : [];
    var _shipperAcctName = _shipperAcctSplit[0]
      ? _shipperAcctSplit[0].toString().trim()
      : "";
    var _shipperAcctNo = _shipperAcctSplit[1]
      ? _shipperAcctSplit[1].toString().trim()
      : "";

    setShipperAcctName(_shipperAcctName);
    setShipperAcctNo(_shipperAcctNo);

    //setFilter(reportFilter);
    setDateFrom(
      reportDateFrom !== ""
        ? moment(reportDateFrom).format("yyyy-MM-DD")
        : moment(new Date()).format("yyyy-MM-DD")
    );
    setDateTo(
      reportDateTo !== ""
        ? moment(reportDateTo).format("yyyy-MM-DD")
        : moment(new Date()).format("yyyy-MM-DD")
    );

    if (reportDateFrom !== "" && reportDateTo !== "") {
      GenerateRemittanceReport(
        _shipperAcctNo,
        moment(reportDateFrom).format("yyyy-MM-DD"),
        moment(reportDateTo).format("yyyy-MM-DD")
      );
    }
  }, []);

  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  // const getGetRemittanceCorpDaily = () => {
  //   setLoading(true);

  //   Auth.validateToken()
  //     .then(async (response) => {
  //       return await response.data;
  //     })
  //     .then((data) => {
  //       if (data.Code === "403") {
  //         Auth.refreshToken()
  //           .then(async (response) => {
  //             return await response.data;
  //           })
  //           .then(async (data) => {
  //             if (data.Code === "200") {
  //               const userInfo = cookies.get("userInfo");
  //               const newToken = data.Data;
  //               userInfo.token = newToken;
  //               cookies.set("userInfo", userInfo, { path: "/" });
  //               cookies.set("accessToken", newToken, { path: "/" });

  //               procGetRemittanceCorpDaily();
  //             } else {
  //               setToastKind("error");
  //               setToastTitle("Remittance Status Failed!");
  //               setToastDesc(data.message);
  //               setTimeout(() => {
  //                 setLoading(false);
  //               }, 1000);
  //               setTimeout(() => {
  //                 setToastOpen(true);
  //               }, 1500);
  //               setTimeout(() => {
  //                 setToastOpen(false);
  //               }, 3000);
  //             }
  //           })
  //           .catch((error) => {
  //             setToastKind("error");
  //             setToastTitle("Remittance Status Failed!");
  //             setToastDesc(error.message);
  //             setTimeout(() => {
  //               setLoading(false);
  //             }, 1000);
  //             setTimeout(() => {
  //               setToastOpen(true);
  //             }, 1500);
  //             setTimeout(() => {
  //               setToastOpen(false);
  //             }, 3000);
  //           });
  //       } else {
  //         procGetRemittanceCorpDaily();
  //       }
  //     })
  //     .catch((error) => {
  //       setToastKind("error");
  //       setToastTitle("Remittance Status Failed!");
  //       setToastDesc(error.message);
  //       setTimeout(() => {
  //         setLoading(false);
  //       }, 1000);
  //       setTimeout(() => {
  //         setToastOpen(true);
  //       }, 1500);
  //       setTimeout(() => {
  //         setToastOpen(false);
  //       }, 3000);
  //     });
  // };

  const getGetRemittanceCorpDaily = async (shipperAcctNo, dateFrom, dateTo) => {
    setLoading(true);

    try {
      const response = await axiosCAPApiPrivate.post("/api/GetRemittanceCorpDaily", {
        CustomerAccountNo: shipperAcctNo,
        FromDate: dateFrom,
        ToDate: dateTo,
      });

      const data = response.data;

      if (data.Code === "200") {
        setLoading(false);
        setRemittanceCorpList(data.Data);
        setRemittanceCorpListFilter(data.Data);
      }
      setLoading(false);
    } catch (error) {
      setToastKind("error");
      setToastTitle("Remittance Status Failed!");
      setToastDesc(error.message);
      setTimeout(() => {
        setLoading(false);
      }, 1000);
      setTimeout(() => {
        setToastOpen(true);
      }, 1500);
      setTimeout(() => {
        setToastOpen(false);
      }, 3000);
    }
  };

  // const getRemittanceCorpSummaryForThreeMonths = () => {
  //   setLoading(true);

  //   Auth.validateToken()
  //     .then(async (response) => {
  //       return await response.data;
  //     })
  //     .then((data) => {
  //       if (data.Code === "403") {
  //         Auth.refreshToken()
  //           .then(async (response) => {
  //             return await response.data;
  //           })
  //           .then(async (data) => {
  //             if (data.Code === "200") {
  //               const userInfo = cookies.get("userInfo");
  //               const newToken = data.Data;
  //               userInfo.token = newToken;
  //               cookies.set("userInfo", userInfo, { path: "/" });
  //               cookies.set("accessToken", newToken, { path: "/" });

  //               procGetRemittanceCorpSummaryForThreeMonths();
  //             } else {
  //               setToastKind("error");
  //               setToastTitle("Remittance Status Failed!");
  //               setToastDesc(data.message);
  //               setTimeout(() => {
  //                 setLoading(false);
  //               }, 1000);
  //               setTimeout(() => {
  //                 setToastOpen(true);
  //               }, 1500);
  //               setTimeout(() => {
  //                 setToastOpen(false);
  //               }, 3000);
  //             }
  //           })
  //           .catch((error) => {
  //             setToastKind("error");
  //             setToastTitle("Remittance Status Failed!");
  //             setToastDesc(error.message);
  //             setTimeout(() => {
  //               setLoading(false);
  //             }, 1000);
  //             setTimeout(() => {
  //               setToastOpen(true);
  //             }, 1500);
  //             setTimeout(() => {
  //               setToastOpen(false);
  //             }, 3000);
  //           });
  //       } else {
  //         procGetRemittanceCorpSummaryForThreeMonths();
  //       }
  //     })
  //     .catch((error) => {
  //       setToastKind("error");
  //       setToastTitle("Remittance Status Failed!");
  //       setToastDesc(error.message);
  //       setTimeout(() => {
  //         setLoading(false);
  //       }, 1000);
  //       setTimeout(() => {
  //         setToastOpen(true);
  //       }, 1500);
  //       setTimeout(() => {
  //         setToastOpen(false);
  //       }, 3000);
  //     });
  // };

  const getRemittanceCorpSummaryForThreeMonths = async () => {
    setLoading(true);

    try {
      const response = await axiosCAPApiPrivate.post(
        "/api/GetRemittanceCorpSummaryForThreeMonths",
        {
          CustomerAccountNo: shipperAcctNo,
          ReportRange: timeline,
        }
      );

      const data = response.data;

      if (data.Code === "200") {
        setLoading(false);
        setRemittanceCorpList(data.Data);
        setRemittanceCorpListFilter(data.Data);
      }
      setLoading(false);
    } catch (error) {
      setToastKind("error");
      setToastTitle("Remittance Status Failed!");
      setToastDesc(error.message);
      setTimeout(() => {
        setLoading(false);
      }, 1000);
      setTimeout(() => {
        setToastOpen(true);
      }, 1500);
      setTimeout(() => {
        setToastOpen(false);
      }, 3000);
    }
  };

  const GenerateRemittanceReport = (shipperAcctNo, dateFrom, dateTo) => {
    if (timeline === "Monthly") getRemittanceCorpSummaryForThreeMonths();
    else if (timeline === "Daily") {
      getGetRemittanceCorpDaily(shipperAcctNo, dateFrom, dateTo);
    }
  };

  React.useEffect(() => {
    if (filter === "Transaction Date")
      setRemittanceCorpListFilter(remittanceCorpList);
    else if (filter === "Delivered Date")
      setRemittanceCorpListFilter(
        remittanceCorpListFilter.filter((x) => x.Delivered > 0).map((s) => s)
      );
    else if (filter === "Remitted Date")
      setRemittanceCorpListFilter(
        remittanceCorpListFilter.filter((x) => x.Remitted > 0).map((s) => s)
      );
  }, [filter]);

  React.useEffect(() => {}, [timeline]);

  return (
    <>
      <LBCModal
        description={toastDesc}
        isOpen={isToastOpen}
        onClose={() => setToastOpen(false)}
        title={toastTitle}
        toastKind={toastKind}
      />

      <Div className="w-full">
        <Spacer className="w-10 h-10" />
        <Div className="w-full border border-grey-400 rounded-lg shadow-lg">
          <FlexRow className="w-full items-center justify-between py-5">
            <FlexRow className="items-center">
              <Div className="w-48 mx-2">
                <RawDropdown
                  icon={<div />}
                  width="w-full"
                  border="border phone:border-grey-100"
                  value={filter}
                  options={FilterOptionsRemittance}
                  onSelect={setFilter}
                />
              </Div>
              <Div className="w-48 mx-2">
                <RawDropdown
                  icon={<div />}
                  width="w-full"
                  border="border phone:border-grey-100"
                  value={timeline}
                  options={RecurrenceOptionsRemittance}
                  onSelect={setTimeline}
                />
              </Div>
              {timeline === "Daily" ? (
                <FlexRow>
                  <input
                    className="text-sm w-40 mx-2 block rounded-lg"
                    type="date"
                    value={dateFrom}
                    onChange={(e) => setDateFrom(e.target.value)}
                  />
                  <input
                    className="text-sm w-40 mx-2 block rounded-lg"
                    type="date"
                    value={dateTo}
                    onChange={(e) => setDateTo(e.target.value)}
                  />
                </FlexRow>
              ) : (
                <></>
              )}

              <Button
                className="w-40 ml-2 h-10"
                isLoading={loading}
                onClick={() =>
                  GenerateRemittanceReport(shipperAcctNo, dateFrom, dateTo)
                }
              >
                Generate
              </Button>
            </FlexRow>
          </FlexRow>
          <Spacer className="w-10 h-10" />

          <FlexRow className="w-full items-center justify-between py-2 border border-grey-400">
            {RemittanceCorpColumns.map((column) => (
              <Text
                className={`text-secondary-100 text-sm text-center font-semibold px-4 py-2 ${"w-1/4"}`}
              >
                {column}
              </Text>
            ))}
          </FlexRow>

          {loading ? (
            <Text className="text-red-400 text-center flex flex-row justify-center items-center my-10">
              <ImSpinner2 className="animate-spin mr-2 text-2xl desktop:text-3xl" />
            </Text>
          ) : (
            <Div className="w-full">
              {newList.map((item, key) => (
                <RemittanceCorpDataRow data={item} key={key} />
              ))}
            </Div>
          )}
        </Div>
        <Spacer className="w-full h-10" />
        <Div className="w-full">
          <Pagination
            currentPage={currentPage}
            itemsPerPage={articlesPerPage}
            paginate={paginate}
            totalItems={remittanceCorpListFilter.length}
          />
        </Div>
      </Div>
    </>
  );
};
