let _appConfig = require("../app-config");
const env = process.env.BUILD_ENV
  ? process.env.BUILD_ENV
  : process.env.NODE_ENV;
_appConfig = _appConfig[env];

const numbersRegx = /^[0-9]*$/; // for Mobile Number
const mobilenumberRegex = /^(9)\d{9}$/; // mobile number validation regex
const emailRegex =
  /^(([^<>()\\[\]\\.,;:\s@"]+(\.[^<>()\\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/; // email validation regex
const passwordRegex =
  /^(?=[A-Za-z0-9!"#$%&'()*+,-./:;<=>?@[\]^_`{|}~]+$)^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!"#$%&'()*+,-./:;<=>?@[\]^_`{|}~])(?=.{8,}).*$/; // password validation regex

export const isNumber = (value) => {
  return value === "" || numbersRegx.test(value);
};

export const isNumberWithComma = (value) => {
  var regex = /^[0-9,\t \b]+$/;
  return value === "" || regex.test(value);
};

export const isMobileNumber = (value) => {
  return value === "" || mobilenumberRegex.test(value);
};

export const isEmail = (value) => {
  return value === "" || emailRegex.test(value);
};

export const isValidPassword = (value) => {
  return value === "" || passwordRegex.test(value);
};

export const getProvinceRegionId = (provinceData, provinceId) => {
  const prov = provinceData.find(
    (prov) => parseInt(prov.ProvinceId) === parseInt(provinceId)
  );
  const regionId = prov
    ? prov.RegionCode === "NCR"
      ? 1
      : prov.RegionCode === "NLA"
      ? 2
      : prov.RegionCode === "SLA"
      ? 3
      : prov.RegionCode === "VIS"
      ? 4
      : prov.RegionCode === "MIN"
      ? 5
      : 0
    : 0;
  return regionId;
};

export const getBoxFee = (item) => {
  return item.PKGBoxFee && item.PKGBoxFee !== "" ? Number(item.PKGBoxFee) : 0;
};

export const getPayCollectOptn = (item) => {
  const attachmentNameThree = item.AttachmentNameThree.split("|");
  const payCollectOptn = attachmentNameThree[1]
    ? Number(attachmentNameThree[1].trim())
    : 0;

  return payCollectOptn;
};

export const appConfig = _appConfig;
