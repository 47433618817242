import React from "react";
import { Cookies } from "react-cookie";
import { Div, Divider, FlexColumn, FlexRow } from "../../core/Containers";
import { NavbarMain } from "../../components/Navigation/NavbarMain";
import { TopNavBar } from "../../components/Navigation/TopNavBar";
import { useLocation, useNavigate } from "react-router-dom";
import { Tab } from "@headlessui/react";
import { classNames } from "../../helpers/ClassNames";
import {
  BookNowTab,
  RequestSuppliesTab,
  SchedulePickUpTab,
} from "./BookNowTabs";
import { LBCModal, LBCModalTwoFunc } from "../../components/Modals/LBCModal";
import { useCallbackPrompt } from "../../hooks";

const cookies = new Cookies();

export const BookNow = () => {
  const navigate = useNavigate();
  const { search } = useLocation();
  const query = new URLSearchParams(search);
  const actionQuery = query.get("option");
  const blkSessionId = query.get("bulksessionid");
  const pickUpSchedule = query.get("pickUpSchedule");

  const [selectedIndex, setSelectedIndex] = React.useState(-1);
  const [toSelectedIndex, setToSelectedIndex] = React.useState(0);
  const [shipperAcct, setShipperAcct] = React.useState("");

  const [showConfirmLeave, setShowConfirmLeave] = React.useState(false);
  const [showPrompt, confirmNavigation, cancelNavigation] =
    useCallbackPrompt(showConfirmLeave);

  const [showPromptTab, setShowPromptTab] = React.useState(false);
  const [bulkSessionId, setBulkSessionId] = React.useState(null);

  const tabs = [
    {
      id: "bookNow",
      name: "Book Now",
      tabIndex: 0,
    },
    {
      id: "bookNowPUpSched",
      name: "Pick Up Schedules",
      tabIndex: 1,
    },
    {
      id: "bookNowReqSupplies",
      name: "Request Supplies",
      tabIndex: 2,
    },
  ];

  const userInfo = cookies.get("userInfo", { path: "/" });

  React.useEffect(() => {
    setShipperAcct(
      cookies.get("shipperAcct", { path: "/" })
        ? cookies.get("shipperAcct", { path: "/" })
        : ""
    );
  }, []);

  React.useEffect(() => {
    if (actionQuery === "0") {
      setSelectedIndex(0);
      setBulkSessionId(blkSessionId ? blkSessionId : "");
      navigate("/book-now");
    } else if (actionQuery === "1") {
      setSelectedIndex(1);
      navigate("/book-now");
    } else if (actionQuery === "2") {
      setSelectedIndex(2);
      navigate("/book-now");
    }

    if (selectedIndex === -1 && actionQuery === null) {
      setSelectedIndex(0);
      setBulkSessionId(blkSessionId ? blkSessionId : "");
      navigate("/book-now");
    }
  }, [actionQuery, navigate]);

  const onTabChange = (index) => {
    if (showConfirmLeave) {
      setToSelectedIndex(index);
      setShowPromptTab(true);
    } else {
      setSelectedIndex(index);
    }

    setBulkSessionId(blkSessionId ? blkSessionId : "");
  };

  const onConfirmLeaveTab = () => {
    setShowPromptTab(false);
    setShowConfirmLeave(false);
    setSelectedIndex(toSelectedIndex);
    setBulkSessionId("");
  };

  const onCancelLeaveTab = () => {
    setShowPromptTab(false);
    setSelectedIndex(selectedIndex);
  };

  return (
    <>
      <LBCModalTwoFunc
        id="confirmLeavePage"
        bodyClassName="justify-between bg-white border border-grey-400 w-500px p-5 rounded-lg shadow-lg"
        description="Any changes will be left unsaved."
        isOpen={showPrompt}
        onClose={cancelNavigation}
        title="Are you sure you want to leave this page?"
        buttonTextFirst="Leave page"
        buttonTextSecond="Stay on this page"
        onClickFirst={confirmNavigation}
        onClickSecond={cancelNavigation}
      />

      <LBCModalTwoFunc
        id="confirmLeaveTab"
        bodyClassName="justify-between bg-white border border-grey-400 w-500px p-5 rounded-lg shadow-lg"
        description="Any changes will be left unsaved."
        isOpen={showPromptTab}
        onClose={onCancelLeaveTab}
        title={`Are you sure you want to leave ${tabs[selectedIndex]?.name}?`}
        buttonTextFirst="Leave"
        buttonTextSecond="Stay"
        onClickFirst={onConfirmLeaveTab}
        onClickSecond={onCancelLeaveTab}
      />

      <FlexRow className="w-full h-full items-center justify-between">
        {/* Side Navigation */}
        <NavbarMain route="Book Now" onBookNowClick={onTabChange} />

        {/* Main Dashboard */}
        <FlexColumn className="w-11/12 h-full">
          {/* Top Navigation */}
          <TopNavBar
            showMenu={true}
            showNotifBell={true}
            showSearchBar={true}
          />

          {/* Body */}
          <Div className="w-full h-full overflow-y-auto p-10 bg-white">
            <FlexRow className="w-full">
              <Tab.Group selectedIndex={selectedIndex} onChange={onTabChange}>
                <FlexColumn className="w-full">
                  <Tab.List className="tabletWide:space-x-4">
                    {tabs.map(
                      (tab, i) =>
                        userInfo?.accessModules?.includes(tab.id) && (
                          <Tab
                            className={({ selected }) =>
                              classNames(
                                "p-2 font-semibold w-auto ring:border-orange-400 outline-none",
                                selected
                                  ? "text-orange-400 border-b-4 border-orange-400"
                                  : "text-secondary-200  hover:text-secondary-100"
                              )
                            }
                            key={i}
                          >
                            {tab.name}
                          </Tab>
                        )
                    )}
                  </Tab.List>
                  <Divider className="text-grey-400" />
                  <Tab.Panels className="w-full">
                    <Tab.Panel className={`w-full`}>
                      {selectedIndex === 0 && bulkSessionId !== null && (
                        <BookNowTab
                          selectedTab={
                            selectedIndex !== -1
                              ? selectedIndex
                              : Number(actionQuery)
                          }
                          setShowConfirmLeave={setShowConfirmLeave}
                          blkSessionId={bulkSessionId}
                        />
                      )}
                    </Tab.Panel>
                    <Tab.Panel className={`w-full`}>
                      {selectedIndex === 1 && (
                        <SchedulePickUpTab shipperAcct={shipperAcct} />
                      )}
                    </Tab.Panel>
                    <Tab.Panel className={`w-full`}>
                      {selectedIndex === 2 && (
                        <RequestSuppliesTab shipperAcct={shipperAcct} />
                      )}
                    </Tab.Panel>
                  </Tab.Panels>
                </FlexColumn>
              </Tab.Group>
            </FlexRow>
          </Div>
        </FlexColumn>
      </FlexRow>
    </>
  );
};
