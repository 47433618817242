import React, { Fragment, useRef } from "react";
import { Cookies } from "react-cookie";
import { Dialog, Transition } from "@headlessui/react";
import { Div, FlexColumn, FlexRow, Spacer } from "../../core/Containers";
import { Span, Text } from "../../core/Text";
import { RawButton } from "../../core/Buttons";
import { useNavigate } from "react-router-dom";
import { LBCModal } from "./LBCModal";
import { Loading } from "../../pages/Window/Loading";
import moment from "moment/moment";
import Barcode from "react-barcode";
import QRCode from "qrcode.react";
import { ImInfo, ImSpinner2, ImCopy } from "react-icons/im";
import { FaTimes } from "react-icons/fa";
import convert from "xml-js";
import { Images } from "../../assets/images/images";
import axiosCAPApi from "../../api/axiosCAPApi";
import axiosCAPApiPrivate from "../../api/axiosCAPApiPrivate";

const cookies = new Cookies();

export const TrackingHistoryModal = ({ isOpen, onClose, data }) => {
  const navigate = useNavigate();
  const dataFetchedRef = useRef(false);

  const [loading, setLoading] = React.useState(false);
  const [trackingHistoryData, setTrackingHistoryData] = React.useState([]);
  const [isToastOpen, setToastOpen] = React.useState(false);
  const [toastKind, setToastKind] = React.useState("");
  const [toastTitle, setToastTitle] = React.useState("");
  const [toastDesc, setToastDesc] = React.useState("");

  React.useEffect(() => {
    if (dataFetchedRef.current) return;
    dataFetchedRef.current = true;

    if (isOpen) {
      console.log("dfsdfs");
      trackPackage(data.trackingNo);
    }
  }, [isOpen]);

  const trackPackage = async (trackingNo) => {
    setTrackingHistoryData([]);
    setLoading(true);

    try {
      const response = await axiosCAPApiPrivate.post(
        "/api/SendEmail",
        `
      <soap:Envelope xmlns:xsi="http://www.w3.org/2001/XMLSchema-instance" xmlns:xsd="http://www.w3.org/2001/XMLSchema" xmlns:soap="http://schemas.xmlsoap.org/soap/envelope/">
        <soap:Body>
          <LBCTrackAndTrace xmlns="http://tempuri.org/">
            <TrackingNo>${trackingNo}</TrackingNo>
          </LBCTrackAndTrace>
        </soap:Body>
      </soap:Envelope>
    `
      );

      const data = response.data;

      const result1 = data;
      const options = {
        compact: true,
        elementNameFn(result1) {
          return result1.replace("soap:", "");
        },
      };
      const result = convert.xml2json(result1, options);
      const soapData = JSON.parse(result);
      const listData =
        soapData.Envelope.Body.LBCTrackAndTraceResponse.LBCTrackAndTraceResult
          .TrackingStatus.TrackingHistory.TrackingHistory;

      setTrackingHistoryData(listData);

      setLoading(false);
    } catch (error) {
      setToastKind("error");
      setToastTitle("API Fetch Failed!");
      setToastDesc(error.message);
      setTimeout(() => {
        setLoading(false);
      }, 1000);
      setTimeout(() => {
        setToastOpen(true);
      }, 1500);
      setTimeout(() => {
        setToastOpen(false);
      }, 3000);
    }
  };

  {
    /* == Actions == */
  }

  {
    /* == End Actions == */
  }

  return (
    <>
      <Transition appear as={Fragment} show={isOpen}>
        <Dialog
          as="div"
          className="fixed inset-0 z-50 overflow-hide"
          onClose={onClose}
        >
          <div className="h-full w-full text-center flex justify-end">
            <Transition.Child
              as={Fragment}
              enter="transition ease duration-300 transform"
              enterFrom="opacity-0 -translate-y-full"
              enterTo="opacity-100 translate-y-0"
              leave="transition ease duration-300 transform"
              leaveFrom="opacity-100 translate-y-0"
              leaveTo="opacity-0 -translate-y-full"
            >
              <div className="h-full w-full overflow-hidden z-20 p-5 align-middle transition-all transform flex flex-col items-center justify-center bg-secondary-200">
                {/* ---------- Modal Body ---------- */}

                {loading && <Loading />}

                <LBCModal
                  id="alertModal"
                  description={toastDesc}
                  isOpen={isToastOpen}
                  onClose={() => setToastOpen(false)}
                  title={toastTitle}
                  toastKind={toastKind}
                />

                <FlexColumn className="justify-start bg-white border border-grey-400 h-95% w-70% px-10 py-5 rounded-lg shadow-lg">
                  <FlexRow className="w-full items-center relative">
                    <RawButton
                      className="absolute -top-2 -right-6"
                      onClick={onClose}
                    >
                      <FaTimes className="text-secondary-200 text-xl" />
                    </RawButton>
                    <Div className="pl-2  w-96 text-left">
                      <Text className="text-sm text-secondary-200">
                        TRACKING NO. / REFERENCE NO.
                      </Text>
                      <FlexRow className="items-center justify-start pt-2">
                        <Text className="text-xl text-secondary-200 font-bold">
                          {data?.trackingNo || ""}
                        </Text>
                        <ImCopy
                          className="inline ml-3 cursor-pointer"
                          title="Copy to clipboard"
                          onClick={() =>
                            navigator.clipboard.writeText(
                              data?.trackingNo || ""
                            )
                          }
                        />
                      </FlexRow>
                    </Div>
                    <Div className="flex flex-col w-full items-end justify-start">
                      <Div className="flex flex-row h-24 items-center justify-start w-300px">
                        <QRCode
                          value={data?.trackingNo || "0"}
                          style={{
                            width: "70px",
                            height: "70px",
                            marginRight: "10px",
                          }}
                        />
                        <Barcode
                          value={data?.trackingNo || "0"}
                          height={70}
                          width={2}
                          displayValue={false}
                        />
                      </Div>
                      <Text className="flex flex-row items-center jusitfy-center text-secondary-200 text-xs font-bold w-300px">
                        <ImInfo className="inline mr-2" />
                        <Span>Keep this QR and barcode as reference</Span>
                      </Text>
                    </Div>
                  </FlexRow>

                  <Spacer className="w-full h-4" />

                  {!loading && (
                    <Div className="w-full h-full overflow-auto px-3">
                      <FlexRow className="items-center justify-center my-4">
                        <FlexColumn className="items-start justify-center w-28 text-sm">
                          <Text className="w-full text-sm text-left">
                            {data &&
                              moment(data.lastDatePosted).format("MMM D")}
                          </Text>
                          <Text className="w-full text-sm text-left">
                            {data &&
                              moment(data.lastDatePosted, "HH:mm:ss A").format(
                                "hh:mma"
                              )}
                          </Text>
                        </FlexColumn>
                        <FlexColumn className="items-center justify-center w-36">
                          <img
                            className="h-12 w-12"
                            src={Images.TrackListIcon}
                            alt="abc"
                          />
                        </FlexColumn>
                        <FlexColumn className="items-start justify-center w-full text-sm ml-4">
                          <Text className="w-full text-sm text-left font-semibold">
                            Booking Confirmed
                          </Text>
                          <p className="w-full text-sm text-grey-200 text-left">
                            Your booking order has been placed and confirmed for
                            shipping
                          </p>
                        </FlexColumn>
                      </FlexRow>

                      {trackingHistoryData &&
                        trackingHistoryData.map((item, i) => (
                          <FlexRow
                            className="items-center justify-center my-4"
                            key={i}
                          >
                            <FlexColumn className="items-start justify-center w-28 text-sm">
                              <Text className="w-full text-sm text-left">
                                {moment(item.DatePosted._text).format("MMM D")}
                              </Text>
                              <Text className="w-full text-sm text-left">
                                {moment(
                                  item.DatePostedTime._text,
                                  "HH:mm:ss A"
                                ).format("hh:mma")}
                              </Text>
                            </FlexColumn>
                            <FlexColumn className="items-center justify-center w-36">
                              <img
                                className="h-12 w-12"
                                src={Images.TrackListIcon}
                                alt="abc"
                              />
                            </FlexColumn>
                            <FlexColumn className="items-start justify-center w-full ml-4">
                              <Text className="w-full text-sm text-left font-semibold">
                                {item.StatusandLocation._text}
                              </Text>
                            </FlexColumn>
                          </FlexRow>
                        ))}
                    </Div>
                  )}
                </FlexColumn>
              </div>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition>
    </>
  );
};
