export const tempBorderColors = [
  'border-l-yellow-100',
  'border-l-blue-100',
  'border-l-red-100',
];

export const tempUsersData = [
  {
    email: 'charles@xtendly.com',
    firstname: 'Charles',
    lastname: 'Chua',
    company: 'Xtendly',
  },
  {
    email: 'bea@xtendly.com',
    firstname: 'Bea',
    lastname: 'Ercia',
    company: 'Xtendly',
  },
  {
    email: 'jan@xtendly.com',
    firstname: 'Jary',
    lastname: 'Ercia',
    company: 'Xtendly',
  },
  {
    email: 'rafael@xtendly.com',
    firstname: 'Rafael',
    lastname: 'Ripoll',
    company: 'Xtendly',
  },
  {
    email: 'testuser@gmail.com',
    firstname: 'Test User',
    lastname: '1',
    company: 'Company Test',
  },
];

export const tempPerformanceDataRow = [
  {
    deliveryId: '#NCR8372846246',
    deliverySize: 'NP Small Dress',
    logisticsType: 'Pickup',
    deliveryDate: '01/01/2023',
    transactionDate: '01/01/2023',
    clientNameFrom: 'John Doe',
    clientAddressFrom: '123 Corner Street',
    clientNameTo: 'Mary Jane',
    clientAddressTo: '321 Edge Street',
  },
  {
    deliveryId: '#NCR83728462464',
    deliverySize: 'NP Small Dress',
    logisticsType: 'Pickup',
    deliveryDate: '01/01/2023',
    transactionDate: '01/01/2023',
    clientNameFrom: 'John Doe',
    clientAddressFrom: '123 Corner Street',
    clientNameTo: 'Mary Jane',
    clientAddressTo: '321 Edge Street',
  },
  {
    deliveryId: '#NCR837284624642',
    deliverySize: 'NP Small Dress',
    logisticsType: 'Pickup',
    deliveryDate: '01/01/2023',
    transactionDate: '01/01/2023',
    clientNameFrom: 'John Doe',
    clientAddressFrom: '123 Corner Street',
    clientNameTo: 'Mary Jane',
    clientAddressTo: '321 Edge Street',
  },
  {
    deliveryId: '#NCR8372846246426',
    deliverySize: 'NP Small Dress',
    logisticsType: 'Pickup',
    deliveryDate: '01/01/2023',
    transactionDate: '01/01/2023',
    clientNameFrom: 'John Doe',
    clientAddressFrom: '123 Corner Street',
    clientNameTo: 'Mary Jane',
    clientAddressTo: '321 Edge Street',
  },
];

export const tempRemittanceDataRow = [
  {
    referenceId: 'BDO Ref 1001',
    depositedDate: '09 Mar 2023',
    amount: 9000,
  },
  {
    referenceId: 'BDO Ref 1001',
    depositedDate: '09 Mar 2023',
    amount: 9000,
  },
  {
    referenceId: 'BDO Ref 1001',
    depositedDate: '09 Mar 2023',
    amount: 9000,
  },
];

export const tempBarGraphData = [
  {
    country: 'Jan 2023',
    'Picked up/Dropped Off': 135,
    'For Disposition': 124,
    'In-transit': 55,
    Delivered: 27,
    Returned: 24,
  },
  {
    country: 'Feb 2023',
    'Picked up/Dropped Off': 120,
    'For Disposition': 100,
    'In-transit': 50,
    Delivered: 25,
    Returned: 20,
  },
  {
    country: 'Mar 2023',
    'Picked up/Dropped Off': 135,
    'For Disposition': 124,
    'In-transit': 55,
    Delivered: 27,
    Returned: 24,
  },
];

export const tempBarGraphData2 = [
  {
    country: 'Dec 28',
    'Picked up/Dropped Off': 135,
    'For Disposition': 124,
    'In-transit': 55,
    Delivered: 27,
    Returned: 24,
  },
  {
    country: 'Dec 29',
    'Picked up/Dropped Off': 120,
    'For Disposition': 100,
    'In-transit': 50,
    Delivered: 25,
    Returned: 20,
  },
  {
    country: 'Dec 30',
    'Picked up/Dropped Off': 135,
    'For Disposition': 124,
    'In-transit': 55,
    Delivered: 27,
    Returned: 24,
  },
  {
    country: 'Dec 31',
    'Picked up/Dropped Off': 120,
    'For Disposition': 100,
    'In-transit': 50,
    Delivered: 25,
    Returned: 20,
  },
  {
    country: 'Jan 1',
    'Picked up/Dropped Off': 215,
    'For Disposition': 45,
    'In-transit': 11,
    Delivered: 12,
    Returned: 20,
  },
  {
    country: 'Jan 2',
    'Picked up/Dropped Off': 178,
    'For Disposition': 78,
    'In-transit': 32,
    Delivered: 9,
    Returned: 18,
  },
  {
    country: 'Jan 3',
    'Picked up/Dropped Off': 121,
    'For Disposition': 101,
    'In-transit': 87,
    Delivered: 67,
    Returned: 15,
  },
  {
    country: 'Jan 4',
    'Picked up/Dropped Off': 75,
    'For Disposition': 12,
    'In-transit': 95,
    Delivered: 156,
    Returned: 9,
  },
  {
    country: 'Jan 5',
    'Picked up/Dropped Off': 97,
    'For Disposition': 25,
    'In-transit': 65,
    Delivered: 178,
    Returned: 21,
  },
  {
    country: 'Jan 6',
    'Picked up/Dropped Off': 135,
    'For Disposition': 12,
    'In-transit': 78,
    Delivered: 156,
    Returned: 13,
  },
  {
    country: 'Jan 7',
    'Picked up/Dropped Off': 141,
    'For Disposition': 31,
    'In-transit': 23,
    Delivered: 167,
    Returned: 34,
  },
];

export const tempDispostionData = [
  {
    deliveryId: 'Refused to Accept',
    deliverySize: '32%',
    logisticsType: '32%',
    deliveryDate: '01/01/2023',
    transactionDate: '01/01/2023',
    clientNameFrom: 'John Doe',
    clientAddressFrom: '123 Corner Street',
    clientNameTo: 'Mary Jane',
    clientAddressTo: '321 Edge Street',
  },
  {
    deliveryId: 'Incomplete/Incorrect address',
    deliverySize: '32%',
    logisticsType: '32%',
    deliveryDate: '01/01/2023',
    transactionDate: '01/01/2023',
    clientNameFrom: 'John Doe',
    clientAddressFrom: '123 Corner Street',
    clientNameTo: 'Mary Jane',
    clientAddressTo: '321 Edge Street',
  },
  {
    deliveryId: 'Consignee Unknown',
    deliverySize: '32%',
    logisticsType: '32%',
    deliveryDate: '01/01/2023',
    transactionDate: '01/01/2023',
    clientNameFrom: 'John Doe',
    clientAddressFrom: '123 Corner Street',
    clientNameTo: 'Mary Jane',
    clientAddressTo: '321 Edge Street',
  },
  {
    deliveryId: 'Others',
    deliverySize: '32%',
    logisticsType: '32%',
    deliveryDate: '01/01/2023',
    transactionDate: '01/01/2023',
    clientNameFrom: 'John Doe',
    clientAddressFrom: '123 Corner Street',
    clientNameTo: 'Mary Jane',
    clientAddressTo: '321 Edge Street',
  },
];

export const tempNotificationData = [
  {
    id: 1,
    date: '05/10/2023 00:00 AM',
    subject: 'Notice of Releases',
    excerpt: 'This is an announcement to provide information!',
    status: 'Read',
  },
  {
    id: 2,
    date: '05/15/2023 00:00 AM',
    subject: 'Notice of Releases',
    excerpt:
      'This is an announcement to provide information! This is an announcement to provide information Dashboard!',
    status: 'Unread',
  },
  {
    id: 3,
    date: '05/17/2023 00:00 AM',
    subject: 'Notice of Releases',
    excerpt:
      'This is an announcement to provide information! This is an announcement to provide information Components!',
    status: 'Unread',
  },
  {
    id: 4,
    date: '05/20/2023 00:00 AM',
    subject: 'Notice of Releases',
    excerpt:
      'This is an announcement to provide information! This is an announcement to provide information Book Now!',
    status: 'Unread',
  },
  {
    id: 5,
    date: '05/22/2023 00:00 AM',
    subject: 'Notice of Releases',
    excerpt:
      'This is an announcement to provide information! This is an announcement to provide information Activity!',
    status: 'Unread',
  },
  {
    id: 6,
    date: '05/25/2023 00:00 AM',
    subject: 'Notice of Releases',
    excerpt:
      'This is an announcement to provide information! This is an announcement to provide information Enhancements!',
    status: 'Unread',
  },
  {
    id: 7,
    date: '05/30/2023 00:00 AM',
    subject: 'Notice of Releases',
    excerpt:
      'This is an announcement to provide information! This is an announcement to provide information Table Sort!',
    status: 'Unread',
  },
];

export const tempPickupScheduleData = [
  {
    id: 1,
    pickUpSchedule: 'Recurs ever Monday and Wednesdays',
    address:
      ': Unit 1D Three World Square, McKinley Hill Dr, Taguig, Metro Manila',
    contactPerson: 'John Doe',
    estVolumePerPickup: '50',
    specialInstruction: 'None',
  },
  {
    id: 2,
    pickUpSchedule: 'Recurs ever Monday and Wednesdays',
    address:
      ': Unit 1D Three World Square, McKinley Hill Dr, Taguig, Metro Manila',
    contactPerson: 'John Doe',
    estVolumePerPickup: '50',
    specialInstruction: 'None',
  },
  {
    id: 3,
    pickUpSchedule: 'Recurs ever Monday and Wednesdays',
    address:
      ': Unit 1D Three World Square, McKinley Hill Dr, Taguig, Metro Manila',
    contactPerson: 'John Doe',
    estVolumePerPickup: '50',
    specialInstruction: 'None',
  },
  {
    id: 1,
    pickUpSchedule: 'Recurs ever Monday and Wednesdays',
    address:
      ': Unit 1D Three World Square, McKinley Hill Dr, Taguig, Metro Manila',
    contactPerson: 'John Doe',
    estVolumePerPickup: '50',
    specialInstruction: 'None',
  },
  {
    id: 2,
    pickUpSchedule: 'Recurs ever Monday and Wednesdays',
    address:
      ': Unit 1D Three World Square, McKinley Hill Dr, Taguig, Metro Manila',
    contactPerson: 'John Doe',
    estVolumePerPickup: '5',
    specialInstruction: 'None',
  },
  {
    id: 3,
    pickUpSchedule: 'Recurs ever Monday and Wednesdays',
    address:
      ': Unit 1D Three World Square, McKinley Hill Dr, Taguig, Metro Manila',
    contactPerson: 'John Doe',
    estVolumePerPickup: '50',
    specialInstruction: 'None',
  },
];

export const scheduledPickups = [
  'Tue Apr 04 2023 00:00:00 GMT+0800 (Singapore Standard Time)',
  'Thu Apr 20 2023 00:00:00 GMT+0800 (Singapore Standard Time)',
  'Thu Apr 27 2023 00:00:00 GMT+0800 (Singapore Standard Time)',
  'Thu May 04 2023 00:00:00 GMT+0800 (Singapore Standard Time)',
  'Tue May 09 2023 00:00:00 GMT+0800 (Singapore Standard Time)',
  'Wed May 10 2023 00:00:00 GMT+0800 (Singapore Standard Time)',
  'Thu May 18 2023 00:00:00 GMT+0800 (Singapore Standard Time)',
  'Sat May 20 2023 22:58:25 GMT+0800 (Singapore Standard Time)',
  'Thu Jun 01 2023 00:00:00 GMT+0800 (Singapore Standard Time)',
  'Fri Jun 02 2023 00:00:00 GMT+0800 (Singapore Standard Time)',
  'Tue Jun 13 2023 00:00:00 GMT+0800 (Singapore Standard Time)',
];

export const BookNowSummaryDetails = [
  {
    id: '1',
    package: 'NP Dress',
    sender: {
      name: 'John Doe',
      contact: '+63 91234567890',
      address: 'Metro Manila',
      type: 'via Pickup',
      pickUpDate: '01/01/2023',
    },
    receiver: {
      name: 'John Doe',
      contact: '+63 91234567890',
      address: 'Metro Manila',
      type: 'via Pickup',
      pickUpDate: '01/01/2023',
    },
    amount: {
      value: 1234.56,
      data: {},
    },
    disbursal: {
      type: 'Bank Deposit',
    },
  },
  {
    id: '2',
    package: 'NP Dress',
    sender: {
      name: 'John Doe',
      contact: '+63 91234567890',
      address: 'Metro Manila',
      type: 'via Pickup',
      pickUpDate: '01/01/2023',
    },
    receiver: {
      name: 'John Doe',
      contact: '+63 91234567890',
      address: 'Metro Manila',
      type: 'via Pickup',
      pickUpDate: '01/01/2023',
    },
    amount: {
      value: 1234.56,
      data: {},
    },
    disbursal: {
      type: 'Bank Deposit',
    },
  },
];

export const tempLBCPackage = [
  {
    id: 166,
    name: 'SAKTO',
    type: 'Pouch',
    dimension: '"22.86 x 19.05 cm"',
    quantity: 1
  },
  {
    id: 45,
    name: 'N-Pack Small',
    type: 'Pouch',
    dimension: '"35.56 x 24.13 cm"',
    quantity: 1
  },
  {
    id: 8,
    name: 'N-Pack Large',
    type: 'Pouch',
    dimension: '"45.72 x 30.48 cm"',
    quantity: 1  
  },
  {
    id: 165,
    name: 'KB Mini',
    type: 'Box',
    dimension: '"30.48 x 25.4 x 12.7 cm"',
    quantity: 1
  },
  {
    id: 63,
    name: 'KB Medium',
    type: 'Box',
    dimension: '"26.67 x 35.56 x 17.78 cm"',
    quantity: 1
  },
  {
    id: 64,
    name: 'KB Large',
    type: 'Box',
    dimension: '"45.72 x 30.48 x 22.86 cm"',
    quantity: 1
  },
];

export const tempReceiverData = {
  name: 'John Doe',
  contact: '+63 91234567890',
  address: 'Metro Manila',
};

export const tempActivityData = [
  {
    tracking: '1234567890',
    createdBy: 'John Doe',
    bookType: 'Book by Batch',
    bookDate: 'Jan 01, 2023',
    status: 'Booking Confirmed',
  },
  {
    tracking: '1234567899',
    createdBy: 'Black Jack',
    bookType: 'Pick Up Schedules',
    bookDate: 'Jan 01, 2023',
    status: 'Pick Up - 01/01/2023',
  },
  {
    tracking: '1234567898',
    createdBy: 'Mary Jane',
    bookType: 'Book by Batch',
    bookDate: 'Jan 01, 2023',
    status: 'Ongoing Recurring Pick Up',
  },
  {
    tracking: '2234567890',
    createdBy: 'John Doe',
    bookType: 'Book by Batch',
    bookDate: 'Jan 01, 2023',
    status: 'Supplies Request Submitted',
  },
  {
    tracking: '3234567899',
    createdBy: 'Black Jack',
    bookType: 'Request supplies',
    bookDate: 'Jan 01, 2023',
    status: 'Pick Up - 01/01/2023',
  },
  {
    tracking: '4234567898',
    createdBy: 'Mary Jane',
    bookType: 'Book by Batch',
    bookDate: 'Jan 01, 2023',
    status: 'Pick Up - 01/01/2023',
  },
  {
    tracking: '12345678901',
    createdBy: 'John Doe',
    bookType: 'Book by Batch',
    bookDate: 'Jan 01, 2023',
    status: 'Booking Confirmed',
  },
  {
    tracking: '12345678992',
    createdBy: 'Black Jack',
    bookType: 'Pick Up Schedules',
    bookDate: 'Jan 01, 2023',
    status: 'Pick Up - 01/01/2023',
  },
  {
    tracking: '12345678983',
    createdBy: 'Mary Jane',
    bookType: 'Book by Batch',
    bookDate: 'Jan 01, 2023',
    status: 'Ongoing Recurring Pick Up',
  },
  {
    tracking: '22345678904',
    createdBy: 'John Doe',
    bookType: 'Book by Batch',
    bookDate: 'Jan 01, 2023',
    status: 'Supplies Request Submitted',
  },
  {
    tracking: '32345678995',
    createdBy: 'Black Jack',
    bookType: 'Request supplies',
    bookDate: 'Jan 01, 2023',
    status: 'Pick Up - 01/01/2023',
  },
  {
    tracking: '42345678986',
    createdBy: 'Mary Jane',
    bookType: 'Book by Batch',
    bookDate: 'Jan 01, 2023',
    status: 'Pick Up - 01/01/2023',
  },
];

export const tempProofDeliveryData = [
  {
    tracking: '1234567890',
    sender: 'John Doe',
    receiver: 'Mark Matt',
    bookType: 'Book by Batch',
    bookDate: 'Jan 01, 2023',
    status: 'Booking Confirmed',
  },
  {
    tracking: '1234567899',
    sender: 'Black Jack',
    receiver: 'Mark Matt',
    bookType: 'Pick Up Schedules',
    bookDate: 'Jan 03, 2023',
    status: 'Pick Up - 01/01/2023',
  },
  {
    tracking: '1234567898',
    sender: 'Mary Jane',
    receiver: 'Mark Matt',
    bookType: 'Book by Batch',
    bookDate: 'Jan 02, 2023',
    status: 'Ongoing Recurring Pick Up',
  },
  {
    tracking: '2234567890',
    sender: 'John Doe',
    receiver: 'Mark Matt',
    bookType: 'Book by Batch',
    bookDate: 'Jan 05, 2023',
    status: 'Supplies Request Submitted',
  },
  {
    tracking: '3234567899',
    sender: 'Black Jack',
    receiver: 'Mark Matt',
    bookType: 'Request supplies',
    bookDate: 'Jan 04, 2023',
    status: 'Pick Up - 01/01/2023',
  },
  {
    tracking: '4234567898',
    sender: 'Mary Jane',
    receiver: 'Mark Matt',
    bookType: 'Book by Batch',
    bookDate: 'Jan 07, 2023',
    status: 'Pick Up - 01/01/2023',
  },
  {
    tracking: '12345678901',
    sender: 'John Doe',
    receiver: 'Mark Matt',
    bookType: 'Book by Batch',
    bookDate: 'Jan 06, 2023',
    status: 'Booking Confirmed',
  },
  {
    tracking: '12345678992',
    sender: 'Black Jack',
    receiver: 'Mark Matt',
    bookType: 'Pick Up Schedules',
    bookDate: 'Jan 09, 2023',
    status: 'Pick Up - 01/01/2023',
  },
  {
    tracking: '12345678983',
    sender: 'Mary Jane',
    receiver: 'Mark Matt',
    bookType: 'Book by Batch',
    bookDate: 'Jan 08, 2023',
    status: 'Ongoing Recurring Pick Up',
  },
  {
    tracking: '22345678904',
    sender: 'John Doe',
    receiver: 'Mark Matt',
    bookType: 'Book by Batch',
    bookDate: 'Jan 11, 2023',
    status: 'Supplies Request Submitted',
  },
  {
    tracking: '32345678995',
    sender: 'Black Jack',
    receiver: 'Mark Matt',
    bookType: 'Request supplies',
    bookDate: 'Jan 10, 2023',
    status: 'Pick Up - 01/01/2023',
  },
  {
    tracking: '42345678986',
    sender: 'Mary Jane',
    receiver: 'Mark Matt',
    bookType: 'Book by Batch',
    bookDate: 'Jan 12, 2023',
    status: 'Pick Up - 01/01/2023',
  },
];

export const tempGroupBarGraphData = [
  {
    'Remaining Qty': 176,
    'Consumed today': 23,
    date: 'Oct-1',
  },
  {
    'Remaining Qty': 158,
    'Consumed today': 31,
    date: 'Oct-2',
  },
  {
    'Remaining Qty': 143,
    'Consumed today': 19,
    date: 'Oct-3',
  },
  {
    'Remaining Qty': 134,
    'Consumed today': 23,
    date: 'Oct-4',
  },
  {
    'Remaining Qty': 156,
    'Consumed today': 31,
    date: 'Oct-5',
  },
  {
    'Remaining Qty': 151,
    'Consumed today': 15,
    date: 'Oct-6',
  },
  {
    'Remaining Qty': 176,
    'Consumed today': 23,
    date: 'Oct-7',
  },
];

export const tempRemittanceHistoryData = [
  {
    reference: 'A1234567890',
    postingDate: 'Jan 01, 2023',
    disbursalMode: 'Bank Deposit',
    accountName: 'John Doe',
    status: 'Received payment from LBC Express',
    amount: 1234,
  },
  {
    reference: 'B1234567890',
    postingDate: 'Jan 02, 2023',
    disbursalMode: 'Bank Deposit',
    accountName: 'John Doe',
    status: 'Payment is ready for collection at Branch',
    amount: 1234,
  },
  {
    reference: 'C1234567890',
    postingDate: 'Jan 03, 2023',
    disbursalMode: 'GCash',
    accountName: 'John Doe',
    status: 'Received payment from LBC Express',
    amount: 1234,
  },
  {
    reference: 'D1234567890',
    postingDate: 'Jan 04, 2023',
    disbursalMode: 'GCash',
    accountName: 'John Doe',
    status: 'Payment is ready for collection at Branch',
    amount: 1234,
  },
  {
    reference: 'E1234567890',
    postingDate: 'Jan 05, 2023',
    disbursalMode: 'GCash',
    accountName: 'John Doe',
    status: 'Payment is ready for collection at Branch',
    amount: 1234,
  },
  {
    reference: 'F1234567890',
    postingDate: 'Jan 06, 2023',
    disbursalMode: 'GCash',
    accountName: 'John Doe',
    status: 'Received payment from LBC Express',
    amount: 1234,
  },
  {
    reference: 'G1234567890',
    postingDate: 'Jan 07, 2023',
    disbursalMode: 'Branch Collect',
    accountName: 'John Doe',
    status: 'Payment is ready for collection at Branch',
    amount: 1234,
  },
  {
    reference: 'H1234567890',
    postingDate: 'Jan 08, 2023',
    disbursalMode: 'GCash',
    accountName: 'John Doe',
    status: 'Received payment from LBC Express',
    amount: 1234,
  },
  {
    reference: 'I1234567890',
    postingDate: 'Jan 09, 2023',
    disbursalMode: 'Branch Collect',
    accountName: 'John Doe',
    status: 'Received payment from LBC Express',
    amount: 1234,
  },
  {
    reference: 'J1234567890',
    postingDate: 'Jan 10, 2023',
    disbursalMode: 'GCash',
    accountName: 'John Doe',
    status: 'Received payment from LBC Express',
    amount: 1234,
  },
  {
    reference: 'K1234567890',
    postingDate: 'Jan 11, 2023',
    disbursalMode: 'Bank Deposit',
    accountName: 'John Doe',
    status: 'Received payment from LBC Express',
    amount: 1234,
  },
  {
    reference: 'L1234567890',
    postingDate: 'Jan 12, 2023',
    disbursalMode: 'Bank Deposit',
    accountName: 'John Doe',
    status: 'Received payment from LBC Express',
    amount: 1234,
  },
];

export const tempBillingHistoryData = [];

export const dailyPerformancePercent = [
  {
    percent: '93.2',
    title: 'Daily Delivery Performance',
  },
  {
    percent: '95.1',
    title: 'Daily Delivery Performance',
  },
  {
    percent: '90.9',
    title: 'Daily Delivery % with SLA',
  },
  {
    percent: '92.7',
    title: 'Daily % Return w/in SLA',
  },
];

export const monthlyPerformancePercent = [
  {
    percent: '0',
    title: 'YTD Delivery Performance',
  },
  {
    percent: '0',
    title: 'MTD Delivery Performance',
  },
  {
    percent: '0',
    title: 'MTD Delivery % w/in SLA',
  },
  {
    percent: '0',
    title: 'MTD % Returned w/in SLA',
  },
];

export const weeklyPerformancePercent = [
  {
    percent: '94.1',
    title: 'WTD Delivery Performance',
  },
  {
    percent: '90.3',
    title: 'WTD Delivery Performance',
  },
  {
    percent: '95.3',
    title: 'WTD Delivery % with SLA',
  },
  {
    percent: '94.6',
    title: 'WTD % Return w/in SLA',
  },
];

export const tempDeliveryTableData = [
  {
    tracking: '#NCR8372846246',
    encodedDate: '03/02/2023',
    deliveryReq: 'NDO',
    status: 'Refused to accept',
    statusDate: '03/03/2023',
    acceptanceDate: '03/04/2023',
    consigneeName: 'John Doe',
    consigneeContact: '0987654321',
    consigneeAddress: '321 Edge Street, Region X, Philippines',
    reason: 'Incorrect Address',
  },
  {
    tracking: '#NCR83728462464',
    encodedDate: '02/01/2023',
    deliveryReq: 'NDO',
    status: 'Incorrect Address',
    statusDate: '02/02/2023',
    acceptanceDate: '02/03/2023',
    consigneeName: 'Black Jack',
    consigneeContact: '0987654322',
    consigneeAddress: '123 Chrome Street, Region X, Philippines',
    reason: 'Refused to accept',
  },
  {
    tracking: '#NCR837284624642',
    encodedDate: '01/04/2023',
    deliveryReq: 'NDO',
    status: 'House closed',
    statusDate: '01/05/2023',
    acceptanceDate: '01/06/2023',
    consigneeName: 'Anne May',
    consigneeContact: '0987654323',
    consigneeAddress: 'Unit 1 Mozilla Street, Region X, Philippines',
    reason: 'Consignee unknown',
  },
  {
    tracking: '#NCR8372846246426',
    encodedDate: '04/05/2023',
    deliveryReq: 'NDO',
    status: 'Consignee unknown',
    statusDate: '04/06/2023',
    acceptanceDate: '04/07/2023',
    consigneeName: 'Mary Jane',
    consigneeContact: '0987654324',
    consigneeAddress: 'Block A Safari Street, Region X, Philippines',
    reason: 'House closed',
  },
  {
    tracking: '#NCR83728462469',
    encodedDate: '03/12/2023',
    deliveryReq: 'NDO',
    status: 'Refused to accept',
    statusDate: '03/13/2023',
    acceptanceDate: '03/14/2023',
    consigneeName: 'John Doe',
    consigneeContact: '0987654321',
    consigneeAddress: '321 Edge Street, Region X, Philippines',
    reason: 'Incorrect Address',
  },
  {
    tracking: '#NCR837284624648',
    encodedDate: '02/11/2023',
    deliveryReq: 'NDO',
    status: 'Incorrect Address',
    statusDate: '02/12/2023',
    acceptanceDate: '02/13/2023',
    consigneeName: 'Black Jack',
    consigneeContact: '0987654322',
    consigneeAddress: '123 Chrome Street, Region X, Philippines',
    reason: 'Refused to accept',
  },
  {
    tracking: '#NCR8372846246427',
    encodedDate: '01/14/2023',
    deliveryReq: 'NDO',
    status: 'House closed',
    statusDate: '01/15/2023',
    acceptanceDate: '01/16/2023',
    consigneeName: 'Anne May',
    consigneeContact: '0987654323',
    consigneeAddress: 'Unit 1 Mozilla Street, Region X, Philippines',
    reason: 'Consignee unknown',
  },
  {
    tracking: '#NCR83728462464266',
    encodedDate: '04/15/2023',
    deliveryReq: 'NDO',
    status: 'Consignee unknown',
    statusDate: '04/16/2023',
    acceptanceDate: '04/17/2023',
    consigneeName: 'Mary Jane',
    consigneeContact: '0987654324',
    consigneeAddress: 'Block A Safari Street, Region X, Philippines',
    reason: 'House closed',
  },
];

export const tempRemittanceTableData = [
  {
    tracking: '#NCR8372846246',
    paymentDetails: 'BDO - NCR8372846246',
    depositedDate: '03/02/2023',
    deliveryDate: '03/02/2023',
    paymentTracking: '0987654321',
    amount: 580.0,
    serviceMode: 'Cash on Delivery',
  },
  {
    tracking: '#NCR83728462464',
    paymentDetails: 'BDO - NCR8372846246',
    depositedDate: '02/01/2023',
    deliveryDate: '03/02/2023',
    paymentTracking: '0987654322',
    amount: 6580.0,
    serviceMode: 'Cash on Delivery',
  },
  {
    tracking: '#NCR837284624642',
    paymentDetails: 'BDO - NCR8372846246',
    depositedDate: '01/04/2023',
    deliveryDate: '03/02/2023',
    paymentTracking: '0987654323',
    amount: 7580.0,
    serviceMode: 'Cash on Delivery',
  },
  {
    tracking: '#NCR8372846246426',
    paymentDetails: 'BDO - NCR8372846246',
    depositedDate: '04/05/2023',
    deliveryDate: '03/02/2023',
    paymentTracking: '0987654324',
    amount: 4580.0,
    serviceMode: 'Cash on Delivery',
  },
  {
    tracking: '#NCR83728462469',
    paymentDetails: 'BDO - NCR8372846246',
    depositedDate: '03/12/2023',
    deliveryDate: '03/02/2023',
    paymentTracking: '0987654321',
    amount: 5580.0,
    serviceMode: 'Cash on Delivery',
  },
  {
    tracking: '#NCR837284624648',
    paymentDetails: 'BDO - NCR8372846246',
    depositedDate: '02/11/2023',
    deliveryDate: '03/02/2023',
    paymentTracking: '0987654322',
    amount: 2580.0,
    serviceMode: 'Cash on Delivery',
  },
  {
    tracking: '#NCR8372846246427',
    paymentDetails: 'BDO - NCR8372846246',
    depositedDate: '01/14/2023',
    deliveryDate: '03/02/2023',
    paymentTracking: '0987654323',
    amount: 3580.0,
    serviceMode: 'Cash on Delivery',
  },
  {
    tracking: '#NCR83728462464266',
    paymentDetails: 'BDO - NCR8372846246',
    depositedDate: '04/15/2023',
    deliveryDate: '03/02/2023',
    paymentTracking: '0987654324',
    amount: 1580.0,
    serviceMode: 'Cash on Delivery',
  },
];

export const tempDeliveryReportData = [
  {
    reference: 'April 2023',
    postingDate: '20,300',
    disbursalMode: '123',
    accountName: '6',
    status: '13',
    amount: 1234,
  },
  {
    reference: 'May 2023',
    postingDate: '20,300',
    disbursalMode: '123',
    accountName: '6',
    status: '13',
    amount: 1234,
  },
  {
    reference: 'June 2023',
    postingDate: '20,300',
    disbursalMode: '123',
    accountName: '6',
    status: '13',
    amount: 1234,
  }
];