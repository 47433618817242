import React, { useRef } from "react";
import { Cookies } from "react-cookie";
import { Div, FlexRow, Spacer } from "../../../core/Containers";
import { useLocation, useNavigate, Link } from "react-router-dom";
import { LBCModal } from "../../../components/Modals/LBCModal";
import { Text } from "../../../core/Text";
import { ImSpinner2 } from "react-icons/im";
import { Pagination } from "../../../components/Tables/Pagination";
import { RemittanceHistoryReportDataRow } from "../../../components/Tables/ReportsDataRow";
import { RemittanceHistoryReportColumns } from "../../../constants/ConstantsData";
import axiosCAPApi from "../../../api/axiosCAPApi";
import axiosCAPApiPrivate from "../../../api/axiosCAPApiPrivate";

const cookies = new Cookies();

export const RemittanceHistoryReport = ({
  reportFilter,
  reportDateFrom,
  reportDateTo,
}) => {
  const navigate = useNavigate();
  const dataFetchedRef = useRef(false);
  const { search } = useLocation();
  const query = new URLSearchParams(search);
  const actionQuery = query.get("option");

  const [selectedIndex, setSelectedIndex] = React.useState(0);
  const tabs = ["Remittance History Report"];

  const [sortBy, setSort] = React.useState("SHIPMENTS BY LAST STATUS");
  const [timeline, setTimeline] = React.useState("Daily");

  const [loading, setLoading] = React.useState(false);

  const [currentPage, setCurrentPage] = React.useState(1);
  const [articlesPerPage] = React.useState(5);
  const indexOfLastArticle = currentPage * articlesPerPage;
  const indexOfFirstArticle = indexOfLastArticle - articlesPerPage;
  const [remittanceHistoryReportList, setRemittanceHistoryReportList] =
    React.useState([]);
  const newList = remittanceHistoryReportList.slice(
    indexOfFirstArticle,
    indexOfLastArticle
  );

  const [isToastOpen, setToastOpen] = React.useState(false);
  const [toastKind, setToastKind] = React.useState("");
  const [toastTitle, setToastTitle] = React.useState("");
  const [toastDesc, setToastDesc] = React.useState("");

  React.useEffect(() => {
    if (dataFetchedRef.current) return;
    dataFetchedRef.current = true;

    var _shipperAcct = cookies.get("shipperAcct", { path: "/" });
    var _shipperAcctSplit = _shipperAcct ? _shipperAcct.split("|") : [];
    var _shipperAcctName = _shipperAcctSplit[0]
      ? _shipperAcctSplit[0].toString().trim()
      : "";
    var _shipperAcctNo = _shipperAcctSplit[1]
      ? _shipperAcctSplit[1].toString().trim()
      : "";

    GetRemittanceHistoryReport(_shipperAcctNo);
  }, []);

  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  const GetRemittanceHistoryReport = async (shipperAcctNo) => {
    setLoading(true);

    try {
      const response = await axiosCAPApiPrivate.post(
        "/api/GetRemittanceHistoryReport",
        {
          CustomerAccountNo: shipperAcctNo,
        }
      );

      const data = response.data;

      if (data.Code === "200") {
        setLoading(false);
        setRemittanceHistoryReportList(data.Data);
      }
      setLoading(false);
    } catch (error) {
      setToastKind("error");
      setToastTitle("Delivery Status Failed!");
      setToastDesc(error.message);
      setTimeout(() => {
        setLoading(false);
      }, 1000);
      setTimeout(() => {
        setToastOpen(true);
      }, 1500);
      setTimeout(() => {
        setToastOpen(false);
      }, 3000);
    }
  };

  React.useEffect(() => {
    if (actionQuery === "0") {
      setSelectedIndex(0);
      navigate("/reports");
    } else if (actionQuery === "1") {
      setSelectedIndex(1);
      navigate("/reports");
    } else if (actionQuery === "2") {
      setSelectedIndex(2);
      navigate("/reports");
    }
  }, [actionQuery, navigate]);

  React.useEffect(() => {}, [timeline]);

  return (
    <>
      <LBCModal
        description={toastDesc}
        isOpen={isToastOpen}
        onClose={() => setToastOpen(false)}
        title={toastTitle}
        toastKind={toastKind}
      />

      <Div className="w-full">
        <Spacer className="w-10 h-10" />
        <Div className="w-full border border-grey-400 rounded-lg shadow-lg">
          <FlexRow className="w-full items-center justify-between py-2 border border-grey-400">
            {RemittanceHistoryReportColumns.map((column) => (
              <Text
                className={`text-secondary-100 text-sm text-center font-semibold px-4 py-2 ${"w-1/4"}`}
              >
                {column}
              </Text>
            ))}
          </FlexRow>

          {loading ? (
            <Text className="text-red-400 text-center flex flex-row justify-center items-center my-10">
              <ImSpinner2 className="animate-spin mr-2 text-2xl desktop:text-3xl" />
            </Text>
          ) : (
            <Div className="w-full">
              {newList.map((item, key) => (
                <RemittanceHistoryReportDataRow data={item} key={key} />
              ))}
            </Div>
          )}
        </Div>
        <Spacer className="w-full h-10" />
        <Div className="w-full">
          <Pagination
            currentPage={currentPage}
            itemsPerPage={articlesPerPage}
            paginate={paginate}
            totalItems={remittanceHistoryReportList.length}
          />
        </Div>
      </Div>
    </>
  );
};
