import React, { Fragment } from "react";
import { Cookies } from "react-cookie";
import { Dialog, Transition } from "@headlessui/react";
import { Div, FlexRow, Spacer } from "../../core/Containers";
import { Text } from "../../core/Text";
import { FaTimes } from "react-icons/fa";
import { Button, RawButton, ScheduleDropdown } from "../../core/Buttons";
import { RawInput, RawSelect } from "../../core/Forms";
import {
  RecurrenceOptions,
  RecurrenceCreateOptions,
} from "../../constants/Dropdowns";
import { LBCModal } from "./LBCModal";
import { ToastData } from "../../constants/ConstantsData";
import axiosCAPApiPrivate from "../../api/axiosCAPApiPrivate"; 
import axiosLexaApiPrivate from "../../api/axiosLexaApiPrivate";
import axiosCommonRefApi from "../../api/axiosCommonRefApi";

const filterConfig = {
  ignoreCase: true,
  ignoreAccents: true,
  trim: true,
  matchFrom: "any",
  stringify: (option) => `${option.label}`,
};

const cookies = new Cookies();

export const PickUpScheduleChange = ({ onClose, isOpen, data }) => {
  const [loading, setLoading] = React.useState(false);
  const [recurrence, setRecurrence] = React.useState(data.PickupSchedule);
  const [daysSelected, setDaysSelected] = React.useState(
    data.DaysSelected || []
  );
  const [contactName, setContactName] = React.useState(data.ContactPerson);
  const [contactNum, setContactNum] = React.useState(data.ContactPersonNo);
  const [estTrans, setEstTrans] = React.useState(data.estVolumePerPickup || "");
  const [specialIns, setSpecialIns] = React.useState(
    data.SpecialInstructions || ""
  );
  const [unit, setUnit] = React.useState("");
  const [pickUpAddress, setPickUpAddress] = React.useState(data.PickUpAddress);

  const [senderProvData, setSenderProvData] = React.useState([]);
  const [senderProvince, setSenderProvince] = React.useState({
    value: data.ProvinceId,
    label: data.ProvinceDescr,
  });
  const [senderProvinceErr, setSenderProvinceErr] = React.useState("");
  const [senderCityData, setSenderCityData] = React.useState([]);
  const [senderCity, setSenderCity] = React.useState({
    value: data.CityId,
    label: data.CityDescr,
  });
  const [senderCityErr, setSenderCityErr] = React.useState("");
  const [senderBrgyData, setSenderBrgyData] = React.useState([]);
  const [senderBrgy, setSenderBrgy] = React.useState({
    value: data.BarangayId,
    label: data.BarangayDescr,
  });
  const [senderBrgyErr, setSenderBrgyErr] = React.useState("");

  const [isToastOpen, setToastOpen] = React.useState(false);
  const [toastKind, setToastKind] = React.useState("success");
  const [toastTitle, setToastTitle] = React.useState(
    "Change Request Submitted"
  );
  const [toastDesc, setToastDesc] = React.useState(
    "Current schedule will be followed until further notice. November 11, 2022 9:06 AM"
  );

  const setDayValue = (day) => {
    if (daysSelected.includes(day)) {
      const tempList = daysSelected.filter((item) => item !== day);
      setDaysSelected(tempList);
      setTimeout(() => {
        setRecurrence(`Recurs every (${tempList.toString()})`);
      }, 750);
    } else {
      const tempList = [...daysSelected];
      tempList.push(day);
      setDaysSelected(tempList);
      setTimeout(() => {
        setRecurrence(`Recurs every (${tempList.toString()})`);
      }, 750);
    }
  };

  const getProvinces = async (sendDelMethod) => {
    setLoading(true);

    let url = "";
    if (sendDelMethod === "RIDER PICK UP") {
      url =
        "/RequestRefFirstMileProvince?token=TEJDdmlzdHJhcGhfdXNlclYxc3RSQHBoMjAxNw==";
    } else if (sendDelMethod === "DROP-OFF") {
      url =
        "/RequestRefProvince?token=TEJDdmlzdHJhcGhfdXNlclYxc3RSQHBoMjAxNw==";
    } else if (sendDelMethod === "HOME DELIVERY") {
      url =
        "/RequestRefProvince?token=TEJDdmlzdHJhcGhfdXNlclYxc3RSQHBoMjAxNw==";
    } else {
      url =
        "/RequestRefProvinceWithActiveBranch?token=TEJDdmlzdHJhcGhfdXNlclYxc3RSQHBoMjAxNw==";
    }

    try {
      const response = await axiosCommonRefApi.get(url);
      const resData = response.data;

      if (resData.Code === "200") {
        if (sendDelMethod === "RIDER PICK UP" || sendDelMethod === "DROP-OFF") {
          setSenderProvData(resData.Province);
        } else {
          setSenderProvData(resData.Province);
        }

        setTimeout(() => {
          setLoading(false);
        }, 1000);
      } else {
        if (sendDelMethod === "RIDER PICK UP" || sendDelMethod === "DROP-OFF") {
          setSenderProvData([]);
        } else {
          setSenderProvData([]);
        }

        setTimeout(() => {
          setLoading(false);
        }, 1000);

        // setToastKind2("error");
        // setToastTitle2("API Fetch Failed!");
        // setToastDesc2(resData.Message);
        // setTimeout(() => {
        //   setLoading(false);
        // }, 1000);
        // setTimeout(() => {
        //   setToastOpen2(true);
        // }, 1500);
        // setTimeout(() => {
        //   setToastOpen2(false);
        // }, 3000);
      }
    } catch (error) {
      //setToastKind2("error");
      //setToastTitle2("API Fetch Failed!");
      //setToastDesc2(error.message);
      setTimeout(() => {
        setLoading(false);
      }, 1000);
      setTimeout(() => {
        //setToastOpen2(true);
      }, 1500);
      setTimeout(() => {
        //setToastOpen2(false);
      }, 3000);
    }
  };

  const getCities = async (sendDelMethod, provinceId) => {
    setLoading(true);

    let url = "";
    if (sendDelMethod === "RIDER PICK UP") {
      url = `/RequestRefFirstMileCity?token=TEJDdmlzdHJhcGhfdXNlclYxc3RSQHBoMjAxNw==&provinceid=${provinceId}`;
    } else if (sendDelMethod === "DROP-OFF") {
      url = `/RequestRefCities?token=TEJDdmlzdHJhcGhfdXNlclYxc3RSQHBoMjAxNw==&provinceid=${provinceId}`;
    } else if (sendDelMethod === "HOME DELIVERY") {
      url = `/RequestRefCities?token=TEJDdmlzdHJhcGhfdXNlclYxc3RSQHBoMjAxNw==&provinceid=${provinceId}`;
    } else {
      url = `/RequestRefCitiesWithActiveBranch?token=TEJDdmlzdHJhcGhfdXNlclYxc3RSQHBoMjAxNw==&provinceid=${provinceId}`;
    }

    try {
      const response = await axiosCommonRefApi.get(url);
      const resData = response.data;

      if (resData.Code === "200") {
        if (sendDelMethod === "RIDER PICK UP" || sendDelMethod === "DROP-OFF") {
          setSenderCityData(resData.City);
        } else {
          setSenderCityData(resData.City);
        }

        setTimeout(() => {
          setLoading(false);
        }, 1000);
      } else {
        if (sendDelMethod === "RIDER PICK UP" || sendDelMethod === "DROP-OFF") {
          setSenderCityData([]);
        } else {
          setSenderCityData([]);
        }

        setTimeout(() => {
          setLoading(false);
        }, 1000);

        // setToastKind2("error");
        // setToastTitle2("API Fetch Failed!");
        // setToastDesc2(resData.Message);
        // setTimeout(() => {
        //   setLoading(false);
        // }, 1000);
        // setTimeout(() => {
        //   setToastOpen2(true);
        // }, 1500);
        // setTimeout(() => {
        //   setToastOpen2(false);
        // }, 3000);
      }
    } catch (error) {
      //setToastKind2("error");
      //setToastTitle2("API Fetch Failed!");
      //setToastDesc2(error.message);
      setTimeout(() => {
        setLoading(false);
      }, 1000);
      setTimeout(() => {
        //setToastOpen2(true);
      }, 1500);
      setTimeout(() => {
        //setToastOpen2(false);
      }, 3000);
    }
  };

  const getBarangays = async (sendDelMethod, provinceId, cityId) => {
    setLoading(true);

    let url = "";
    if (sendDelMethod === "RIDER PICK UP") {
      url = `/RequestRefFirstMileBarangay?token=TEJDdmlzdHJhcGhfdXNlclYxc3RSQHBoMjAxNw==&cityid=${cityId}`;
    } else if (sendDelMethod === "DROP-OFF") {
      url = `/RequestRefBarangayPSGC?token=TEJDdmlzdHJhcGhfdXNlclYxc3RSQHBoMjAxNw==&cityid=${cityId}`;
    } else if (sendDelMethod === "HOME DELIVERY") {
      url = `/RequestRefBarangayPSGC?token=TEJDdmlzdHJhcGhfdXNlclYxc3RSQHBoMjAxNw==&cityid=${cityId}`;
    } else {
      url = `/RequestRefBranch?token=TEJDdmlzdHJhcGhfdXNlclYxc3RSQHBoMjAxNw==&provinceid=${provinceId}&cityid=${cityId}`;
    }

    try {
      const response = await axiosCommonRefApi.get(url);
      const resData = response.data;

      if (resData.Code === "200") {
        if (sendDelMethod === "RIDER PICK UP" || sendDelMethod === "DROP-OFF") {
          setSenderBrgyData(resData.Barangay);
        } else {
          setSenderBrgyData(resData.Barangay || resData.Branch);
        }

        setTimeout(() => {
          setLoading(false);
        }, 1000);
      } else {
        if (sendDelMethod === "RIDER PICK UP" || sendDelMethod === "DROP-OFF") {
          //setSenderBrgyData([]);
        } else {
          //setSenderBrgyData([]);
        }

        setTimeout(() => {
          setLoading(false);
        }, 1000);

        // setToastKind2("error");
        // setToastTitle2("API Fetch Failed!");
        // setToastDesc2(resData.Message);
        // setTimeout(() => {
        //   setLoading(false);
        // }, 1000);
        // setTimeout(() => {
        //   setToastOpen2(true);
        // }, 1500);
        // setTimeout(() => {
        //   setToastOpen2(false);
        // }, 3000);
      }
    } catch (error) {
      //setToastKind2("error");
      //setToastTitle2("API Fetch Failed!");
      //setToastDesc2(error.message);
      setTimeout(() => {
        setLoading(false);
      }, 1000);
      setTimeout(() => {
        //setToastOpen2(true);
      }, 1500);
      setTimeout(() => {
        //setToastOpen2(false);
      }, 3000);
    }
  };

  const UpdateSchedulePickup = async () => {
    var pickupSchedule = {};
    pickupSchedule.PickUPAddressId = data.PickUPAddressId;
    pickupSchedule.PickupDetailsId = data.PickupDetailsId;
    pickupSchedule.ContactPerson = contactName;
    pickupSchedule.ContactPersonNo = contactNum;
    pickupSchedule.PickUpAddress = unit + " " + pickUpAddress;
    pickupSchedule.ProvinceId = senderProvince.value;
    pickupSchedule.CityId = senderCity.value;
    pickupSchedule.BarangayId = senderBrgy.value;
    pickupSchedule.EstimatedTransactions = estTrans;
    pickupSchedule.SpecialInstructions = specialIns;
    pickupSchedule.PickupSchedule = recurrence;
    pickupSchedule.DaysSelected = daysSelected;

    setLoading(true);

    try {
      const response = await axiosCAPApiPrivate.post(
        "/api/UpdateSchedulePickup",
        pickupSchedule
      );
      const resData = response.data;

      if (resData.Code === "200") {
        setTimeout(() => {
          setToastOpen(true);
          setLoading(false);
          setToastKind(ToastData.type.suc);
          setToastTitle(ToastData.title.update200);
          setToastDesc("Pick Up Schedule has been submitted.");
        }, 2000);
        setTimeout(() => {
          onClose();
          setToastOpen(false);
        }, 5000);
      }
    } catch (error) {
      setTimeout(() => {
        setToastOpen(true);
        setLoading(false);
        setToastKind(ToastData.type.err);
        setToastTitle(ToastData.title.update400);
        setToastDesc(ToastData.desc.err02);
      }, 2000);
      setTimeout(() => {
        onClose();
      }, 5000);
    }
  };

  const onSave = () => {
    UpdateSchedulePickup();
  };

  React.useEffect(() => {
    setRecurrence(data.PickupSchedule);
    setContactName(data.ContactPerson);
    setContactNum(data.ContactPersonNo);
    setEstTrans(data.estVolumePerPickup || "");
    setSpecialIns(data.SpecialInstructions || "");
    setUnit("");
    setPickUpAddress(data.PickUpAddress);

    setSenderProvData([]);
    setSenderProvince({
      value: data.ProvinceId,
      label: data.ProvinceDescr,
    });
    setSenderCityData([]);
    setSenderCity({
      value: data.CityId,
      label: data.CityDescr,
    });
    setSenderBrgyData([]);
    setSenderBrgy({
      value: data.BarangayId,
      label: data.BarangayDescr,
    });
    getProvinces("RIDER PICK UP" /*sendingMethod*/);
    getCities("RIDER PICK UP" /*sendingMethod*/, data.ProvinceId);
    getBarangays(
      "RIDER PICK UP" /*sendingMethod*/,
      data.ProvinceId,
      data.CityId
    );
  }, [isOpen]);

  React.useEffect(() => {
    if (senderProvince.value !== 0) {
      setSenderCityData([]);
      getCities("RIDER PICK UP", senderProvince.value);
    }
  }, [senderProvince.value]);

  React.useEffect(() => {
    setSenderBrgyData([]);
    getBarangays("RIDER PICK UP", senderProvince.value, senderCity.value);
  }, [senderCity.value]);

  return (
    <>
      <LBCModal
        description={toastDesc}
        isOpen={isToastOpen}
        onClose={() => setToastOpen(false)}
        title={toastTitle}
        toastKind={toastKind}
      />

      <Transition appear as={Fragment} show={isOpen}>
        <Dialog
          as="div"
          className="fixed inset-0 z-50 overflow-hide"
          onClose={onClose}
        >
          <div className="h-full w-full text-center flex justify-end">
            <Transition.Child
              as={Fragment}
              enter="transition ease duration-300 transform"
              enterFrom="opacity-0 -translate-y-full"
              enterTo="opacity-100 translate-y-0"
              leave="transition ease duration-300 transform"
              leaveFrom="opacity-100 translate-y-0"
              leaveTo="opacity-0 -translate-y-full"
            >
              <div className="h-full w-full overflow-hidden z-20 p-5 align-middle transition-all transform flex flex-col items-center justify-center bg-secondary-200">
                {/* ---------- Modal Body ---------- */}

                <div className="bg-white border border-grey-400 w-700px px-10 py-5 rounded-lg shadow-lg">
                  <FlexRow className="w-full items-center justify-between">
                    <Text className="text-secondary-200 text-sm font-bold">
                      PICK UP SCHEDULE CHANGE REQUEST
                    </Text>
                    <RawButton onClick={onClose}>
                      <FaTimes className="text-secondary-200 text-xl" />
                    </RawButton>
                  </FlexRow>
                  <Spacer className="h-5" />
                  <FlexRow className="w-full items-center justify-between py-2">
                    <Text className="text-secondary-200 text-sm text-left w-1/3">
                      PICK UP SCHEDULE:
                    </Text>
                    <Text className="text-secondary-200 text-sm text-left w-1/3">
                      {recurrence}
                    </Text>
                    <Div className="w-1/3 px-auto">
                      <ScheduleDropdown
                        icon={<div />}
                        value={recurrence}
                        dayValue={daysSelected}
                        width="w-48"
                        options={RecurrenceOptions}
                        onSelect={setRecurrence}
                        onSelectDay={setDayValue}
                      />
                    </Div>
                  </FlexRow>
                  <FlexRow className="w-full items-center justify-between py-2">
                    <Text className="text-secondary-200 text-sm text-left w-1/3">
                      CONTACT PERSON:
                    </Text>
                    <RawInput
                      className="phone:border-grey-400 text-secondary-200 text-sm"
                      containerClass="w-1/3 pr-2"
                      name="contactName"
                      placeholder="Name"
                      onChange={setContactName}
                      type="text"
                      value={contactName}
                    />
                    <RawInput
                      className="phone:border-grey-400 text-secondary-200 text-sm"
                      containerClass=" w-1/3 pl-2"
                      name="contactNum"
                      placeholder="Number"
                      onChange={setContactNum}
                      type="text"
                      value={contactNum}
                    />
                  </FlexRow>
                  <FlexRow className="w-full items-center justify-between py-2">
                    <Text className="text-secondary-200 text-sm text-left w-1/3">
                      PICK UP ADDRESS:
                    </Text>
                    <RawInput
                      className="phone:border-grey-400 text-secondary-200 text-sm"
                      containerClass="w-1/3 pr-2"
                      name=""
                      placeholder="Unit"
                      onChange={setUnit}
                      value={unit}
                      type="text"
                    />
                    <RawInput
                      className="phone:border-grey-400 text-secondary-200 text-sm"
                      containerClass=" w-1/3 pl-2"
                      name=""
                      placeholder="Street"
                      onChange={setPickUpAddress}
                      value={pickUpAddress}
                      type="text"
                    />
                  </FlexRow>
                  <FlexRow className="w-full items-center justify-between py-2">
                    <Spacer className="w-1/3" />
                    <RawSelect
                      id="senderProvince"
                      name="senderProvince"
                      label=""
                      placeholder="Province"
                      containerClass="w-1/3 pr-2"
                      className="border-grey-400 text-secondary-200 text-sm h-10 block w-full"
                      classNamePrefix="react-select"
                      isDisabled={senderProvData.length === 0}
                      onChange={setSenderProvince}
                      value={senderProvince}
                      maxMenuHeight={180}
                      minHeight={50}
                      options={senderProvData.map((item) => ({
                        value: `${item.ProvinceId || item.ProvinceID}`,
                        label: `${item.ProvinceName}`,
                      }))}
                      filterOption={filterConfig}
                      validation={senderProvinceErr}
                    />
                    <RawSelect
                      id="senderCity"
                      name="senderCity"
                      label=""
                      placeholder="City"
                      containerClass="w-1/3 pl-2"
                      className="border-grey-400 text-secondary-200 text-sm h-10 block w-full"
                      classNamePrefix="react-select"
                      isDisabled={senderCityData.length === 0}
                      onChange={setSenderCity}
                      value={senderCity}
                      maxMenuHeight={180}
                      minHeight={50}
                      options={senderCityData.map((item) => ({
                        value: `${item.CityId || item.CityID}`,
                        label: `${item.CityName}`,
                      }))}
                      filterOption={filterConfig}
                      validation={senderCityErr}
                    />
                  </FlexRow>
                  <FlexRow className="w-full items-center justify-between py-2">
                    <Spacer className="w-1/3" />
                    <RawSelect
                      id="senderBrgy"
                      name="senderBrgy"
                      label=""
                      placeholder="Barangay"
                      containerClass="w-1/3 pr-2"
                      className="border-grey-400 text-secondary-200 text-sm h-10 block w-full"
                      classNamePrefix="react-select"
                      isDisabled={senderBrgyData.length === 0}
                      onChange={setSenderBrgy}
                      value={senderBrgy}
                      maxMenuHeight={180}
                      minHeight={50}
                      options={senderBrgyData.map((item) => ({
                        value: `${item.BarangayId || item.BarangayID}`,
                        label: `${item.BarangayName}`,
                      }))}
                      filterOption={filterConfig}
                      validation={senderBrgyErr}
                    />
                    <Spacer className="w-1/3" />
                  </FlexRow>
                  <FlexRow className="w-full items-center justify-between py-2">
                    <Text className="text-secondary-200 text-sm text-left w-1/3">
                      ESTIMATED VOLUME PER PICK UP:
                    </Text>
                    <FlexRow className="w-1/3 items-center justify-start">
                      <RawInput
                        className="phone:border-grey-400 text-secondary-200 text-sm"
                        containerClass="w-10"
                        name=""
                        placeholder="0"
                        onChange={setEstTrans}
                        type="text"
                        value={estTrans}
                      />
                      <Spacer className="w-4" />
                      <Text className="text-secondary-200 text-sm text-left">
                        Transactions
                      </Text>
                    </FlexRow>
                    <Spacer className="w-1/3" />
                  </FlexRow>
                  <FlexRow className="w-full items-center justify-between py-2">
                    <Text className="text-secondary-200 text-sm text-left w-1/3">
                      SPECIAL INSTRUCTIONS:
                    </Text>
                    <RawInput
                      className="phone:border-grey-400 text-secondary-200 text-sm"
                      containerClass="w-2/3"
                      name="Instructions"
                      placeholder="Instructions"
                      onChange={setSpecialIns}
                      type="text"
                      value={specialIns}
                    />
                  </FlexRow>
                  <FlexRow className="w-full items-center justify-end py-2">
                    <Button
                      className="w-1/4 mr-2 phone:bg-white phone:text-secondary-200 hover:bg-grey-500 phone:border phone:border-grey-400 h-10"
                      onClick={onClose}
                    >
                      Cancel
                    </Button>
                    <Button
                      className="w-1/4 ml-2 h-10"
                      onClick={onSave}
                      isLoading={loading}
                    >
                      Save
                    </Button>
                  </FlexRow>
                  <Spacer className="h-2" />
                </div>
              </div>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition>
    </>
  );
};

export const PickUpScheduleCreate = ({ shipperAcct, onClose, isOpen }) => {
  const [loading, setLoading] = React.useState(false);
  const [recurrence, setRecurrence] = React.useState("Daily");
  const [contactName, setContactName] = React.useState("");
  const [contactNum, setContactNum] = React.useState("");
  const [estTrans, setEstTrans] = React.useState("");
  const [specialIns, setSpecialIns] = React.useState("");

  const [isToastOpen, setToastOpen] = React.useState(false);
  const [toastKind, setToastKind] = React.useState("success");
  const [toastTitle, setToastTitle] = React.useState(
    "Change Request Submitted"
  );
  const [toastDesc, setToastDesc] = React.useState(
    "Current schedule will be followed until further notice. November 11, 2022 9:06 AM"
  );

  const [daysSelected, setDaysSelected] = React.useState([]);
  const [shipperAcctNo, setShipperAcctNo] = React.useState("");
  const [unit, setUnit] = React.useState("");
  const [pickUpAddress, setPickUpAddress] = React.useState("");

  const [senderProvData, setSenderProvData] = React.useState([]);
  const [senderProvince, setSenderProvince] = React.useState({
    value: 0,
    label: "Province",
  });
  const [senderProvinceErr, setSenderProvinceErr] = React.useState("");
  const [senderCityData, setSenderCityData] = React.useState([]);
  const [senderCity, setSenderCity] = React.useState({
    value: 0,
    label: "City/Municipality",
  });
  const [senderCityErr, setSenderCityErr] = React.useState("");
  const [senderBrgyData, setSenderBrgyData] = React.useState([]);
  const [senderBrgy, setSenderBrgy] = React.useState({
    value: 0,
    label: "Barangay",
  });
  const [senderBrgyErr, setSenderBrgyErr] = React.useState("");

  React.useEffect(() => {
    if (shipperAcct) {
      var shipAcctNo = shipperAcct.split("|");
      setShipperAcctNo(shipAcctNo[1].toString().trim());
    }
  }, [shipperAcct]);

  const setDayValue = (day) => {
    setRecurrence("Weekly");
    if (daysSelected.includes(day)) {
      const tempList = daysSelected.filter((item) => item !== day);
      setDaysSelected(tempList);
      setTimeout(() => {
        setRecurrence(`Recurs every (${tempList.toString()})`);
      }, 750);
    } else {
      const tempList = [...daysSelected];
      tempList.push(day);
      setDaysSelected(tempList);
      setTimeout(() => {
        setRecurrence(`Recurs every (${tempList.toString()})`);
      }, 750);
    }
  };

  const getProvinces = async (sendDelMethod) => {
    setLoading(true);

    let url = "";
    if (sendDelMethod === "RIDER PICK UP") {
      url =
        "/RequestRefFirstMileProvince?token=TEJDdmlzdHJhcGhfdXNlclYxc3RSQHBoMjAxNw==";
    } else if (sendDelMethod === "DROP-OFF") {
      url =
        "/RequestRefProvince?token=TEJDdmlzdHJhcGhfdXNlclYxc3RSQHBoMjAxNw==";
    } else if (sendDelMethod === "HOME DELIVERY") {
      url =
        "/RequestRefProvince?token=TEJDdmlzdHJhcGhfdXNlclYxc3RSQHBoMjAxNw==";
    } else {
      url =
        "/RequestRefProvinceWithActiveBranch?token=TEJDdmlzdHJhcGhfdXNlclYxc3RSQHBoMjAxNw==";
    }

    try {
      const response = await axiosCommonRefApi.get(url);
      const resData = response.data;

      if (resData.Code === "200") {
        if (sendDelMethod === "RIDER PICK UP" || sendDelMethod === "DROP-OFF") {
          setSenderProvData(resData.Province);
        } else {
          setSenderProvData(resData.Province);
        }

        setTimeout(() => {
          setLoading(false);
        }, 1000);
      } else {
        if (sendDelMethod === "RIDER PICK UP" || sendDelMethod === "DROP-OFF") {
          setSenderProvData([]);
        } else {
          setSenderProvData([]);
        }

        setTimeout(() => {
          setLoading(false);
        }, 1000);

        // setToastKind2("error");
        // setToastTitle2("API Fetch Failed!");
        // setToastDesc2(resData.Message);
        // setTimeout(() => {
        //   setLoading(false);
        // }, 1000);
        // setTimeout(() => {
        //   setToastOpen2(true);
        // }, 1500);
        // setTimeout(() => {
        //   setToastOpen2(false);
        // }, 3000);
      }
    } catch (error) {
      //setToastKind2("error");
      //setToastTitle2("API Fetch Failed!");
      //setToastDesc2(error.message);
      setTimeout(() => {
        setLoading(false);
      }, 1000);
      setTimeout(() => {
        //setToastOpen2(true);
      }, 1500);
      setTimeout(() => {
        //setToastOpen2(false);
      }, 3000);
    }
  };

  const getCities = async (sendDelMethod, provinceId) => {
    setLoading(true);

    let url = "";
    if (sendDelMethod === "RIDER PICK UP") {
      url = `/RequestRefFirstMileCity?token=TEJDdmlzdHJhcGhfdXNlclYxc3RSQHBoMjAxNw==&provinceid=${provinceId}`;
    } else if (sendDelMethod === "DROP-OFF") {
      url = `/RequestRefCities?token=TEJDdmlzdHJhcGhfdXNlclYxc3RSQHBoMjAxNw==&provinceid=${provinceId}`;
    } else if (sendDelMethod === "HOME DELIVERY") {
      url = `/RequestRefCities?token=TEJDdmlzdHJhcGhfdXNlclYxc3RSQHBoMjAxNw==&provinceid=${provinceId}`;
    } else {
      url = `/RequestRefCitiesWithActiveBranch?token=TEJDdmlzdHJhcGhfdXNlclYxc3RSQHBoMjAxNw==&provinceid=${provinceId}`;
    }

    try {
      const response = await axiosCommonRefApi.get(url);
      const resData = response.data;

      if (resData.Code === "200") {
        if (sendDelMethod === "RIDER PICK UP" || sendDelMethod === "DROP-OFF") {
          setSenderCityData(resData.City);
        } else {
          setSenderCityData(resData.City);
        }

        setTimeout(() => {
          setLoading(false);
        }, 1000);
      } else {
        if (sendDelMethod === "RIDER PICK UP" || sendDelMethod === "DROP-OFF") {
          setSenderCityData([]);
        } else {
          setSenderCityData([]);
        }

        setTimeout(() => {
          setLoading(false);
        }, 1000);

        // setToastKind2("error");
        // setToastTitle2("API Fetch Failed!");
        // setToastDesc2(resData.Message);
        // setTimeout(() => {
        //   setLoading(false);
        // }, 1000);
        // setTimeout(() => {
        //   setToastOpen2(true);
        // }, 1500);
        // setTimeout(() => {
        //   setToastOpen2(false);
        // }, 3000);
      }
    } catch (error) {
      //setToastKind2("error");
      //setToastTitle2("API Fetch Failed!");
      //setToastDesc2(error.message);
      setTimeout(() => {
        setLoading(false);
      }, 1000);
      setTimeout(() => {
        //setToastOpen2(true);
      }, 1500);
      setTimeout(() => {
        //setToastOpen2(false);
      }, 3000);
    }
  };

  const getBarangays = async (sendDelMethod, provinceId, cityId) => {
    setLoading(true);

    let url = "";
    if (sendDelMethod === "RIDER PICK UP") {
      url = `/RequestRefFirstMileBarangay?token=TEJDdmlzdHJhcGhfdXNlclYxc3RSQHBoMjAxNw==&cityid=${cityId}`;
    } else if (sendDelMethod === "DROP-OFF") {
      url = `/RequestRefBarangayPSGC?token=TEJDdmlzdHJhcGhfdXNlclYxc3RSQHBoMjAxNw==&cityid=${cityId}`;
    } else if (sendDelMethod === "HOME DELIVERY") {
      url = `/RequestRefBarangayPSGC?token=TEJDdmlzdHJhcGhfdXNlclYxc3RSQHBoMjAxNw==&cityid=${cityId}`;
    } else {
      url = `/RequestRefBranch?token=TEJDdmlzdHJhcGhfdXNlclYxc3RSQHBoMjAxNw==&provinceid=${provinceId}&cityid=${cityId}`;
    }

    try {
      const response = await axiosCommonRefApi.get(url);
      const resData = response.data;

      if (resData.Code === "200") {
        if (sendDelMethod === "RIDER PICK UP" || sendDelMethod === "DROP-OFF") {
          setSenderBrgyData(resData.Barangay);
        } else {
          setSenderBrgyData(resData.Barangay || resData.Branch);
        }

        setTimeout(() => {
          setLoading(false);
        }, 1000);
      } else {
        if (sendDelMethod === "RIDER PICK UP" || sendDelMethod === "DROP-OFF") {
          //setSenderBrgyData([]);
        } else {
          //setSenderBrgyData([]);
        }

        setTimeout(() => {
          setLoading(false);
        }, 1000);

        // setToastKind2("error");
        // setToastTitle2("API Fetch Failed!");
        // setToastDesc2(resData.Message);
        // setTimeout(() => {
        //   setLoading(false);
        // }, 1000);
        // setTimeout(() => {
        //   setToastOpen2(true);
        // }, 1500);
        // setTimeout(() => {
        //   setToastOpen2(false);
        // }, 3000);
      }
    } catch (error) {
      //setToastKind2("error");
      //setToastTitle2("API Fetch Failed!");
      //setToastDesc2(error.message);
      setTimeout(() => {
        setLoading(false);
      }, 1000);
      setTimeout(() => {
        //setToastOpen2(true);
      }, 1500);
      setTimeout(() => {
        //setToastOpen2(false);
      }, 3000);
    }
  };

  const SubmitSchedulePickup = async () => {
    var pickupSchedule = {};
    pickupSchedule.CustomerAccountNo = shipperAcctNo;
    pickupSchedule.ContactPerson = contactName;
    pickupSchedule.ContactPersonNo = contactNum;
    pickupSchedule.PickUpAddress = unit + " " + pickUpAddress;
    pickupSchedule.ProvinceId = senderProvince.value;
    pickupSchedule.CityId = senderCity.value;
    pickupSchedule.BarangayId = senderBrgy.value;
    pickupSchedule.EstimatedTransactions = estTrans;
    pickupSchedule.SpecialInstructions = specialIns;
    pickupSchedule.PickupSchedule = recurrence;
    pickupSchedule.DaysSelected = daysSelected;

    setLoading(true);

    try {
      const response = await axiosCAPApiPrivate.post(
        "/api/SubmitSchedulePickup",
        pickupSchedule
      );
      const resData = response.data;

      if (resData.Code === "200") {
        setTimeout(() => {
          setToastOpen(true);
          setLoading(false);
          setToastKind(ToastData.type.suc);
          setToastTitle(ToastData.title.create200);
          setToastDesc("Pick Up Schedule has been submitted.");
        }, 2000);
        setTimeout(() => {
          onClose();
          setToastOpen(false);
        }, 5000);
      }
    } catch (error) {
      setTimeout(() => {
        setToastOpen(true);
        setLoading(false);
        setToastKind(ToastData.type.err);
        setToastTitle(ToastData.title.update400);
        setToastDesc(ToastData.desc.err02);
      }, 2000);
      setTimeout(() => {
        onClose();
      }, 5000);
    }
  };

  const onSave = () => {
    SubmitSchedulePickup();
    //setLoading(true);
    //if ((Math.random() * 2) % 2 === 0) {
    //  setTimeout(() => {
    //    setToastOpen(true);
    //    setLoading(false);
    //    setToastKind(ToastData.type.err);
    //    setToastTitle(ToastData.title.update400);
    //    setToastDesc(ToastData.desc.err02);
    //  }, 2000);
    //  setTimeout(() => {
    //    onClose();
    //  }, 5000);
    //} else {
    //  setTimeout(() => {
    //    setToastOpen(true);
    //    setLoading(false);
    //    setToastKind(ToastData.type.suc);
    //    setToastTitle(ToastData.title.create200);
    //    setToastDesc("Pick Up Schedule has been submitted.");
    //  }, 2000);
    //  setTimeout(() => {
    //    onClose();
    //    setToastOpen(false);
    //  }, 5000);
    //}
  };

  React.useEffect(() => {
    setRecurrence("Daily");
    setDaysSelected([]);
    setContactName("");
    setContactNum("");
    setEstTrans("");
    setSpecialIns("");
    setUnit("");
    setPickUpAddress("");

    setSenderProvData([]);
    setSenderProvince({
      value: 0,
      label: "Province",
    });
    setSenderCityData([]);
    setSenderCity({
      value: 0,
      label: "City/Municipality",
    });
    setSenderBrgyData([]);
    setSenderBrgy({
      value: 0,
      label: "Barangay",
    });
    getProvinces("RIDER PICK UP" /*sendingMethod*/);
    //getCities("RIDER PICK UP"/*sendingMethod*/, data.ProvinceId);
    //getBarangays("RIDER PICK UP"/*sendingMethod*/, data.ProvinceId, data.CityId);
  }, [isOpen]);

  React.useEffect(() => {
    //setSenderUnit("");
    //setSenderStreet("");
    //setSenderLandmark("");
    //setSenderProvData([]);
    //setSenderProvince({
    //  //value: 0,
    //  //label: "Select Province..",
    //  value: data.ProvinceId,
    //  label: data.ProvinceDescr,
    //});
    //setSenderCityData([]);
    //setSenderCity({
    //  value: 0,
    //  label: "Select City..",
    //});
    //setSenderBrgyData([]);
    //setSenderBrgy({
    //  value: 0,
    //  label: "Select Barangay..",
    //});
    getProvinces("RIDER PICK UP" /*sendingMethod*/);
  }, []);

  React.useEffect(() => {
    if (senderProvince.value !== 0) {
      setSenderCityData([]);
      //setSenderCity({
      //  value: 0,
      //  label: "Select City..",
      //});
      //setSenderBrgyData([]);
      //setSenderBrgy({
      //  value: 0,
      //  label: "Select Barangay..",
      //});
      getCities("RIDER PICK UP" /*sendingMethod*/, senderProvince.value);
    }
  }, [senderProvince.value]);

  React.useEffect(() => {
    setSenderBrgyData([]);
    //setSenderBrgy({
    //  value: 0,
    //  label: "Select Barangay..",
    //});
    getBarangays(
      "RIDER PICK UP" /*sendingMethod*/,
      senderProvince.value,
      senderCity.value
    );
  }, [senderCity.value]);

  return (
    <>
      <LBCModal
        description={toastDesc}
        isOpen={isToastOpen}
        onClose={() => setToastOpen(false)}
        title={toastTitle}
        toastKind={toastKind}
      />
      <Transition appear as={Fragment} show={isOpen}>
        <Dialog
          as="div"
          className="fixed inset-0 z-50 overflow-hide"
          onClose={onClose}
        >
          <div className="h-full w-full text-center flex justify-end">
            <Transition.Child
              as={Fragment}
              enter="transition ease duration-300 transform"
              enterFrom="opacity-0 -translate-y-full"
              enterTo="opacity-100 translate-y-0"
              leave="transition ease duration-300 transform"
              leaveFrom="opacity-100 translate-y-0"
              leaveTo="opacity-0 -translate-y-full"
            >
              <div className="h-full w-full overflow-hidden z-20 p-5 align-middle transition-all transform flex flex-col items-center justify-center bg-secondary-200">
                {/* ---------- Modal Body ---------- */}

                <div className="bg-white border border-grey-400 w-700px px-10 py-5 rounded-lg shadow-lg">
                  <FlexRow className="w-full items-center justify-between">
                    <Text className="text-secondary-200 text-sm font-bold">
                      ADD PICK UP SCHEDULE REQUEST
                    </Text>
                    <RawButton onClick={onClose}>
                      <FaTimes className="text-secondary-200 text-xl" />
                    </RawButton>
                  </FlexRow>
                  <Spacer className="h-5" />
                  <FlexRow className="w-full items-center justify-between py-2">
                    <Text className="text-secondary-200 text-sm text-left w-1/3">
                      PICK UP SCHEDULE:
                    </Text>
                    <Text className="text-secondary-200 text-sm text-left w-1/3">
                      {recurrence}
                    </Text>
                    <Div className="w-1/3 px-auto">
                      <ScheduleDropdown
                        icon={<div />}
                        value={recurrence}
                        dayValue={daysSelected}
                        width="w-48"
                        options={RecurrenceCreateOptions}
                        onSelect={setRecurrence}
                        onSelectDay={setDayValue}
                      />
                    </Div>
                  </FlexRow>
                  <FlexRow className="w-full items-center justify-between py-2">
                    <Text className="text-secondary-200 text-sm text-left w-1/3">
                      CONTACT PERSON:
                    </Text>
                    <RawInput
                      className="phone:border-grey-400 text-secondary-200 text-sm"
                      containerClass="w-1/3 pr-2"
                      name="contactName"
                      placeholder="Name"
                      onChange={setContactName}
                      type="text"
                      value={contactName}
                    />
                    <RawInput
                      className="phone:border-grey-400 text-secondary-200 text-sm"
                      containerClass=" w-1/3 pl-2"
                      name="contactNum"
                      placeholder="Number"
                      onChange={setContactNum}
                      type="text"
                      value={contactNum}
                    />
                  </FlexRow>
                  <FlexRow className="w-full items-center justify-between py-2">
                    <Text className="text-secondary-200 text-sm text-left w-1/3">
                      PICK UP ADDRESS:
                    </Text>
                    <RawInput
                      className="phone:border-grey-400 text-secondary-200 text-sm"
                      containerClass="w-1/3 pr-2"
                      name=""
                      placeholder="Unit"
                      onChange={setUnit}
                      value={unit}
                      type="text"
                    />
                    <RawInput
                      className="phone:border-grey-400 text-secondary-200 text-sm"
                      containerClass=" w-1/3 pl-2"
                      name=""
                      placeholder="Street"
                      onChange={setPickUpAddress}
                      value={pickUpAddress}
                      type="text"
                    />
                  </FlexRow>
                  <FlexRow className="w-full items-center justify-between py-2">
                    <Spacer className="w-1/3" />
                    <RawSelect
                      id="senderProvince"
                      name="senderProvince"
                      label=""
                      placeholder="Province"
                      containerClass="w-1/3 pr-2"
                      className="border-grey-400 text-secondary-200 text-sm h-10 block w-full"
                      classNamePrefix="react-select"
                      isDisabled={senderProvData.length === 0}
                      onChange={setSenderProvince}
                      value={senderProvince}
                      maxMenuHeight={180}
                      minHeight={50}
                      options={senderProvData.map((item) => ({
                        value: `${item.ProvinceId || item.ProvinceID}`,
                        label: `${item.ProvinceName}`,
                      }))}
                      filterOption={filterConfig}
                      validation={senderProvinceErr}
                    />
                    <RawSelect
                      id="senderCity"
                      name="senderCity"
                      label=""
                      placeholder="City"
                      containerClass="w-1/3 pl-2"
                      className="border-grey-400 text-secondary-200 text-sm h-10 block w-full"
                      classNamePrefix="react-select"
                      isDisabled={senderCityData.length === 0}
                      onChange={setSenderCity}
                      value={senderCity}
                      maxMenuHeight={180}
                      minHeight={50}
                      options={senderCityData.map((item) => ({
                        value: `${item.CityId || item.CityID}`,
                        label: `${item.CityName}`,
                      }))}
                      filterOption={filterConfig}
                      validation={senderCityErr}
                    />
                  </FlexRow>
                  <FlexRow className="w-full items-center justify-between py-2">
                    <Spacer className="w-1/3" />
                    <RawSelect
                      id="senderBrgy"
                      name="senderBrgy"
                      label=""
                      placeholder="Barangay"
                      containerClass="w-1/3 pr-2"
                      className="border-grey-400 text-secondary-200 text-sm h-10 block w-full"
                      classNamePrefix="react-select"
                      isDisabled={senderBrgyData.length === 0}
                      onChange={setSenderBrgy}
                      value={senderBrgy}
                      maxMenuHeight={180}
                      minHeight={50}
                      options={senderBrgyData.map((item) => ({
                        value: `${item.BarangayId || item.BarangayID}`,
                        label: `${item.BarangayName}`,
                      }))}
                      filterOption={filterConfig}
                      validation={senderBrgyErr}
                    />
                    <Spacer className="w-1/3" />
                  </FlexRow>
                  <FlexRow className="w-full items-center justify-between py-2">
                    <Text className="text-secondary-200 text-sm text-left w-1/3">
                      ESTIMATED VOLUME PER PICK UP:
                    </Text>
                    <FlexRow className="w-1/3 items-center justify-start">
                      <RawInput
                        className="phone:border-grey-400 text-secondary-200 text-sm"
                        containerClass="w-10"
                        name=""
                        placeholder="0"
                        onChange={setEstTrans}
                        type="text"
                        value={estTrans}
                      />
                      <Spacer className="w-4" />
                      <Text className="text-secondary-200 text-sm text-left">
                        Transactions
                      </Text>
                    </FlexRow>
                    <Spacer className="w-1/3" />
                  </FlexRow>
                  <FlexRow className="w-full items-center justify-between py-2">
                    <Text className="text-secondary-200 text-sm text-left w-1/3">
                      SPECIAL INSTRUCTIONS:
                    </Text>
                    <RawInput
                      className="phone:border-grey-400 text-secondary-200 text-sm"
                      containerClass="w-2/3"
                      name="Instructions"
                      placeholder="Instructions"
                      onChange={setSpecialIns}
                      type="text"
                      value={specialIns}
                    />
                  </FlexRow>
                  <FlexRow className="w-full items-center justify-end py-2">
                    <Button
                      className="w-1/4 mr-2 phone:bg-white phone:text-secondary-200 hover:bg-grey-500 phone:border phone:border-grey-400 h-10"
                      onClick={onClose}
                    >
                      Cancel
                    </Button>
                    <Button
                      className="w-1/4 ml-2 h-10"
                      onClick={onSave}
                      isLoading={loading}
                    >
                      Save
                    </Button>
                  </FlexRow>
                  <Spacer className="h-2" />
                </div>
              </div>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition>
    </>
  );
};
