import React from 'react';
import { Div, FlexRow } from '../../core/Containers';
import { Text } from '../../core/Text';
import { CheckBox } from '../../core/Forms';
import { AiOutlineUpload } from 'react-icons/ai';
import { Link } from "react-router-dom";

export const ProofOfDeliveryCorporateDataRow = ({ data, list, onSelect }) => {
  return (
    <>
      <FlexRow className="items-center justify-between w-full py-2 border-b border-grey-400 hover:bg-grey-400">
        <Text className="text-secondary-100 text-sm w-15p px-4">
          {data.TransactionDate}
        </Text>
        <Text className="text-secondary-100 text-sm w-15p px-4">
          {data.Customer}
        </Text>
        <Text className="text-secondary-100 text-sm w-15p px-4">
          {data.DeliveryStatus}
        </Text>
        <Text
          className={`hover:text-red-400 text-sm w-15p px-4 ${list.includes(data.TrackingNo)
            ? 'text-red-400 underline'
            : 'text-secondary-100'
            }`}
        >
          {data.TrackingNo}
        </Text>
        {/* <Div className="pl-5">
          <CheckBox
            className="phone:mx-0"
            containerClass="mx-auto"
            id={data.name}
            name={data.name}
            label=""
            onClick={() => onSelect(data.tracking)}
          />
        </Div> */}
        <Div className="pl-5">
          {/* <Link
            className="w-10"
            to={data.TNSource}//"/LBC_shipments.xlsx"
            target="_blank"
            //download
          > */}
          <a href={"https://dmsstoragehot.blob.core.windows.net/dmscorpimages/pod/" + data.TNSource} target="_blank">
            <AiOutlineUpload className="text-secondary-200 text-2xl hover:bg-grey-400 rounded-lg" />
          </a>
          {/* </Link> */}
        </Div>
      </FlexRow>
    </>
  );
};

export const ProofOfDeliveryHandheldDataRow = ({ data, list, onSelect }) => {
  return (
    <>
      <FlexRow className="items-center justify-between w-full py-2 border-b border-grey-400 hover:bg-grey-400">
        <Text className="text-secondary-100 text-sm w-15p px-4">
          {data.TransactionDate}
        </Text>
        <Text className="text-secondary-100 text-sm w-15p px-4">
          {data.Customer}
        </Text>
        <Text className="text-secondary-100 text-sm w-15p px-4">
          {data.DeliveryStatus}
        </Text>
        <Text
          className={`hover:text-red-400 text-sm w-15p px-4 ${list.includes(data.TrackingNo)
            ? 'text-red-400 underline'
            : 'text-secondary-100'
            }`}
        >
          {data.TrackingNo}
        </Text>
        {/* <Div className="pl-5">
          <CheckBox
            className="phone:mx-0"
            containerClass="mx-auto"
            id={data.name}
            name={data.name}
            label=""
            onClick={() => onSelect(data.tracking)}
          />
        </Div> */}
        <Div className="pl-5">
          {/* <Link
            className="w-10"
            to={data.TNSource}//"/LBC_shipments.xlsx"
            target="_blank"
            //download
          > */}
          <a href={"https://dmsstoragehot.blob.core.windows.net/dmscorpimages/pod/" + data.TNSource} target="_blank">
            <AiOutlineUpload className="text-secondary-200 text-2xl hover:bg-grey-400 rounded-lg" />
          </a>
          {/* </Link> */}
        </Div>
      </FlexRow>
    </>
  );
};

export const SupplyMonitoringDataRow = ({ data }) => {
  return (
    <>
      <FlexRow className="items-center justify-between w-full py-2 border-b border-grey-400 hover:bg-grey-400">
        <Text className="text-secondary-100 text-sm text-center w-1/6 px-4">
          {data.tracking}
        </Text>
        <Text className="text-secondary-100 text-sm text-center w-1/6 px-4">
          {data.sender}
        </Text>
        <Text className="text-secondary-100 text-sm text-center w-1/6 px-4">
          {data.bookDate}
        </Text>
        <Text className="text-secondary-100 text-sm text-center w-1/6 px-4">
          Account Handler
        </Text>
        <Text className="text-secondary-100 text-sm text-center w-1/6 px-4">
          {data.receiver}
        </Text>
        <Text className="text-secondary-100 text-sm text-center w-1/6 px-4">
          {data.status}
        </Text>
      </FlexRow>
    </>
  );
};

export const RemittanceHistoryDataRow = ({ data }) => {
  return (
    <>
      <FlexRow className="items-center justify-between w-full py-2 border-b border-grey-400 hover:bg-grey-400">
        <Text className="text-secondary-100 text-sm text-center w-1/8 px-4">
          {data.trackingNumber}
        </Text>
        <Text className="text-secondary-100 text-sm text-center w-1/8 px-4">
          {data.referenceNumber}
        </Text>
        <Text className="text-secondary-100 text-sm text-center w-1/8 px-4">
          {data.remittanceDate}
        </Text>
        <Text className="text-secondary-100 text-sm text-center w-1/8 px-4">
          {data.remittanceMode}
        </Text>
        <Text className="text-secondary-100 text-sm text-center w-1/4 px-4">
          {data.accountName}
        </Text>
        <Text className="text-secondary-100 text-sm text-center w-1/8 px-4">
          {data.codAmount}
        </Text>
        <Text className="text-secondary-100 text-sm text-center w-1/8 px-4">
          {data.amountRemitted}
        </Text>
      </FlexRow>
    </>
  );
};

export const DeliveryReportDataRow = ({ data, key }) => {
  return (
    <>
      <FlexRow className="items-center justify-between w-full py-2 border-b border-grey-400 hover:bg-grey-400" key={key}>
        <Text className="text-secondary-100 text-sm text-center w-1/8 px-4">
          {data.DateText}
        </Text>

        <Link to={data.DeliveredLink} >
          <Text className="text-secondary-100 text-sm text-center w-1/8 px-4 hover:text-red-400">
            {data.Delivered.toLocaleString()}
          </Text>
        </Link>

        <Link to={data.PendingLink} >
          <Text className="text-secondary-100 text-sm text-center w-1/8 px-4 hover:text-red-400">
            {data.Pending.toLocaleString()}
          </Text>
        </Link>

        <Link to={data.ForUpdateLink} >
          <Text className="text-secondary-100 text-sm text-center w-1/8 px-4 hover:text-red-400">
            {data.ForUpdate.toLocaleString()}
          </Text>
        </Link>

        <Link to={data.RTSLink} >
          <Text className="text-secondary-100 text-sm text-center w-1/8 px-4 hover:text-red-400">
            {data.RTS.toLocaleString()}
          </Text>
        </Link>

        <Link to={data.LostLink} >
          <Text className="text-secondary-100 text-sm text-center w-1/8 px-4 hover:text-red-400">
            {data.Lost.toLocaleString()}
          </Text>
        </Link>

        {/* <Link to='' >
          <Text className="text-secondary-100 text-sm text-center w-1/8 px-4">
            {data.Total}
          </Text>
        </Link> */}
        <Text className="text-secondary-100 text-sm text-center w-1/8 px-4">
            {data.Total.toLocaleString()}
          </Text>
      </FlexRow>
    </>
  );
};

export const RemittanceCorpDataRow = ({ data, key }) => {
  return (
    <>
      <FlexRow className="items-center justify-between w-full py-2 border-b border-grey-400 hover:bg-grey-400" key={key}>
        <Text className="text-secondary-100 text-sm text-center w-1/8 px-4">
          {data.DateText}
        </Text>

        <Link to={data.RemittedLink} >
          <Text className="text-secondary-100 text-sm text-center w-1/8 px-4">
            {data.Remitted.toLocaleString()}
          </Text>
        </Link>

        <Link to={data.UnRemittedLink} >
          <Text className="text-secondary-100 text-sm text-center w-1/8 px-4">
            {data.UnRemitted.toLocaleString()}
          </Text>
        </Link>

        {/* <Link to={data.DeliveredLink} >
          <Text className="text-secondary-100 text-sm text-center w-1/8 px-4">
            {data.Delivered}
          </Text>
        </Link> */}
        <Text className="text-secondary-100 text-sm text-center w-1/8 px-4">
          {data.Delivered.toLocaleString()}
        </Text>
      </FlexRow>
    </>
  );
};

export const RemittanceHistoryReportDataRow = ({ data, key }) => {
  return (
    <>
      <FlexRow className="items-center justify-between w-full py-2 border-b border-grey-400 hover:bg-grey-400" key={key}>
        <Text className="text-secondary-100 text-sm text-center w-1/8 px-4">
          {data.TrackingNo}
        </Text>

        <Text className="text-secondary-100 text-sm text-center w-1/8 px-4">
          {data.ReferenceNumber}
        </Text>

        <Text className="text-secondary-100 text-sm text-center w-1/8 px-4">
          {data.RemittanceDate}
        </Text>

        <Text className="text-secondary-100 text-sm text-center w-1/8 px-4">
          {data.RemittanceStatus}
        </Text>

        <Text className="text-secondary-100 text-sm text-center w-1/8 px-4">
          {data.CODAmount}
        </Text>

        {/* <Text className="text-secondary-100 text-sm text-center w-1/8 px-4">
          {data.AmountRemitted}
        </Text> */}
      </FlexRow>
    </>
  );
};