import React from "react";
import { Cookies } from "react-cookie";
import { FlexRow } from "../../core/Containers";
import { Text } from "../../core/Text";
import { RawInput } from "../../core/Forms";
import { ImUpload3 } from "react-icons/im";
import { FaRegEdit } from "react-icons/fa";
import { AiOutlineDownload } from "react-icons/ai";
import { RawButton } from "../../core/Buttons";
import { LBCModal } from "../../components/Modals/LBCModal";
import axiosCAPApi from "../../api/axiosCAPApi";
import axiosCAPApiPrivate from "../../api/axiosCAPApiPrivate";

export const DeliveryDataRow = ({
  deliveryStatus,
  count,
  exportToCSV,
  // logisticsType,
  // deliveryDate,
  // transactionDate,
  // clientNameFrom,
  // clientAddressFrom,
  // clientNameTo,
  // clientAddressTo,
}) => (
  <>
    <FlexRow className="items-center justify-between py-2 hover:bg-grey-400">
      <Text className="text-secondary-100 text-sm w-200px px-4">
        {deliveryStatus}
      </Text>
      <Text className="text-secondary-100 text-sm w-200px px-4">
        {count !== null ? parseInt(count).toLocaleString() : ""}
      </Text>
      {deliveryStatus !== "Remitted" &&
      deliveryStatus !== "UnRemitted" &&
      deliveryStatus !== "For Disposition" ? (
        <RawButton onClick={() => exportToCSV()}>
          <AiOutlineDownload className="text-secondary-200 text-xl" />
        </RawButton>
      ) : (
        <></>
      )}
      {/* <Text className="text-secondary-100 text-sm w-200px px-4">
        {logisticsType}
      </Text>
      <Text className="text-secondary-100 text-sm w-200px px-4">
        {deliveryDate}
      </Text>
      <Text className="text-secondary-100 text-sm w-200px px-4">
        {transactionDate}
      </Text>
      <Text className="text-secondary-100 text-sm w-200px px-4">
        {clientNameFrom}
      </Text>
      <Text className="text-secondary-100 text-sm w-200px px-4">
        {clientAddressFrom}
      </Text>
      <Text className="text-secondary-100 text-sm w-200px px-4">
        {clientNameTo}
      </Text>
      <Text className="text-secondary-100 text-sm w-200px px-4">
        {clientAddressTo}
      </Text>
      <Text className="text-secondary-100 text-sm w-200px px-4">Test</Text>
      <RawInput
        className="phone:border-grey-400"
        containerClass="w-200px"
        name="disposition"
        placeholder=""
        onChange={() => { }}
        type="disposition"
        value=""
      />
      <FlexRow className="items-center justify-center w-200px">
        <ImUpload3 className="text-red-400 text-xl mr-2" />
        <FaRegEdit className="text-red-400 text-xl ml-2" />
      </FlexRow> */}
    </FlexRow>
  </>
);

export const ForDispositionDataRow = ({ reason, count, percent }) => (
  <>
    <FlexRow className="items-center justify-between py-2 hover:bg-grey-400">
      <Text className="text-secondary-100 text-sm w-200px px-4">{reason}</Text>
      <Text className="text-secondary-100 text-sm w-200px px-4">{count}</Text>
      <Text className="text-secondary-100 text-sm w-200px px-4">{percent}</Text>
    </FlexRow>
  </>
);

export const DeliveryDataTableRow = ({ data, status, onDispoChange }) => {
  const cookies = new Cookies();
  const [isToastOpen, setToastOpen] = React.useState(false);
  const [toastKind, setToastKind] = React.useState("");
  const [toastTitle, setToastTitle] = React.useState("");
  const [toastDesc, setToastDesc] = React.useState("");
  const [loading, setLoading] = React.useState(true);

  const sendEmail = async (res) => {
    var prmList = [];
    prmList.push(data);

    try {
      const response = await axiosCAPApiPrivate.post(
        "/api/SendEmailForDisposition",
        {
          To: "ecom_support@lbcexpress.com", //cookies.get("username"),
          From: "customercare@lbcexpress.com",
          Subject: "For Disposition",
          prm: prmList,
        }
      );

      const data = response.data;

      if (data.Code === "200") {
        setToastKind("Success");
        //setToastTitle("OTP sent to your email address.");
        //setToastDesc("Please check your email address.");
        if (data.Data.status === "ok") {
          setToastTitle("Email sent!");
          setToastDesc("Email sent!");
        } else {
          setToastTitle(data.Data.message);
          setToastDesc(data.Data.message);
        }

        setTimeout(() => {
          setLoading(false);
        }, 1000);
        setTimeout(() => {
          setToastOpen(true);
        }, 1500);
        setTimeout(() => {
          setToastOpen(false);
        }, 5500);
      } else {
        setToastKind("error");
        setToastTitle("Sending For Disposition Failed!");
        setToastDesc(data.Message);
        setTimeout(() => {
          setLoading(false);
        }, 1000);
        setTimeout(() => {
          setToastOpen(true);
        }, 1500);
        setTimeout(() => {
          setToastOpen(false);
        }, 5000);
      }
    } catch (error) {
      setToastKind("Error");
      setToastTitle("Sending For Disposition Failed!");
      setToastDesc(
        "Unable to send For Disposition. Please contact Support Team."
      );
      setTimeout(() => {
        setLoading(false);
      }, 1000);
      setTimeout(() => {
        setToastOpen(true);
      }, 1500);
      setTimeout(() => {
        setToastOpen(false);
      }, 5000);
    }
  };

  return (
    <>
      <LBCModal
        description={toastDesc}
        isOpen={isToastOpen}
        onClose={() => setToastOpen(false)}
        title={toastTitle}
        toastKind={toastKind}
      />
      <FlexRow className="items-center justify-between w-2800px py-2 hover:bg-grey-400">
        <Text className="text-secondary-100 text-sm w-200px px-4">
          {data.tracking}
        </Text>
        <Text className="text-secondary-100 text-sm text-center w-200px px-4">
          {data.encodedDate}
        </Text>
        <Text className="text-secondary-100 text-sm text-center w-200px px-4">
          {data.deliveryReq}
        </Text>
        <Text className="text-secondary-100 text-sm text-center w-200px px-4">
          {data.consigneeName}
        </Text>
        <Text className="text-secondary-100 text-sm text-center w-200px px-4">
          {data.consigneeContact}
        </Text>
        <Text className="text-secondary-100 text-sm text-center w-400px px-4">
          {data.consigneeAddress}
        </Text>
        <Text className="text-secondary-100 text-sm text-center w-200px px-4">
          {data.consigneeContact}
        </Text>
        <Text className="text-secondary-100 text-sm text-center w-200px px-4">
          {data.acceptanceDate}
        </Text>
        <Text className="text-secondary-100 text-sm text-center w-200px px-4">
          {data.status}
        </Text>
        <Text className="text-secondary-100 text-sm text-center w-200px px-4">
          {data.statusDate}
        </Text>
        <Text className="text-secondary-100 text-sm text-center w-200px px-4">
          {data.reason}
        </Text>
        {status === "For Disposition" ? (
          // <input
          //   className="border border-grey-400 rounded-lg block disabled:bg-grey-500 disabled:cursor-no-drop
          //     text-secondary-200 w-200px text-sm h-9"
          //   id="disposition"
          //   name="disposition"
          //   placeholder=""
          //   onChange={(e) => onDispoChange(data.tracking, e.target.value)}
          //   type="text"
          //   value={data?.disposition || ""}
          //   autoComplete="off"
          // />
          <></>
        ) : (
          <></>
        )}
        {status !== "ViewAll" &&
        status !== "CorpUnRemitted" &&
        status !== "CorpRemitted" &&
        status !== "For Disposition" ? (
          <FlexRow className="items-center justify-center w-200px">
            <ImUpload3
              className="text-red-400 text-xl mr-2"
              onClick={() => sendEmail(data)}
            />
            {/* <FaRegEdit className="text-red-400 text-xl ml-2" /> */}
          </FlexRow>
        ) : (
          <></>
        )}
      </FlexRow>
    </>
  );
};

export const ReportsDeliveryDataTableRow = ({
  data,
  status,
  onDispoChange,
}) => {
  const cookies = new Cookies();
  const [isToastOpen, setToastOpen] = React.useState(false);
  const [toastKind, setToastKind] = React.useState("");
  const [toastTitle, setToastTitle] = React.useState("");
  const [toastDesc, setToastDesc] = React.useState("");
  const [loading, setLoading] = React.useState(true);

  const sendEmail = async (res) => {
    var prmList = [];
    prmList.push(data);

    try {
      const response = await axiosCAPApiPrivate.post(
        "/api/SendEmailForDisposition",
        {
          To: "ecom_support@lbcexpress.com",
          From: "customercare@lbcexpress.com",
          Subject: "For Disposition",
          prm: prmList,
        }
      );

      const data = response.data;

      if (data.Code === "200") {
        setToastKind("Success");
        //setToastTitle("OTP sent to your email address.");
        //setToastDesc("Please check your email address.");
        if (data.Data.status === "ok") {
          setToastTitle("Email sent!");
          setToastDesc("Email sent!");
        } else {
          setToastTitle(data.Data.message);
          setToastDesc(data.Data.message);
        }

        setTimeout(() => {
          setLoading(false);
        }, 1000);
        setTimeout(() => {
          setToastOpen(true);
        }, 1500);
        setTimeout(() => {
          setToastOpen(false);
        }, 5500);
      } else {
        setToastKind("error");
        setToastTitle("Sending For Disposition Failed!");
        setToastDesc(data.Message);
        setTimeout(() => {
          setLoading(false);
        }, 1000);
        setTimeout(() => {
          setToastOpen(true);
        }, 1500);
        setTimeout(() => {
          setToastOpen(false);
        }, 5000);
      }
    } catch (error) {
      setToastKind("Error");
      setToastTitle("Sending For Disposition Failed!");
      setToastDesc(
        "Unable to send For Disposition. Please contact Support Team."
      );
      setTimeout(() => {
        setLoading(false);
      }, 1000);
      setTimeout(() => {
        setToastOpen(true);
      }, 1500);
      setTimeout(() => {
        setToastOpen(false);
      }, 5000);
    }
  };

  return (
    <>
      <LBCModal
        description={toastDesc}
        isOpen={isToastOpen}
        onClose={() => setToastOpen(false)}
        title={toastTitle}
        toastKind={toastKind}
      />
      <FlexRow className="items-center justify-between w-2800px py-2 hover:bg-grey-400">
        <Text className="text-secondary-100 text-sm w-200px px-4">
          {data.tracking}
        </Text>
        <Text className="text-secondary-100 text-sm text-center w-200px px-4">
          {data.encodedDate}
        </Text>
        <Text className="text-secondary-100 text-sm text-center w-200px px-4">
          {data.deliveryReq}
        </Text>
        <Text className="text-secondary-100 text-sm text-center w-200px px-4">
          {data.consigneeName}
        </Text>
        <Text className="text-secondary-100 text-sm text-center w-200px px-4">
          {data.consigneeContact}
        </Text>
        <Text className="text-secondary-100 text-sm text-center w-400px px-4">
          {data.consigneeAddress}
        </Text>
        <Text className="text-secondary-100 text-sm text-center w-200px px-4">
          {data.consigneeContact}
        </Text>
        <Text className="text-secondary-100 text-sm text-center w-200px px-4">
          {data.acceptanceDate}
        </Text>
        <Text className="text-secondary-100 text-sm text-center w-200px px-4">
          {data.status}
        </Text>
        <Text className="text-secondary-100 text-sm text-center w-200px px-4">
          {data.statusDate}
        </Text>
        <Text className="text-secondary-100 text-sm text-center w-200px px-4">
          {data.itemName}
        </Text>
        <Text className="text-secondary-100 text-sm text-center w-200px px-4">
          {parseFloat(data.collectedAmount).toLocaleString()}
        </Text>
        <Text className="text-secondary-100 text-sm text-center w-200px px-4">
          {parseFloat(data.collectedAmount).toLocaleString()}
        </Text>
        <Text className="text-secondary-100 text-sm text-center w-200px px-4">
          {parseFloat(data.collectedAmount).toLocaleString()}
        </Text>
        <Text className="text-secondary-100 text-sm text-center w-200px px-4">
          {data.reason}
        </Text>
      </FlexRow>
    </>
  );
};

export const ForDispoTableRow = ({ data, status }) => (
  <>
    <FlexRow className="items-center justify-between w-2800px py-2 hover:bg-grey-400">
      <Text className="text-secondary-100 text-sm w-200px px-4">
        {data.tracking}
      </Text>
      <Text className="text-secondary-100 text-sm text-center w-200px px-4">
        {data.encodedDate}
      </Text>
      <Text className="text-secondary-100 text-sm text-center w-200px px-4">
        {data.deliveryReq}
      </Text>
      <Text className="text-secondary-100 text-sm text-center w-200px px-4">
        {data.consigneeName}
      </Text>
      <Text className="text-secondary-100 text-sm text-center w-200px px-4">
        {data.consigneeContact}
      </Text>
      <Text className="text-secondary-100 text-sm text-center w-400px px-4">
        {data.consigneeAddress}
      </Text>
      <Text className="text-secondary-100 text-sm text-center w-200px px-4">
        {data.consigneeContact}
      </Text>
      <Text className="text-secondary-100 text-sm text-center w-200px px-4">
        {data.acceptanceDate}
      </Text>
      <Text className="text-secondary-100 text-sm text-center w-200px px-4">
        {data.status}
      </Text>
      <Text className="text-secondary-100 text-sm text-center w-200px px-4">
        {data.statusDate}
      </Text>
      <Text className="text-secondary-100 text-sm text-center w-200px px-4">
        {data.reason}
      </Text>
      <RawInput
        className="phone:border-grey-400"
        containerClass="w-200px"
        name="disposition"
        placeholder=""
        onChange={() => {}}
        type="disposition"
        value=""
      />
      <FlexRow className="items-center justify-center w-200px">
        <ImUpload3 className="text-red-400 text-xl mr-2" />
        <FaRegEdit className="text-red-400 text-xl ml-2" />
      </FlexRow>
    </FlexRow>
  </>
);
