import React from "react";
import { Div, Divider, FlexColumn, FlexRow } from "../../core/Containers";
import { NavbarMain } from "../../components/Navigation/NavbarMain";
import { TopNavBar } from "../../components/Navigation/TopNavBar";
import { useLocation, useNavigate } from "react-router-dom";
import { Tab } from "@headlessui/react";
import { classNames } from "../../helpers/ClassNames";
import { TrackAndTraceTab, DeliveryLeadTimeTab } from "./TrackAndTraceTabs";

export const TrackAndTrace = () => {
  const navigate = useNavigate();
  const { search } = useLocation();
  const query = new URLSearchParams(search);
  const option = query.get("option");

  const [selectedIndex, setSelectedIndex] = React.useState(0);
  const tabs = ["Track and Trace", "Delivery Lead Time"];
  const [searchTriggrerd, setSearchTriggrerd] = React.useState(false);

  React.useEffect(() => {
    if (option === "0") {
      setSelectedIndex(0);
      navigate("/track-and-trace");
    } else if (option === "1") {
      setSelectedIndex(1);
      navigate("/track-and-trace");
    }
  }, [option, navigate]);

  const searchTracking = () => {
    setSearchTriggrerd(true);
  };

  return (
    <FlexRow className="w-full h-full items-center justify-between">
      {/* Side Navigation */}
      <NavbarMain route="TrackAndTrace" />

      {/* Main Dashboard */}
      <FlexColumn className="w-11/12 h-full">
        {/* Top Navigation */}
        <TopNavBar
          showMenu={true}
          showNotifBell={true}
          showSearchBar={true}
          searchTracking={searchTracking}
        />

        {/* Body */}
        <Div className="w-full h-full overflow-y-auto p-10 bg-white">
          <FlexRow className="w-full">
            <Tab.Group
              selectedIndex={selectedIndex}
              onChange={setSelectedIndex}
            >
              <FlexColumn className="w-full">
                <Tab.List className="tabletWide:space-x-4">
                  {tabs.map((tab) => (
                    <Tab
                      className={({ selected }) =>
                        classNames(
                          "p-2 font-semibold w-auto focus:border-b-4 focus:border-orange-400 ring:border-orange-400 outline-none",
                          selected
                            ? "text-orange-400 border-b-4 border-orange-400"
                            : "text-secondary-200  hover:text-secondary-100"
                        )
                      }
                      key={tab}
                    >
                      {tab}
                    </Tab>
                  ))}
                </Tab.List>
                <Divider className="text-grey-400" />
                <Tab.Panels className="w-full">
                  <Tab.Panel className={`w-full`}>
                    <TrackAndTraceTab
                      searchTriggrerd={searchTriggrerd}
                      setSearchTriggrerd={setSearchTriggrerd}
                    />
                  </Tab.Panel>
                  <Tab.Panel className={`w-full`}>
                    <DeliveryLeadTimeTab />
                  </Tab.Panel>
                </Tab.Panels>
              </FlexColumn>
            </Tab.Group>
          </FlexRow>
        </Div>
      </FlexColumn>
    </FlexRow>
  );
};
