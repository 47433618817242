import React from "react";
import { classNames } from "../../helpers/ClassNames";

export const RawInput = ({
  className,
  disabled,
  type,
  placeholder,
  props,
  validation,
  onChange,
  onBlur,
  value,
  name,
  containerClass,
  maxlength,
  inputmode,
  onWheel,
  readOnly,
  autoComplete,
  spellCheck,
}) => {
  const onChangeInput = (event) => {
    if (type === "file") {
      onChange(event.target.files);
    } else if (maxlength) {
      if (maxlength >= event.target.value.length) {
        onChange(event.target.value);
      }
    } else {
      onChange(event.target.value);
    }
  };

  return (
    <div className={`${containerClass}`}>
      <input
        className={classNames(
          `border ${
            validation ? "border-red-100" : "border-grey-400"
          } rounded-lg block w-full disabled:bg-grey-500 disabled:cursor-no-drop`,
          className
        )}
        disabled={disabled || false}
        id={name}
        inputMode={inputmode}
        name={name}
        onChange={(event) => onChangeInput(event)}
        onBlur={onBlur}
        onWheel={onWheel === "" ? "" : (e) => e.target.blur()}
        placeholder={placeholder}
        required
        type={type || "auto"}
        value={value}
        readOnly={readOnly || false}
        autoComplete={autoComplete || "on"}
        spellCheck={spellCheck || false}
        {...props}
      />
      {validation && (
        <p className="mt-1 pl-2 text-xs text-left text-red-400 dark:text-red-400">{validation}</p>
      )}
    </div>
  );
};
