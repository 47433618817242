import LBClogo from "./LBC_Logo.png";
import SampleQrBarCode from "./sample_qr_bar_code.png";
import packageImg from "./package_item.png";
import lbcBag from "./lbc_bag.png";
import banner1 from "./banner_1.png";
import banner2 from "./banner_2.png";
import banner3 from "./banner_3.png";
// import NPackSakto from "./pkg-n-pack-sakto.png";
// import NPackSmall from "./pkg-n-pack-small.png";
// import NPackLarge from "./pkg-n-pack-large.png";
// import KiloboxMini from "./pkg-kilobox-mini.png";
// import KiloboxSmall from "./pkg-kilobox-small.png";
// import KiloboxSlim from "./pkg-kilobox-slim.png";
// import KiloboxMedium from "./pkg-kilobox-medium.png";
// import OwnBox from "./pkg-own-box.png";
import NPouchRegular from "./pkgs/pkg-n-pouch-regular.jpg";
import NPouchXL from "./pkgs/pkg-n-pouch-xl.jpg";
import NPouchSS from "./pkgs/pkg-n-pouch-ss.jpg";
import NPackSakto from "./pkgs/pkg-n-pack-sakto.jpg";
import NPackSmall from "./pkgs/pkg-n-pack-small.jpg";
import NPackLarge from "./pkgs/pkg-n-pack-large.jpg";
import Day1 from "./pkgs/pkg-day1.jpg";
import KiloboxMini from "./pkgs/pkg-kilobox-mini.jpg";
import KiloboxSlim from "./pkgs/pkg-kilobox-slim.jpg";
import KiloboxSmall from "./pkgs/pkg-kilobox-small.jpg";
import KiloboxMedium from "./pkgs/pkg-kilobox-medium.jpg";
import KiloboxLarge from "./pkgs/pkg-kilobox-large.jpg";
import KiloboxXL from "./pkgs/pkg-kilobox-xl.jpg";
import TrackListIcon from "./tracking-icons/list-icon.png";
import addressBook1 from "./address-bookIcon-icon1.png";

const getPackageImage = (pkgId) => {
  return pkgId === 3 || pkgId === 98
    ? NPouchRegular
    : pkgId === 61 || pkgId === 99
    ? NPouchXL
    : pkgId === 62 || pkgId === 100
    ? NPouchSS
    : pkgId === 166
    ? NPackSakto
    : pkgId === 45 || pkgId === 101
    ? NPackSmall
    : pkgId === 8 || pkgId === 102
    ? NPackLarge
    : pkgId === 16
    ? Day1
    : pkgId === 165
    ? KiloboxMini
    : pkgId === 164
    ? KiloboxSlim
    : pkgId === 6 || pkgId === 103
    ? KiloboxSmall
    : pkgId === 63 || pkgId === 104
    ? KiloboxMedium
    : pkgId === 7 || pkgId === 105
    ? KiloboxLarge
    : pkgId === 64 || pkgId === 106
    ? KiloboxXL
    : packageImg;
};

export const Images = {
  LBClogo,
  SampleQrBarCode,
  packageImg,
  lbcBag,
  banner1,
  banner2,
  banner3,
  getPackageImage,
  TrackListIcon,
  addressBook1,
};
