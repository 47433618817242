import React, { useRef } from "react";
import { Cookies } from "react-cookie";
import {
  Div,
  Divider,
  FlexColumn,
  FlexRow,
  Spacer,
} from "../../../core/Containers";
import { Text } from "../../../core/Text";
import { LBCModal } from "../../../components/Modals/LBCModal";
import { NavbarMain } from "../../../components/Navigation/NavbarMain";
import { TopNavBar } from "../../../components/Navigation/TopNavBar";
import { ColumnHeader } from "../../../components/Tables/ColumnHeader";
import {
  DeliveryDataRow,
  ForDispositionDataRow,
  DeliveryDataTableRow,
} from "../../../components/Tables/DeliveryDataRow";
import { Pagination } from "../../../components/Tables/Pagination";
import { useSearchParams } from "react-router-dom";
import { ImSpinner2 } from "react-icons/im";
import * as FileSaver from "file-saver";
// import * as XLSX from "xlsx";
import axiosCAPApi from "../../../api/axiosCAPApi";
import axiosCAPApiPrivate from "../../../api/axiosCAPApiPrivate";

const cookies = new Cookies();

export const DashboardDeliveryTableData = ({ shipperAcct }) => {
  const dataFetchedRef = useRef(false);

  const [loading, setLoading] = React.useState(true);
  const [isAscending, setAscending] = React.useState(true);
  const [sortList, setSortList] = React.useState([] /*tempDeliveryTableData*/);

  const [refusedToAccept, setRefusedToAccept] = React.useState(0);
  const [incompleteIncorrectAddress, setIncompleteIncorrectAddress] =
    React.useState(0);
  const [consigneeUnknown, setConsigneeUnknown] = React.useState(0);

  const [DeliveredExcelList, setDeliveredExcelList] = React.useState([]);
  const [ForDispositionExcelList, setForDispositionExcelList] = React.useState(
    []
  );
  const [InTransitExcelList, setInTransitExcelList] = React.useState([]);
  const [ReturnedExcelList, setReturnedExcelList] = React.useState([]);
  const [OthersExcelList, setOthersExcelList] = React.useState([]);

  const [currentPage, setCurrentPage] = React.useState(1);
  const [articlesPerPage] = React.useState(5);
  const indexOfLastArticle = currentPage * articlesPerPage;
  const indexOfFirstArticle = indexOfLastArticle - articlesPerPage;

  const [shipperAcctName, setShipperAcctName] = React.useState("");
  const [shipperAcctNo, setShipperAcctNo] = React.useState("");
  const [deliveryStatus, setDeliveryStatus] = React.useState("");
  const [dateFrom, setDateFrom] = React.useState("");
  const [dateTo, setDateTo] = React.useState("");

  const [isToastOpen, setToastOpen] = React.useState(false);
  const [toastKind, setToastKind] = React.useState("");
  const [toastTitle, setToastTitle] = React.useState("");
  const [toastDesc, setToastDesc] = React.useState("");

  const newItems = sortList.slice(indexOfFirstArticle, indexOfLastArticle);

  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  const [searchParams, setSearchParams] = useSearchParams();

  React.useEffect(() => {
    if (dataFetchedRef.current) return;
    dataFetchedRef.current = true;

    var _shipperAcct = cookies.get("shipperAcct", { path: "/" });
    var _shipperAcctSplit = _shipperAcct ? _shipperAcct.split("|") : [];
    var _shipperAcctName = _shipperAcctSplit[0]
      ? _shipperAcctSplit[0].toString().trim()
      : "";
    var _shipperAcctNo = _shipperAcctSplit[1]
      ? _shipperAcctSplit[1].toString().trim()
      : "";

    setShipperAcctName(_shipperAcctName);
    setShipperAcctNo(_shipperAcctNo);

    var _deliveryStatus = searchParams.get("deliveryStatus");
    var _dateFrom = searchParams.get("dateFrom");
    var _dateTo = searchParams.get("dateTo");

    console.log(_deliveryStatus);
    console.log(_dateFrom);
    console.log(_dateTo);

    setDeliveryStatus(_deliveryStatus);
    setDateFrom(_dateFrom);
    setDateTo(_dateTo);
  }, []);

  React.useEffect(() => {
    var _shipperAcct = cookies.get("shipperAcct", { path: "/" });
    var _shipperAcctSplit = _shipperAcct ? _shipperAcct.split("|") : [];
    var _shipperAcctName = _shipperAcctSplit[0]
      ? _shipperAcctSplit[0].toString().trim()
      : "";
    var _shipperAcctNo = _shipperAcctSplit[1]
      ? _shipperAcctSplit[1].toString().trim()
      : "";

    if (shipperAcctNo !== "" && _shipperAcctNo !== shipperAcctNo) {
      setShipperAcctName(_shipperAcctName);
      setShipperAcctNo(_shipperAcctNo);
    }
  }, [cookies.get("shipperAcct", { path: "/" })]);

  React.useEffect(() => {
    if (
      shipperAcctNo !== "" &&
      deliveryStatus !== "" &&
      dateFrom !== "" &&
      dateTo !== ""
    ) {
      setSortList([]);
      GetReportDetail();
      GetDeliveredExcelDetail();
      GetForDispositionExcelDetail();
      GetInTransitExcelDetail();
      GetReturnedExcelDetail();
      GetOthersExcelDetail();
    }
  }, [shipperAcctNo, deliveryStatus, dateFrom, dateTo]);

  // console.log(searchParams.get("deliveryStatus"));

  // React.useEffect(() => {
  //   if (shipperAcct) {
  //     var shipAcctNo = shipperAcct ? shipperAcct.split("|") : [];
  //     setShipperAcctNo(shipAcctNo[1] ? shipAcctNo[1].toString().trim() : "");
  //   }
  // }, [shipperAcct]);

  const onSort = async (column) => {
    setLoading(true);
    if (isAscending) {
      if (column === 0) {
        const tempList = await sortList.sort((a, b) => {
          if (a.tracking < b.tracking) {
            return -1;
          }
          if (a.tracking > b.tracking) {
            return 1;
          }
          return 0;
        });
        setSortList(tempList);
      } else if (column === 1) {
        const tempList = await sortList.sort((a, b) => {
          if (a.encodedDate < b.encodedDate) {
            return -1;
          }
          if (a.encodedDate > b.encodedDate) {
            return 1;
          }
          return 0;
        });
        setSortList(tempList);
      } else if (column === 2) {
        const tempList = await sortList.sort((a, b) => {
          if (a.deliveryReq < b.deliveryReq) {
            return -1;
          }
          if (a.deliveryReq > b.deliveryReq) {
            return 1;
          }
          return 0;
        });
        setSortList(tempList);
      } else if (column === 3) {
        const tempList = await sortList.sort((a, b) => {
          if (a.consigneeName < b.consigneeName) {
            return -1;
          }
          if (a.consigneeName > b.consigneeName) {
            return 1;
          }
          return 0;
        });
        setSortList(tempList);
      } else if (column === 4 || column === 6) {
        const tempList = await sortList.sort((a, b) => {
          if (a.consigneeContact < b.consigneeContact) {
            return -1;
          }
          if (a.consigneeContact > b.consigneeContact) {
            return 1;
          }
          return 0;
        });
        setSortList(tempList);
      } else if (column === 5) {
        const tempList = await sortList.sort((a, b) => {
          if (a.consigneeAddress < b.consigneeAddress) {
            return -1;
          }
          if (a.consigneeAddress > b.consigneeAddress) {
            return 1;
          }
          return 0;
        });
        setSortList(tempList);
      } else if (column === 7) {
        const tempList = await sortList.sort((a, b) => {
          if (a.acceptanceDate < b.acceptanceDate) {
            return -1;
          }
          if (a.acceptanceDate > b.acceptanceDate) {
            return 1;
          }
          return 0;
        });
        setSortList(tempList);
      } else if (column === 8) {
        const tempList = await sortList.sort((a, b) => {
          if (a.status < b.status) {
            return -1;
          }
          if (a.status > b.status) {
            return 1;
          }
          return 0;
        });
        setSortList(tempList);
      } else if (column === 9) {
        const tempList = await sortList.sort((a, b) => {
          if (a.statusDate < b.statusDate) {
            return -1;
          }
          if (a.statusDate > b.statusDate) {
            return 1;
          }
          return 0;
        });
        setSortList(tempList);
      } else if (column === 10) {
        const tempList = await sortList.sort((a, b) => {
          if (a.reason < b.reason) {
            return -1;
          }
          if (a.reason > b.reason) {
            return 1;
          }
          return 0;
        });
        setSortList(tempList);
      }
      setTimeout(() => {
        setLoading(false);
        setAscending(!isAscending);
      }, 1000);
    } else {
      if (column === 0) {
        const tempList = await sortList.sort((a, b) => {
          if (a.tracking > b.tracking) {
            return -1;
          }
          if (a.tracking < b.tracking) {
            return 1;
          }
          return 0;
        });
        setSortList(tempList);
      } else if (column === 1) {
        const tempList = await sortList.sort((a, b) => {
          if (a.encodedDate > b.encodedDate) {
            return -1;
          }
          if (a.encodedDate < b.encodedDate) {
            return 1;
          }
          return 0;
        });
        setSortList(tempList);
      } else if (column === 2) {
        const tempList = await sortList.sort((a, b) => {
          if (a.deliveryReq > b.deliveryReq) {
            return -1;
          }
          if (a.deliveryReq < b.deliveryReq) {
            return 1;
          }
          return 0;
        });
        setSortList(tempList);
      } else if (column === 3) {
        const tempList = await sortList.sort((a, b) => {
          if (a.consigneeName > b.consigneeName) {
            return -1;
          }
          if (a.consigneeName < b.consigneeName) {
            return 1;
          }
          return 0;
        });
        setSortList(tempList);
      } else if (column === 4 || column === 6) {
        const tempList = await sortList.sort((a, b) => {
          if (a.consigneeContact > b.consigneeContact) {
            return -1;
          }
          if (a.consigneeContact < b.consigneeContact) {
            return 1;
          }
          return 0;
        });
        setSortList(tempList);
      } else if (column === 5) {
        const tempList = await sortList.sort((a, b) => {
          if (a.consigneeAddress > b.consigneeAddress) {
            return -1;
          }
          if (a.consigneeAddress < b.consigneeAddress) {
            return 1;
          }
          return 0;
        });
        setSortList(tempList);
      } else if (column === 7) {
        const tempList = await sortList.sort((a, b) => {
          if (a.acceptanceDate > b.acceptanceDate) {
            return -1;
          }
          if (a.acceptanceDate < b.acceptanceDate) {
            return 1;
          }
          return 0;
        });
        setSortList(tempList);
      } else if (column === 8) {
        const tempList = await sortList.sort((a, b) => {
          if (a.status > b.status) {
            return -1;
          }
          if (a.status < b.status) {
            return 1;
          }
          return 0;
        });
        setSortList(tempList);
      } else if (column === 9) {
        const tempList = await sortList.sort((a, b) => {
          if (a.statusDate > b.statusDate) {
            return -1;
          }
          if (a.statusDate < b.statusDate) {
            return 1;
          }
          return 0;
        });
        setSortList(tempList);
      } else if (column === 10) {
        const tempList = await sortList.sort((a, b) => {
          if (a.reason > b.reason) {
            return -1;
          }
          if (a.reason < b.reason) {
            return 1;
          }
          return 0;
        });
        setSortList(tempList);
      }
      setTimeout(() => {
        setLoading(false);
        setAscending(!isAscending);
      }, 1000);
    }
  };

  const GetReportDetail = async () => {
    setLoading(true);
    var status = "";
    if (deliveryStatus === "In-transit") {
      status = "ForUpdate";
    } else if (deliveryStatus === "For Disposition") {
      status = "Pending";
    } else if (deliveryStatus === "Delivered") {
      status = "Delivered";
    } else if (deliveryStatus === "Returned") {
      status = "RTS";
    } else if (deliveryStatus === "Others") {
      status = "LOST";
    } else if (deliveryStatus === "ViewAll") {
      status = "ViewAll";
    }
    // var shipAcctNo = shipperAcct ? shipperAcct.split("|") : [];
    // var actNo = shipAcctNo[1] ? shipAcctNo[1].toString().trim() : "";

    try {
      const response = await axiosCAPApiPrivate.post("/api/GetReportDetail", {
        CustomerAccountNo: shipperAcctNo, //searchParams.get("customerAccountNo"),
        Column: status,
        FromDate: dateFrom,
        ToDate: dateFrom,
      });

      const data = response.data;

      if (data.Code === "200") {
        setSortList(data.Data);
        var refusedToAcceptCount = 0;
        refusedToAcceptCount += data.Data.filter((x) =>
          x.status.toUpperCase().includes("REFUSE")
        ).length;

        var incompleteIncorrectAddressCount = 0;
        incompleteIncorrectAddressCount += data.Data.filter((x) =>
          x.status.toUpperCase().includes("INCOMPLETE")
        ).length;
        incompleteIncorrectAddressCount += data.Data.filter((x) =>
          x.status.toUpperCase().includes("INCORRECT")
        ).length;

        var consigneeUnknownCount = 0;
        consigneeUnknownCount += data.Data.filter((x) =>
          x.status.toUpperCase().includes("UNKNOWN")
        ).length;

        setRefusedToAccept(refusedToAcceptCount);
        setIncompleteIncorrectAddress(incompleteIncorrectAddressCount);
        setConsigneeUnknown(consigneeUnknownCount);
      }
      setLoading(false);
    } catch (error) {
      setToastKind("error");
      setToastTitle("Delivery Report Failed!");
      setToastDesc(error.message);
      setTimeout(() => {
        setLoading(false);
      }, 1000);
      setTimeout(() => {
        setToastOpen(true);
      }, 1500);
      setTimeout(() => {
        setToastOpen(false);
      }, 3000);
    }
  };

  // React.useEffect(() => {
  //   if(shipperAcctNo) {
  //     setSortList([]);
  //     GetReportDetail();
  //   }
  // }, [shipperAcctNo]);
  // React.useEffect(() => {
  //     setSortList([]);
  //     GetReportDetail();

  //     GetDeliveredExcelDetail();
  //     GetForDispositionExcelDetail();
  //     GetInTransitExcelDetail();
  //     GetReturnedExcelDetail();
  //     GetOthersExcelDetail();
  // }, []);

  const onDispoChange = (tracking, value) => {
    const newSortList = sortList.map((item) => {
      if (item.tracking === tracking) {
        return { ...item, disposition: value };
      }
      return item;
    });

    setSortList(newSortList);
  };

  const GetDeliveredExcelDetail = async () => {
    // var shipAcctNo = shipperAcct ? shipperAcct.split("|") : [];
    // var actNo = shipAcctNo[1] ? shipAcctNo[1].toString().trim() : "";

    try {
      const response = await axiosCAPApiPrivate.post("/api/GetReportDetail", {
        CustomerAccountNo: shipperAcctNo, //searchParams.get("customerAccountNo"),
        Column: "Delivered",
        FromDate: dateFrom,
        ToDate: dateFrom,
      });

      const data = response.data;

      if (data.Code === "200") {
        setDeliveredExcelList(data.Data);
      }
    } catch (error) {}
  };

  const GetForDispositionExcelDetail = async () => {
    // var shipAcctNo = shipperAcct ? shipperAcct.split("|") : [];
    // var actNo = shipAcctNo[1] ? shipAcctNo[1].toString().trim() : "";

    try {
      const response = await axiosCAPApiPrivate.post("/api/GetReportDetail", {
        CustomerAccountNo: shipperAcctNo, //searchParams.get("customerAccountNo"),
        Column: "Pending",
        FromDate: dateFrom,
        ToDate: dateFrom,
      });

      const data = response.data;

      if (data.Code === "200") {
        setForDispositionExcelList(data.Data);
      }
    } catch (error) {}
  };

  const GetInTransitExcelDetail = async () => {
    // var shipAcctNo = shipperAcct ? shipperAcct.split("|") : [];
    // var actNo = shipAcctNo[1] ? shipAcctNo[1].toString().trim() : "";

    try {
      const response = await axiosCAPApiPrivate.post("/api/GetReportDetail", {
        CustomerAccountNo: shipperAcctNo, //searchParams.get("customerAccountNo"),
        Column: "ForUpdate",
        FromDate: dateFrom,
        ToDate: dateFrom,
      });

      const data = response.data;

      if (data.Code === "200") {
        setInTransitExcelList(data.Data);
      }
    } catch (error) {}
  };

  const GetReturnedExcelDetail = async () => {
    // var shipAcctNo = shipperAcct ? shipperAcct.split("|") : [];
    // var actNo = shipAcctNo[1] ? shipAcctNo[1].toString().trim() : "";

    try {
      const response = await axiosCAPApiPrivate.post("/api/GetReportDetail", {
        CustomerAccountNo: shipperAcctNo, //searchParams.get("customerAccountNo"),
        Column: "RTS",
        FromDate: dateFrom,
        ToDate: dateFrom,
      });

      const data = response.data;

      if (data.Code === "200") {
        setReturnedExcelList(data.Data);
      }
    } catch (error) {}
  };

  const GetOthersExcelDetail = async () => {
    // var shipAcctNo = shipperAcct ? shipperAcct.split("|") : [];
    // var actNo = shipAcctNo[1] ? shipAcctNo[1].toString().trim() : "";

    const data = {
      CustomerAccountNo: shipperAcctNo, //searchParams.get("customerAccountNo"),
      Column: "LOST",
      FromDate: dateFrom,
      ToDate: dateFrom,
    };

    try {
      const response = await axiosCAPApiPrivate.post("/api/GetReportDetail", {
        CustomerAccountNo: shipperAcctNo, //searchParams.get("customerAccountNo"),
        Column: "LOST",
        FromDate: dateFrom,
        ToDate: dateFrom,
      });

      const data = response.data;

      if (data.Code === "200") {
        setOthersExcelList(data.Data);
      }
    } catch (error) {}
  };

  const exportToCSV = () => {
    const Excel = require("exceljs");
    const workbook = new Excel.Workbook();

    //#region Delivery Report
    const deliveryreport = workbook.addWorksheet("Delivery Report");
    deliveryreport.columns = [
      { header: "Tracking No.", key: "tracking", width: 15 },
      { header: "Encoded Date", key: "encodedDate", width: 20 },
      { header: "Delivery Requirement", key: "deliveryReq", width: 25 },
      { header: "Consignee Name", key: "consigneeName", width: 35 },
      { header: "Consignee Mobile", key: "consigneeContact", width: 18 },
      { header: "Consignee Address", key: "consigneeAddress", width: 50 },
      { header: "Consignee Mobile No.", key: "consigneeContact", width: 22 },
      { header: "Acceptance Date", key: "acceptanceDate", width: 20 },
      { header: "Last Status", key: "status", width: 30 },
      { header: "Last Status Date", key: "statusDate", width: 15 },
      { header: "Reason", key: "reason", width: 30 },
    ];

    deliveryreport.insertRow(1, "");
    deliveryreport.insertRow(2, "");
    deliveryreport.getCell("A2").value = "DELIVERY REPORT";
    deliveryreport.getCell("A2").font = { bold: true };
    deliveryreport.insertRow(3, "");
    deliveryreport.mergeCells("A2:B2");
    deliveryreport.getRow(4).font = { bold: true };
    addBorder(deliveryreport, 4);

    var row = 5;
    DeliveredExcelList.map((list, index) => {
      deliveryreport.addRow({
        tracking: list.tracking,
        encodedDate: list.encodedDate,
        deliveryReq: list.deliveryReq,
        consigneeName: list.consigneeName,
        consigneeContact: list.consigneeContact,
        consigneeAddress: list.consigneeAddress,
        consigneeContact: list.consigneeContact,
        acceptanceDate: list.acceptanceDate,
        status: list.status,
        statusDate: list.statusDate,
        reason: list.reason,
      });
      addBorder(deliveryreport, row);
      row++;
    });
    //#endregion Delivery Report

    //#region For Disposition
    const forDisposition = workbook.addWorksheet("For Disposition");
    forDisposition.columns = deliveryreport.columns;
    forDisposition.insertRow(1, "");
    forDisposition.insertRow(2, "");
    forDisposition.getCell("A2").value = "FOR DISPOSITION";
    forDisposition.getCell("A2").font = { bold: true };
    forDisposition.insertRow(3, "");
    forDisposition.mergeCells("A2:B2");
    forDisposition.getRow(4).font = { bold: true };
    addBorder(forDisposition, 4);

    var row = 5;
    ForDispositionExcelList.map((list, index) => {
      forDisposition.addRow({
        tracking: list.tracking,
        encodedDate: list.encodedDate,
        deliveryReq: list.deliveryReq,
        consigneeName: list.consigneeName,
        consigneeContact: list.consigneeContact,
        consigneeAddress: list.consigneeAddress,
        consigneeContact: list.consigneeContact,
        acceptanceDate: list.acceptanceDate,
        status: list.status,
        statusDate: list.statusDate,
        reason: list.reason,
      });
      addBorder(forDisposition, row);
      row++;
    });
    //#endregion For Disposition

    //#region In-Transit
    const inTransit = workbook.addWorksheet("In-Transit");
    inTransit.columns = deliveryreport.columns;
    inTransit.insertRow(1, "");
    inTransit.insertRow(2, "");
    inTransit.getCell("A2").value = "IN-TRANSIT";
    inTransit.getCell("A2").font = { bold: true };
    inTransit.insertRow(3, "");
    inTransit.mergeCells("A2:B2");
    inTransit.getRow(4).font = { bold: true };
    addBorder(inTransit, 4);

    var row = 5;
    InTransitExcelList.map((list, index) => {
      inTransit.addRow({
        tracking: list.tracking,
        encodedDate: list.encodedDate,
        deliveryReq: list.deliveryReq,
        consigneeName: list.consigneeName,
        consigneeContact: list.consigneeContact,
        consigneeAddress: list.consigneeAddress,
        consigneeContact: list.consigneeContact,
        acceptanceDate: list.acceptanceDate,
        status: list.status,
        statusDate: list.statusDate,
        reason: list.reason,
      });
      addBorder(inTransit, row);
      row++;
    });
    //#endregion In-Transit

    //#region RTS
    const rts = workbook.addWorksheet("RTS");
    rts.columns = deliveryreport.columns;
    rts.insertRow(1, "");
    rts.insertRow(2, "");
    rts.getCell("A2").value = "RTS";
    rts.getCell("A2").font = { bold: true };
    rts.insertRow(3, "");
    rts.mergeCells("A2:B2");
    rts.getRow(4).font = { bold: true };
    addBorder(rts, 4);

    var row = 5;
    ReturnedExcelList.map((list, index) => {
      rts.addRow({
        tracking: list.tracking,
        encodedDate: list.encodedDate,
        deliveryReq: list.deliveryReq,
        consigneeName: list.consigneeName,
        consigneeContact: list.consigneeContact,
        consigneeAddress: list.consigneeAddress,
        consigneeContact: list.consigneeContact,
        acceptanceDate: list.acceptanceDate,
        status: list.status,
        statusDate: list.statusDate,
        reason: list.reason,
      });
      addBorder(rts, row);
      row++;
    });
    //#endregion RTS

    //#region LOST
    const lost = workbook.addWorksheet("Lost");
    lost.columns = deliveryreport.columns;
    lost.insertRow(1, "");
    lost.insertRow(2, "");
    lost.getCell("A2").value = "RTS";
    lost.getCell("A2").font = { bold: true };
    lost.insertRow(3, "");
    lost.mergeCells("A2:B2");
    lost.getRow(4).font = { bold: true };
    addBorder(lost, 4);

    var row = 5;
    OthersExcelList.map((list, index) => {
      lost.addRow({
        tracking: list.tracking,
        encodedDate: list.encodedDate,
        deliveryReq: list.deliveryReq,
        consigneeName: list.consigneeName,
        consigneeContact: list.consigneeContact,
        consigneeAddress: list.consigneeAddress,
        consigneeContact: list.consigneeContact,
        acceptanceDate: list.acceptanceDate,
        status: list.status,
        statusDate: list.statusDate,
        reason: list.reason,
      });
      addBorder(lost, row);
      row++;
    });
    //#endregion LOST

    workbook.xlsx
      .writeBuffer()
      .then((buffer) =>
        FileSaver.saveAs(new Blob([buffer]), `${dateFrom} - ${dateFrom}.xlsx`)
      )
      .catch((err) => console.log("Error writing excel export", err));
  };

  const addBorder = (sheet, row) => {
    sheet.getCell("A" + row).border = {
      top: { style: "thin" },
      left: { style: "thin" },
      bottom: { style: "thin" },
      right: { style: "thin" },
    };
    sheet.getCell("B" + row).border = {
      top: { style: "thin" },
      left: { style: "thin" },
      bottom: { style: "thin" },
      right: { style: "thin" },
    };
    sheet.getCell("C" + row).border = {
      top: { style: "thin" },
      left: { style: "thin" },
      bottom: { style: "thin" },
      right: { style: "thin" },
    };
    sheet.getCell("D" + row).border = {
      top: { style: "thin" },
      left: { style: "thin" },
      bottom: { style: "thin" },
      right: { style: "thin" },
    };
    sheet.getCell("E" + row).border = {
      top: { style: "thin" },
      left: { style: "thin" },
      bottom: { style: "thin" },
      right: { style: "thin" },
    };
    sheet.getCell("F" + row).border = {
      top: { style: "thin" },
      left: { style: "thin" },
      bottom: { style: "thin" },
      right: { style: "thin" },
    };
    sheet.getCell("G" + row).border = {
      top: { style: "thin" },
      left: { style: "thin" },
      bottom: { style: "thin" },
      right: { style: "thin" },
    };
    sheet.getCell("H" + row).border = {
      top: { style: "thin" },
      left: { style: "thin" },
      bottom: { style: "thin" },
      right: { style: "thin" },
    };
    sheet.getCell("I" + row).border = {
      top: { style: "thin" },
      left: { style: "thin" },
      bottom: { style: "thin" },
      right: { style: "thin" },
    };
    sheet.getCell("J" + row).border = {
      top: { style: "thin" },
      left: { style: "thin" },
      bottom: { style: "thin" },
      right: { style: "thin" },
    };
    sheet.getCell("K" + row).border = {
      top: { style: "thin" },
      left: { style: "thin" },
      bottom: { style: "thin" },
      right: { style: "thin" },
    };
  };

  return (
    <FlexRow className="w-full h-full items-center justify-between">
      {/* Side Navigation */}
      <NavbarMain route="Home" />

      {/* Main Dashboard */}
      <FlexColumn className="w-11/12 h-full">
        {/* Top Navigation */}
        <TopNavBar showMenu={true} showNotifBell={true} showSearchBar={true} />
        {/* Body */}
        <Div className="w-full h-full overflow-y-auto p-10 bg-white">
          <FlexRow className="justify-between w-full">
            {deliveryStatus === "For Disposition" ? (
              <>
                <Div className="shadow-lg rounded-lg border border-grey-400 p-5 w-2/5 h-48">
                  {deliveryStatus === "ViewAll" ? (
                    <Text className="text-secondary-100 text-xs font-bold">
                      View All {dateFrom}
                    </Text>
                  ) : (
                    <>
                      {deliveryStatus === "For Disposition" ? (
                        <Text className="text-secondary-100 text-xs font-bold">
                          {deliveryStatus} Report
                        </Text>
                      ) : (
                        <Text className="text-secondary-100 text-xs font-bold">
                          {deliveryStatus} From {dateFrom} To {dateFrom}
                        </Text>
                      )}
                    </>
                  )}
                </Div>
                <FlexColumn className="justify-between shadow-lg rounded-lg border border-grey-400 p-5 w-1/2 h-48">
                  <Text className="text-secondary-100 text-xs font-bold">
                    Summary per Reason
                    <ForDispositionDataRow
                      reason={""}
                      count={"Count"}
                      percent={"%"}
                    />
                  </Text>
                  <Div className="overflow-scroll w-full h-28">
                    {/* {tempDispostionData.map(list => (
                    <DeliveryDataRow
                      deliveryId={list.deliveryId}
                      deliverySize={list.deliverySize}
                      logisticsType={list.logisticsType}
                      deliveryDate={list.deliveryDate}
                      transactionDate={list.transactionDate}
                      clientNameFrom={list.clientNameFrom}
                      clientAddressFrom={list.clientAddressFrom}
                      clientNameTo={list.clientNameTo}
                      clientAddressTo={list.clientAddressTo}
                    />
                  ))} */}
                    <>
                      <ForDispositionDataRow
                        reason={"Refused to Accept"}
                        count={refusedToAccept}
                        // percent={Number(((refusedToAccept / sortList.length) * 100)).toFixed(2) + '%'}
                        percent={
                          Math.round(
                            (refusedToAccept / sortList.length) * 100
                          ) + "%"
                        }
                      />
                      <ForDispositionDataRow
                        reason={"Incomplete/Incorrect Address"}
                        count={incompleteIncorrectAddress}
                        // percent={Number(((incompleteIncorrectAddress / sortList.length) * 100)).toFixed(2) + '%'}
                        percent={
                          Math.round(
                            (incompleteIncorrectAddress / sortList.length) * 100
                          ) + "%"
                        }
                      />
                      <ForDispositionDataRow
                        reason={"Consignee Unknown"}
                        count={consigneeUnknown}
                        //percent={Number(((consigneeUnknown / sortList.length) * 100)).toFixed(2) + '%'}
                        percent={
                          Math.round(
                            (consigneeUnknown / sortList.length) * 100
                          ) + "%"
                        }
                      />
                    </>
                  </Div>
                </FlexColumn>
              </>
            ) : (
              <>
                <Div className="shadow-lg rounded-lg border border-grey-400 p-5 w-3/5 h-48">
                  {deliveryStatus === "ViewAll" ? (
                    <Text className="text-secondary-100 text-xs font-bold">
                      View All {dateFrom}
                    </Text>
                  ) : (
                    <Text className="text-secondary-100 text-xs font-bold">
                      {deliveryStatus} From {dateFrom} To {dateFrom}
                    </Text>
                  )}
                </Div>
                <FlexColumn className="justify-between shadow-lg rounded-lg border border-grey-400 p-5 w-1/3 h-48">
                  <Text className="text-secondary-100 text-xs font-bold">
                    Summary per Reason
                  </Text>
                  <Div className="overflow-scroll w-full h-28">
                    <DeliveryDataRow
                      deliveryStatus={deliveryStatus}
                      count={searchParams.get("count")}
                      exportToCSV={exportToCSV}
                    />
                  </Div>
                </FlexColumn>
              </>
            )}
          </FlexRow>
          <Spacer className="h-10" />
          <FlexColumn className="w-full">
            <Div className="w-full overflow-x-scroll border border-grey-400 rounded-lg shadow-lg p-5">
              <FlexRow className="items-center justify-between w-2800px">
                <ColumnHeader
                  title="Tracking No."
                  onClick={() => onSort(0)}
                  containerClass="hover:bg-grey-400 rounded-full w-200px px-4 py-2"
                  titleClassName=""
                />
                <ColumnHeader
                  title="Encoded Date"
                  onClick={() => onSort(1)}
                  containerClass="hover:bg-grey-400 rounded-full w-200px px-4 py-2"
                  titleClassName=""
                />
                <ColumnHeader
                  title="Delivery Requirement"
                  onClick={() => onSort(2)}
                  containerClass="hover:bg-grey-400 rounded-full w-200px px-4 py-2"
                  titleClassName=""
                />
                <ColumnHeader
                  title="Consignee Name"
                  onClick={() => onSort(3)}
                  containerClass="hover:bg-grey-400 rounded-full w-200px px-4 py-2"
                  titleClassName=""
                />
                <ColumnHeader
                  title="Consignee Mobile"
                  onClick={() => onSort(4)}
                  containerClass="hover:bg-grey-400 rounded-full w-200px px-4 py-2"
                  titleClassName=""
                />
                <Div className="w-400px">
                  <ColumnHeader
                    title="Consignee Address"
                    onClick={() => onSort(5)}
                    containerClass="hover:bg-grey-400 rounded-full w-200px px-4 py-2 mx-auto"
                    titleClassName=""
                  />
                </Div>
                <ColumnHeader
                  title="Consignee Mobile No."
                  onClick={() => onSort(6)}
                  containerClass="hover:bg-grey-400 rounded-full w-200px px-4 py-2"
                  titleClassName=""
                />
                <ColumnHeader
                  title="Acceptance Date"
                  onClick={() => onSort(7)}
                  containerClass="hover:bg-grey-400 rounded-full w-200px px-4 py-2"
                  titleClassName=""
                />
                <ColumnHeader
                  title="Last Status"
                  onClick={() => onSort(8)}
                  containerClass="hover:bg-grey-400 rounded-full w-200px px-4 py-2"
                  titleClassName=""
                />
                <ColumnHeader
                  title="Last Status Date"
                  onClick={() => onSort(9)}
                  containerClass="hover:bg-grey-400 rounded-full w-200px px-4 py-2"
                  titleClassName=""
                />
                <ColumnHeader
                  title="Reason"
                  onClick={() => onSort(10)}
                  containerClass="hover:bg-grey-400 rounded-full w-200px px-4 py-2"
                  titleClassName=""
                />
                {deliveryStatus === "For Disposition" ? (
                  <Text className="text-secondary-100 text-xs text-center font-bold w-200px px-4 py-2">
                    Disposition
                  </Text>
                ) : (
                  <></>
                )}
                {deliveryStatus !== "ViewAll" ? (
                  <Text className="text-secondary-100 text-xs text-center font-bold w-200px px-4 py-2">
                    Action
                  </Text>
                ) : (
                  <></>
                )}
              </FlexRow>
              <Divider className="text-grey-100 my-2 w-2800px" />

              <LBCModal
                description={toastDesc}
                isOpen={isToastOpen}
                onClose={() => setToastOpen(false)}
                title={toastTitle}
                toastKind={toastKind}
              />

              {loading ? (
                <Text className="text-red-400 text-center flex flex-row justify-center items-center my-20">
                  <ImSpinner2 className="animate-spin mr-2 text-2xl desktop:text-3xl" />
                  Loading data ...
                </Text>
              ) : (
                <>
                  {newItems.map((list) => (
                    <DeliveryDataTableRow
                      data={list}
                      status={deliveryStatus}
                      onDispoChange={onDispoChange}
                    />
                  ))}
                </>
              )}
            </Div>
            <Text className="text-red-400 text-xs font-bold my-2">
              {`Disclaimer: Coverage is Twelve (12) months from encoded date.`}
            </Text>
            <Div className="w-full">
              <Pagination
                currentPage={currentPage}
                itemsPerPage={articlesPerPage}
                paginate={paginate}
                totalItems={sortList.length}
              />
            </Div>
          </FlexColumn>
        </Div>
      </FlexColumn>
    </FlexRow>
  );
};
