import React from "react";
import {
  Div,
  Divider,
  FlexColumn,
  FlexRow,
  Spacer,
} from "../../core/Containers";
import { NavbarMain } from "../../components/Navigation/NavbarMain";
import { TopNavBar } from "../../components/Navigation/TopNavBar";
import { useLocation, useNavigate } from "react-router-dom";
import { Tab } from "@headlessui/react";
import { classNames } from "../../helpers/ClassNames";
import { ActiveTab, PastTab, ForDispoTab } from "./TransactionTabs";
import { Span, Text } from "../../core/Text";
import moment from "moment";

export const Transaction = () => {
  const navigate = useNavigate();
  const { search } = useLocation();
  const query = new URLSearchParams(search);
  const actionQuery = query.get("option");

  const [selectedIndex, setSelectedIndex] = React.useState(0);

  const [dateFrom, setDateFrom] = React.useState(
    moment().subtract(3, "months").format("yyyy-MM-DD")
  );
  const [dateTo, setDateTo] = React.useState(
    moment(new Date()).format("yyyy-MM-DD")
  );

  const tabs = [
    {
      id: "activityAllTransActive",
      name: "Active",
    },
    {
      id: "activityAllTransPast",
      name: "Past",
    },
    {
      id: "activityAllTransForDispo",
      name: "For Disposition",
    },
  ];

  React.useEffect(() => {
    if (actionQuery === "0") {
      setSelectedIndex(0);
      navigate("/transaction");
    } else if (actionQuery === "1") {
      setSelectedIndex(1);
      navigate("/transaction");
    } else if (actionQuery === "2") {
      setSelectedIndex(2);
      navigate("/transaction");
    }
  }, [actionQuery, navigate]);

  return (
    <FlexRow className="w-full h-full items-center justify-between">
      {/* Side Navigation */}
      <NavbarMain route="Activity" />

      {/* Main Dashboard */}
      <FlexColumn className="w-11/12 h-full">
        {/* Top Navigation */}
        <TopNavBar showMenu={true} showNotifBell={true} showSearchBar={true} />

        {/* Body */}
        <Div className="w-full h-full overflow-y-auto p-10 bg-white">
          <FlexRow className="items-center justify-between">
            <Text className="text-secondary-200 font-bold">
              All Transactions
            </Text>
            <FlexRow className="items-center">
              <Text className="text-secondary-200">From:</Text>
              <input
                className="text-sm w-40 mx-2 block rounded-lg"
                type="date"
                value={dateFrom}
                onChange={(e) => setDateFrom(e.target.value)}
              />
              <Spacer className="w-8" />
              <Text className="text-secondary-200">To:</Text>
              <input
                className="text-sm w-40 mx-2 block rounded-lg"
                type="date"
                value={dateTo}
                onChange={(e) => setDateTo(e.target.value)}
              />
            </FlexRow>
          </FlexRow>

          <Spacer className="h-2" />

          <FlexRow className="w-full">
            <Tab.Group
              selectedIndex={selectedIndex}
              onChange={setSelectedIndex}
            >
              <FlexColumn className="w-full">
                <Tab.List className="tabletWide:space-x-4">
                  {tabs.map((tab, i) => (
                    <Tab
                      className={({ selected }) =>
                        classNames(
                          "p-2 font-semibold w-auto focus:border-b-4 focus:border-orange-400 ring:border-orange-400 outline-none",
                          selected
                            ? "text-orange-400 border-b-4 border-orange-400"
                            : "text-secondary-200  hover:text-secondary-100"
                        )
                      }
                      key={i}
                    >
                      {tab.name}
                    </Tab>
                  ))}
                </Tab.List>
                <Divider className="text-grey-400" />
                <Tab.Panels className="w-full">
                  <Tab.Panel className={`w-full`}>
                    <ActiveTab dateFrom={dateFrom} dateTo={dateTo} />
                  </Tab.Panel>
                  <Tab.Panel className={`w-full`}>
                    <PastTab dateFrom={dateFrom} dateTo={dateTo} />
                  </Tab.Panel>
                  <Tab.Panel className={`w-full`}>
                    <ForDispoTab dateFrom={dateFrom} dateTo={dateTo} />
                  </Tab.Panel>
                </Tab.Panels>
              </FlexColumn>
            </Tab.Group>
          </FlexRow>
        </Div>
      </FlexColumn>
    </FlexRow>
  );
};
