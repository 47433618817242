import React from "react";
import { Cookies } from "react-cookie";
import { Div, FlexColumn, FlexRow, Spacer } from "../../core/Containers";
import { NavbarMain } from "../../components/Navigation/NavbarMain";
import { TopNavBar } from "../../components/Navigation/TopNavBar";
import { Analytics } from "./DashboardHome/Analytics";
import { LBCCarouselSlider } from "./DashboardHome/Carousel";
import { PerformanceReports } from "./DashboardHome/PerformanceReports";
import { RemittanceHistory } from "./DashboardHome/RemittanceHistory";
import { RecentBookingReports } from "./DashboardHome/RecentBookingReports";
import { useLocation } from "react-router-dom";
import { useNavigate } from "react-router-dom";

const cookies = new Cookies();

export const DashboardHome = () => {
  const { search } = useLocation();
  const navigate = useNavigate();
  const query = new URLSearchParams(search);
  const actionQuery = query.get("status");

  const [reports, setReports] = React.useState("Delivery");
  const [shipperAcct, setShipperAcct] = React.useState("");

  const token = cookies.get("accessToken", { path: "/" });

  if (!token) {
    navigate("/");
  }

  React.useEffect(() => {
    setShipperAcct(
      cookies.get("shipperAcct", { path: "/" })
        ? cookies.get("shipperAcct", { path: "/" })
        : ""
    );
  }, []);

  return (
    <FlexRow className="w-full h-full items-center justify-between">
      {/* Side Navigation */}
      <NavbarMain route="Home" />

      {/* Main Dashboard */}
      <FlexColumn className="w-full h-full">
        {/* Top Navigation */}
        <TopNavBar
          showMenu={true}
          showNotifBell={true}
          showSearchBar={true}
          setShipperAcct={(acct) => setShipperAcct(acct)}
        />

        {/* Dashboard */}
        <Div className="w-full h-full overflow-y-auto p-10 bg-white">
          <FlexRow className="justify-between w-full h-700px">
            <Analytics shipperAcct={shipperAcct} />
            <Div className="mx-auto w-1/2">
              <LBCCarouselSlider />
              <Spacer className="h-10" />

              {actionQuery === "remittance-status" ? (
                <FlexRow className="w-full">
                  <RecentBookingReports />
                  <RemittanceHistory />
                </FlexRow>
              ) : (
                <PerformanceReports shipperAcct={shipperAcct} />
              )}

              <Spacer className="h-10" />
            </Div>
          </FlexRow>
        </Div>
      </FlexColumn>
    </FlexRow>
  );
};
