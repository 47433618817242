import { Cookies } from "react-cookie";
import { httpBase } from "../http-common";
import { httpCommonRef } from "../http-common";
import { httpLexaApi } from "../http-common";

const cookies = new Cookies();

class BookNowService {
  getProvinces(data) {
    if (data.sendDelMethod === "RIDER PICK UP") {
      return httpCommonRef.get(
        "/RequestRefFirstMileProvince?token=TEJDdmlzdHJhcGhfdXNlclYxc3RSQHBoMjAxNw=="
      );
    } else if (data.sendDelMethod === "DROP-OFF") {
      return httpCommonRef.get(
        "/RequestRefProvince?token=TEJDdmlzdHJhcGhfdXNlclYxc3RSQHBoMjAxNw=="
      );
    } else if (data.sendDelMethod === "HOME DELIVERY") {
      return httpCommonRef.get(
        "/RequestRefProvince?token=TEJDdmlzdHJhcGhfdXNlclYxc3RSQHBoMjAxNw=="
      );
    } else {
      return httpCommonRef.get(
        "/RequestRefProvinceWithActiveBranch?token=TEJDdmlzdHJhcGhfdXNlclYxc3RSQHBoMjAxNw=="
      );
    }
  }

  getCities(data) {
    if (data.sendDelMethod === "RIDER PICK UP") {
      return httpCommonRef.get(
        `/RequestRefFirstMileCity?token=TEJDdmlzdHJhcGhfdXNlclYxc3RSQHBoMjAxNw==&provinceid=${data.provinceId}`
      );
    } else if (data.sendDelMethod === "DROP-OFF") {
      return httpCommonRef.get(
        `/RequestRefCities?token=TEJDdmlzdHJhcGhfdXNlclYxc3RSQHBoMjAxNw==&provinceid=${data.provinceId}`
      );
    } else if (data.sendDelMethod === "HOME DELIVERY") {
      return httpCommonRef.get(
        `/RequestRefCities?token=TEJDdmlzdHJhcGhfdXNlclYxc3RSQHBoMjAxNw==&provinceid=${data.provinceId}`
      );
    } else {
      return httpCommonRef.get(
        `/RequestRefCitiesWithActiveBranch?token=TEJDdmlzdHJhcGhfdXNlclYxc3RSQHBoMjAxNw==&provinceid=${data.provinceId}`
      );
    }
  }

  getBarangays(data) {
    if (data.sendDelMethod === "RIDER PICK UP") {
      return httpCommonRef.get(
        `/RequestRefFirstMileBarangay?token=TEJDdmlzdHJhcGhfdXNlclYxc3RSQHBoMjAxNw==&cityid=${data.cityId}`
      );
    } else if (data.sendDelMethod === "DROP-OFF") {
      return httpCommonRef.get(
        `/RequestRefBarangayPSGC?token=TEJDdmlzdHJhcGhfdXNlclYxc3RSQHBoMjAxNw==&cityid=${data.cityId}`
      );
    } else if (data.sendDelMethod === "HOME DELIVERY") {
      return httpCommonRef.get(
        `/RequestRefBarangayPSGC?token=TEJDdmlzdHJhcGhfdXNlclYxc3RSQHBoMjAxNw==&cityid=${data.cityId}`
      );
    } else {
      return httpCommonRef.get(
        `/RequestRefBranch?token=TEJDdmlzdHJhcGhfdXNlclYxc3RSQHBoMjAxNw==&provinceid=${data.provinceId}&cityid=${data.cityId}`
      );
    }
  }

  RequestSupplies(data) {
    const token = cookies.get("accessToken");
    const config = {
      headers: {
        Authorization: `Bearer ${token.accessToken}`,
      },
    };

    return httpBase.post("/api/RequestSupplies", data, config);
  }

  GetReceiverDetails(data) {
    const token = cookies.get("accessToken");
    const config = {
      headers: {
        Authorization: `Bearer ${token.accessToken}`,
      },
    };

    return httpBase.get("/api/GetReceiverDetails/" + data, config);
  }

  GetDailyBookings(data) {
    const token = cookies.get("accessToken");
    const config = {
      headers: {
        Authorization: `Bearer ${token.accessToken}`,
      },
    };

    return httpBase.post("/api/GetDailyBookings", data, config);
  }

  GetSchedulePickupList(data) {
    const token = cookies.get("accessToken");
    const config = {
      headers: {
        Authorization: `Bearer ${token.accessToken}`,
      },
    };

    return httpBase.get("/api/GetSchedulePickupList/" + data, config);
  }

  SubmitSchedulePickup(data) {
    const token = cookies.get("accessToken");
    const config = {
      headers: {
        Authorization: `Bearer ${token.accessToken}`,
      },
    };

    return httpBase.post("/api/SubmitSchedulePickup", data, config);
  }

  UpdateSchedulePickup(data) {
    const token = cookies.get("accessToken");
    const config = {
      headers: {
        Authorization: `Bearer ${token.accessToken}`,
      },
    };

    return httpBase.post("/api/UpdateSchedulePickup", data, config);
  }

  UpdateReceiverInfo(data) {
    const token = cookies.get("accessToken");
    const config = {
      headers: {
        Authorization: `Bearer ${token.accessToken}`,
      },
    };

    return httpBase.post("/api/UpdateReceiverInfo", data, config);
  }

  GetBulkDetails(data) {
    const token = cookies.get("accessToken");
    const config = {
      headers: {
        Authorization: `Bearer ${token.accessToken}`,
      },
    };

    return httpBase.get(`/api/GetCorpDomesticStagingBulkDetails/${data.shipperAccountNo}/${data.bulkSessionId}`, config);
  }

  GetBookingDetails(data) {
    const token = cookies.get("accessToken");
    const config = {
      headers: {
        Authorization: `Bearer ${token.accessToken}`,
      },
    };

    return httpBase.post(`/api/GetCorpDomesticStagingDetails`, data, config);
  }

  GetCorpPortalAcctProducts(data) {
    const token = cookies.get("accessToken");
    const config = {
      headers: {
        Authorization: `Bearer ${token.accessToken}`,
      },
    };

    return httpBase.post("/api/GetCorpPortalAcctProducts", data, config);
  }

  CalculatePriceNewRates(data) {
    return httpLexaApi.post("/CalculatePriceNewRates", data);
  }

  CorpDomesticBooking(data) {
    return httpLexaApi.post("/CorpDomesticBooking", data);
  }

  CorpDomesticBookingUpdate(data) {
    return httpLexaApi.post("/CorpDomesticBookingUpdate", data);
  }

  UpdateBulkStatus(data) {
    const config = {
      headers: {
        shipperuuid: data.shipperAccountNo,
      },
    };

    const payload = {
      bulkSessionId: data.bulkSessionId,
      bulkstatus: data.bulkstatus,
      refBookingNo: data.refBookingNo,
      referenceNoOne: data.referenceNoOne,
      claimantFirstName: data.claimantFirstName,
      claimantLastName: data.claimantLastName,
    };

    return httpLexaApi.post("/UpdateBulkStatus", payload, config);
  }

  BulkUpload(data, file, onUploadProgress) {
    let formData = new FormData();

    formData.append("media", file[0]);

    const config = {
      headers: {
        "Content-Type": "multipart/form-data",
        shipperuuid: data.shipperAccountNo,
        bulksessionid: data.bulkSessionId,
        bulkstatusupdate: data.bulkStatusUpdate,
        pickupdate: data.pickupDate,
      },
      onUploadProgress,
    };

    return httpLexaApi.post("/BulkUpload/Corp", formData, config);
  }

  GenerateMultiplePOD(data) {
    return httpLexaApi.post("/GenerateMultiplePOD", data);
  }

  TrackNTraceSeach(data) {
    const token = cookies.get("accessToken");
    const config = {
      headers: {
        Authorization: `Bearer ${token.accessToken}`,
      },
    };

    return httpBase.post("/api/TrackNTraceSeach", data, config);
  }

  GetAccountActivities(data) {
    const token = cookies.get("accessToken");
    const config = {
      headers: {
        Authorization: `Bearer ${token.accessToken}`,
      },
    };

    return httpBase.post("/api/GetAccountActivities", data, config);
  }

  GetDeliveryTAT(data) {
    return httpLexaApi.post("/GetDeliveryTAT", data);
  }

  PrintWayBill(data) {
    return httpBase.get("/api/PrintWayBill/" + data);
  }

  getHolidays() {
    return httpLexaApi.get("/getHolidays");
  }
}

export default new BookNowService();
