import React from "react";
import { FlexColumn, FlexRow, Spacer } from "../../core/Containers";
import { Text } from "../../core/Text";
import { Image } from "../../core/Image";
import { CheckBox, RawInput } from "../../core/Forms";
import { classNames } from "../../helpers/ClassNames";
import { Images } from "../../assets/images/images";
import { RawButton } from "../../core/Buttons";
import { RiDeleteBin6Line } from "react-icons/ri";
import { FaMinus, FaPlus } from "react-icons/fa";

export const PackageItem = ({ className, isSelected, data, onClick, list }) => {

  const isItemAdded = (pkgId) => {
    const getItemAdded = list.find(pkg => pkg.id === pkgId);
    return getItemAdded !== undefined;
  };

  return (
    <FlexColumn
      className={`items-center justify-between border-grey-400 rounded-lg shadow-lg border-2 ${isItemAdded(data.id) ? '' : 'hover:border-red-200'} p-2 ${isSelected ? 'border-red-400' : ''
        } ${isItemAdded(data.id) ? 'cursor-not-allowed' : ''} ${className}`}
      onClick={isItemAdded(data.id) ? null : () => onClick(data)}
    >
      <FlexRow className="w-full items-center">
        <Text
          className={`text-sm ${isSelected ? 'text-red-400' : 'text-secondary-200'
            }`}
        >
          {data.type}
        </Text>
        <CheckBox
          checked={isSelected}
          className={`phone:mx-0 ${isItemAdded(data.id) ? 'cursor-not-allowed' : ''
            }`}
          containerClass="ml-auto"
          id={data.name}
          name={data.name}
          label=""
          onClick={isItemAdded(data.id) ? null : () => onClick(data)}
          disabled={isItemAdded(data.id)}
        />
      </FlexRow>
      <Image alt={data.name} className="my-5" src={Images.getPackageImage(Number(data.id))} />
      <Text
        className={`${isItemAdded(data.id) ? 'text-grey-400' : 'text-secondary-100'
          } text-center font-bold`}
      >
        {data.name}
      </Text>
      <Text
        className={`${isItemAdded(data.id) ? 'text-grey-400' : 'text-secondary-100'
          } text-center`}
      >
        {data.dimension}
      </Text>
    </FlexColumn>
  );
};

export const CorpPortalAcctPackageItem = ({
  className,
  isSelected,
  data,
  onClick,
  isInch,
  disabled,
}) => {
  const [cmToIN, setCMToIN] = React.useState(2.54);

  return (
    <>
      {!disabled ? (
        <FlexColumn
          className={`items-center justify-between border-grey-400 rounded-lg shadow-lg border-2 hover:border-red-200 p-2 ${isSelected ? "border-red-400 bg-red-70" : ""
            } ${className}`}
          onClick={() => onClick(data)}
        >
          <FlexRow className="w-full items-center">
            <Text
              className={`text-sm ${isSelected ? "text-red-400" : "text-secondary-200"
                }`}
            >
              {data.type}
            </Text>
            <CheckBox
              checked={isSelected}
              className="phone:mx-0"
              containerClass="ml-auto"
              id={`pkg-optn-${data.prodId}`}
              name="package"
              label=""
              value={data.prodDisplayName}
              onClick={() => onClick(data)}
            />
          </FlexRow>
          <Image
            alt={data.prodDisplayName}
            className="my-5 w-40 h-32"
            src={Images.getPackageImage(Number(data.prodId))}
          />
          <Text
            className="text-secondary-100 text-center font-bold"
          >
            {data.prodDisplayName}
          </Text>
          <Text
            className="text-secondary-100 text-center text-xs"
          >
            {(data.length > 0 || data.width > 0 || data.height > 0) ? (
              `${data.length > 0
                ? `${isInch ? data.length / cmToIN : data.length} x `
                : ""
              } ${data.width > 0
                ? `${isInch ? data.width / cmToIN : data.width} x `
                : ""
              } ${data.height > 0
                ? `${isInch ? data.height / cmToIN : data.height}`
                : ""
              } ${isInch ? "in" : "cm"}`) : (
              <>&nbsp;</>
            )}
          </Text>
          <Text
            className="text-secondary-100 text-center text-xs"
          >
            {data.weight > 0 || data.prodId === 16 ? (
              `Max weight: ${data.prodId === 16 ? 100.0 : data.weight
              } kg`
            ) : (
              <>&nbsp;</>
            )}
          </Text>
          <Spacer className="h-4" />
        </FlexColumn>
      ) : (
        <FlexColumn
          className={`items-center justify-between border-grey-400 rounded-lg shadow-lg border-2 p-2 ${isSelected ? "border-red-400 bg-red-70" : ""
            } cursor-not-allowed ${className}`}
        >
          <FlexRow className="w-full items-center">
            <Text
              className={`text-sm ${isSelected ? "text-red-400" : "text-secondary-200"
                }`}
            >
              {data.type}
            </Text>
            <CheckBox
              checked={isSelected}
              className="phone:mx-0"
              containerClass="ml-auto"
              id={`pkg-optn-${data.prodId}`}
              name="package"
              label=""
              value={data.prodDisplayName}
              disabled={disabled}
            />
          </FlexRow>
          <Image
            alt={data.prodDisplayName}
            className="my-5 w-40 h-32"
            src={Images.getPackageImage(Number(data.prodId))}
          />
          <Text
            className="text-grey-400 text-center font-bold"
          >
            {data.prodDisplayName}
          </Text>
          <Text
            className="text-grey-400 text-center text-xs"
          >
            {(data.length > 0 || data.width > 0 || data.height > 0) ? (
              `${data.length > 0
                ? `${isInch ? data.length / cmToIN : data.length} x `
                : ""
              } ${data.width > 0
                ? `${isInch ? data.width / cmToIN : data.width} x `
                : ""
              } ${data.height > 0
                ? `${isInch ? data.height / cmToIN : data.height}`
                : ""
              } ${isInch ? "in" : "cm"}`) : (
              <>&nbsp;</>
            )}
          </Text>
          <Text
            className="text-grey-400 text-center text-xs"
          >
            {data.weight > 0 || data.prodId === 16 ? (
              `Max weight: ${data.prodId === 16 ? 100.0 : data.weight
              } kg`
            ) : (
              <>&nbsp;</>
            )}
          </Text>
          <Spacer className="h-4" />
        </FlexColumn>
      )
      }
    </>
  );
};

export const RequestItems = ({ data }) => {
  const [newCount, setCount] = React.useState(1);

  const updateCount = (ascending) => {
    if (newCount === 0 && ascending) {
      setCount(newCount + 1);
    } else if (newCount === 0 && !ascending) {
      //
    } else if (newCount > 0 && ascending) {
      setCount(newCount + 1);
      data.quantity = parseInt(data.quantity) + 1;
    } else if (newCount > 0 && !ascending) {
      setCount(newCount - 1);
      data.quantity = parseInt(data.quantity) - 1;
    }
  };

  const updateCountOnInput = (event) => {
    setCount(parseInt(event.target.value));
    data.quantity = parseInt(event.target.value);
  };

  const deleteBin = () => {
    setCount(0);
    data.quantity = 0;
  };

  return (
    <FlexRow className="w-full items-center justify-between py-2">
      <RawButton onClick={() => deleteBin()}>
        <RiDeleteBin6Line className="text-red-400 text-xl ml-2" />
      </RawButton>
      <Text className="text-secondary-100 break-words">{data.name}</Text>
      <FlexRow className="w-2/5 items-center justify-around">
        <RawButton
          className="text-red-400 font-bold border-2 border-red-400 rounded-lg mx-1 w-8 h-8 flex items-center justify-center hover:bg-red-300"
          onClick={() => updateCount(false)}
        >
          <FaMinus />
        </RawButton>
        {/*<Text className="text-secondary-100 px-2">{newCount}</Text>*/}
        {/*<RawInput
          className="text-secondary-100 px-2"
          containerClass="w-1/3 pr-2"
          name="newCount"
          placeholder="Quantity"
          type="text"
          value={newCount}
          onChange={(value) => updateCountOnInput(value)}
        />*/}
        <div className="w-1/3 pr-2">
          <input
            type="text"
            className={classNames(
              `border ${"border-grey-400"
              } rounded-lg block w-full`,
              "text-secondary-100 px-2"
            )}
            id="newCount"
            name="newCount"
            placeholder="Quantity"
            value={newCount}
            onChange={(event) => updateCountOnInput(event)}
            style={{ textAlign: 'center' }}>
          </input>
        </div>
        <RawButton
          className="text-red-400 font-bold border-2 border-red-400 rounded-lg mx-1 w-8 h-8 flex items-center justify-center hover:bg-red-300"
          onClick={() => updateCount(true)}
        >
          <FaPlus />
        </RawButton>
      </FlexRow>
    </FlexRow>
  );
};
