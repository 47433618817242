import React from 'react';
import { CSVLink } from 'react-csv';
import { Div, Divider, FlexColumn, FlexRow, Spacer } from '../../../core/Containers';
import { Text } from '../../../core/Text';
import { ColumnHeader } from '../../../components/Tables';
import { tempProofDeliveryData } from '../../../constants/TempData';
import { Pagination } from '../../../components/Tables/Pagination';
import { useLocation, useNavigate } from 'react-router-dom';
import { RawDropdown } from '../../../core/Buttons';
import { ProofOfDeliveryDataRow } from '../../../components/Tables/ReportsDataRow';
import { AiOutlineDownload } from 'react-icons/ai';
import { Tab } from '@headlessui/react';
import { ImSpinner2 } from 'react-icons/im';
import { classNames } from '../../../helpers/ClassNames';
import { BiSearchAlt2 } from "react-icons/bi";
import { isNumberWithComma } from "../../../helpers/common";
import {
  ProofOfDeliveryCorporate,
  ProofOfDeliveryHandheld
} from '/';

export const ProofOfDelivery = () => {
  const navigate = useNavigate();
  const { search } = useLocation();
  const query = new URLSearchParams(search);
  const actionQuery = query.get('active');

  const [loading, setLoading] = React.useState(true);
  const [isAscending, setAscending] = React.useState(true);
  const [sortList, setSortList] = React.useState(tempProofDeliveryData);

  const [singleView, setSingleView] = React.useState('default');
  const [selected, setSelected] = React.useState('default');
  const [selectedList, setSelectedList] = React.useState([]);

  const [currentPage, setCurrentPage] = React.useState(1);
  const [articlesPerPage] = React.useState(10);
  const indexOfLastArticle = currentPage * articlesPerPage;
  const indexOfFirstArticle = indexOfLastArticle - articlesPerPage;

  const [selectedIndex, setSelectedIndex] = React.useState(actionQuery || 0);

  const [trackingNosTemp, setTrackingNosTemp] = React.useState("");
  const [trackingNos, setTrackingNos] = React.useState("");

  const tabs = [
    'Corporate',
    'Handheld'
  ];

  React.useEffect(() => {
    if (actionQuery === 'single-view') {
      //
    }
  }, [actionQuery]);

  React.useEffect(() => {
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
    }, 1000);
  }, []);

  const onSelectItems = id => {
    if (selectedList.includes(id)) {
      const tempList = [...selectedList].filter(value => value !== id);
      setSelectedList(tempList);
    } else {
      const tempList = [...selectedList];
      tempList.push(id);
      setSelectedList(tempList);
    }
  };

  const onSort = async column => {
    setLoading(true);
    if (isAscending) {
      if (column === 0) {
        const tempList = await sortList.sort((a, b) => {
          if (a.tracking < b.tracking) {
            return -1;
          }
          if (a.tracking > b.tracking) {
            return 1;
          }
          return 0;
        });
        setSortList(tempList);
      } else if (column === 1) {
        const tempList = await sortList.sort((a, b) => {
          if (a.bookDate < b.bookDate) {
            return -1;
          }
          if (a.bookDate > b.bookDate) {
            return 1;
          }
          return 0;
        });
        setSortList(tempList);
      } else if (column === 2) {
        const tempList = await sortList.sort((a, b) => {
          if (a.tracking < b.tracking) {
            return -1;
          }
          if (a.tracking > b.tracking) {
            return 1;
          }
          return 0;
        });
        setSortList(tempList);
      } else if (column === 3) {
        const tempList = await sortList.sort((a, b) => {
          if (a.sender < b.sender) {
            return -1;
          }
          if (a.sender > b.sender) {
            return 1;
          }
          return 0;
        });
        setSortList(tempList);
      } else if (column === 4) {
        const tempList = await sortList.sort((a, b) => {
          if (a.receiver < b.receiver) {
            return -1;
          }
          if (a.receiver > b.receiver) {
            return 1;
          }
          return 0;
        });
        setSortList(tempList);
      } else if (column === 5) {
        const tempList = await sortList.sort((a, b) => {
          if (a.bookDate < b.bookDate) {
            return -1;
          }
          if (a.bookDate > b.bookDate) {
            return 1;
          }
          return 0;
        });
        setSortList(tempList);
      }
      setTimeout(() => {
        setLoading(false);
        setAscending(!isAscending);
      }, 1000);
    } else {
      if (column === 0) {
        const tempList = await sortList.sort((a, b) => {
          if (a.tracking > b.tracking) {
            return -1;
          }
          if (a.tracking < b.tracking) {
            return 1;
          }
          return 0;
        });
        setSortList(tempList);
      } else if (column === 1) {
        const tempList = await sortList.sort((a, b) => {
          if (a.bookDate > b.bookDate) {
            return -1;
          }
          if (a.bookDate < b.bookDate) {
            return 1;
          }
          return 0;
        });
        setSortList(tempList);
      } else if (column === 2) {
        const tempList = await sortList.sort((a, b) => {
          if (a.tracking > b.tracking) {
            return -1;
          }
          if (a.tracking < b.tracking) {
            return 1;
          }
          return 0;
        });
        setSortList(tempList);
      } else if (column === 3) {
        const tempList = await sortList.sort((a, b) => {
          if (a.sender > b.sender) {
            return -1;
          }
          if (a.sender < b.sender) {
            return 1;
          }
          return 0;
        });
        setSortList(tempList);
      } else if (column === 4) {
        const tempList = await sortList.sort((a, b) => {
          if (a.receiver > b.receiver) {
            return -1;
          }
          if (a.receiver < b.receiver) {
            return 1;
          }
          return 0;
        });
        setSortList(tempList);
      } else if (column === 5) {
        const tempList = await sortList.sort((a, b) => {
          if (a.bookDate > b.bookDate) {
            return -1;
          }
          if (a.bookDate < b.bookDate) {
            return 1;
          }
          return 0;
        });
        setSortList(tempList);
      }
      setTimeout(() => {
        setLoading(false);
        setAscending(!isAscending);
      }, 1000);
    }
  };

  const onSubmit = (e) => {
    e.preventDefault();
    setTrackingNos(trackingNosTemp.replaceAll("\n", ","));
  };

  const onTrackingNosChange = (value) => {
    if (
      isNumberWithComma(value.replaceAll("\n", " ")) &&
      value.substr(value.replaceAll("\n", " ").length - 2) !== ",," &&
      value.substr(value.replaceAll("\n", " ").length - 2) !== "  " &&
      value.substr(value.replaceAll("\n", " ").length - 2) !== "\t\t"
    ) {
      setTrackingNosTemp(value);
    }
  };

  // CSV Variables
  const newDate = new Date();
  const fileName = newDate.getTime();

  const headers = [
    { label: 'TRANSACTION DATE', key: 'tracking' },
    { label: 'CLIENT', key: 'bookDate' },
    { label: 'DELIVERY STATUS', key: 'tracking' },
    { label: 'TRACKING NUMBER', key: 'sender' },
    { label: '', key: 'receiver' },
  ];

  return (
    <>
    <FlexRow className="p-2">

    </FlexRow>
    <FlexRow className="p-2">
      <Text className="text-secondary-100 text-xs font-bold">
        Enter Tracking Number(s)
      </Text>
    </FlexRow>
    <FlexRow className="p-2">
      <Text className="text-secondary-100 text-xs font-bold" style={{ fontSize: 10 }}>
        (separated by comma, maximum of 50 tracking numbers)
      </Text>
    </FlexRow>
    <FlexRow className="p-2">
      <Text className="text-secondary-100 text-xs font-bold text-red-100" style={{ fontSize: 10 }}>
        Note that walk-in transactions will not be visible in this module.
      </Text>
    </FlexRow>
    <FlexRow className="w-full px-10 py-5 items-center justify-between bg-white-400">
      <FlexRow className="items-center border border-grey-400 rounded-lg shadow-lg p-2 bg-white">
          <form onSubmit={(e) => onSubmit(e)}>
            <div className="flex items-center px-1 py-1 rounded-lg bg-gray-50">
              <textarea
                id="trackingNos"
                rows="2"
                cols="40"
                className="block w-full text-sm text-gray-900 bg-white rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500"
                placeholder="Tracking Numbers"
                autoComplete="off"
                onChange={e => onTrackingNosChange(e.target.value)}
                value={trackingNosTemp}
              />
              <button type="submit" className="inline-flex justify-center ml-2 p-2 text-blue-600 rounded-full cursor-pointer hover:bg-red-70">
                <BiSearchAlt2 className="text-red-400 text-2xl" />
              </button>
            </div>
          </form>
        </FlexRow>
      </FlexRow>
     <Tab.Group
      selectedIndex={selectedIndex}
      onChange={setSelectedIndex}
     >
      <FlexColumn className="w-full">
          <Tab.List className="tabletWide:space-x-4">
            {tabs.map(tab => (
              <Tab
                className={({ selected }) =>
                  classNames(
                    'p-2 font-semibold w-auto focus:border-b-4 focus:border-orange-400 ring:border-orange-400 outline-none',
                    selected
                      ? 'text-orange-400 border-b-4 border-orange-400'
                      : 'text-secondary-200  hover:text-secondary-100',
                  )
                }
                key={tab}
              >
                {tab}
              </Tab>
            ))}
          </Tab.List>
          <Divider className="text-grey-400" />
          <Tab.Panels className="w-full">
            <Tab.Panel className={`w-full`}>
              {selectedIndex === 0 && (
                <ProofOfDeliveryCorporate
                  trackingNos={trackingNos}
                />
              )}
            </Tab.Panel>
            <Tab.Panel className={`w-full`}>
              {selectedIndex === 1 && (
                <ProofOfDeliveryHandheld 
                  trackingNos={trackingNos}
                />
              )}
            </Tab.Panel>
          </Tab.Panels>
        </FlexColumn>
      </Tab.Group>
    </>
  );
};
