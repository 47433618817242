import React from 'react';

export const Radio = ({
  className,
  label,
  name,
  optionId,
  containerClass,
  isChecked,
  onClick,
  disabled,
}) => !disabled ? (
  <div className={`flex flex-row items-center ${containerClass}`} onClick={onClick}>
    <input
      aria-describedby={optionId}
      aria-labelledby={optionId}
      checked={isChecked}
      className={`${className} w-4 h-4 border-red-100 focus:ring-2 
      text-red-400 focus:ring-red-400`}
      id={optionId}
      name={name}
      onClick={onClick}
      readOnly
      type="radio"
      value=""
    />
    <label className="block ml-2 text-secondary-100" htmlFor={optionId}>
      {label}
    </label>
  </div>
) : (
    <div className={`flex flex-row items-center bg-grey-500 cursor-no-drop ${containerClass}`}>
      <input
        aria-describedby={optionId}
        aria-labelledby={optionId}
        checked={isChecked}
        className={`${className} w-4 h-4 border-red-100 focus:ring-2 
      text-red-400 focus:ring-red-400 cursor-no-drop`}
        id={optionId}
        name={name}
        readOnly
        type="radio"
        value=""
        disabled={true}
      />
      <label className="block ml-2 text-secondary-100 cursor-no-drop" htmlFor={optionId}>
        {label}
      </label>
    </div>
  );
