import React, { useRef } from "react";
import { Cookies } from "react-cookie";
import Barcode from "react-barcode";
import QRCode from "qrcode.react";
import { FlexRow, Div, Spacer, FlexColumn } from "../../../core/Containers";
import { Image } from "../../../core/Image";
import { LBCModal } from "../../../components/Modals/LBCModal";
import { Span, Text } from "../../../core/Text";
import moment from "moment/moment";
import { SuppliesReceiverInfo } from "../../../components/SuppliesReceiverInfo";
import { CorpPortalAcctPackageItem } from "../../../components/Feature/PackageItem";
import { Button, RawButton } from "../../../core/Buttons";
import { Images } from "../../../assets/images/images";
import { ImInfo } from "react-icons/im";
import { HiCheckCircle } from "react-icons/hi";
import BookNow from "../../../services/BookNow.service";
import { classNames } from "../../../helpers/ClassNames";
import { RiDeleteBin6Line } from "react-icons/ri";
import { FaMinus, FaPlus } from "react-icons/fa";
import { isNumber, isMobileNumber, isEmail } from "../../../helpers/common";
import { Loading } from "../../Window/Loading";
import axiosCAPApi from "../../../api/axiosCAPApi";
import axiosCAPApiPrivate from "../../../api/axiosCAPApiPrivate";
import axiosLexaApiPrivate from "../../../api/axiosLexaApiPrivate";

const cookies = new Cookies();

export const RequestSuppliesTab = ({ shipperAcct }) => {
  const dataFetchedRef = useRef(false);

  const [loading, setLoading] = React.useState(false);
  const [loadingRD, setLoadingRD] = React.useState(false);
  const [requestSubmitted, setRequest] = React.useState(false);
  const [dateSubmitted, setDateSubmitted] = React.useState(
    moment(new Date()).format("MMMM Do YYYY, h:mm:ss a")
  );

  const [suppliesBagList, setSuppliesBagList] = React.useState([]);
  const [selectedItems, setSelected] = React.useState([]);
  const [addedItems, setAdded] = React.useState([]);

  const [shipperAcctNo, setShipperAcctNo] = React.useState("");
  const [companyName, setCompanyName] = React.useState("");
  const [receiverData, setReceiverData] = React.useState(null);
  const [bookingNo, setBookingNo] = React.useState("");

  const [isToastOpen, setToastOpen] = React.useState(false);
  const [toastKind, setToastKind] = React.useState("");
  const [toastTitle, setToastTitle] = React.useState("");
  const [toastDesc, setToastDesc] = React.useState("");

  const [pkgData, setPkgData] = React.useState([]);
  const [isValidReceiverData, setIsValidReceiverData] = React.useState(false);

  React.useEffect(() => {
    if (dataFetchedRef.current) return;
    dataFetchedRef.current = true;

    var _shipperAcct = cookies.get("shipperAcct", { path: "/" });
    var _shipperAcctSplit = _shipperAcct ? _shipperAcct.split("|") : [];
    var _shipperAcctName = _shipperAcctSplit[0]
      ? _shipperAcctSplit[0].toString().trim()
      : "";
    var _shipperAcctNo = _shipperAcctSplit[1]
      ? _shipperAcctSplit[1].toString().trim()
      : "";

    setCompanyName(_shipperAcctName);
    setShipperAcctNo(_shipperAcctNo);

    getCustomerDetails(_shipperAcctNo);
    getCorpPortalAcctProducts("Supply");
  }, []);

  React.useEffect(() => {
    var _shipperAcct = cookies.get("shipperAcct", { path: "/" });
    var _shipperAcctSplit = _shipperAcct ? _shipperAcct.split("|") : [];
    var _shipperAcctName = _shipperAcctSplit[0]
      ? _shipperAcctSplit[0].toString().trim()
      : "";
    var _shipperAcctNo = _shipperAcctSplit[1]
      ? _shipperAcctSplit[1].toString().trim()
      : "";

    if (shipperAcctNo !== "" && _shipperAcctNo !== shipperAcctNo) {
      setCompanyName(_shipperAcctName);
      setShipperAcctNo(_shipperAcctNo);

      getCustomerDetails(_shipperAcctNo);
      getCorpPortalAcctProducts("Supply");
    }
  }, [cookies.get("shipperAcct", { path: "/" })]);

  React.useEffect(() => {
    if (
      receiverData &&
      (receiverData.contactPerson === "" ||
        receiverData.mobileNo === "" ||
        !isMobileNumber(receiverData.mobileNo) ||
        receiverData.pickUpAddress === "" ||
        receiverData.provName === "" ||
        receiverData?.cityName === "" ||
        receiverData.brgyName === "")
    ) {
      setIsValidReceiverData(false);
    } else {
      setIsValidReceiverData(true);
    }
  }, [receiverData]);

  const getCustomerDetails = async (customerAccountNo) => {
    setReceiverData(null);
    setLoadingRD(true);

    try {
      const response = await axiosCAPApiPrivate.get(
        "/api/GetCustomerDetails/" + customerAccountNo
      );

      const data = response.data;

      if (data.Code === "200") {
        cookies.set("receiverData", data.Data);
        setReceiverData(data.Data);
        setLoadingRD(false);
      } else {
        setToastKind("info");
        setToastTitle("Invormation");
        setToastDesc(data.Message);
        setTimeout(() => {
          setLoadingRD(false);
        }, 1000);
        setTimeout(() => {
          setToastOpen(true);
        }, 1500);
        setTimeout(() => {
          setToastOpen(false);
        }, 5000);
      }
    } catch (error) {
      setToastKind("error");
      setToastTitle("API Fetch Failed!");
      setToastDesc(error.message);
      setTimeout(() => {
        setLoadingRD(false);
      }, 1000);
      setTimeout(() => {
        setToastOpen(true);
      }, 1500);
      setTimeout(() => {
        setToastOpen(false);
      }, 5000);
    }
  };

  const getCorpPortalAcctProducts = async (productFor) => {
    setPkgData([]);
    setLoading(true);

    const reqData = {
      productFor: productFor,
    };

    try {
      const response = await axiosCAPApiPrivate.post(
        "/api/GetCorpPortalAcctProducts",
        reqData
      );
      const resData = response.data;

      if (resData.Code === "200") {
        setPkgData(resData.Data);

        setLoading(false);
      } else {
        setToastKind("info");
        setToastTitle("Information");
        setToastDesc(resData.Message);
        setTimeout(() => {
          setLoading(false);
        }, 1000);
        setTimeout(() => {
          setToastOpen(true);
        }, 1500);
        setTimeout(() => {
          setToastOpen(false);
        }, 5000);
      }
    } catch (error) {
      setToastKind("error");
      setToastTitle("API Fetch Failed!");
      setToastDesc(error.message);
      setTimeout(() => {
        setLoading(false);
      }, 1000);
      setTimeout(() => {
        setToastOpen(true);
      }, 1500);
      setTimeout(() => {
        setToastOpen(false);
      }, 5000);
    }
  };

  const onSelectAddress = (item) => {
    const newReceiverData = {
      customerAccountNo: receiverData.customerAccountNo,
      companyName: receiverData.companyName,
      companyAddress: receiverData.companyAddress,
      pickUpAddress: item.pickupAddress,
      provId: item.provId,
      provName: item.provName,
      cityId: item.cityId,
      cityName: item.cityName,
      brgyId: item.brgyId,
      brgyName: item.brgyName,
      contactPerson: item.contactPerson,
      emailAddress: receiverData.emailAddress,
      mobileNo: item.mobileNo,
      payMode: receiverData.payMode,
      acctHandlerEmail: receiverData.acctHandlerEmail,
    };

    cookies.set("receiverData", newReceiverData);
    setReceiverData(newReceiverData);
  };

  // React.useEffect(() => {
  //   if (shipperAcct) {
  //     var shipAcctNo = shipperAcct ? shipperAcct.split("|") : [];
  //     setCompanyName(shipAcctNo[0] ? shipAcctNo[0].toString().trim() : "");
  //     setShipperAcctNo(shipAcctNo[1] ? shipAcctNo[1].toString().trim() : "");
  //   }
  // }, [shipperAcct]);

  // React.useEffect(() => {
  //   setLoading(true);
  //   setTimeout(() => {
  //     setLoading(false);
  //   }, 1000);
  // }, []);

  const onSelectItems = (item) => {
    setSelected([item]);
  };

  const onAddItems = () => {
    const tempList = [...addedItems];
    selectedItems[0].quantity = 1;
    const tempList2 = tempList.concat(selectedItems);

    setAdded(tempList2);
    setSelected([]);
  };

  // const GetReceiverDetails = () => {
  //   Auth.validateToken()
  //     .then(async (response) => {
  //       return await response.data;
  //     })
  //     .then((data) => {
  //       if (data.Code === "403") {
  //         Auth.refreshToken()
  //           .then(async (response) => {
  //             return await response.data;
  //           })
  //           .then(async (data) => {
  //             if (data.Code === "200") {
  //               const userInfo = cookies.get("userInfo");
  //               const newToken = data.Data;
  //               userInfo.token = newToken;
  //               cookies.set("userInfo", userInfo, { path: "/" });
  //               cookies.set("accessToken", newToken, { path: "/" });

  //               procGetReceiverDetails();
  //             } else {
  //               setToastKind("error");
  //               setToastTitle("Delivery Performance Failed!");
  //               setToastDesc(data.message);
  //               setTimeout(() => {
  //                 setLoading(false);
  //               }, 1000);
  //               setTimeout(() => {
  //                 setToastOpen(true);
  //               }, 1500);
  //               setTimeout(() => {
  //                 setToastOpen(false);
  //               }, 3000);
  //             }
  //           })
  //           .catch((error) => {
  //             setToastKind("error");
  //             setToastTitle("Delivery Performance Failed!");
  //             setToastDesc(error.message);
  //             setTimeout(() => {
  //               setLoading(false);
  //             }, 1000);
  //             setTimeout(() => {
  //               setToastOpen(true);
  //             }, 1500);
  //             setTimeout(() => {
  //               setToastOpen(false);
  //             }, 3000);
  //           });
  //       } else {
  //         procGetReceiverDetails();
  //       }
  //     })
  //     .catch((error) => {
  //       setToastKind("error");
  //       setToastTitle("Delivery Performance Failed!");
  //       setToastDesc(error.message);
  //       setTimeout(() => {
  //         setLoading(false);
  //       }, 1000);
  //       setTimeout(() => {
  //         setToastOpen(true);
  //       }, 1500);
  //       setTimeout(() => {
  //         setToastOpen(false);
  //       }, 3000);
  //     });
  // };

  // const GetReceiverDetails = () => {
  //   setLoading(true);
  //   BookNow.GetReceiverDetails(shipperAcctNo)
  //     .then((response) => {
  //       return response.data;
  //     })
  //     .then((data) => {
  //       if (data.Code === "200") {
  //         cookies.set("receiverData", data.Data);
  //         setReceiverData(data.Data);
  //       }
  //       setLoading(false);
  //     })
  //     .catch((error) => {
  //       setToastKind("error");
  //       setToastTitle("Receiver Details Failed!");
  //       setToastDesc(error.message);
  //       setTimeout(() => {
  //         setLoading(false);
  //       }, 1000);
  //       setTimeout(() => {
  //         setToastOpen(true);
  //       }, 1500);
  //       setTimeout(() => {
  //         setToastOpen(false);
  //       }, 3000);
  //     });
  // };

  // React.useEffect(() => {
  //   if(receiverData != "") setReceiverData(cookies.get("receiverData"));
  // }, [cookies.get("receiverData")]);

  // React.useEffect(() => {
  //   if (shipperAcctNo) GetReceiverDetails();
  // }, [shipperAcctNo]);

  // React.useEffect(() => {
  //   if(receiverData === "" && shipperAcctNo) GetReceiverDetails();
  // }, [receiverData]);

  const SubmitRequestSupplies = () => {
    var date = new Date();
    date.setDate(date.getDate() + 1);
    var suppliesList = [];
    addedItems.map(function (item) {
      // var supplies = {};
      // supplies.BookingNo = null;
      // supplies.TrackingNo = null;
      // supplies.ShipmentMode = 1; //AIR
      // supplies.Paymentmode = 3; //Collect Shipper
      // supplies.PickupDate = moment(date).format('MM/DD/yyyy');//"06/25/2023";
      // supplies.ShipperAccountNo = shipperAcctNo;
      // supplies.CompanyName = companyName;
      // supplies.ContactPerson = receiverData.ContactPerson;
      // supplies.ShipperContactNumber = "";
      // supplies.ShipperMobileNumber = "";
      // supplies.ShipperEmail = "";
      // supplies.Description = "";
      // supplies.ShipperStBldg = receiverData.PickUpAddress;
      // supplies.ShipperBrgy = receiverData.BarangayId;
      // supplies.ShipperCityMunicipality = receiverData.CityId;
      // supplies.ShipperProvince = receiverData.ProvinceId;
      // supplies.Consignee = "";
      // supplies.ConsigneeAccountNo = "";
      // supplies.ConsigneeContactNumber = "";
      // supplies.ConsigneeMobileNumber = "";
      // supplies.ConsigneeStBldg = "";
      // supplies.ConsigneeBrgy = 0;
      // supplies.ConsigneeCityMunicipality = 0;
      // supplies.ConsigneeProvince = 0;
      // supplies.ProductLine = 4; //SUPPLIES
      // supplies.ServiceMode = 8;
      // supplies.Quantity = item.quantity;
      // supplies.PKG = item.id;
      // supplies.EstimatedFreightRate = 0;
      // suppliesList.push(supplies);

      var supplies = {};
      supplies.ShipmentMode = 1; //AIR
      supplies.TransactionDate = moment(new Date()).format(
        "yyyy-MM-DD HH:mm:ss"
      ); //"06/25/2023"; "2023-08-08 16:02:49"
      supplies.ShipperAccountNo = shipperAcctNo;
      supplies.Paymentmode = receiverData.payMode; //3; //Collect Shipper
      supplies.ShipperFirstName = companyName;
      supplies.ShipperMiddleName = "";
      supplies.ShipperLastName = "";
      supplies.ShipperStBldg = receiverData.pickUpAddress;
      supplies.ShipperBrgy = receiverData.brgyId;
      supplies.ShipperCityMunicipality = receiverData.cityId;
      supplies.ShipperProvince = receiverData.provId;
      supplies.ShipperContactNumber = receiverData.mobileNo;
      supplies.ShipperSendSMS = 0;
      supplies.ShipperMobileNumber = receiverData.mobileNo;
      supplies.ProductLine = item.productLineId; //4; //SUPPLIES
      supplies.ServiceMode = 8;
      supplies.CODAmount = 0;
      supplies.ShipperEmail = "";
      supplies.Description = "";
      supplies.ConsigneeFirstName = "";
      supplies.ConsigneeMiddleName = "";
      supplies.ConsigneeLastName = "";
      supplies.ConsigneeStBldg = "";
      supplies.ConsigneeBrgy = "0";
      supplies.ConsigneeCityMunicipality = "0";
      supplies.ConsigneeProvince = "0";
      supplies.ConsigneeContactNumber = 0;
      supplies.ConsigneeMobileNumber = 0;
      supplies.ConsigneeSendSMS = 0;
      supplies.Quantity = item.quantity;
      supplies.PKG = item.prodId;
      supplies.ACTWTKgs = 0;
      supplies.LengthCM = 0;
      supplies.WidthCM = 0;
      supplies.HeightCM = 0;
      supplies.VolWTcbm = 0;
      supplies.CBM = 0;
      supplies.ChargeableWT = 0;
      supplies.DeclaredValue = 0;
      supplies.Commodity = 0;
      supplies.ForCrating = 0;
      supplies.Paymentmode = 1;
      supplies.EstimatedFreightRate = 0;
      supplies.PickupDate = moment(date).format("yyyy-MM-DD hh:mm A"); //"2023-08-09 1:00 pm";
      supplies.SpecialInstructions = "";
      supplies.AttachmentNameOne = "";
      supplies.ReferenceNoOne = "";
      supplies.ReferenceNoTwo = "8";
      supplies.ContactPerson = receiverData.contactPerson;
      suppliesList.push(supplies);
    });
    //alert(JSON.stringify(suppliesList))
    //setSuppliesBagList(suppliesList);
    //alert(JSON.stringify(suppliesBagList))

    //alert(JSON.stringify(suppliesBagList))

    //setDateSubmitted(moment(new Date()).format("MMMM Do YYYY, h:mm:ss a"));
    //setLoading1(true);
    //setTimeout(() => {
    //  setLoading1(false);
    //  setRequest(true);
    //}, 1000);

    setLoading(true);
    BookNow.RequestSupplies(suppliesList)
      .then((response) => {
        return response.data;
      })
      .then((data) => {
        if (data.Code === "200") {
          setBookingNo(data.Data);
          setDateSubmitted(
            moment(new Date()).format("MMMM Do YYYY, h:mm:ss a")
          );
          setLoading(false);
          setRequest(true);
          if (receiverData.acctHandlerEmail !== "") {
            sendEmailConfirmation();
          } else {
            setToastKind("info");
            setToastTitle("Confirmation Email Sending Failed!");
            setToastDesc(
              "Confirmation Email not sent due to no Account Handler/Sales Email Address."
            );
            setTimeout(() => {
              setLoading(false);
            }, 1000);
            setTimeout(() => {
              setToastOpen(true);
            }, 1500);
            setTimeout(() => {
              setToastOpen(false);
            }, 7000);
          }
        } else {
          setToastKind("error");
          setToastTitle("Request Supplies Failed!");
          setToastDesc(data.Message);
          setTimeout(() => {
            setLoading(false);
          }, 1000);
          setTimeout(() => {
            setToastOpen(true);
          }, 1500);
          setTimeout(() => {
            setToastOpen(false);
          }, 7000);
        }
        setLoading(false);
      })
      .catch((error) => {
        setToastKind("error");
        setToastTitle("Request Supplies Failed!");
        setToastDesc(error.message);
        setTimeout(() => {
          setLoading(false);
        }, 1000);
        setTimeout(() => {
          setToastOpen(true);
        }, 1500);
        setTimeout(() => {
          setToastOpen(false);
        }, 5000);
      });
  };

  const sendEmailConfirmation = async () => {
    var items = "";
    addedItems.map(
      (item) =>
        (items += `<p>${item.prodDisplayName} ${item.type}: ${item.quantity}pcs</p>`)
    );

    try {
      const response = await axiosCAPApiPrivate.post("/api/SendEmail", {
        fromEmail: "customercare@lbcexpress.com",
        toEmail: isEmail(receiverData.acctHandlerEmail)
          ? receiverData.acctHandlerEmail
          : "Sales_Support@lbcexpress.com", // cookies.get("username"),
        subject: `For Approval: ${shipperAcctNo} - Customer Supplies Request`,
        html: true,
        body: `<div><p>Dear Approver,</p><p>Please approve your customers supplies request with the following details:</p><p><b>Account Number:</b> ${shipperAcctNo}</p><p><b>Account Name:</b> ${companyName}</p><p><b>Supplies Requested</b></p>${items}</div><br><div><p>LBC Corporate Dashboard</p></div>%Unsubscribe_Link%`,
      });

      const data = response.data;

      if (data.Code === "200") {
        setToastKind("success");
        setToastTitle(
          "Confirmation sent to your account handler's email address."
        );
        setToastDesc(""); //setToastDesc("Please check your email address.");
        setTimeout(() => {
          setLoading(false);
        }, 1000);
        setTimeout(() => {
          setToastOpen(true);
        }, 1500);
        setTimeout(() => {
          setToastOpen(false);
        }, 5500);
      } else {
        setToastKind("error");
        setToastTitle("Email Confirmation Failed!");
        setToastDesc(data.Message);
        setTimeout(() => {
          setLoading(false);
        }, 1000);
        setTimeout(() => {
          setToastOpen(true);
        }, 1500);
        setTimeout(() => {
          setToastOpen(false);
        }, 5000);
      }
    } catch (error) {
      setToastKind("error");
      setToastTitle("Email Confirmation Failed!");
      setToastDesc(
        "Unable to generate email confirmation. Please contact Corporate Accounts Portal Support Team."
      );
      setTimeout(() => {
        setLoading(false);
      }, 1000);
      setTimeout(() => {
        setToastOpen(true);
      }, 1500);
      setTimeout(() => {
        setToastOpen(false);
      }, 5000);
    }
  };

  const onSubmit = () => {
    SubmitRequestSupplies();
  };

  const [newCount, setCount] = React.useState(1);

  const updateCount = (item, index, ascending) => {
    // if (item.quantity === 0 && ascending) {
    //   //setCount(newCount + 1);
    //   addedItems.filter(added => added.id === item.id).map(x => {
    //     x.quantity = x.quantity + 1;
    //   });
    //   setAdded(addedItems);
    // } else if (item.quantity === 0 && !ascending) {
    //   //
    // } else if (item.quantity > 0 && ascending) {
    //   //setCount(newCount + 1);
    //   addedItems.filter(added => added.id === item.id).map(x => {
    //     x.quantity = x.quantity + 1;
    //   });
    //   setAdded(addedItems);
    // } else if (item.quantity > 0 && !ascending) {
    //   //setCount(newCount - 1);
    //   addedItems.filter(added => added.id === item.id).map(x => {
    //     x.quantity = x.quantity - 1;
    //   });
    //   setAdded(addedItems);

    //   if (item.quantity <= 0)
    //     deleteBin(index);
    // }

    if (!ascending && item.quantity <= 1) {
      deleteBin(item);
    } else {
      const newAddedItems = addedItems.map((pkg) => {
        if (pkg.id === item.id) {
          return {
            ...pkg,
            quantity: ascending ? item.quantity + 1 : item.quantity - 1,
          };
        }
        return pkg;
      });

      setAdded(newAddedItems);
    }
  };

  const updateCountOnInput = (item, index, value) => {
    // addedItems.filter(added => added.id === item.id).map(x => {
    //   x.quantity = parseInt(event.target.value);
    // });
    // setCount(parseInt(event.target.value));
    // setAdded(addedItems);

    if (isNumber(value)) {
      const newAddedItems = addedItems.map((pkg) => {
        if (pkg.id === item.id) {
          return {
            ...pkg,
            quantity: value === "" ? "0" : Number(value) > 100 ? "100" : value,
          };
        }
        return pkg;
      });

      setAdded(newAddedItems || []);

      if (value === "" || value === "0") {
        deleteBin(item);
      }
    }
  };

  const deleteBin = (item) => {
    // setCount(0);
    // setAdded([]);
    // addedItems.splice(index, 1);
    // setAdded(addedItems);

    // let newItems = addedItems;
    // newItems.splice(index, 1);
    // setAdded(newItems);

    const newAddedItems = addedItems
      .filter((pkg) => pkg.id !== item.id)
      .map((pkg) => {
        return pkg;
      });
    setAdded(newAddedItems);
  };

  const isItemAdded = (prodId) => {
    const getItemAdded = addedItems.find((pkg) => pkg.prodId === prodId);
    return getItemAdded !== undefined;
  };

  return (
    <>
      {loading && <Loading />}

      <LBCModal
        description={toastDesc}
        isOpen={isToastOpen}
        onClose={() => setToastOpen(false)}
        title={toastTitle}
        toastKind={toastKind}
      />

      {requestSubmitted ? (
        <FlexColumn className="items-center justify-start w-full">
          <Div className="w-500px border border-grey-400 rounded-lg shadow-lg my-10">
            <Div className="flex flex-col items-center justify-between p-4 w-full bg-white border-l-8 rounded-lg shadow-lg border-green-100">
              <Div className="w-full flex flex-row items-center phone:justify-between tablet:justify-center">
                <Div className="w-1/5">
                  <HiCheckCircle className="text-green-100 text-4xl" />
                </Div>
                <Div className="w-4/5">
                  <Text className="font-bold italic text-2xl text-secondary-100 pb-2">
                    Supplies Request Submitted
                  </Text>
                  <Text className="text-sm text-secondary-200 pt-2">
                    You’ve successfully requested supplies!
                  </Text>
                  <Text className="text-sm text-secondary-200 pt-2">
                    {dateSubmitted}
                  </Text>
                </Div>
              </Div>
            </Div>
          </Div>

          <Div className="w-2/3 rounded-sm shadow-sm border border-grey-400 p-10">
            <FlexRow className="w-full items-center">
              <Image
                className="h-14 w-14"
                alt="LBC logo"
                src={Images.LBClogo}
              />
              <Div className="pl-2  w-500px">
                <Text className="text-sm text-secondary-200">
                  SUPPLIES REQUEST REFERENCE NO.
                </Text>
                <Text className="text-xl text-secondary-200 font-bold">
                  {bookingNo !== "" ? bookingNo.split(",")[0] : ""}
                </Text>
              </Div>
              <Div className="flex flex-col w-full items-end justify-start">
                <Div className="flex flex-row h-24 items-center justify-start w-300px">
                  {bookingNo !== "" && (
                    <>
                      <QRCode
                        value={bookingNo.split(",")[0]}
                        style={{
                          width: "70px",
                          height: "70px",
                          marginRight: "10px",
                        }}
                      />
                      <Barcode
                        value={bookingNo.split(",")[0]}
                        height={70}
                        width={2}
                        displayValue={false}
                      />
                    </>
                  )}
                </Div>
                <Text className="flex flex-row items-center jusitfy-center text-secondary-200 text-xs font-bold w-300px">
                  <ImInfo className="inline mr-2" />
                  <Span>Keep this QR and barcode as reference</Span>
                </Text>
              </Div>
            </FlexRow>

            <Spacer className="h-5" />

            <FlexRow className="w-full py-1">
              <Text className="text-secondary-200 text-sm text-right w-1/3">
                PACKAGING:
              </Text>
              <Spacer className="w-2" />
              <FlexColumn className="w-2/3">
                {addedItems.map((item) => (
                  <FlexRow className="w-full">
                    <Text className="text-secondary-100 text-sm w-16">
                      {`${item.quantity}x`}
                    </Text>
                    <Text className="text-secondary-100 text-sm w-full">
                      <strong>{`${item.prodDisplayName} ${item.type}`}</strong>{" "}
                      (in pieces)
                    </Text>
                  </FlexRow>
                ))}
                <hr class="text-grey-400 my-2" role="none"></hr>
                <Text className="text-secondary-100 text-sm w-full font-bold">
                  {`TOTAL (${Number(
                    addedItems.reduce((accum, obj) => {
                      return accum + Number(obj.quantity);
                    }, 0)
                  )
                    .toFixed(0)
                    .replace(/\d(?=(\d{3})+\.)/g, "$&,")} Items)`}
                </Text>
              </FlexColumn>
            </FlexRow>
            <FlexRow className="w-full py-1">
              <Text className="text-secondary-200 text-sm text-right w-1/3">
                RECEIVER:
              </Text>
              <Spacer className="w-2" />
              <Text className="text-secondary-100 text-sm w-2/3">
                {receiverData
                  ? `${receiverData.contactPerson} (${receiverData.mobileNo})`
                  : ""}
              </Text>
            </FlexRow>
            <FlexRow className="w-full py-1">
              <Text className="text-secondary-200 text-sm text-right w-1/3">
                RECEIVER ADDRESS:
              </Text>
              <Spacer className="w-2" />
              <Text className="text-secondary-100 text-sm w-2/3">
                {receiverData ? receiverData.pickUpAddress : ""}
              </Text>
            </FlexRow>
          </Div>

          <FlexRow className="w-full items-center justify-center pt-5 pb-20">
            <Button
              className="w-32 mr-2 phone:bg-white phone:text-secondary-200 hover:bg-grey-500 phone:border phone:border-grey-400 h-10"
              onClick={() => {
                setSelected([]);
                setAdded([]);
                setRequest(false);
              }}
            >
              Back
            </Button>
            <Button
              className="w-32 ml-2 h-10"
              onClick={() => {
                setSelected([]);
                setAdded([]);
                setRequest(false);
              }}
            >
              View Activity
            </Button>
          </FlexRow>
        </FlexColumn>
      ) : (
        <FlexRow className="justify-between w-full">
          {/* First Body */}
          <Div className="border-r border-grey-400 w-2/3 h-600px pr-10 py-10">
            {/*loading ? (
              <Text className="text-red-400 text-center flex flex-row justify-center items-center my-20">
                <ImSpinner2 className="animate-spin mr-2 text-2xl desktop:text-3xl" />
                Loading data ...
              </Text>
            ) : (
              <Div className="p-5 w-full h-40 shadow-lg rounded-lg border border-grey-400">
                <Text className="text-secondary-100 text-xs font-bold">
                  Supplies Inventory Report
                  <Span className="mx-1">as of Jan 1, 2023, 12:00 PM</Span>
                </Text>

                <FlexRow className="justify-between items-center my-2 w-full">
                  <Div className="w-1/5">
                    <Text className="text-secondary-100 text-xl text-center font-bold">
                      295
                    </Text>
                    <Text className="text-secondary-100 text-center text-sm">
                      No. of Supplies Delivered
                    </Text>
                    <Text className="text-secondary-200 text-center text-xs my-2">
                      as of 01/01/2023
                    </Text>
                  </Div>
                  <Div className="w-1/5">
                    <Text className="text-secondary-100 text-xl text-center font-bold">
                      95
                    </Text>
                    <Text className="text-secondary-100 text-center text-sm">
                      No. of Supplies Consumed
                    </Text>
                    <Text className="text-secondary-200 text-center text-xs my-2">
                      as of 01/01/2023
                    </Text>
                  </Div>
                  <Div className="w-1/5">
                    <Text className="text-secondary-100 text-xl text-center font-bold">
                      232
                    </Text>
                    <Text className="text-secondary-100 text-center text-sm">
                      No. of Supplies Remaining
                    </Text>
                    <Text className="text-secondary-200 text-center text-xs my-2">
                      as of 01/01/2023
                    </Text>
                  </Div>
                  <Div className="w-1/5">
                    <Text className="text-green-100 text-xl text-center font-bold">
                      95%
                    </Text>
                    <Text className="text-secondary-100 text-center text-sm">
                      % Supplies Remaining
                    </Text>
                    <Text className="text-secondary-200 text-center text-xs my-2">
                      as of 01/01/2023
                    </Text>
                  </Div>
                </FlexRow>
              </Div>
            )*/}

            {/*<Spacer className="h-5" />*/}

            <Text className="text-secondary-200 text-left font-bold">
              CHOOSE PACKAGING
            </Text>

            <Div className="w-full overflow-x-scroll p-2">
              <FlexRow className="w-min py-2">
                {/* {tempLBCPackage.map((item) => (
                  <PackageItem
                    className="w-200px mx-2"
                    isSelected={selectedItems.includes(item)}
                    data={item}
                    onClick={onSelectItems}
                    list={addedItems}
                  />
                ))} */}
                {pkgData.map((item, key) => (
                  <CorpPortalAcctPackageItem
                    key={key}
                    className="w-200px mx-2"
                    isSelected={selectedItems.includes(item)}
                    data={item}
                    onClick={onSelectItems}
                    isCM={true}
                    disabled={isItemAdded(item.prodId)}
                  />
                ))}
              </FlexRow>
            </Div>

            <Spacer className="h-5" />

            <FlexRow className="w-full items-center justify-center py-2">
              {selectedItems.length > 0 ? (
                <Button className="w-40 ml-2 h-10" onClick={onAddItems}>
                  Add to Bag
                </Button>
              ) : (
                <Button className="w-40 ml-2 h-10" disabled={true}>
                  Add to Bag
                </Button>
              )}
            </FlexRow>
          </Div>

          {/* Second Body */}
          <Div className="border-l border-grey-400 w-1/3 h-600px p-5">
            <FlexColumn className="p-5 w-full h-full items-center justify-between">
              <Div className="w-full">
                <SuppliesReceiverInfo
                  loading={loadingRD}
                  data={receiverData}
                  shipperAcctNo={shipperAcctNo}
                  onSelectAddress={onSelectAddress}
                  isValidReceiverData={isValidReceiverData}
                />

                <Text className="text-secondary-100 text-center font-bold my-5">
                  MY SUPPLIES BAG
                </Text>

                {addedItems.length > 0 ? (
                  <Div className="w-full">
                    {addedItems.map((item, index) => (
                      /*<RequestItems
                        className="w-200px mx-2"
                        isSelected={selectedItems.includes(item)}
                        data={item}
                        onClick={onSelectItems}
                      />*/
                      <FlexRow className="w-full items-center justify-between py-2">
                        <RawButton onClick={() => deleteBin(item)}>
                          <RiDeleteBin6Line className="text-red-400 text-xl ml-2" />
                        </RawButton>
                        <Text className="text-secondary-100 break-words">
                          {item.prodDisplayName}
                        </Text>
                        <FlexRow className="w-2/5 items-center justify-around">
                          <RawButton
                            className="text-red-400 font-bold border-2 border-red-400 rounded-lg mx-1 w-8 h-8 flex items-center justify-center hover:bg-red-300 hover:text-white"
                            onClick={() => updateCount(item, index, false)}
                          >
                            <FaMinus />
                          </RawButton>
                          {/*<Text className="text-secondary-100 px-2">{newCount}</Text>*/}
                          {/*<RawInput
                            className="text-secondary-100 px-2"
                            containerClass="w-1/3 pr-2"
                            name="newCount"
                            placeholder="Quantity"
                            type="text"
                            value={newCount}
                            onChange={(value) => updateCountOnInput(value)}
                          />*/}
                          <Div className="w-14">
                            <input
                              className={classNames(
                                `border ${"border-grey-400"} rounded-lg block w-full`,
                                "text-secondary-100 px-2 text-center"
                              )}
                              id="newCount"
                              name="newCount"
                              placeholder="Quantity"
                              value={item.quantity}
                              type="text"
                              onChange={(event) =>
                                updateCountOnInput(
                                  item,
                                  index,
                                  event.target.value
                                )
                              }
                              maxLength={3}
                              autoComplete="off"
                            />
                          </Div>
                          {item.quantity < 100 ? (
                            <RawButton
                              className="text-red-400 font-bold border-2 border-red-400 rounded-lg mx-1 w-8 h-8 flex items-center justify-center hover:bg-red-300 hover:text-white"
                              onClick={() => updateCount(item, index, true)}
                            >
                              <FaPlus />
                            </RawButton>
                          ) : (
                            <RawButton className="text-grey-400 font-bold border-2 border-grey-300 rounded-lg mx-1 w-8 h-8 flex items-center justify-center">
                              <FaPlus />
                            </RawButton>
                          )}
                        </FlexRow>
                      </FlexRow>
                    ))}
                  </Div>
                ) : (
                  <Div className="w-full">
                    <Image
                      alt="LBC Bag"
                      className="my-5 mx-auto"
                      src={Images.lbcBag}
                    />

                    <Text className="text-secondary-200 text-center text-sm">
                      Your bag is empty
                    </Text>
                    <Text className="text-secondary-200 text-center text-sm">
                      Add packaging from the list
                    </Text>

                    <Spacer className="h-16 hidden" />
                  </Div>
                )}
              </Div>

              <FlexRow className="w-full items-center justify-center">
                {receiverData &&
                addedItems.length > 0 &&
                isValidReceiverData ? (
                  <Button
                    className="w-4/5 ml-2 h-10 phone:rounded-full"
                    isLoading={loading}
                    onClick={onSubmit}
                  >
                    Submit Request
                  </Button>
                ) : (
                  <Button
                    className="w-4/5 ml-2 h-10 phone:rounded-full phone:bg-grey-400 cursor-no-drop"
                    isLoading={loading}
                    disabled={true}
                  >
                    Submit Request
                  </Button>
                )}
              </FlexRow>
            </FlexColumn>
          </Div>
        </FlexRow>
      )}
    </>
  );
};
