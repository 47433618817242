export const UserDrawers = [
  {
    name: "USER 1",
    links: [
      {
        name: "Company Account 1",
        url: "/profile/account1",
      },
      {
        name: "Company Account 2",
        url: "/profile/account2",
      },
      {
        name: "Log Out",
        url: "/logout",
      },
    ],
  },
];

export const XtendlyDrawers = [
  {
    name: "USER 1",
    links: [
      {
        name: "Xtendly",
        url: "/profile/xtendly",
      },
      {
        name: "Log Out",
        url: "/logout",
      },
    ],
  },
];

export const AnalyticsDeliveryDrawers = [
  {
    name: "USER 1",
    links: [
      {
        name: "Delivery Report",
        // url: '/dashboard-home/delivery-status-report',//'/dashboard-home?status=delivery-status',
        url: "/reports?option=1",
        filter: [
          {
            name: "In-transit",
            url: "in-transit",
          },
          {
            name: "For Disposition",
            url: "for-disposition",
          },
          {
            name: "Delivered",
            url: "delivered",
          },
          {
            name: "Returned",
            url: "returned",
          },
          {
            name: "Others",
            url: "others",
          },
          {
            name: "Make Default",
            url: "default-delivery-status",
          },
        ],
      },
      {
        name: "Remittance Report",
        // url: '/dashboard-home/remittance-status-report',//'/dashboard-home?status=remittance-status',
        url: "/reports?option=2",
        filter: [
          {
            name: "Total COD Amount",
            url: "total-cod-amount",
          },
          {
            name: "Amount Remitted",
            url: "amount-remitted",
          },
          {
            name: "For Remittance",
            url: "for-remittance",
          },
          {
            name: "Make Default",
            url: "default-remittance",
          },
        ],
      },
    ],
  },
];

export const DataSort = [
  {
    name: "DATE",
    list: [
      {
        name: "Ascending",
        link: "ascending",
      },
      {
        name: "Descending",
        link: "descending",
      },
    ],
  },
];

export const DataSortStatus1 = [
  {
    name: "Status 1",
    list: [
      {
        name: "Read",
        link: "read",
      },
      {
        name: "Unread",
        link: "unread",
      },
      {
        name: "Default",
        link: "default",
      },
    ],
  },
];

export const BookNowOptions = [
  {
    id: "bookNow",
    name: "Book Now",
    url: "/book-now?option=0",
    tabIndex: 0,
  },
  {
    id: "bookNowPUpSched",
    name: "Pick Up Schedules",
    url: "/book-now?option=1",
    tabIndex: 1,
  },
  {
    id: "bookNowReqSupplies",
    name: "Request Supplies",
    url: "/book-now?option=2",
    tabIndex: 2,
  },
];

export const ActivityOptions = [
  {
    id: "activityOLBookings",
    name: "Online Bookings",
    url: "/activity",
    tabIndex: 0,
  },
  {
    id: "activityAllTrans",
    name: "All Transactions",
    url: "/transaction",
    tabIndex: 1,
  },
];

export const ToolsOptions = {
  tools1: [
    {
      name: "Address Book",
      url: "/tools/address-book",
    },
    {
      name: "Nearby Branches",
      url: "/tools/nearby-branches",
    },
    {
      name: "Prohibited Items",
      url: "/tools/prohibited-items",
    },
  ],
  tools2: [
    {
      name: "Delivery Zones",
      url: "/tools/delivery-zones",
    },
    {
      name: "Delivery Lead Time",
      url: "/tools/delivery-lead-time",
    },
    {
      name: "Customer Support",
      url: "/tools/customer-support",
    },
  ],
  tools3: [
    {
      name: "Terms & Conditions",
      url: "/tools/terms-conditions",
    },
    {
      name: "Products & Services",
      url: "/tools/products-services",
    },
    {
      name: "Send Tipid",
      url: "/tools/send-tipid",
    },
  ],
};

export const RecurrenceDeliveryPerformanceOptions = [
  {
    name: "Recurrence",
    links: [
      {
        name: "Monthly",
        url: "/book-now?recurrence=2",
      },
    ],
  },
];

export const RecurrenceOptions = [
  {
    name: "Recurrence",
    links: [
      {
        name: "Daily",
        url: "/book-now?recurrence=0",
      },
      {
        name: "Weekly",
        url: "/book-now?recurrence=1",
      },
      {
        name: "Monthly",
        url: "/book-now?recurrence=2",
      },
    ],
  },
];

export const RecurrenceOptionsStatusReport = [
  {
    name: "Recurrence",
    links: [
      // {
      //   name: 'Daily',
      //   url: '/book-now?recurrence=0',
      // },
      // {
      //   name: 'Weekly',
      //   url: '/book-now?recurrence=1',
      // },
      {
        name: "Monthly",
        url: "/book-now?recurrence=2",
      },
    ],
  },
];

export const RecurrenceOptionsDelivery = [
  {
    name: "Recurrence",
    links: [
      {
        name: "Daily",
        url: "/book-now?recurrence=0",
      },
      // {
      //   name: 'Weekly',
      //   url: '/book-now?recurrence=1',
      // },
      {
        name: "Monthly",
        url: "/book-now?recurrence=2",
      },
    ],
  },
];

export const RecurrenceOptionsRemittance = [
  {
    name: "Recurrence",
    links: [
      {
        name: "Daily",
        url: "/book-now?recurrence=0",
      },
      // {
      //   name: 'Weekly',
      //   url: '/book-now?recurrence=1',
      // },
      {
        name: "Monthly",
        url: "/book-now?recurrence=2",
      },
    ],
  },
];

export const FilterOptionsRemittance = [
  {
    name: "Recurrence",
    links: [
      {
        name: "Transaction Date",
        url: "/book-now?recurrence=0",
      },
      {
        name: "Delivered Date",
        url: "/book-now?recurrence=1",
      },
      {
        name: "Remitted Date",
        url: "/book-now?recurrence=2",
      },
    ],
  },
];

export const RecurrenceCreateOptions = [
  {
    name: "Recurrence",
    links: [
      {
        name: "Daily",
        url: "/book-now?recurrence=0",
      },
      {
        name: "Weekly",
        url: "/book-now?recurrence=1",
      },
      {
        name: "Monthly",
        url: "/book-now?recurrence=2",
      },
    ],
  },
];

export const ShipmentOptionsStatusReport = [
  {
    name: 'Recurrence',
    links: [
       {
         name: 'Transaction Date',
         url: '/book-now?recurrence=0',
       },
       {
         name: 'Last Status Date',
         url: '/book-now?recurrence=1',
       }
    ],
  },
];

export const Weekly = [
  {
    abb: "M",
    value: "Mon",
  },
  {
    abb: "T",
    value: "Tue",
  },
  {
    abb: "W",
    value: "Wed",
  },
  {
    abb: "Th",
    value: "Thu",
  },
  {
    abb: "F",
    value: "Fri",
  },
  {
    abb: "S",
    value: "Sat",
  },
  {
    abb: "Su",
    value: "Sun",
  },
];
