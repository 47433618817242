import React from "react";
import { Div, FlexColumn, FlexRow } from "../core/Containers";
import { Text } from "../core/Text";
import { RawButton } from "../core/Buttons";
import { FaRegEdit } from "react-icons/fa";
import { MdLocationPin } from "react-icons/md";
import { ImSpinner2 } from "react-icons/im";
import { SuppliesReceiverInfoModal } from "./Modals";
import { AddressBookModal } from "./Modals/AddressBookModal";
import { Images } from "../assets/images/images";

export const SuppliesReceiverInfo = ({
  loading,
  className,
  data,
  shipperAcctNo,
  onSelectAddress,
  isValidReceiverData,
}) => {
  const [isToastOpenA, setToastOpenA] = React.useState(false);
  const [isABMOpen, setIsABMOpen] = React.useState(false);

  return (
    <>
      {/* <SuppliesReceiverInfoModal
        data={data}
        isOpen={isToastOpenA}
        onClose={() => setToastOpenA(false)}
      /> */}

      {isABMOpen && (
        <AddressBookModal
          isOpen={isABMOpen}
          onClose={() => setIsABMOpen(false)}
          data={{ customerAccountNo: shipperAcctNo }}
          onSelect={onSelectAddress}
        />
      )}

      <FlexColumn
        className={`p-5 items-center justify-center w-full border ${
          isValidReceiverData ? "border-grey-400" : "border-red-100"
        } rounded-lg ${className}`}
      >
        <FlexRow className="w-full">
          <Text className="text-secondary-100 text-sm font-bold">
            Receiver Details:
          </Text>
          {/* <RawButton className="ml-auto" onClick={() => setToastOpenA(true)}>
            <FaRegEdit className="text-red-400 text-xl ml-2" />
          </RawButton> */}
          <RawButton className="ml-auto" onClick={() => setToastOpenA(true)}>
            <img
              className="w-6 h-5 cursor-pointer"
              src={Images.addressBook1}
              onClick={() => setIsABMOpen(true)}
            />
          </RawButton>
        </FlexRow>
        {loading ? (
          <Text className="text-red-400 text-center flex flex-row justify-center items-center my-10">
            <ImSpinner2 className="animate-spin mr-2 text-2xl desktop:text-3xl" />
            Loading data ...
          </Text>
        ) : (
          <FlexRow className="w-full items-center justify-between">
            {data && (
              <>
                <MdLocationPin className="text-red-400" />
                <Div className="w-11/12">
                  <Text className="text-secondary-200 text-sm text-left font-bold">
                    {data && data.contactPerson}
                  </Text>
                  <Text className="text-secondary-200 text-sm text-left">
                    {`+63 ${data && data.mobileNo}`}
                  </Text>
                  <Text className="text-secondary-200 text-sm text-left">
                    {data && data.pickUpAddress}
                  </Text>
                  <Text className="text-secondary-200 text-sm text-left">
                    {data &&
                      `${data.brgyName}, ${data.cityName}, ${data.provName}`}
                  </Text>
                </Div>
              </>
            )}
          </FlexRow>
        )}
      </FlexColumn>
      {!isValidReceiverData && (
        <p className="mt-1 pl-2 text-xs text-left text-red-400 dark:text-red-400">
          Please check customer data and try again.
        </p>
      )}
    </>
  );
};
