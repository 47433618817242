import axios from "axios";

let appConfig = require("./app-config");
const env = process.env.BUILD_ENV
  ? process.env.BUILD_ENV
  : process.env.NODE_ENV;
appConfig = appConfig[env];

// ----------------------------------------------------------------------

const httpBase = axios.create({
  baseURL: appConfig.api.baseUrl,
  headers: {
    "Content-type": "application/json",
    lbcOAkey: appConfig.api.lbcOAkey,
  },
});

const httpCommonRef = axios.create({
  baseURL: appConfig.api.lbccommonrefv2Url,
  headers: {
    "Content-type": "application/json",
    lbcOAkey: appConfig.api.lbccommonrefv2LbcOAkey,
  },
});

const httpLexaApi = axios.create({
  baseURL: appConfig.api.lexaapiUrl,
  headers: {
    "Content-type": "application/json",
    token: appConfig.api.lexaapiToken,
    lbcOAkey: appConfig.api.lexaapiLBCOAkey,
  },
});

const httpTrackingApi = axios.create({
  baseURL: appConfig.api.trackingUrl,
  headers: {
    "Content-type": "text/xml",
    lbcOAkey: appConfig.api.trackingLBCOAkey,
  },
});

export { httpBase, httpCommonRef, httpLexaApi, httpTrackingApi };
