import { Cookies } from "react-cookie";
import { httpBase } from "../http-common";

const cookies = new Cookies();

class AuthService {
  authenticate(data) {
    return httpBase.post("/api/Authenticate", data);
  }

  logout(data) {
    return httpBase.post("/api/Logout", data);
  }

  validateToken() {
    const token = cookies.get("accessToken");
    return httpBase.post("/api/ValidateToken", token);
  }

  refreshToken() {
    const token = cookies.get("accessToken");
    return httpBase.post("/api/RefreshToken", token);
  }

  revokeRefreshToken() {
    const token = cookies.get("accessToken");
    const config = {
      headers: {
        Authorization: `Bearer ${token.accessToken}`,
      },
    };

    return httpBase.post("/api/RevokeRefreshToken", token, config);
  }
}

export default new AuthService();
