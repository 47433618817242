import React from "react";
import { Div, FlexRow } from "../../core/Containers";
import { Text } from "../../core/Text";
import moment from "moment";

export const TransactionDataRow = ({
  data,
  onSelect,
  diableDelete,
  diableEdit,
}) => {
  return (
    <>
      <FlexRow className="items-start justify-between w-full py-2 border-l-8 border-l-white hover:border-l-red-400 hover:bg-grey-400">
        <Text className="text-red-400 text-xs font-semibold text-left w-40 px-2">
          {data.trackingNo}
        </Text>
        <Text className="text-secondary-100 text-xs text-left w-1/6 px-2">
          {data.consigneeName}
        </Text>
        <Text className="text-secondary-100 text-xs text-left w-1/6 px-2">
          {data.consigneeAddress || ""}
        </Text>
        <Text className="text-secondary-100 text-xs text-left w-32 px-2">
          {data.product}
        </Text>
        <Text className="text-secondary-100 text-xs text-left w-32 px-2">
          {data.transactionDate !== ""
            ? moment(data.transactionDate).format("MMM. D, YYYY")
            : ""}
        </Text>
        <Text className="text-secondary-100 text-xs text-left w-1/6 px-2">
          {data.lastStatus}
        </Text>
      </FlexRow>
    </>
  );
};
